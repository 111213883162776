import React, { useState } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { classNames } from '../../utils/misc'
import { MessageBubble } from '../misc/message-bubble'

interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    to: string
    title: string
    iconPath?: string
    isDepartmentTab?: boolean
    iconPathInactive?: string
    chatNumber?: number
}

export const SideBarLink: React.FC<LinkProps> = ({
    to,
    title,
    iconPath,
    iconPathInactive,
    chatNumber,
    onClick,
}) => {
    const active = useMatch(to)
    const [isHovered, setIsHovered] = useState(false)

    return (
        <Link
            to={to}
            onClick={onClick}
            className={classNames(
                'border-b-[1px] border-none relative',
                'flex items-center px-4 mx-4 my-[6px]',
                'font-normal w-[273px] h-[50px]',
                'text-[#002b38]',
                'hover:rounded-[8px] hover:ml-4 hover:bg-[#f9fbfc]',
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {active && (
                <div
                    className={classNames(
                        'rounded-[8px] bg-[#f9fbfc]',
                        'absolute inset-0 ',
                    )}
                ></div>
            )}

            {iconPathInactive && (
                <div className={classNames('p-1 rounded-md !w-7 z-10')}>
                    <img
                        src={active || isHovered ? iconPath : iconPathInactive}
                        className={classNames('!w-6 !h-6')}
                        alt="Icon for link"
                    />
                </div>
            )}

            <div
                className={classNames(
                    'relative z-10 ml-2 text-[19px] text-decoration-none',
                )}
            >
                {title}
            </div>

            {chatNumber && chatNumber !== 0 ? (
                <MessageBubble count={chatNumber} />
            ) : null}
        </Link>
    )
}
