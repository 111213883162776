/** @jsxImportSource @emotion/react */
import React, { ReactElement, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

import { MachineContext } from '../../stores/bookingMachine'
import { Action } from '../../stores/bookingMachine/config'
import heartIcon from '../../assets/icons/heart.svg'
import fullStarIcon from '../../assets/icons/star-full.svg'
import emptyStarIcon from '../../assets/icons/star-empty.svg'

import Popup from './Popup'

export default function RatingPrompt({
    when,
}: {
    when: boolean
}): ReactElement {
    const { t } = useTranslation()
    const [, send] = useContext(MachineContext)
    const [rating, setRating] = useState(5)
    const [hoverRating, setHoverRating] = useState(0)
    const stars = Array.from(new Array(5))
    const starClass = css({
        display: 'inline-block',
        width: '35px',
        height: '35px',
        marginRight: '10px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        cursor: 'pointer',
    })

    return (
        <Popup
            when={when}
            icon={heartIcon.toString()}
            title={t('RatingPrompt.title')}
        >
            <div>
                <div onMouseLeave={() => setHoverRating(0)}>
                    {stars.map((val, i) => (
                        <span
                            key={i}
                            css={starClass}
                            style={{
                                backgroundImage: `url(${
                                    (hoverRating !== 0 ? hoverRating : rating) >
                                    i
                                        ? fullStarIcon
                                        : emptyStarIcon
                                })`,
                            }}
                            onClick={() => setRating(i + 1)}
                            onMouseEnter={() => setHoverRating(i + 1)}
                        />
                    ))}
                </div>
                <button
                    onClick={() =>
                        send({ type: Action.rateCoach, payload: { rating } })
                    }
                >
                    {t('RatingPrompt.submit')}
                </button>
            </div>
        </Popup>
    )
}
