import * as Sentry from '@sentry/browser'
import { stores } from '../utils/stores'

export const SentryInit = async () => {
    Sentry.init({
        dsn: 'https://ffe22039e9734317ae342418f496eedb@o784619.ingest.sentry.io/5799157',
        integrations: [Sentry.browserTracingIntegration()],
        environment: import.meta.env.VITE_APP_NODE_ENV,
        release: import.meta.env.VITE_APP_SENTRY_RELEASE_NAME ?? 'unknown',
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            'https://staging-api.sama.io',
            'https://api.sama.io',
        ],
    })
    if (stores.coachee.coachee) {
        Sentry.setUser({ id: stores.coachee.coachee._id })
    }
}
