import { IsISO8601, IsNotEmpty, validateSync } from 'class-validator'
import { addHours } from 'date-fns'
import { assign } from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { Observable, of, map as rxmap, switchMap, tap } from 'rxjs'
import { HttpMethod } from '../utils/constants/http'
import {
    Resettable,
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
    toBase64,
} from '../utils/misc'
import { dataURItoBlobString } from '../utils/misc/to-blog'
import { request } from '../utils/request'

const IMAGE_KEY = 'SAMA:IMAGE/'
const HOURS_TO_CACHE_IMG = 4

class CacheInformation {
    @IsNotEmpty()
    public data!: string

    @IsISO8601()
    public expires!: string
}

export class ImageStore implements Resettable {
    public picture: string | null = null
    public coachPicture: string | null = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    private getImage(
        url: string,
        type: string,
        id: string,
    ): Observable<string | null> {
        const pictureInformation = hydrateFromStorage<CacheInformation>(
            IMAGE_KEY + type + '/' + id,
        )

        const model = assign(new CacheInformation(), pictureInformation)

        if (pictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)

            if (now <= expiresAt) {
                return of(pictureInformation.data)
            }
        }
        return request<never, Blob>(url, HttpMethod.GET, {
            responseType: 'blob',
        }).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    runInAction(() => this.storeImage(type, id, image))
                }
            }),
        )
    }

    @action
    public storeImage(type: string, id: string, image: string) {
        dehydrateToStorage(IMAGE_KEY + type + '/' + id, {
            data: image,
            expires: addHours(new Date(), HOURS_TO_CACHE_IMG),
        })
    }

    @action
    public reset(): void {
        removeFromStorage(IMAGE_KEY)
    }

    @action
    public getCoachPicture(
        id: string,
        size: string = 'small',
    ): Observable<string | null> {
        return this.getImage(
            `/coach/${id}/picture?size=${size}`,
            'coach',
            id,
        ).pipe(
            tap((image) => {
                if (image) {
                    runInAction(() => {
                        this.coachPicture = dataURItoBlobString(image)
                    })
                }
            }),
        )
    }

    @action
    public getCoacheePicture(
        id: string,
        size: string = 'small',
    ): Observable<string | null> {
        return this.getImage(
            `/coachee/${id}/picture?size=${size}`,
            'coachee',
            id,
        ).pipe(
            tap((image) => {
                if (image) {
                    runInAction(() => {
                        this.picture = dataURItoBlobString(image)
                    })
                }
            }),
        )
    }

    @action
    public setPicture(image: string, id: string, type: string) {
        if (id) {
            this.picture = dataURItoBlobString(image)
            this.storeImage(type, id, image)
        }
    }

    @action
    public getCoachHistoryPicture(
        id: string,
        size: string = 'small',
    ): Observable<string | null> {
        const image = null
        return image
            ? image
            : this.getImage(`/coach/${id}/picture?size=${size}`, 'coach', id)
    }
}
