import { IsNotEmpty, ValidateIf } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class ChangeMyCoachModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public reasonCategory: string = ''

    @ValidateIf((object) => object.reasonCategory === 'other')
    @IsNotEmpty({
        message: t('validation.mustBeSet') as string,
    })
    public reason: string = ''

    constructor(reasonCategory = '', reason = '') {
        this.reasonCategory = reasonCategory
        this.reason = reason
    }

    public getRequestBody() {
        return {
            reasonCategory: this.reasonCategory,
            reason: this.reason,
        }
    }
}
