import { IsNotEmpty, IsString } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class EditGuestModel implements BaseModel {
    constructor(name: string) {
        this.name = name
    }

    @IsString()
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public name: string
}
