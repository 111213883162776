import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BannerImage from '../../../assets/icons/banner.png'
import SatisfactionsIcon from '../../../assets/icons/satisfactions-icon.svg'
import AppleIconFr from '../../../assets/icons/store-download-button-apple-fr.svg'
import AppleIcon from '../../../assets/icons/store-download-button-apple.svg'
import GoogleIconFr from '../../../assets/icons/store-download-button-google-fr.svg'
import GoogleIcon from '../../../assets/icons/store-download-button-google.svg'
import TopValuesIcon from '../../../assets/icons/top-values-icon.svg'
import { CenterModal } from '../../../components/modals'
import { classNames } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { OutcomeAssessmentModel } from '../../assessments/models/outcome'
import { RateSatisfactionStage } from '../../onboarding/stages/rate-satisfaction.stage'
import { TopValuesStage } from '../../onboarding/stages/top-values'

interface CardProps {
    title: string
    subtitle: string
    img?: string
    component?: React.ReactNode
    imageOverflowVisible?: boolean
    onClick?: () => void
    className?: string
    cursorPointer?: boolean
    banner?: boolean
    appleImage?: string
    googleImage?: string
    isAssessmentStatsEmptyOrIncomplete?: boolean
}

interface DonuntProps {
    progress: number
    centerText: string
    bgColour?: string
}

const DonutProgressBar: React.FC<DonuntProps> = observer(
    ({ progress, centerText, bgColour }) => {
        if (!progress) {
            progress = 0
        }

        const radius = 40
        const circumference = 2 * Math.PI * radius
        const offset = circumference - (progress / 100) * circumference
        return (
            <div
                className={classNames(
                    'flex justify-end items-center relative pr-5',
                    'w-full rounded-[12px] h-[25vh] min-h-[70px]',
                    bgColour ? bgColour : 'bg-white',
                )}
            >
                <svg
                    className="donut"
                    width="80%"
                    height="80%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 100 100"
                    style={{ transform: 'rotate(-90deg)' }}
                >
                    <circle
                        className="donut-hole"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="none"
                    ></circle>
                    <circle
                        className="donut-ring"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#a13636"
                        strokeWidth="10"
                    ></circle>
                    <circle
                        className="donut-segment"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        stroke="#236978"
                        strokeWidth="10"
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={offset}
                    ></circle>
                    <text
                        x="50"
                        y="50"
                        transform="rotate(90 50 50)"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="centerText"
                    >
                        {centerText}
                    </text>
                </svg>
            </div>
        )
    },
)

const Card: React.FC<CardProps> = observer(
    ({
        title,
        subtitle,
        img,
        component,
        imageOverflowVisible,
        onClick,
        className,
        cursorPointer = true,
        banner = false,
        appleImage,
        googleImage,
        isAssessmentStatsEmptyOrIncomplete,
    }) => {
        const { t } = useTranslation()
        return (
            <div
                className={classNames(
                    className,
                    'flex flex-row justify-between xl:items-start items-center relative',
                    'rounded-[12px] h-[25vh] min-h-[70px] shadow',
                    cursorPointer ? 'cursor-pointer' : '',
                )}
                onClick={onClick}
            >
                <div
                    className={classNames(
                        'ml-5 z-0 w-3/4',
                        banner ? 'xl:mt-6' : 'xl:mt-7',
                    )}
                >
                    <div
                        className={classNames(
                            banner
                                ? 'text-[16px] lg:text-[19px] xl:text-[26px] text-transparent bg-clip-text bg-gradient-to-l from-[#ff7154] via-[#207183] to-[#17c1e4]'
                                : 'text-[10px] xl:text-[17px] text-[#002b38]',
                            isAssessmentStatsEmptyOrIncomplete && banner
                                ? '2xl:text-[16px]'
                                : '',
                        )}
                    >
                        {title}
                    </div>
                    <div
                        className={classNames(
                            'text-[#10123a] leading-[1] mt-1',
                            banner
                                ? 'text-[11px] xl:text-[17px]'
                                : 'text-[18px] xl:text-[29px]',
                            isAssessmentStatsEmptyOrIncomplete && banner
                                ? '2xl:text-[14px]'
                                : '',
                        )}
                    >
                        {subtitle}
                    </div>

                    {banner && (
                        <div className="flex space-x-2 mt-3">
                            <a
                                href={t('assessments.banner.appleLink')}
                                target="_blank"
                                className="block text-inherit no-underline"
                            >
                                <img
                                    src={appleImage}
                                    className="w-[120px] h-[40px]"
                                />
                            </a>
                            <a
                                href={t('assessments.banner.googleLink')}
                                target="_blank"
                                className="block text-inherit no-underline"
                            >
                                <img
                                    src={googleImage}
                                    className="w-[120px] h-[40px]"
                                />
                            </a>
                        </div>
                    )}
                </div>
                <div className="h-full w-1/2 z-40 relative ">
                    {component && component}
                    {img && (
                        <img
                            className={classNames(
                                'z-50 object-cover',
                                banner
                                    ? 'absolute bottom-0 right-0 sm:overflow-y-visible'
                                    : 'relative',
                                imageOverflowVisible
                                    ? 'lg:overflow-visible'
                                    : '',
                                banner
                                    ? 'lg:w-[10vw] md:w-[15vw] sm:w-[16vw] h-[27vh] w-[26vw]'
                                    : 'h-full w-full object-right',
                            )}
                            src={img}
                        />
                    )}
                </div>
            </div>
        )
    },
)

interface SatisfactionModelProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const SatisfactionModel: React.FC<SatisfactionModelProps> = observer(
    ({ isOpen, setIsOpen }) => {
        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[36rem] h-full overflow-y-scroll pt-2"
                showCloseButton={true}
            >
                <div className="h-[200px] px-10 ">
                    <RateSatisfactionStage
                        showBackArrow={false}
                        buttonTranslationKey="buttons.save"
                        formClassNames="pb-[130px] max-w-none"
                        onComplete={() => setIsOpen(false)}
                        showMainError={false}
                    />
                </div>
            </CenterModal>
        )
    },
)

const TopValuesModel: React.FC<SatisfactionModelProps> = observer(
    ({ isOpen, setIsOpen }) => {
        const { coachee } = useStores()
        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[36rem] h-full overflow-y-scroll pt-2"
                showCloseButton={true}
            >
                <div className="h-[200px] px-10 ">
                    <TopValuesStage
                        showBackArrow={false}
                        buttonTranslationKey="buttons.save"
                        initialValue={coachee.coachee?.topValues}
                        formClassNames="pb-[130px] max-w-none"
                        onComplete={() => setIsOpen(false)}
                    />
                </div>
            </CenterModal>
        )
    },
)

export const AssessmentsView: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee } = useStores()

    const [isSatisfactionLevelsOpen, setOpenSatisfactionLevels] =
        useState(false)
    const [isTopValuesOpen, setTopValuesLevels] = useState(false)
    const [isSelfAssessmentQuestions, setOpenSelfAssessmentQuestions] =
        useState(false)

    const isAssessmentStatsEmptyOrIncomplete =
        !coachee?.assessmentsStats ||
        coachee?.assessmentsStats.completedAssessment === 0

    const getStats = useCallback(() => {
        const subscription = coachee?.getOAStats()?.subscribe()

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    useEffect(() => {
        getStats()
    }, [coachee, getStats])

    const closeAssessmentQuestions = useCallback(() => {
        getStats()
        setOpenSelfAssessmentQuestions(false)
    }, [getStats])

    const getDonutTest = useCallback(() => {
        const totalNumOfQuestions =
            (coachee?.assessmentsStats?.notAnsweredQuestion ?? 5) +
            (coachee?.assessmentsStats?.completedQuestions ?? 0)
        if (coachee?.assessmentsStats?.uncompletedForm) {
            return (
                (coachee?.assessmentsStats?.completedQuestions ?? 5) +
                ' / ' +
                totalNumOfQuestions
            )
        } else {
            return '5 / 5'
        }
    }, [coachee?.assessmentsStats])

    const getDonutProgress = useCallback(() => {
        if (coachee?.assessmentsStats?.uncompletedForm) {
            const totalNumOfQuestions =
                (coachee?.assessmentsStats?.notAnsweredQuestion ?? 5) +
                (coachee?.assessmentsStats?.completedQuestions ?? 0)
            const result =
                ((coachee?.assessmentsStats?.completedQuestions ??
                    coachee?.assessmentsStats?.notAnsweredQuestion ??
                    5) /
                    totalNumOfQuestions) *
                100
            return result
        } else {
            return 100
        }
    }, [
        coachee?.assessmentsStats?.completedQuestions,
        coachee?.assessmentsStats?.notAnsweredQuestion,
        coachee?.assessmentsStats?.uncompletedForm,
    ])

    return (
        <div
            id="assessments-view"
            className={classNames(
                'grid gap-5 grid-cols-1 lg:grid-cols-2 m-auto w-full',
                isAssessmentStatsEmptyOrIncomplete ? '2xl:grid-cols-3' : '',
            )}
        >
            {coachee?.assessmentsStats &&
                coachee?.assessmentsStats.completedAssessment !== 0 && (
                    <>
                        <Card
                            title={t('assessments.selfAssessment.title')}
                            subtitle={
                                getDonutProgress() === 100
                                    ? t('assessments.selfAssessment.subtitle')
                                    : t(
                                          'assessments.selfAssessment.subtitleIncomplete',
                                      )
                            }
                            component={
                                <DonutProgressBar
                                    progress={getDonutProgress()}
                                    centerText={getDonutTest()}
                                    bgColour="bg-[#fff5f3]"
                                />
                            }
                            className={classNames('bg-[#fff5f3]')}
                            onClick={() =>
                                getDonutProgress() === 100
                                    ? undefined
                                    : setOpenSelfAssessmentQuestions(true)
                            }
                            cursorPointer={
                                getDonutProgress() === 100 ? false : true
                            }
                        />
                        <OutcomeAssessmentModel
                            isOpen={isSelfAssessmentQuestions}
                            setIsOpen={closeAssessmentQuestions}
                        />
                    </>
                )}

            <Card
                title={t('assessments.satisfactionLevels.title')}
                subtitle={t('assessments.satisfactionLevels.subtitle')}
                img={SatisfactionsIcon}
                imageOverflowVisible={true}
                onClick={() => setOpenSatisfactionLevels(true)}
                className="bg-[#fff8eb]"
            />
            <SatisfactionModel
                isOpen={isSatisfactionLevelsOpen}
                setIsOpen={setOpenSatisfactionLevels}
            />

            <Card
                title={t('assessments.topValues.title')}
                subtitle={t('assessments.topValues.subtitle')}
                img={TopValuesIcon}
                onClick={() => setTopValuesLevels(true)}
                className="bg-[#ebf8f8]"
            />
            <TopValuesModel
                isOpen={isTopValuesOpen}
                setIsOpen={setTopValuesLevels}
            />

            <Card
                title={t('assessments.banner.title')}
                subtitle={t('assessments.banner.subtitle')}
                img={BannerImage}
                className="bg-[#fff]"
                banner={true}
                cursorPointer={false}
                appleImage={
                    coachee.coachee?.user?.appLanguage === 'en'
                        ? AppleIcon
                        : AppleIconFr
                }
                googleImage={
                    coachee.coachee?.user?.appLanguage === 'en'
                        ? GoogleIcon
                        : GoogleIconFr
                }
                isAssessmentStatsEmptyOrIncomplete={
                    isAssessmentStatsEmptyOrIncomplete
                }
            />
        </div>
    )
})
