import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddTopValuesModel } from '../../../models/request/onboarding/add-top-values'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage?: Stage
    showBackArrow?: boolean
    buttonTranslationKey?: string
    initialValue?: string[]
    formClassNames?: string
    onComplete?: () => void
}

export const TopValuesStage: React.FC<Props> = observer(
    ({
        currentStage,
        showBackArrow,
        buttonTranslationKey,
        initialValue,
        formClassNames,
        onComplete,
    }) => {
        const { t } = useTranslation()
        const { statics, coachee } = useStores()
        const [subscriptions] = useState<Subscription[]>([])

        const getInitialValues = useCallback(() => {
            return new AddTopValuesModel(initialValue)
        }, [initialValue])

        const setValues = useCallback(
            (
                values: AddTopValuesModel,
                helpers: FormikHelpers<AddTopValuesModel>,
            ) => {
                helpers.setSubmitting(true)

                cancelSubscriptions(subscriptions)
                const subscription = coachee.updateCoachee(values).subscribe({
                    next(response) {
                        if (response.ok) {
                            coachee.setOnboardingStage(Stage.SATISFACTION)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                        onComplete ? onComplete() : null
                        if (coachee.coachee) {
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.topValues = values.topValues
                            coachee.setCoachee(newCoachee)
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [coachee, onComplete, subscriptions],
        )

        return (
            <QuestionForm
                title={t('onboarding.topValues.title')}
                subTitle={t('onboarding.topValues.subTitle')}
                className={formClassNames}
                currentNumber={currentStage}
                totalNumber={
                    coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
                }
                questions={statics.topValues}
                name="topValues"
                maxSelectedOptions={5}
                model={getInitialValues()}
                onSubmit={setValues}
                showBackArrow={showBackArrow}
                buttonTranslationKey={buttonTranslationKey}
                canSkip={true}
                isOnboarding={true}
                onPrevious={() =>
                    coachee.setOnboardingStage(
                        coachee.onboardingStage.stage - 1,
                    )
                }
            />
        )
    },
)
