import { useField } from 'formik'
import { HTMLMotionProps } from 'framer-motion'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'

import IconButton from '@mui/material/IconButton'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import './styles.css'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    label?: string
    validatePassword?: (value: string) => void
    disableValidationMessage?: boolean
}

export const PasswordInput: React.FC<Props> = ({
    name,
    label,
    className,
    validatePassword,
    disableValidationMessage = false,
    ...props
}) => {
    const [field, meta, helpers] = useField(name)
    const { t } = useTranslation()

    const [showPassword, setShowPassword] = React.useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const onChange = useCallback(
        (value: string) => {
            helpers.setValue(value)
        },
        [helpers],
    )

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[13px] mb-2">
                    {label}
                </label>
            )}

            <TextField
                value={field.value}
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                    onChange(e.target.value)
                    validatePassword && validatePassword(e.target.value)
                }}
                InputProps={{
                    className: 'background-input',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                placeholder={props.placeholder}
                className="custom-input"
            />

            {!disableValidationMessage && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={t(meta.error[0])} />
                </div>
            )}
        </div>
    )
}
