import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddIndustryModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const IndustryStage: React.FC<Props> = observer(({ currentStage }) => {
    const { t } = useTranslation()
    const { statics, coachee } = useStores()
    const [subscriptions] = useState<Subscription[]>([])

    const setIndustry = useCallback(
        (
            values: AddIndustryModel,
            helpers: FormikHelpers<AddIndustryModel>,
        ) => {
            helpers.setSubmitting(true)

            cancelSubscriptions(subscriptions)

            const subscription = coachee.updateCoachee(values).subscribe({
                next(response) {
                    if (response.ok && coachee.coachee) {
                        coachee.setOnboardingStage(Stage.FUNCTION)
                        const newCoachee = { ...coachee.coachee }
                        newCoachee.industry = values.industry
                        coachee.setCoachee(newCoachee)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [coachee, subscriptions],
    )

    return (
        <QuestionForm
            title={t('onboarding.industry.title')}
            currentNumber={currentStage}
            totalNumber={
                coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
            }
            questions={statics.industries}
            name="industry"
            model={new AddIndustryModel(coachee.coachee?.industry)}
            onSubmit={setIndustry}
            isOnboarding={true}
            onPrevious={() =>
                coachee.setOnboardingStage(coachee.onboardingStage.stage - 1)
            }
        />
    )
})
