import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import LeftArrow from '../../assets/icons/arrow-left.svg'
import { classNames } from '../../utils/misc'
import { PrimaryButton } from './primary-button'

interface Props {
    onNext?: MouseEventHandler<HTMLButtonElement>
    onPrevious?: MouseEventHandler<HTMLImageElement>
    showArrow?: boolean
    buttonTranslationKey?: string
    disabled?: boolean
}

export const OnboardingButtons: React.FC<Props> = ({
    onNext,
    onPrevious,
    buttonTranslationKey,
    showArrow = true,
    disabled = false,
}) => {
    const { t } = useTranslation()
    return (
        <footer
            className={classNames(
                'flex',
                'max-w-[90%] md:max-w-[30rem]',
                'fixed inset-x-0 bottom-0',
                'mx-auto',
                'mb-16 md:mb-10',
                'bg-white p-5 rounded-[50px] shadow-lg',
            )}
        >
            {showArrow && onPrevious && (
                <div
                    onClick={onPrevious}
                    className={classNames(
                        'bg-[#f3f4f4] shrink-0 h-9 w-9 mr-3 mt-2',
                        'rounded-full flex cursor-pointer',
                        'justify-center items-center ',
                    )}
                >
                    <img src={LeftArrow} alt={t('alt.arrowLeft')} />
                </div>
            )}
            <PrimaryButton
                className={classNames('w-full text-[#a8eaf6]')}
                disabled={disabled}
                onClick={onNext}
            >
                <span>
                    {buttonTranslationKey
                        ? t(buttonTranslationKey)
                        : t('buttons.next')}
                </span>
            </PrimaryButton>
        </footer>
    )
}
