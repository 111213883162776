import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import Edit from '/src/assets/icons/edit.svg'
type Props = {
    label: string
    value?: string
    onEdit?: () => void
}

const SelectTab: React.FC<Props> = observer(({ label, value, onEdit }) => {
    return (
        <div>
            <div
                className={classNames(
                    'flex items-center justify-between h-[100px]',
                    'ml-0 mt-0 lg:mr-2 p-0 pt-0 lg:pr-9 pr-6 lg:pl-8 pl-2 rounded-lg',
                )}
            >
                <span
                    className={classNames(
                        'font-normal mb-2 lg:text-3xl text-xl pt-6 px-3 pb-6',
                        value ? 'text-primary' : 'text-[#a5b2b6]',
                    )}
                >
                    {label}
                </span>
                {value && (
                    <div
                        className={classNames(
                            'flex space-x-4 items-center justify-center mb-[20px]',
                            'w-auto lg:px-6 px-3 h-[56px] rounded-lg bg-white',
                        )}
                    >
                        <span className="text-[#002b38] lg:text-xl text-base">
                            {value}
                        </span>
                        <div>
                            <img
                                src={Edit}
                                alt="Icon edit"
                                className="cursor-pointer"
                                onClick={onEdit}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="h-[1px] flex-grow-0 lg:ml-10 ml-5 mr-[20px] mb-[20px] bg-[#d2d9da]"></div>
        </div>
    )
})

export default SelectTab
