import { LayoutGroup } from 'framer-motion'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ConfirmationModal } from '../../pages/dashboard/modals'
import { Goals } from '../../pages/goals/goals'
import { Note } from '../../pages/note/note'
import { Task } from '../../pages/task/task'
import { RouteLink } from '../../utils/constants'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { PrimaryButton } from '../buttons/primary-button'
import { SessionLeft } from '../misc/sessions-left'
import { SideBarLink } from './side-bar-link'
import SidebarModalLink from './side-bar-modal-link'
import AccountActive from '/src/assets/icons/Account-Active.svg'
import AccountInactive from '/src/assets/icons/Account-Inactive.svg'
import ChatActive from '/src/assets/icons/Chat-Active.svg'
import ChatInactive from '/src/assets/icons/Chat-Inactive.svg'
import GoalsActive from '/src/assets/icons/Goals-Active.svg'
import GoalsInactive from '/src/assets/icons/Goals-Inactive.svg'
import HomeActive from '/src/assets/icons/Home-Active.svg'
import HomeInactive from '/src/assets/icons/Home-Inactive.svg'
import LeaderboardActive from '/src/assets/icons/Leaderboard-Active.svg'
import LeaderboardInactive from '/src/assets/icons/Leaderboard-Inactive.svg'
import NotesActive from '/src/assets/icons/Notes-Active.svg'
import NotesInactive from '/src/assets/icons/Notes-Inactive.svg'
import TasksActive from '/src/assets/icons/Tasks-Active.svg'
import TasksInactive from '/src/assets/icons/Tasks-Inactive.svg'
import xIcon from '/src/assets/icons/grey-x-icon.svg'
import hamburgerIcon from '/src/assets/icons/hamburger.svg'
import Logo from '/src/assets/logo/logo-dark.svg'

export const Spacer: React.FC = observer(() => {
    return (
        <div className="w-[90%] h-[1px] flex-grow-0 m-auto bg-[#e9eced]"></div>
    )
})

const INNER_WIDTH = 768

export const SideBar: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth, coachee, channel } = useStores()
    const navigate = useNavigate()

    const location = useLocation()
    const isOnNewBookingPage = location.pathname === RouteLink.NEW_BOOKING

    const [openNoteList, setOpenNoteList] = useState(false)
    const [openTaskList, setOpenTaskList] = useState(false)
    const [openGoalsList, setOpenGoalsList] = useState(false)
    const [menuOpen, setMenuOpen] = useState(window.innerWidth > INNER_WIDTH)
    const [openLogout, setOpenLogout] = useState(false)

    useEffect(() => {
        const subscription = channel.getCoacheeChannel().subscribe({
            next(response) {
                if (response.data) {
                    channel.getChannelToken().subscribe({
                        next(response) {
                            if (response.data) {
                                channel.getChatClient()
                                channel.setupMessageListeners()
                            }
                        },
                    })
                }
            },
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [channel])

    function updateSize() {
        if (window.innerWidth < INNER_WIDTH) {
            setMenuOpen(false)
        } else {
            setMenuOpen(true)
        }
    }

    const openSenonderOptions = useCallback(
        (openFunction: (open: boolean) => void) => {
            openFunction(true)
            if (window.innerWidth < INNER_WIDTH) {
                setMenuOpen(false)
            }
        },
        [],
    )

    useEffect(() => {
        window.addEventListener('resize', updateSize)

        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return (
        <>
            <aside
                className={classNames(
                    'overflow-y-auto m-0 z-50 md:z-40',
                    'z-20 transition-all bg-white text-[14px] flex-shrink-0',
                    'fixed left-0',
                    menuOpen
                        ? 'md:relative h-full sm:max-w-[14rem] lg:max-w-[19rem]'
                        : 'h-[70px] w-[100%]',
                    'flex flex-col space-y-4',
                )}
            >
                <div
                    className={classNames(
                        'min-h-[50px]',
                        'px-4 pt-1 ml-2  flex items-center justify-between',
                    )}
                >
                    <Link to={RouteLink.DASHBOARD}>
                        <img src={Logo} alt={t('alt.logo')} className="h-9" />
                    </Link>
                    <img
                        className="md:hidden"
                        src={menuOpen ? xIcon : hamburgerIcon}
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                </div>
                <Spacer />
                {menuOpen && (
                    <>
                        <div
                            className={classNames(
                                'min-h-[240px]',
                                'w-full py-3 flex flex-col justify-between',
                            )}
                        >
                            <LayoutGroup>
                                <SideBarLink
                                    to={RouteLink.DASHBOARD}
                                    title={t('pageTitles.dashboard')}
                                    iconPath={HomeActive}
                                    iconPathInactive={HomeInactive}
                                />

                                <SideBarLink
                                    to={RouteLink.CHAT}
                                    title={t('pageTitles.chat')}
                                    iconPath={ChatActive}
                                    iconPathInactive={ChatInactive}
                                    chatNumber={channel.numberOfUnreadMessages}
                                />

                                <SideBarLink
                                    to={RouteLink.LEADERBOARD}
                                    title={t('pageTitles.leaderboard')}
                                    iconPath={LeaderboardActive}
                                    iconPathInactive={LeaderboardInactive}
                                />

                                <SideBarLink
                                    to={RouteLink.ACCOUNT}
                                    title={t('pageTitles.account')}
                                    iconPath={AccountActive}
                                    iconPathInactive={AccountInactive}
                                />
                            </LayoutGroup>
                        </div>
                        <Spacer />
                        <div
                            className={classNames(
                                'flex flex-col flex-grow',
                                'pt-3 ',
                                'justify-between',
                                'min-h-[300px] w-full h-full',
                            )}
                        >
                            <div className="max-h-[300px]">
                                <LayoutGroup>
                                    <SidebarModalLink
                                        title={t('pageTitles.goals')}
                                        iconPath={GoalsActive}
                                        iconPathInactive={GoalsInactive}
                                        onClick={() =>
                                            openSenonderOptions(
                                                setOpenGoalsList,
                                            )
                                        }
                                        isActive={openGoalsList}
                                    />

                                    <SidebarModalLink
                                        title={t('pageTitles.tasks')}
                                        iconPath={TasksActive}
                                        iconPathInactive={TasksInactive}
                                        onClick={() =>
                                            openSenonderOptions(setOpenTaskList)
                                        }
                                        isActive={openTaskList}
                                    />

                                    <SidebarModalLink
                                        title={t('pageTitles.notes')}
                                        iconPath={NotesActive}
                                        iconPathInactive={NotesInactive}
                                        onClick={() =>
                                            openSenonderOptions(setOpenNoteList)
                                        }
                                        isActive={openNoteList}
                                    />
                                </LayoutGroup>
                            </div>
                            <div className={classNames('flex flex-col')}>
                                <PrimaryButton
                                    className={classNames(
                                        'w-[80%] mx-auto mb-2',
                                        isOnNewBookingPage
                                            ? 'btn-secondary'
                                            : 'btn-primary',
                                        'text-[13px] h-[70px] shadow',
                                    )}
                                    onClick={() =>
                                        navigate(RouteLink.NEW_BOOKING)
                                    }
                                    disabled={
                                        coachee.coachee
                                            ?.coacheeMinutesRemaining !==
                                            undefined &&
                                        coachee.coachee
                                            ?.coacheeMinutesRemaining <= 0
                                    }
                                >
                                    <span className="text-xl">
                                        {t('buttons.bookSession')}
                                    </span>
                                </PrimaryButton>

                                <SessionLeft />
                            </div>
                        </div>
                        {/* <Spacer />
                        <div
                            className={classNames(
                                'flex flex-col flex-grow',
                                'pb-8 pt-3 ',
                                'justify-between',
                            )}
                        >
                            <SidebarModalLink
                                onClick={() => setOpenLogout(true)}
                                title={t('pageTitles.logout')}
                                iconPathInactive={LogoutInactive}
                                iconPath={LogoutInactive}
                                isActive={openLogout}
                            />
                        </div> */}
                    </>
                )}
            </aside>
            {openNoteList && (
                <Note
                    isOpen={openNoteList}
                    setIsOpen={() => setOpenNoteList(false)}
                />
            )}
            {openTaskList && (
                <Task
                    isOpen={openTaskList}
                    setIsOpen={() => setOpenTaskList(false)}
                />
            )}
            {openGoalsList && (
                <Goals isOpen={openGoalsList} setIsOpen={setOpenGoalsList} />
            )}
            {openLogout && (
                <ConfirmationModal
                    isOpen={openLogout}
                    setIsOpen={setOpenLogout}
                    onClick={auth.signOut}
                    message={t('modals.logOut.heading')}
                    actionBtnText={t('buttons.logOut')}
                    actionBtnCancelText={t('buttons.stayLogin')}
                />
            )}
        </>
    )
})
