/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
    MutableRefObject,
    ReactElement,
    useCallback,
    useContext,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import { Participant, Room } from 'twilio-video'
import cameraOff from '../../assets/icons/camera-off.svg'
import invisibleIcon from '../../assets/icons/invisible.svg'
import visibleIcon from '../../assets/icons/visible.svg'
import { MachineContext } from '../../stores/bookingMachine'
import { State } from '../../stores/bookingMachine/config'
import {
    Coach,
    ParticipantDetail,
    ParticipantRefs,
    TrackStatuses,
    User,
} from '../../types/SamaApi'
import BgCoachPic from './BgCoachPic'
import ConfirmLeaving from './ConfirmLeaving'
import Loader from './Loader'
import RatingPrompt from './RatingPrompt'
import SessionControls from './SessionControls'
import ViewSettings from './ViewSettings'
import VoteSubmitted from './VoteSubmitted'
import WaitingForCoach from './WaitingForCoach'

import Draggable from 'react-draggable'
import MicView from './MicView'
import NetworkQuality from './NetworkQuality'
import ProfilePicture from './ProfilePicture'
import './session.css'

export default function Session(): ReactElement {
    const { t } = useTranslation()
    const [state] = useContext(MachineContext)
    const stateMatches = state.matches
    const {
        twilioRoom,
        localRef,
        participantRefs,
        coach,
        user,
        sessionSettings,
        participantDetails,
        trackStatuses,
    }: {
        twilioRoom: Room
        localRef: MutableRefObject<any>
        participantRefs: ParticipantRefs[]
        coach: Coach
        user: User
        sessionSettings: {
            activeSpeaker: Participant | null
        }
        participantDetails: ParticipantDetail[]
        trackStatuses: TrackStatuses[]
    } = state.context
    const [isSelfHidden, setSelfHidden] = useState(false)

    const [
        coachDisconnected,
        isCoachVideoOff,
        isVideoOn,
        isLeaving,
        isViewingSettings,
        isRating,
        hasRated,
    ]: boolean[] = [
        'sessionConnected.coach.coachDisconnected',
        'sessionConnected.coachVideo.coachVideoUnsubscribed',
        'sessionConnected.coacheeVideo.coacheeVideoOn',
        'sessionConnected.coachee.isLeaving',
        'sessionConnected.coachee.isViewingSettings',
        'sessionConnected.coachee.isRating',
        'sessionConnected.coachee.hasRated',
    ].map(stateMatches)

    const getPartcipantName = useCallback(
        (identityId: string) => {
            const name = participantDetails
                .map((detail) => {
                    if (detail.twilioParticipantId === identityId) {
                        return detail.name
                    }
                })
                ?.pop()
            return name
        },
        [participantDetails],
    )

    const isVideoTurnedOn = useCallback(
        (identity: string) => {
            const particpantVideo = trackStatuses
                .filter(
                    (participant) =>
                        participant._id === identity &&
                        participant.type === 'video',
                )
                .pop()
            return particpantVideo?.enabled
        },
        [trackStatuses],
    )

    return (
        <>
            {stateMatches(State.connectingSession) && <Loader />}
            {isCoachVideoOff && <BgCoachPic picUrl={coach?.picUrl || ''} />}
            {coachDisconnected && <WaitingForCoach />}
            {stateMatches(State.sessionConnected) && <SessionControls />}
            <TransitionGroup>
                <ConfirmLeaving when={isLeaving} />
                <ViewSettings when={isViewingSettings} />
                <RatingPrompt when={isRating} />
                <VoteSubmitted when={hasRated} />
            </TransitionGroup>

            <div
                css={css({
                    paddingTop: '10px',
                    height: 'calc(100vh - 128px)',
                    alignItems: 'center',
                    alignContent: 'space-evenly',
                })}
            >
                <div
                    className={
                        twilioRoom?.participants?.size === 1
                            ? 'cards'
                            : 'cards-2'
                    }
                    css={css({
                        position: 'relative',
                        gridTemplateRows: 'calc(100vh - 128px)',
                        display: 'grid',
                        gridTemplateColumns:
                            twilioRoom?.participants?.size === 1
                                ? 'repeat(1, 85%)'
                                : 'repeat(2, 1fr)',
                        gridGap: '8px',
                        flexWrap: 'wrap',
                        alignContent: 'space-evenly',
                        alignItems: 'center',
                        justifyContent: 'center',
                    })}
                >
                    {participantRefs.map(
                        (ref: ParticipantRefs, key: number) => {
                            return (
                                <div
                                    key={key}
                                    css={css({
                                        position: 'relative',
                                        display: ref.identity ? 'flex' : 'none',
                                        width: '100%',
                                        backgroundColor: '#151515',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '15px',
                                        border:
                                            twilioRoom?.participants?.size >
                                                1 &&
                                            sessionSettings.activeSpeaker
                                                ?.identity === ref.identity
                                                ? 'solid 2px rgb(22 180 170)'
                                                : 'none',
                                    })}
                                >
                                    <div
                                        ref={ref.ref}
                                        css={css({
                                            display: ref.identity
                                                ? 'inline-block'
                                                : 'none',
                                            margin: 'auto',
                                            transform: 'scaleX(-1)',
                                            overflow: 'hidden',
                                            maxHeight: 'calc(100vh - 128px)',
                                            height: 'calc(100vh - 128px)',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        })}
                                    >
                                        <audio
                                            autoPlay
                                            style={{ display: 'none' }}
                                        />
                                        {ref.identity && (
                                            <>
                                                <NetworkQuality
                                                    identityId={ref.identity}
                                                />
                                                <MicView
                                                    identityId={ref.identity}
                                                />
                                            </>
                                        )}
                                        <video
                                            autoPlay
                                            playsInline
                                            css={css({
                                                height: '100%',
                                                objectFit: 'cover',
                                            })}
                                        />
                                    </div>
                                    {ref.identity &&
                                        !isVideoTurnedOn(ref.identity) && (
                                            <ProfilePicture
                                                identityId={ref.identity}
                                            />
                                        )}
                                    {ref.identity &&
                                        getPartcipantName(ref.identity) && (
                                            <span
                                                css={css({
                                                    backgroundColor: '#000000',
                                                    position: 'absolute',
                                                    margin: '5px',
                                                    padding:
                                                        '5px 10px 5px 10px',
                                                    bottom: '0px',
                                                    left: '0px',
                                                    borderRadius: '25px',
                                                    fontSize: '1.2rem',
                                                })}
                                            >
                                                <strong>
                                                    {getPartcipantName(
                                                        ref.identity,
                                                    )}
                                                </strong>
                                            </span>
                                        )}
                                </div>
                            )
                        },
                    )}
                </div>
                <Draggable>
                    <div
                        ref={localRef}
                        id="local-media"
                        className={isSelfHidden ? 'self-hidden' : ''}
                        css={css({
                            position: 'absolute',
                            bottom: '150px',
                            right: '40px',
                            width: '15%',
                            minWidth: !isSelfHidden ? '100px' : 'none',
                            backgroundColor: '#fff',
                            backgroundImage: !isSelfHidden
                                ? `url(${cameraOff})`
                                : 'none',
                            backgroundSize: '50px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            transform: 'scaleX(1)',
                            // transform: 'rotateY(180deg)',
                            border: '4px solid #fff',
                            borderRadius: '11px',
                            '&:hover .hide-self': {
                                display: 'block',
                            },
                            '&.self-hidden': {
                                width: '38px',
                                borderRadius: '20px',
                                backgroundImage: 'none',
                            },
                        })}
                    >
                        <div
                            style={{
                                transform: 'scaleX(-1)',
                                display: isSelfHidden ? 'none' : 'block',
                            }}
                        >
                            <audio muted autoPlay style={{ display: 'none' }} />
                            <NetworkQuality identityId={user._id} />
                            <MicView identityId={user._id} />
                            <video
                                muted
                                autoPlay
                                playsInline
                                style={{
                                    borderRadius: '11px',
                                    visibility: isVideoOn
                                        ? 'initial'
                                        : 'hidden',
                                }}
                            />
                            <button
                                className="hide-self"
                                css={css({
                                    display: 'none',
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: '-1px',
                                    padding: '7px 0',
                                    transform: 'scaleX(-1)',
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    fontSize: '18px',
                                    fontFamily: 'var(--font-medium)',
                                })}
                                onClick={() => setSelfHidden(true)}
                            >
                                <div className="flex justify-center gap-4">
                                    <img
                                        src={invisibleIcon.toString()}
                                        alt=""
                                        css={css({ width: '19px' })}
                                    />
                                    {t('Session.hideSelf')}
                                </div>
                            </button>
                        </div>
                        <button
                            style={{ display: isSelfHidden ? 'block' : 'none' }}
                            onClick={() => setSelfHidden(false)}
                        >
                            <img
                                src={visibleIcon.toString()}
                                alt="Network strength icon"
                                css={css({
                                    width: '19px',
                                    margin: '0 5px 5px',
                                })}
                            />
                        </button>
                    </div>
                </Draggable>
            </div>
        </>
    )
}
