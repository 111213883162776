import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import exclamationIcon from '../../../assets/icons/info-gray.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

interface ListProps {
    label: string
}

const List: React.FC<ListProps> = observer(({ label }) => {
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center space-x-4',
                    'py-1 my-1',
                )}
            >
                <span className="text-[20px] first-letter:font-semibold capitalize">
                    {label}
                </span>
            </div>
        </>
    )
})

export const SmartGoalModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()

        const ListData = [
            { id: 5, label: t('goals.smartGoalList.specific') },
            { id: 4, label: t('goals.smartGoalList.measurable') },
            { id: 3, label: t('goals.smartGoalList.achievable') },
            { id: 2, label: t('goals.smartGoalList.relevant') },
            { id: 1, label: t('goals.smartGoalList.time') },
        ]

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[31rem] pb-5 !pt-4"
            >
                <div className="flex flex-col px-7 pb-3">
                    <div className="mb-2">
                        <img
                            src={exclamationIcon}
                            alt="Icon"
                            className="h-8 w-8"
                        />
                        <h2 className="text-xl mt-2 font-semibold">
                            {t('goals.smartGoals')}
                        </h2>
                    </div>
                    <div>
                        {ListData.map((item) => (
                            <List key={item.id} {...item} />
                        ))}
                    </div>
                    <div>
                        <PrimaryButton
                            className="w-full mt-6"
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{t('buttons.close')}</span>
                        </PrimaryButton>
                    </div>
                </div>
            </CenterModal>
        )
    },
)
