import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { TaskResponse } from '../models/response/task/task'
import { HttpMethod } from '../utils/constants'
import { Resettable, removeFromStorage } from '../utils/misc'
import { request } from '../utils/request'

const TASK_KEY = 'SAMA:TASK'

export class TaskStore implements Resettable {
    public tasks: TaskResponse[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public getCoacheeTasks(coacheeId: string) {
        return request(`/coachee/${coacheeId}/task`, HttpMethod.GET, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.tasks = response.data
                    }
                })
            }),
        )
    }

    @action
    public updateTask(taskId: string, data: any) {
        return request(`/task/${taskId}`, HttpMethod.PUT, {
            body: data,
        })
    }

    @action
    public reset(): void {
        this.tasks = []
        removeFromStorage(TASK_KEY)
    }
}
