import Linkify from 'linkify-react'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { CenterModal } from '../modals'
import ExclamationMark from '/src/assets/icons/exclamation-blue-slanted.svg'
import InfoIcon from '/src/assets/icons/info-icon-new.svg'
import InfoIcon2 from '/src/assets/icons/info-icon-new2.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

interface SessionLeftProps {
    isDashboard?: boolean
    className?: string
}

export const SessionLeftPopup: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { auth, coachee } = useStores()

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
            >
                <div className="px-9 pb-8">
                    <header className="flex flex-col">
                        <img
                            src={ExclamationMark}
                            className="w-[6rem] h-[6rem] m-auto"
                        />
                    </header>
                    <main className="flex flex-col mt-4 text-center">
                        <span className="text-2xl">
                            {
                                t('modals.remainingSessions.heading', {
                                    count:
                                        (coachee.coachee
                                            ?.coacheeMinutesRemaining as number) /
                                        25,
                                }) as string
                            }
                        </span>
                        <span className="text-xl mt-4">
                            {t('modals.remainingSessions.subText', {
                                email: auth.email,
                            })}
                        </span>

                        <Linkify
                            options={{
                                target: 'blank',
                                className: 'font-bold',
                            }}
                        >
                            <span className={classNames('text-lg mt-4')}>
                                {t('modals.remainingSessions.contactUs')}
                            </span>
                        </Linkify>
                    </main>

                    {/* <footer className="flex pt-[25px]">
                        <PrimaryButton
                            className="w-full m-2 btn-default"
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        >
                            <a
                                className="btn-default"
                                href={`mailto:${
                                    import.meta.env.VITE_APP_EMAIL_SUPPORT
                                }`}
                            >
                                <span>{t('buttons.contactUs')}</span>
                            </a>
                        </PrimaryButton>

                        <PrimaryButton
                            className="w-full m-2 btn-default"
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{t('buttons.close')}</span>
                        </PrimaryButton>
                    </footer> */}
                </div>
            </CenterModal>
        )
    },
)

export const SessionLeft: React.FC<SessionLeftProps> = observer(
    ({ isDashboard, className }) => {
        const { t } = useTranslation()
        const { coachee } = useStores()
        const [isRemainingSessionOpen, openRemainingSessionModal] =
            useState(false)

        return (
            <>
                {coachee.coachee?.coacheeMinutesRemaining != null && (
                    <div className="flex space-x-2 items-center justify-center mb-[20px]">
                        <span
                            className={classNames(
                                className,
                                'text-[#002b38] text-[17px]',
                            )}
                        >
                            {t('messages.remainingSessions', {
                                count:
                                    coachee.coachee.coacheeMinutesRemaining /
                                    25,
                            })}
                        </span>
                        <div>
                            <img
                                src={!isDashboard ? InfoIcon : InfoIcon2}
                                alt={t('alt.profile')}
                                onClick={() => openRemainingSessionModal(true)}
                                className="cursor-pointer"
                            />
                        </div>
                        {isRemainingSessionOpen && (
                            <SessionLeftPopup
                                isOpen={isRemainingSessionOpen}
                                setIsOpen={() =>
                                    openRemainingSessionModal(false)
                                }
                            />
                        )}
                    </div>
                )}
            </>
        )
    },
)
