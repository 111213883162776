import {
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    Matches,
} from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class GoalsModel implements BaseModel {
    constructor(label?: string, progress?: number, dueDate?: Date) {
        ;(this.label = label || ''),
            (this.progress = progress || 0),
            (this.dueDate = dueDate)
    }

    @IsString()
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    @Matches(/\S/, {
        message: t('validation.mustBeSet') as string,
    })
    public label: string = ''

    @IsNumber()
    @IsNotEmpty()
    public progress: number

    @IsOptional()
    public dueDate?: Date
}
