interface Props {
    count: number
}

export const MessageBubble: React.FC<Props> = ({ count }) => {
    return (
        <div className="w-[25px] h-[27px] flex-grow-0 m-0 ml-[11px] p-0 pt-[3px] pb-[4px] rounded-[10px] bg-[#ff7558] flex items-center justify-center">
            <span className="text-center text-white text-[17px] leading-[1.2] font-normal tracking-normal">
                {count}
            </span>
        </div>
    )
}
