import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import Linkify from 'linkify-react'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import urgentIcon from '../../../assets/icons/urgent.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { OptionInput } from '../../../components/inputs/option-input'
import { TextInput } from '../../../components/inputs/text-input'
import { CenterModal } from '../../../components/modals'
import { ChangeMyCoachModel } from '../../../models/request/coachee/change-my-coach.model'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { FindCoachStage } from '../../onboarding/stages'

export const ChangeMyCoach: React.FC<any> = observer(
    ({ isOpen, setIsOpen }) => {
        const { statics } = useStores()
        const { t } = useTranslation()
        const [reasonValues, setReasonValues] =
            useState<ChangeMyCoachModel | null>(null)

        const onSubmit = useCallback(
            (
                values: ChangeMyCoachModel,
                helpers: FormikHelpers<ChangeMyCoachModel>,
            ) => {
                helpers.setSubmitting(true)
                setReasonValues(values)
                helpers.setSubmitting(false)
            },
            [],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[36rem] h-full overflow-y-scroll pt-2"
                headerTitle={t('account.changeCoach')}
                showCloseButton={reasonValues ? false : true}
                title="change_coach"
                {...(reasonValues ? { optionalClose: () => null } : {})}
            >
                <div
                    className={classNames('h-[1px] p-0 bg-[#e9eced] mt-8')}
                ></div>
                <div className="px-10 mt-8">
                    <div
                        className={classNames(
                            'flex flex-row justify-start space-x-4 border',
                            'p-4 my-2',
                        )}
                    >
                        <img src={urgentIcon} className="h-9 w-9" />
                        <Linkify
                            options={{
                                target: 'blank',
                                className: 'font-bold',
                            }}
                        >
                            <span className={classNames('text-[19px]')}>
                                {t('account.changeCoachDesc')}
                            </span>
                        </Linkify>
                    </div>
                    {reasonValues ? (
                        <div
                            className={classNames(
                                'flex item-center justify-center max-w-[30rem] w-full mt-4',
                            )}
                        >
                            <FindCoachStage reasonValues={reasonValues} />
                        </div>
                    ) : (
                        <Formik
                            validate={validateModel}
                            initialValues={new ChangeMyCoachModel()}
                            onSubmit={onSubmit}
                        >
                            {({
                                handleSubmit,
                                isValid,
                                isSubmitting,
                                values,
                                isValidating,
                                setStatus,
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className={classNames(
                                        'max-w-[30rem] w-full',
                                    )}
                                >
                                    <header className="flex flex-col">
                                        <span className="text-[22px] font-semibold mt-4">
                                            {t('account.changeReasonQuestion')}
                                        </span>
                                    </header>
                                    <div className="mt-8 pb-[20px]">
                                        {statics.reMatchReasons.map(
                                            (question, i) => (
                                                <OptionInput
                                                    name="reasonCategory"
                                                    label={question.name}
                                                    labelKey={question.key}
                                                    key={i}
                                                    description={
                                                        question.description
                                                    }
                                                    icon={question.icon}
                                                    setStatus={setStatus}
                                                />
                                            ),
                                        )}
                                        {values.reasonCategory === 'other' && (
                                            <TextInput
                                                name="reason"
                                                label={
                                                    t(
                                                        'fields.reason.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.reason.placeholder',
                                                    ) as string
                                                }
                                                className="mt-4"
                                                applyFocusStyle={true}
                                                disabled={isSubmitting}
                                            />
                                        )}

                                        <div className="mt-10">
                                            <PrimaryButton
                                                className="w-full "
                                                type="submit"
                                                loading={isSubmitting}
                                                disabled={
                                                    !isValid || isValidating
                                                }
                                            >
                                                <span>
                                                    {t('account.changeCoach')}
                                                </span>
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    )}
                </div>
            </CenterModal>
        )
    },
)
