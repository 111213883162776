import { IsNotEmpty, IsStrongPassword } from 'class-validator'
import { t } from 'i18next'
import { Match } from '../../../utils/validation/match'
import { BaseModel } from '../base.model'

export class PasswordResetModel implements BaseModel {
    constructor(token: string | null) {
        this.token = token
    }

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    @IsStrongPassword(
        {
            minLength: 10,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
        },
        { message: t('fields.password.validationMessage') },
    )
    public password: string = ''

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    @Match('password', {
        message: t('validation.passwordsDoNotMatch') as string,
    })
    public confirmPassword: string = ''

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public token: string | null = null

    public getRequestBody() {
        return {
            token: this.token,
            password: this.password,
        }
    }
}
