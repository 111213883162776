import React from 'react'

import { classNames } from '../../utils/misc'

interface Props {
    initials: string
    picture?: string | null
    wrapperClassNames?: string
    className?: string
    onClick?: any
    wrapperRef?: any
}

export const ProfilePictureCircle: React.FC<Props> = ({
    initials,
    picture,
    wrapperClassNames,
    className,
    onClick,
    wrapperRef,
}) => {
    return (
        <div
            ref={wrapperRef}
            className={classNames(
                wrapperClassNames,
                !picture
                    ? 'bg-accent shrink-0 w-[38px] h-[38px] rounded-full flex cursor-pointer'
                    : '',
                !picture
                    ? 'justify-center items-center bg-cover bg-center'
                    : '',
                !picture ? 'bg-no-repeat select-none' : '',
            )}
            onClick={onClick}
        >
            {!picture && (
                <span className="text-white text-[17px]">{initials}</span>
            )}
            {picture && (
                <img
                    src={picture}
                    className={classNames(
                        className,
                        'shrink-0 h-10 w-10 rounded-full flex z-[1100] cursor-pointer',
                    )}
                />
            )}
        </div>
    )
}
