import { detect } from 'detect-browser'
import { HttpMethod } from '../utils/constants'
import { request } from '../utils/request'
import { stores } from '../utils/stores'

const browser = detect()

const _capitalize = async (string: any): Promise<string> => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const trackEvent = async (name: string, properties?: any) => {
    properties.event = name
    properties.platform = 'Web'
    properties.$browser = await _capitalize(browser?.name)
    properties.$browser_version = browser?.version
    properties.$current_url = window.location.href
    properties.$os = await _capitalize(browser?.os)
    properties.$screen_height = window.innerHeight
    properties.$screen_width = window.innerWidth

    // Only track if user is authenticated
    if (stores.auth.authResponse?.accessToken) {
        return request('/sama/mix', HttpMethod.POST, {
            body: properties,
        }).subscribe()
    }
}
