/** @jsxImportSource @emotion/react */
import { ReactElement, useContext, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { MachineContext } from '../../stores/bookingMachine'
import { Action } from '../../stores/bookingMachine/config'
import microphoneOn from '../../assets/icons/microphone-on.svg'
import microphoneOff from '../../assets/icons/microphone-off.svg'
import cameraOn from '../../assets/icons/camera-on.svg'
import cameraOff from '../../assets/icons/camera-off.svg'
import close from '../../assets/icons/close.svg'
import settings from '../../assets/icons/settings.svg'

export default function SessionControls(): ReactElement {
    const [state, send] = useContext(MachineContext)
    const stateMatches = state.matches
    const isAudioOn = stateMatches(
        'sessionConnected.coacheeAudio.coacheeAudioOn',
    )
    const isVideoOn = stateMatches(
        'sessionConnected.coacheeVideo.coacheeVideoOn',
    )
    const [settingToolTip, showSettingToolTip] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        const interval = setInterval(() => {
            showSettingToolTip(false)
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div
            css={css({
                position: 'absolute',
                zIndex: 10,
                left: 0,
                right: 0,
                bottom: 0,
                height: '110px',
                display: 'flex',
                alignItems: 'center',
                backdropFilter: 'blur(15px)',
                backgroundColor: 'rgba(0,0,0,.7)',
            })}
        >
            <div
                css={css({
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                })}
            >
                <div
                    css={css({
                        flex: '1',
                        minWidth: '250px',
                        textAlign: 'center',
                        button: {
                            margin: '10px',
                            width: '50px',
                            height: '50px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            borderRadius: '50%',
                            transition: 'box-shadow 250ms ease-in',
                        },
                        'button:hover': {
                            boxShadow: '0px 2px 7px rgba(256, 256, 256, 0.7)',
                        },
                    })}
                >
                    <button
                        style={{
                            backgroundImage: `url(${
                                isVideoOn ? cameraOn : cameraOff
                            })`,
                        }}
                        onClick={() => send(Action.toggleCoacheeVideo)}
                    />
                    <button
                        style={{
                            backgroundImage: `url(${close})`,
                            // width: '75px',
                            // height: '75px',
                        }}
                        onClick={() => send(Action.initLeaving)}
                    />
                    <button
                        style={{
                            backgroundImage: `url(${
                                isAudioOn ? microphoneOn : microphoneOff
                            })`,
                        }}
                        onClick={() => send(Action.toggleCoacheeAudio)}
                    />
                </div>
                <div
                    css={css({
                        // width: '5%',
                        textAlign: 'right',
                        button: {
                            margin: '10px',
                            width: '50px',
                            height: '50px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            borderRadius: '50%',
                            transition: 'box-shadow 250ms ease-in',
                        },
                        'button:hover': {
                            boxShadow: '0px 2px 7px rgba(256, 256, 256, 0.7)',
                        },
                    })}
                >
                    {settingToolTip && (
                        <div
                            css={css({
                                display: 'inline-block',
                                position: 'relative',
                                borderBottom: '1px dotted #666',
                                textAlign: 'left',
                            })}
                        >
                            <div
                                css={css({
                                    left: '-54px',
                                    minWidth: '256px',
                                    height: '46px',
                                    top: '-40px',
                                    transform: 'translate(-50%, -100%)',
                                    padding: '10px 20px',
                                    color: '#444444',
                                    backgroundColor: '#FFFFFF',
                                    fontWeight: 'normal',
                                    fontSize: '13px',
                                    borderRadius: '8px',
                                    position: 'absolute',
                                    zIndex: 99999999,
                                    boxSizing: 'border-box',
                                    boxShadow: '0 1px 8px rgba(0,0,0,0.5)',
                                })}
                            >
                                <h3>{t('Settings.tooltip')}</h3>
                                <i
                                    css={css({
                                        position: 'absolute',
                                        top: '98%',
                                        left: '80%',
                                        marginleft: '-12px',
                                        width: '24px',
                                        height: '12px',
                                        overflow: 'hidden',
                                        '&:after': {
                                            content: '""',
                                            position: 'absolute',
                                            width: '12px',
                                            height: '12px',
                                            left: '50%',
                                            transform:
                                                'translate(-50%,-50%) rotate(45deg)',
                                            backgroundColor: '#FFFFFF',
                                            boxShadow:
                                                '0 1px 8px rgba(0,0,0,0.5)',
                                        },
                                    })}
                                ></i>
                            </div>
                        </div>
                    )}
                    <button
                        style={{
                            marginRight: '40px',
                            backgroundImage: `url(${settings})`,
                        }}
                        onMouseOver={() => showSettingToolTip(true)}
                        onMouseLeave={() => showSettingToolTip(false)}
                        onClick={() => send(Action.openSettings)}
                    />
                </div>
            </div>
        </div>
    )
}
