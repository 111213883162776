import { Allow, IsNotEmpty, IsPhoneNumber } from 'class-validator'
import { autorun } from 'mobx'
import { stores } from '../../../utils/stores'
import { BaseModel } from '../base.model'

export class AddPhoneModel implements BaseModel {
    @IsPhoneNumber(undefined, {
        message: 'validation.invalidPhone' as string,
    })
    public phone: string = ''

    @IsNotEmpty({ message: 'validation.mustBeSet' })
    public phoneCountry: string = ''

    @Allow()
    public accessToken: string = ''

    constructor() {
        autorun(() => {
            const accessToken = stores.auth.authResponse?.accessToken

            if (accessToken) {
                this.accessToken = accessToken
            }
        })
    }
}
