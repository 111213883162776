import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MissedSession } from '../../../models/response'
import { classNames, formatDate, getFormatString } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import MissedSessionIcon from '/src/assets/icons/missedSession-icon.svg'

interface ListProps {
    missedSession: MissedSession
}

const List: React.FC<ListProps> = observer(({ missedSession }) => {
    const { t } = useTranslation()
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center justify-between',
                    'px-2 py-4 my-1',
                )}
            >
                <span className="text-[21px]">
                    {formatDate(
                        missedSession.date,
                        getFormatString('date-time'),
                    )}
                </span>

                <div
                    className={classNames(
                        'inline-flex rounded-full bg-[#fff1ee]',
                        'px-4 py-2 justify-center items-center',
                    )}
                >
                    <span className="text-[17px] text-[#ff0000]">
                        {(missedSession.state === 'cancel' ||
                            missedSession.state === 'noShow') &&
                            t('leaderboard.cancel')}
                    </span>
                </div>
            </div>
            <div className="flex-grow h-[2px] bg-[#e9eced]"></div>
        </>
    )
})

export const MissedSessionList = observer(() => {
    const { coachee } = useStores()
    const { missedSessions } = coachee
    const { t } = useTranslation()

    return (
        <div
            className={classNames(
                'rounded-lg bg-[#fafafa] w-full min-h-[90vh]',
                'py-2 flex flex-col justify-between space-y-8',
            )}
        >
            <div className="px-8">
                {missedSessions.length > 0 &&
                    missedSessions
                        .slice()
                        .sort(
                            (a, b) =>
                                new Date(b.date).getTime() -
                                new Date(a.date).getTime(),
                        )
                        .map((item) => (
                            <List key={item._id} missedSession={item} />
                        ))}
            </div>

            <div
                className={classNames(
                    'flex flex-col items-center ',
                    'justify-center px-8 pb-4 min-h-[15vh]',
                )}
            >
                <img src={MissedSessionIcon} />
                <span className="text-[17px] inline-block text-center mt-5">
                    {t('leaderboard.missedSessionsInfo')}
                </span>
            </div>
        </div>
    )
})
