import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function UserMediaPrompt({
  when,
}: {
  when: boolean
}): ReactElement {
  const { t } = useTranslation()
  const [isReadyToShow, setReadyToShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReadyToShow(true)
    }, 600)

    return () => clearTimeout(timeout)
  })

  return (
      <Popup
          when={when && isReadyToShow}
          icon={errorIcon.toString()}
          title={t('MediaPrompt.title')}
      >
          <ReactMarkdown>{t('MediaPrompt.body') as string}</ReactMarkdown>
      </Popup>
  )
}
