import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

interface ListProps {
    id: number
    label: string
}

const List: React.FC<ListProps> = observer(({ id, label }) => {
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center space-x-4',
                    'py-1 my-1',
                )}
            >
                <div
                    className={classNames(
                        'flex items-center justify-center',
                        'rounded-full bg-[#a8eaf6] p-3 w-8 h-8',
                    )}
                >
                    <span className="text-[17px]">{id}</span>
                </div>
                <span className="text-[19px]">{label}</span>
            </div>
        </>
    )
})

export const TrackerModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()

        const ListData = [
            { id: 5, label: t('leaderboard.peak') },
            { id: 4, label: t('leaderboard.strong') },
            { id: 3, label: t('leaderboard.good') },
            { id: 2, label: t('leaderboard.moderate') },
            { id: 1, label: t('leaderboard.low') },
        ]

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[31rem] !pt-12"
                headerTitle={t('leaderboard.aboutTracker')}
            >
                <div className="flex flex-col px-7 pb-8 mt-8">
                    <span className="text-[17px]">
                        {t('leaderboard.aboutTrackerDesc')}
                    </span>
                    <div className="mt-4">
                        {ListData.map((item) => (
                            <List key={item.id} {...item} />
                        ))}
                    </div>
                    <div>
                        <PrimaryButton
                            className="w-full mt-6"
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{t('buttons.close')}</span>
                        </PrimaryButton>
                    </div>
                </div>
            </CenterModal>
        )
    },
)
