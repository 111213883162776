import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { DashboardPageWrapper } from '../../components/navigation'
import { ChatSection } from './components/chatSection'
import { SendChatSection } from './components/sendChat'

export const ChatPage: React.FC = observer(() => {
    const { t } = useTranslation()

    return (
        <DashboardPageWrapper
            pageName={t('pageTitles.chat')}
            backgroundColour="bg-[#f9fbfc]"
        >
            <div className="flex flex-col">
                <ChatSection />
                <SendChatSection />
            </div>
        </DashboardPageWrapper>
    )
})
