import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddPersonalValuesModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage?: Stage
    showBackArrow?: boolean
    buttonTranslationKey?: string
    formClassNames?: string
    onComplete?: () => void
    showMainError?: boolean
}

export const RateSatisfactionStage: React.FC<Props> = observer(
    ({
        currentStage,
        showBackArrow,
        buttonTranslationKey,
        formClassNames,
        onComplete,
        showMainError = true,
    }) => {
        const { t } = useTranslation()
        const { statics, coachee } = useStores()
        const [subscriptions] = useState<Subscription[]>([])

        const setRating = useCallback(
            (
                values: AddPersonalValuesModel,
                helpers: FormikHelpers<AddPersonalValuesModel>,
            ) => {
                helpers.setSubmitting(true)

                cancelSubscriptions(subscriptions)

                const subscription = coachee.updateCoachee(values).subscribe({
                    next(response) {
                        if (response.ok && coachee.coachee) {
                            coachee.setOnboardingStage(Stage.COACHGENDER)
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.personalValues = values
                            coachee.setCoachee(newCoachee)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                        onComplete ? onComplete() : null
                        if (coachee.coachee) {
                            coachee.coachee.personalValues = values
                            coachee.setCoachee(coachee.coachee)
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [coachee, onComplete, subscriptions],
        )

        return (
            <QuestionForm
                title={t('onboarding.rateSatifaction.title')}
                subTitle={t('onboarding.rateSatifaction.subTitle')}
                className={formClassNames}
                currentNumber={currentStage}
                totalNumber={
                    coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
                }
                questions={statics.satisfactionLevel}
                name="personalValues"
                rating={true}
                model={
                    new AddPersonalValuesModel(coachee.coachee?.personalValues)
                }
                onSubmit={setRating}
                showBackArrow={showBackArrow}
                buttonTranslationKey={buttonTranslationKey}
                canSkip={true}
                isOnboarding={true}
                onPrevious={() =>
                    coachee.setOnboardingStage(
                        coachee.onboardingStage.stage - 1,
                    )
                }
                showMainError={showMainError}
            />
        )
    },
)
