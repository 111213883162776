import { Allow, IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { autorun } from 'mobx'
import { stores } from '../../../utils/stores'
import { BaseModel } from '../base.model'

export class PasswordLoginModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public password: string = ''

    @Allow()
    public email: string = ''

    constructor() {
        autorun(() => {
            const email = stores.auth.email

            if (email) {
                this.email = email
            }
        })
    }
}
