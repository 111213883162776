import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import ArrowIcon from '../../../assets/icons/arrow-right.svg'

interface Props {
    title: string
    image: string
    onClick?: () => void
}

export const Card: React.FC<Props> = ({ title, image, onClick }) => {
    return (
        <motion.main
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
            className={classNames(
                'cursor-pointer',
                'bg-white relative h-auto',
                'rounded-lg w-full p-8',
                'flex flex-col gap-[10rem]',
            )}
        >
            <div>
                <img
                    src={image}
                    alt=""
                    className="absolute top-0 left-0 w-28 h-28"
                />
            </div>
            <footer className="flex justify-between">
                <span className="text-[29px]">{title}</span>
                <img src={ArrowIcon} alt="" className="w-9 h-9" />
            </footer>
        </motion.main>
    )
}
