import { createContext, Context } from 'react'
import { Machine } from 'xstate'

import machineConfig from './config'

const machine = Machine(machineConfig)

export default machine

export const MachineContext: Context<any> = createContext(null)
