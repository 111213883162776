import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TotalSession } from '../../../models/response'
import { classNames, formatDate, getFormatString } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import TimeSession from '/src/assets/icons/timeSession.svg'

interface ListProps {
    totalSession: TotalSession
}

const List: React.FC<ListProps> = observer(({ totalSession }) => {
    const { t } = useTranslation()
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center  justify-between',
                    'px-2 py-4 my-1',
                )}
            >
                <span className="text-[19px]">
                    {formatDate(
                        totalSession.date,
                        getFormatString('date-time'),
                    )}
                </span>
                <div className="flex space-x-2 rounded-full bg-[#a8eaf6] p-3">
                    <img src={TimeSession} />
                    <span className="text-[17px]">
                        {t('dashboard.session.duration', {
                            mins: totalSession.duration,
                        })}
                    </span>
                </div>
            </div>
            <div className="flex-grow h-[2px] bg-[#e9eced]"></div>
        </>
    )
})

export const TotalSessionList = observer(() => {
    const { coachee } = useStores()
    const { totalSessions } = coachee

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8 pb-4">
                {totalSessions.length > 0 &&
                    totalSessions
                        .slice()
                        .sort(
                            (a, b) =>
                                new Date(b.date).getTime() -
                                new Date(a.date).getTime(),
                        )
                        .map((item) => (
                            <List key={item._id} totalSession={item} />
                        ))}
            </div>
        </div>
    )
})
