import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../../../assets/logo/logo-dark.svg'
import { ProgressBar } from '../../../components/misc/progress-bar'
import { useStores } from '../../../utils/stores'
import { ProfileDropDownModal } from '../../dashboard/modals'

interface Props {
    children: React.ReactNode
}

export const OnboardingPageWrapper: React.FC<Props> = ({ children }) => {
    const { t } = useTranslation()
    const { coachee } = useStores()

    const progress = useMemo(() => {
        const totalQuestions =
            coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
        let rawProgress = (coachee.onboardingStage.stage / totalQuestions) * 100
        rawProgress = Math.min(rawProgress, 100)
        rawProgress = Math.max(rawProgress, 0)
        return rawProgress
    }, [
        coachee.onboardingOptions?.numberOfOnboardingQuestions,
        coachee.onboardingStage.stage,
    ])
    return (
        <div>
            <header className="fixed z-10 bg-[#ffffff] top-0 w-full flex justify-between p-8 border-b-[1px] border-[#e9eced]">
                <img src={Logo} alt={t('alt.logo')} />
                {coachee.onboardingStage.stage !== 11 && (
                    <ProgressBar progress={progress} />
                )}
                <div className={classNames('hidden md:block', 'relative ')}>
                    <ProfileDropDownModal
                        showAccount={false}
                        isOnboarding={true}
                    />
                </div>
            </header>

            <main className="flex justify-center items-center mt-14 mb-20 pt-[75px]">
                {children}
            </main>
        </div>
    )
}
