import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import FilledIcon from '../../assets/icons/checkmark_filled.svg'
import { OnboardingButtons } from '../../components/buttons/onboarding-button'
import { RouteLink } from '../../utils/constants'

interface Props {
    title: string
    subTitle: string
}

export const TcPage: React.FC<Props> = ({ title, subTitle }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)

    const handleAccept = () => {
        if (checked) {
            navigate(RouteLink.ONBOARDING, {
                state: {
                    canOnboard: true,
                },
            })
        }
    }
    return (
        <div>
            <main className="flex flex-col max-w-[30rem] px-5 md:p-0">
                <span className="text-5xl mb-[2rem]">{title} </span>

                <span className="text-xl leading-8 mb-[2rem]"> {subTitle}</span>

                <div className="flex gap-2">
                    {checked ? (
                        <img
                            src={FilledIcon}
                            alt={t('alt.checked')}
                            onClick={() => setChecked(!checked)}
                            className="w-5 h-5 mt-1"
                        />
                    ) : (
                        <span
                            className="rounded-full h-5 w-5 border-2 border-[#cecdd0] cursor-pointer mt-1"
                            onClick={() => setChecked(!checked)}
                        ></span>
                    )}

                    <span className="text-[1.2rem]">
                        {/* {t('fields.tc.label') as string} */}
                        <Trans
                            t={t}
                            i18nKey="fields.tc.label"
                            components={{
                                a1: (
                                    <a
                                        className={'text-[#236978] font-medium'}
                                        target="_blank"
                                        href={t('fields.tc.tcLink') as string}
                                    />
                                ),
                                a2: (
                                    <a
                                        className={'text-[#236978] font-medium'}
                                        target="_blank"
                                        href={
                                            t('fields.tc.privacyLink') as string
                                        }
                                    />
                                ),
                            }}
                        ></Trans>
                    </span>
                </div>
            </main>

            <div className="h-20"></div>
            <OnboardingButtons onNext={handleAccept} />
        </div>
    )
}

export default TcPage
