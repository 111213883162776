import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SideModal } from '../../../components/modals/side-modal'
import { Channel } from '../../../models/response/channel'
import { ChatSection } from '../../chat/components/chatSection'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    channel: Channel
    profileImage: string | undefined
    coachInitial: string
}

export const ChatHistoryModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, channel, profileImage, coachInitial }) => {
        const { t } = useTranslation()

        return (
            <SideModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
                title="chat_history"
            >
                <div className="rounded-lg bg-white max-w-[28rem] w-full">
                    <div className="flex flex-col px-8 py-7 sticky top-0 z-20 bg-white">
                        <div className="flex items-center">
                            <img
                                src={profileImage}
                                className="w-[4rem] h-[4rem] rounded-[100px]"
                            />
                            <div className="flex flex-col my-auto ml-3">
                                <span className="text-[13px] text-[#6b6a72] uppercase">
                                    {t('account.chatHistory')}
                                </span>
                                <span>
                                    <strong>
                                        {channel.coach?.user.firstName}
                                        &nbsp;
                                        {channel.coach?.user.lastName}
                                    </strong>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg bg-[#fafafa] w-full min-h-[90vh] py-1">
                        <div className="flex flex-col">
                            <ChatSection
                                id={channel.twilio.id}
                                coachId={channel.coach._id}
                                coachInitial={coachInitial}
                            />
                        </div>
                    </div>
                </div>
            </SideModal>
        )
    },
)
