import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { NoteModel } from '../models/request/note/note.model'
import { NoteResponse } from '../models/response/note/note'
import { HttpMethod } from '../utils/constants'
import { Resettable, removeFromStorage } from '../utils/misc'
import { request } from '../utils/request'

const NOTE_KEY = 'SAMA:NOTE'

export class NoteStore implements Resettable {
    public notes: NoteResponse[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public getCoacheeNotes() {
        return request('/me/coachee/notepad', HttpMethod.GET, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.notes = response.data
                    }
                })
            }),
        )
    }

    @action
    public createNote(data: NoteModel) {
        return request('/me/coachee/notepad', HttpMethod.POST, {
            body: data,
        })
    }

    @action
    public updateNote(noteId: string, data: NoteModel) {
        return request(`/notepad/${noteId}`, HttpMethod.PUT, {
            body: data,
        })
    }

    @action
    public reset(): void {
        this.notes = []
        removeFromStorage(NOTE_KEY)
    }
}
