import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddPositionModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const PositionStage: React.FC<Props> = observer(({ currentStage }) => {
    const { t } = useTranslation()
    const { statics, coachee } = useStores()
    const [subscriptions] = useState<Subscription[]>([])

    const setPosition = useCallback(
        (
            values: AddPositionModel,
            helpers: FormikHelpers<AddPositionModel>,
        ) => {
            helpers.setSubmitting(true)

            cancelSubscriptions(subscriptions)

            const subscription = coachee.updateCoachee(values).subscribe({
                next(response) {
                    if (response.ok && coachee.coachee) {
                        coachee.setOnboardingStage(Stage.AREA_OF_COACHING)
                        const newCoachee = { ...coachee.coachee }
                        newCoachee.position = values.position
                        coachee.setCoachee(newCoachee)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [coachee, subscriptions],
    )

    return (
        <QuestionForm
            title={t('onboarding.position.title')}
            currentNumber={currentStage}
            totalNumber={
                coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
            }
            questions={statics.positions}
            name="position"
            model={new AddPositionModel(coachee.coachee?.position)}
            onSubmit={setPosition}
            isOnboarding={true}
            onPrevious={() =>
                coachee.setOnboardingStage(coachee.onboardingStage.stage - 1)
            }
        />
    )
})
