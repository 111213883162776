import {
    IsArray,
    IsBoolean,
    IsOptional,
    IsString,
    ValidateNested,
} from 'class-validator'
import { Participant } from '../../response/booking'
import { BaseModel } from '../base.model'

export class BookingModel implements BaseModel {
    constructor(
        date: string,
        duration: number,
        isVideoSession: boolean,
        participants?: Participant[],
    ) {
        ;(this.date = date),
            (this.duration = duration),
            (this.isVideoSession = isVideoSession),
            (this.participants = participants)
    }

    @IsString()
    public date: string | undefined

    @IsString()
    public duration: number | undefined

    @IsBoolean()
    public isVideoSession: boolean

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    public participants?: Participant[]

    public getRequestBody() {
        return {
            date: this.date,
            duration: this.duration,
            isVideoSession: this.isVideoSession,
            participants: this.participants,
        }
    }
}
