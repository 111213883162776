/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

export default function ApiError(): ReactElement {
    const { t } = useTranslation()
    const [dotCount, setDotCount] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setDotCount((dotCount + 1) % 4)
        }, 150)
        return () => clearInterval(interval)
    })

    // The <span> is needed to stop
    // translation plugins from breacking the site
    // please do not remove it
    return (
        <span>
            <div
                css={css({
                    position: 'absolute',
                    top: 'calc(50vh - 15px)',
                    left: 'calc(50vw - 75px)',
                    height: '30px',
                    width: '150px',
                })}
            >
                {t('Loader.loading')}.
                {Array.from(new Array(dotCount)).map(() => '.')}
            </div>
        </span>
    )
}
