import { Skeleton } from '@mui/material'

const SideModalSkeleton = () => {
    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8 py-8">
                <div className="space-y-4">
                    <Skeleton variant="rectangular" width="100%" height={100} />
                    <Skeleton variant="rectangular" width="100%" height={98} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={70} />
                </div>
            </div>
        </div>
    )
}

export default SideModalSkeleton
