export enum Stage {
    INITIAL,
    GENDER,
    AGE_RANGE,
    INDUSTRY,
    FUNCTION,
    POSITION,
    AREA_OF_COACHING,
    TOP_VALUES,
    SATISFACTION,
    COACHGENDER,
    LANGUAGE,
    FINDINGCOACH,
}

export const coachGender = {
    en: [
        { key: 'male', name: 'Male' },
        { key: 'female', name: 'Female' },
        { key: 'everything', name: 'I don’t mind' },
    ],
    fr: [
        { key: 'male', name: 'Homme' },
        { key: 'female', name: 'Femme' },
        { key: 'everything', name: 'Peu importe' },
    ],
}
