import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import ReactDOMServer from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'
import { Trans, useTranslation } from 'react-i18next'
import profileImage from '../../../assets/icons/upload-profile-image.svg'
import { CenterModal, ModalProps } from '../../../components/modals'

import * as Sentry from '@sentry/react'
import { trackEvent } from '../../../services/event-tracking'
import { toBase64 } from '../../../utils/misc/to-base-64'
import { getHeaders } from '../../../utils/request'
import './upload-profile-image.modal.css'

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
)

interface UploadModelProps {
    uploadType: string
    uploadId: string
    setPicture: any
}

export const UploadProfileImageModal: React.FC<ModalProps & UploadModelProps> =
    observer(({ isOpen, setIsOpen, uploadType, uploadId, setPicture }) => {
        const { t } = useTranslation()

        const placeholder = useMemo(
            () => (
                <div className="flex flex-col">
                    <img
                        src={profileImage}
                        className="h-6"
                        alt="Upload profile image"
                    />
                    <span className="!text-accent-2 !text-[17px]">
                        <Trans
                            t={t}
                            i18nKey="modals.uploadProfileImage.dragAndDrop"
                            values={{
                                browse: t('modals.uploadProfileImage.browse'),
                            }}
                        >
                            <span className="text-[#3095AA]" />
                        </Trans>
                    </span>
                    <span className="!text-[#56576A] !text-[13px]">
                        {t('modals.uploadProfileImage.supportedFileTypes')}
                    </span>
                </div>
            ),
            [t],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[468px] p-10"
            >
                <header className="flex flex-col mb-8">
                    <span className="text-[24px]">
                        {t('modals.uploadProfileImage.heading')}
                    </span>
                    <span className="text-[13px]">
                        {t('modals.uploadProfileImage.subText')}
                    </span>
                </header>
                <main>
                    <FilePond
                        name="file"
                        server={{
                            process: (
                                fieldName,
                                file,
                                _,
                                load,
                                error,
                                progress,
                                abort,
                            ) => {
                                const formData = new FormData()
                                formData.append(fieldName, file, file.name)

                                const request = new XMLHttpRequest()
                                const headers = getHeaders()

                                request.open(
                                    'POST',
                                    import.meta.env.VITE_APP_BASE_URL +
                                        '/' +
                                        uploadType +
                                        '/' +
                                        uploadId +
                                        '/picture',
                                )

                                request.setRequestHeader(
                                    'Authorization',
                                    headers['Authorization'],
                                )

                                request.upload.onprogress = (e) => {
                                    progress(
                                        e.lengthComputable,
                                        e.loaded,
                                        e.total,
                                    )
                                }

                                request.onload = async function () {
                                    if (
                                        request.status >= 200 &&
                                        request.status < 300
                                    ) {
                                        trackEvent('uploadedProfileImage', {
                                            type: uploadType,
                                        })
                                        load(request.responseText)
                                        const base64 = await toBase64(file)
                                        setPicture(base64, uploadId, uploadType)
                                        setIsOpen(false)
                                    } else {
                                        Sentry.captureMessage(
                                            'Upload failed: status:' +
                                                request.status +
                                                ' message: ' +
                                                request.responseText,
                                        )
                                        error('')
                                    }
                                }

                                request.send(formData)

                                return {
                                    abort: () => {
                                        request.abort()
                                        abort()
                                    },
                                }
                            },
                        }}
                        instantUpload={true}
                        credits={false}
                        allowMultiple={false}
                        labelIdle={ReactDOMServer.renderToString(placeholder)}
                        labelTapToCancel=""
                        acceptedFileTypes={[
                            'image/jpeg',
                            'image/png',
                            'image/svg+xml',
                        ]}
                    />
                </main>
            </CenterModal>
        )
    })
