import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IconChecked from '../../assets/icons/checkmark_filled.svg'
import DropDownArrowIcon from '../../assets/icons/dropdown-arrow.svg'
import { Option } from '../../models/response'

interface Props {
    options: Option[]
    value?: Option
    values?: Option[]
    onSelect: (...args: any[]) => any
    selectAllOption?: boolean
    placeholder?: string
    name?: string
    className?: string
    buttonWidth?: string
    buttonHeight?: string
    buttonClassName?: string
    optionsClassName?: string
    optionClassName?: string
    optionClassNameActive?: string
    optionClassNameSelected?: string
    arrowClassName?: string
    showArrow?: boolean
    disabled?: boolean
    checkElement?: React.ReactNode
    notCheckedElement?: React.ReactNode
    multiple?: boolean
}

export const SELECT_ALL_VALUE = 'select-all'

export const ListBox: React.FC<Props> = ({
    options,
    value,
    values,
    onSelect,
    selectAllOption,
    placeholder,
    className,
    buttonClassName,
    buttonWidth,
    buttonHeight,
    optionClassName,
    optionsClassName,
    optionClassNameActive,
    optionClassNameSelected,
    arrowClassName,
    showArrow,
    disabled,
    checkElement,
    notCheckedElement,
    multiple,
    ...props
}) => {
    const { t } = useTranslation()

    useEffect(() => {
        if (selectAllOption) {
            const allOption = {
                value: SELECT_ALL_VALUE,
                display: t('options.selectAll'),
            }

            if (values?.length === options.length) {
                values.push(allOption)
            }
            const hasSelectAll = options.filter(
                (option) => option.value === SELECT_ALL_VALUE,
            )

            if (hasSelectAll.length === 0) {
                options.unshift(allOption)
            }
        }
    }, [options, values])

    return (
        <Listbox
            multiple={multiple}
            value={value ?? values}
            onChange={onSelect}
            as="div"
            className={classNames('relative w-full', className)}
            disabled={disabled}
            {...props}
        >
            {({ open }) => (
                <>
                    <Listbox.Button
                        className={classNames(
                            'bg-[#ffffff] inline-flex px-4 items-center justify-between rounded-[.25rem]',
                            'active:focus:focus-within:ring-accent-2',
                            open && 'ring-2 ring-accent-2',
                            disabled && 'text-accent-2/40',
                            buttonWidth ? buttonWidth : 'w-full',
                            buttonHeight ? buttonHeight : 'h-11',
                            buttonClassName,
                        )}
                    >
                        <div className="flex justify-between">
                            {value?.icon && (
                                <img
                                    className={classNames('h-7 w-7 mr-4')}
                                    src={value?.icon ?? ''}
                                    alt={t('alt.icon')}
                                />
                            )}
                            <span className="text-xl">
                                {value?.display ??
                                    placeholder ??
                                    t('validation.selectAnOption')}
                            </span>
                        </div>
                        {!disabled && (
                            <img src={DropDownArrowIcon} alt="Down arrow" />
                        )}
                    </Listbox.Button>
                    {open && showArrow && (
                        <div
                            className={classNames(
                                'absolute right-4 w-2 h-2 top-full translate-y-2 rotate-45 transform z-[1]',
                                arrowClassName,
                            )}
                        />
                    )}
                    <Listbox.Options
                        className={classNames(
                            'top-full transform translate-y-3 z-[1] absolute right-0 w-[9rem] origin-top-right  divide-y divide-[#fff]/10 rounded-md max-h-32 overflow-auto',
                            optionsClassName,
                        )}
                    >
                        {options.map((option) => (
                            <Listbox.Option
                                key={option.value}
                                value={option}
                                disabled={option.disabled}
                                as={Fragment}
                            >
                                {({ active, selected }) => (
                                    <li
                                        className={classNames(
                                            'flex items-center text-[13px] mx-4 py-2 cursor-pointer',
                                            optionClassName,
                                            active && 'text-primary',
                                            active && optionClassNameActive,
                                            selected && optionClassNameSelected,
                                            option.disabled && 'opacity-60',
                                        )}
                                    >
                                        {selected &&
                                            (checkElement ?? (
                                                <img
                                                    className="w-2 mr-2"
                                                    src={IconChecked}
                                                    alt="Check mark"
                                                />
                                            ))}
                                        {!selected && notCheckedElement}
                                        <span className="text-xl">
                                            {option.display}
                                        </span>
                                    </li>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </>
            )}
        </Listbox>
    )
}
