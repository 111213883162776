import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStores } from '../../../utils/stores'

export const Resend2fa: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [secondsLeft, setSecondsLeft] = useState(
        auth.getSecondsToResendCode(),
    )

    const canResendCode = useMemo(() => {
        return auth.authResponse?.codeRequestedAt && secondsLeft <= 0
    }, [auth, secondsLeft])

    const secondsLeftTextKey = useMemo(() => {
        return secondsLeft > 0 ? 'messages.resend2fa' : 'messages.canResend2fa'
    }, [secondsLeft])

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft(auth.getSecondsToResendCode())
        }, 1000 /* Update every second */)

        return () => {
            clearInterval(interval)
        }
    }, [auth])

    const resendCode = useCallback(() => {
        auth.resend2fa().subscribe()
    }, [auth])

    return (
        <div className="flex items-center justify-center">
            {auth.authResponse?.codeRequestedAt && (
                <div>
                    {secondsLeft <= 0 ? (
                        <span
                            onClick={() =>
                                canResendCode ? resendCode() : undefined
                            }
                            className="text-[14px] cursor-pointer  text-[#236978] font-semibold"
                        >
                            <Trans
                                t={t}
                                i18nKey={secondsLeftTextKey}
                                values={{
                                    seconds: secondsLeft > 0 ? secondsLeft : 0,
                                }}
                            >
                                <a className="text-[#0000FF]" />
                            </Trans>
                        </span>
                    ) : (
                        <span
                            onClick={() =>
                                canResendCode ? resendCode() : undefined
                            }
                            className="text-[14px]  text-[#236978] font-semibold"
                        >
                            <Trans
                                t={t}
                                i18nKey={secondsLeftTextKey}
                                values={{
                                    seconds: secondsLeft > 0 ? secondsLeft : 0,
                                }}
                            >
                                <a className="text-[#0000FF]" />
                            </Trans>
                        </span>
                    )}
                </div>
            )}
        </div>
    )
})
