import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { classNames, formatDate, getFormatString } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

interface ListProps {
    date: string
    count: number
}

const List: React.FC<ListProps> = observer(({ date, count }) => {
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center justify-between',
                    'px-2 py-4 my-1',
                )}
            >
                <span className="text-[22px]">{date}</span>
                <span className="text-[22px]">{count}</span>
            </div>
            <div className="flex-grow h-[2px] bg-[#e9eced]"></div>
        </>
    )
})

export const CompletedTaskList = observer(() => {
    const { coachee } = useStores()
    const { t } = useTranslation()

    const { taskCompleted } = coachee

    const sortedTaskCompleted = taskCompleted
        .reduce((counts: { date: string; count: number }[], task) => {
            const date = new Date(task.updatedAt)
            const monthYearKey = formatDate(date, getFormatString('month-year'))

            const existingEntry = counts.find(
                (entry: { date: string }) => entry.date === monthYearKey,
            )
            if (existingEntry) {
                existingEntry.count++
            } else {
                counts.push({ date: monthYearKey, count: 1 })
            }

            return counts
        }, [])
        .sort(
            (
                a: { date: string | number | Date },
                b: { date: string | number | Date },
            ) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        )

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8 pb-4">
                <div
                    className={classNames(
                        'flex flex-row items-center justify-between',
                        'px-2 py-1 mt-4',
                    )}
                >
                    <span className="text-[13px]">
                        {t('leaderboard.month')}
                    </span>
                    <span className="text-[13px]">
                        {t('leaderboard.completed')}
                    </span>
                </div>

                {sortedTaskCompleted.length > 0 &&
                    sortedTaskCompleted.map(
                        (item: ListProps, index: number) => (
                            <List key={index} {...item} />
                        ),
                    )}
            </div>
        </div>
    )
})
