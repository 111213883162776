import { Allow, IsNumberString, Length } from 'class-validator'
import { t } from 'i18next'
import { autorun } from 'mobx'
import { stores } from '../../../utils/stores'
import { BaseModel } from '../base.model'

const TWO_FA_CODE_LENGTH = 4

export class TwoFaModel implements BaseModel {
    @IsNumberString(undefined, {
        message: t('validation.twoFaCode.format') as string,
    })
    @Length(TWO_FA_CODE_LENGTH, TWO_FA_CODE_LENGTH, {
        message: t('validation.twoFaCode.length') as string,
    })
    public code: string = ''

    @Allow()
    public accessToken: string = ''

    @Allow()
    public phone?: string

    constructor(phone?: string) {
        this.phone = phone

        autorun(() => {
            const accessToken = stores.auth.authResponse?.accessToken

            if (accessToken) {
                this.accessToken = accessToken
            }
        })
    }
}
