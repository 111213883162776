/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { MachineContext } from '../../stores/bookingMachine'
import { ParticipantDetail } from '../../types/SamaApi'

export default function WaitingForCoach(): ReactElement {
    const [state] = useContext(MachineContext)
    const { t } = useTranslation()
    const { coach, booking, token, participantDetails } = state.context

    const coacheeUser = participantDetails.find(
        (participant: ParticipantDetail) => {
            return participant.type === 'coachee'
        },
    )

    return (
        <div
            css={css({
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(15px)',
                backgroundColor: 'rgba(0,0,0,.7)',
                '&>div': {
                    textAlign: 'center',
                    maxWidth: '80%',
                },
            })}
        >
            {coach && (
                <div>
                    <img
                        src={coach.picUrl}
                        alt={t('WaitingSessionMessage.picAlt') ?? ''}
                        css={css({
                            width: '120px',
                            height: '120px',
                            borderRadius: '50%',
                            margin: '0 auto'
                        })}
                    />
                    <h2 style={{ fontSize: '37px' }}>
                        {t('WaitingSessionMessage.title', {
                            type:
                                booking.participants.length > 0 === true
                                    ? 'participant'
                                    : token.objectData?.type === 'coachee'
                                    ? 'coach'
                                    : 'coachee',
                            name:
                                booking.participants.length > 0
                                    ? ''
                                    : token.objectData?.type === 'coachee'
                                    ? `${coach.user.firstName} ${coach.user.lastName}`
                                    : coacheeUser.name,
                        })}
                    </h2>
                    <h3 style={{ fontSize: '22px' }}>
                        {t('WaitingSessionMessage.body', {
                            bookingTime: booking.time,
                        })}
                    </h3>
                </div>
            )}
        </div>
    )
}
