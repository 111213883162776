import { Editor } from '@tinymce/tinymce-react'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'
import { NoteModel } from '../../../models/request/note/note.model'
import { NoteResponse } from '../../../models/response/note/note'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    timePeriod?: string
    currentItem?: NoteResponse
    setCurrentItem?: (item: NoteResponse | undefined) => void
}

export const NoteModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, currentItem, setCurrentItem }) => {
        const { t } = useTranslation()
        const { note, coachee } = useStores()

        const editorSettings = {
            menubar: false,
            inline: false,
            plugins: ['insertdatetime', 'lists'],
            paste_as_text: true,
            color_cols: 6,
            color_map: [
                '#FF2600',
                'Red',
                '#FFFB00',
                'Yellow',
                '#00F900',
                'Green',
                '#FF40FF',
                'Purple',
                '#00FDFF',
                'Blue',
            ],
            custom_colors: false,
            toolbar: 'bold italic underline backcolor bullist numlist ',
            branding: false,
            statusbar: false,
            height: 350,
        }

        const close = useCallback(() => {
            setIsOpen(false)
            setCurrentItem && setCurrentItem(undefined)
        }, [setCurrentItem, setIsOpen])

        const onSubmit = useCallback(
            (values: NoteModel) => {
                const data = {
                    content: values.content,
                }
                if (currentItem && currentItem._id) {
                    note.updateNote(
                        currentItem._id,
                        data as NoteModel,
                    ).subscribe({
                        next(response) {
                            if (response.ok) {
                                note.getCoacheeNotes().subscribe()
                            }
                        },
                    })
                } else {
                    note.createNote(data as NoteModel).subscribe({
                        next(response) {
                            if (response.ok) {
                                note.getCoacheeNotes().subscribe()
                            }
                        },
                    })
                }
                setCurrentItem && setCurrentItem(undefined)
                setIsOpen(false)
            },
            [currentItem, note, setCurrentItem, setIsOpen],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[34rem]"
                headerTitle={
                    currentItem?._id
                        ? t('modals.notes.editNote')
                        : t('modals.notes.createNote')
                }
                optionalClose={close}
            >
                <Formik
                    validate={validateModel}
                    initialValues={new NoteModel()}
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        setFieldValue,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col px-8 py-4 bg-[#fafafa] mt-10"
                            data-testid="form"
                        >
                            <main className="space-y-2 mb-6 mt-2">
                                <Editor
                                    tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
                                    disabled={false}
                                    apiKey={
                                        import.meta.env
                                            .VITE_APP_TINY_MCE_API_KEY
                                    }
                                    onInit={(evt, editor) => {
                                        setFieldValue(
                                            'content',
                                            editor.getContent(),
                                        )
                                    }}
                                    onEditorChange={(newValue) => {
                                        setFieldValue('content', newValue)
                                    }}
                                    initialValue={currentItem?.content ?? ''}
                                    init={{
                                        ...editorSettings,
                                        toolbar_location: 'bottom',
                                        language:
                                            coachee.coachee?.user
                                                ?.appLanguage === 'en'
                                                ? 'en_US'
                                                : 'fr_FR',
                                        language_url:
                                            coachee.coachee?.user
                                                ?.appLanguage === 'en'
                                                ? ''
                                                : '/assets/js/tinymce/langs/fr_FR.js',
                                    }}
                                />
                            </main>
                            <footer>
                                <div>
                                    <PrimaryButton
                                        className="w-full mt-6"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!isValid || isValidating}
                                    >
                                        <span>{t('buttons.save')}</span>
                                    </PrimaryButton>
                                </div>
                            </footer>
                        </form>
                    )}
                </Formik>
            </CenterModal>
        )
    },
)
