import { HTMLMotionProps, motion } from 'framer-motion'
import { MouseEventHandler, forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/misc'

interface Props extends HTMLMotionProps<'button'> {
    loading?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    bgColour?: string
    className?: string
}

export const PrimaryButton = forwardRef<any, Props>(
    ({ loading, children, className, bgColour, onClick, ...props }, ref) => {
        const { t } = useTranslation()

        const internalOnClick = useCallback(
            (e: any) => {
                e.currentTarget.blur()

                if (onClick) {
                    onClick(e)
                }
            },
            [onClick],
        )

        return (
            <motion.button
                data-testid="button"
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
                className={classNames(
                    className,
                    'rounded-full',
                    bgColour ? bgColour : 'bg-primary active:bg-accent-2',
                    'disabled:bg-[#f9fbfc]',
                    'disabled:text-[#a5b2b6] text-[#ffffff] text-[17px]',
                    'h-[50px]',
                    'px-4',
                )}
                onClick={
                    loading
                        ? (e) => e.preventDefault()
                        : (e) => internalOnClick(e)
                }
                {...props}
                ref={ref}
            >
                {loading ? <span>{t('messages.loading')}...</span> : children}
            </motion.button>
        )
    },
)
