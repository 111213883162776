import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition,
} from '@headlessui/react'
import { enUS, fr } from 'date-fns/locale'
import React, { Fragment, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import ArrowIcon from '../../assets/icons/dropdown-circle.svg'
import { formatDate } from '../../utils/misc'

registerLocale('en', enUS)
registerLocale('fr', fr)

interface Props {
    name: string
    selectedDate: Date | null
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
    setFieldValue: (field: string, value: any) => void
    coachee?: any
}

export const DatePickerComponent: React.FC<Props> = ({
    name,
    selectedDate,
    setFieldValue,
    setSelectedDate,
    coachee,
}) => {
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
        setFieldValue(name, date)
        setIsOpen(false)
    }

    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Popover>
            <>
                <PopoverButton
                    className="focus:outline-none"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="bg-white flex items-center p-4 rounded-lg gap-3">
                        {selectedDate ? (
                            <span>
                                {formatDate(selectedDate, 'd MMM yyyy')}
                            </span>
                        ) : (
                            <span>{t('goals.selectDueDate')}</span>
                        )}
                        <img src={ArrowIcon} className="w-6 h-6" />
                    </div>
                </PopoverButton>
                <Transition
                    as={Fragment}
                    show={isOpen}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute z-10 md:mt-4 mt-2 right-5 md:right-0 w-[280px] ">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 max-w-xs">
                            <div className="relative grid gap-8 bg-white p-3">
                                <DatePicker
                                    calendarClassName="w-full datepicker"
                                    className="datepicker"
                                    popperClassName="custom-datepicker-popper"
                                    name={name}
                                    minDate={new Date()}
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    inline
                                    locale={coachee.coachee?.user?.appLanguage}
                                />
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </>
        </Popover>
    )
}
