import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { AuthRequirement } from '../../pages/routes'
import { trackEvent } from '../../services/event-tracking'
import { useStores } from '../../utils/stores'
import { AUTH_REQUIREMENTS } from './authRequirement'

interface Props {
    title: string
    path: string
    component: React.FC
    authRequirements: AuthRequirement[]
}

export const RouteWrapper: React.FC<Props> = observer(
    ({ title, path, component: Component, authRequirements }) => {
        const { t } = useTranslation()
        const { coachee } = useStores()
        useEffect(() => {
            trackEvent('screen_viewed', {
                name: path,
                title: title,
                coachee_id: coachee.coachee?._id,
            })
        }, [coachee.coachee?._id, path, title])

        for (const requirement of authRequirements) {
            const [verified, route, state] = AUTH_REQUIREMENTS[requirement]()
            if (!verified) {
                return (
                    <Navigate
                        to={route /* Link to correct page */}
                        replace={false}
                        state={state}
                    />
                )
            }
        }

        return (
            <>
                <Helmet>
                    <title>{t(`pageTitles.${title}`)}</title>
                </Helmet>
                <Component />
            </>
        )
    },
)
