import { addHours } from 'date-fns'
import i18next from 'i18next'
import { memoize } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { Static, Statics } from '../models/response'
import { HttpMethod } from '../utils/constants'
import {
    Resettable,
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
} from '../utils/misc'
import { request } from '../utils/request'

import CommunicationIcon from '/src/assets/icons/areas-of-coaching/communication.svg'
import EngagementIcon from '/src/assets/icons/areas-of-coaching/engagement.svg'
import {
    default as LeadershipIcon,
    default as PerformanceIcon,
} from '/src/assets/icons/areas-of-coaching/leadership.svg'
import LifeBalanceIcon from '/src/assets/icons/areas-of-coaching/life-balance.svg'

const STATICS_KEY = 'SAMA:STATICS'
const HOURS_TO_CACHE_STATICS_KEY = 24
const STATICS_KEY_VERSION = 1

interface StaticsCache {
    data: Statics
    version: number
    expires: string
}
const SUPPORTED_LANG_CODES = ['en', 'fr']

export const getStaticsMap = memoize(
    (statics: Static[]): Record<string, string> => {
        return statics.reduce((acc, current) => {
            acc[current.key] = current.name
            return acc
        }, {} as any)
    },
)

export const getStaticsValue = memoize(
    (statics: Static[], key: string) => {
        const data = getStaticsMap(statics)
        return data[key]
    },
    (arg1, arg2) => JSON.stringify([arg1, arg2]),
)

export const getStaticsValues = memoize(
    (statics: Static[], keys: string[]) => {
        if (keys && keys.length > 0) {
            const data = getStaticsMap(statics)

            return keys.map((key) => data[key])
        }
    },
    (arg1, arg2) => JSON.stringify([arg1, arg2]),
)

export class StaticsStore implements Resettable {
    private AreaOfCoachingIcons = {
        performance: PerformanceIcon,
        engagement: EngagementIcon,
        leadership: LeadershipIcon,
        lifeBalance: LifeBalanceIcon,
        communication: CommunicationIcon,
    }
    private statics: Statics | null = null

    @computed
    public get ageRanges(): Static[] {
        return this.statics?.ageRanges ?? []
    }

    @computed
    public get industries(): Static[] {
        return this.statics?.industries ?? []
    }

    @computed
    public get competencies(): Static[] {
        return this.statics?.competencies ?? []
    }

    @computed
    public get accreditations(): Static[] {
        return this.statics?.accreditations ?? []
    }

    @computed
    public get memberships(): Static[] {
        return this.statics?.memberships ?? []
    }

    @computed
    public get positions(): Static[] {
        return this.statics?.positions ?? []
    }

    @computed
    public get functions(): Static[] {
        return this.statics?.functions ?? []
    }

    @computed
    public get coacheeGender(): Static[] {
        return this.statics?.coacheeGender ?? []
    }

    @computed
    public get satisfactionLevel(): Static[] {
        return this.statics?.personalValues ?? []
    }

    @computed
    public get topValues(): Static[] {
        return this.statics?.topValues ?? []
    }

    @computed
    public get languages(): Static[] {
        return this.statics?.languages ?? []
    }

    @computed
    public get supportedLanguages(): Static[] {
        if (!this.statics) {
            return []
        }

        return this.statics?.languages.filter(({ key }) =>
            SUPPORTED_LANG_CODES.includes(key),
        )
    }

    @computed
    public get reMatchReasons(): Static[] {
        return this.statics?.reMatchReasons ?? []
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public setStatics(statics: Statics) {
        statics.competencies = statics.competencies.map((competency) => {
            return {
                ...competency,
                icon: this.AreaOfCoachingIcons[
                    competency.key as keyof typeof this.AreaOfCoachingIcons
                ],
            }
        })

        this.statics = statics
        dehydrateToStorage(STATICS_KEY, {
            data: statics,
            version: STATICS_KEY_VERSION,
            expires: addHours(new Date(), HOURS_TO_CACHE_STATICS_KEY),
        })
    }

    @action
    public loadStatics(language: string = i18next.language.slice(0, 2)) {
        return new Promise((resolve) => {
            const staticsCache: StaticsCache | null =
                hydrateFromStorage(STATICS_KEY, true) ?? null

            if (staticsCache && staticsCache.data) {
                const now = new Date()
                const expiresAt = new Date(staticsCache.expires)

                if (
                    now <= expiresAt &&
                    staticsCache.version === STATICS_KEY_VERSION
                ) {
                    this.statics = staticsCache.data
                }

                resolve(this.statics)
            }

            if (!this.statics) {
                request<never, Statics>('/statics', HttpMethod.GET, {
                    silentErrors: true,
                    query: {
                        language: language.slice(0, 2), // Language without region
                    },
                }).subscribe({
                    next: (response) => {
                        runInAction(() => {
                            if (response.data) {
                                this.setStatics(response.data)
                                resolve(response.data)
                            }
                        })
                    },
                })
            }
        })
    }

    @action
    public reset() {
        this.statics = null
        removeFromStorage(STATICS_KEY)
    }
}
