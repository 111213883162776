import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddCoachingLanguageModel } from '../../../models/request/onboarding'
import { getStaticsValue } from '../../../stores'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { Question, QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const CoachingLanguageStage: React.FC<Props> = observer(
    ({ currentStage }) => {
        const { t } = useTranslation()
        const { coachee, statics } = useStores()
        const [subscriptions] = useState<Subscription[]>([])

        useEffect(() => {
            if (coachee.allowedCoachingLangugages.length === 0) {
                coachee.setOnboardingStage(Stage.FINDINGCOACH)
            }
        }, [coachee])

        const getLanguages = useCallback(() => {
            return coachee.allowedCoachingLangugages
                .map((lang) => {
                    return {
                        key: lang,
                        name: getStaticsValue(statics.languages, lang),
                    } as Question
                })
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
        }, [coachee.allowedCoachingLangugages, statics.languages])

        const setCoachingLang = useCallback(
            (
                values: AddCoachingLanguageModel,
                helpers: FormikHelpers<AddCoachingLanguageModel>,
            ) => {
                helpers.setSubmitting(true)

                cancelSubscriptions(subscriptions)

                const subscription = coachee.updateCoachee(values).subscribe({
                    next(response) {
                        if (response.ok && coachee.coachee) {
                            coachee.setOnboardingStage(Stage.FINDINGCOACH)
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.coachingLanguage =
                                values.coachingLanguage
                            coachee.setCoachee(newCoachee)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                    },
                })

                subscriptions.push(subscription)
            },
            [coachee, subscriptions],
        )

        return (
            <>
                {getLanguages() && (
                    <QuestionForm
                        title={t('onboarding.langauge.title')}
                        subTitle={t('onboarding.langauge.subTitle')}
                        currentNumber={currentStage}
                        totalNumber={
                            coachee.onboardingOptions
                                ?.numberOfOnboardingQuestions ?? 10
                        }
                        questions={getLanguages() ?? []}
                        name="coachingLanguage"
                        model={
                            new AddCoachingLanguageModel(
                                coachee.coachee?.coachingLanguage,
                            )
                        }
                        onSubmit={setCoachingLang}
                        isOnboarding={true}
                        onPrevious={() =>
                            coachee.setOnboardingStage(
                                coachee.onboardingStage.stage - 1,
                            )
                        }
                    />
                )}
            </>
        )
    },
)
