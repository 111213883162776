import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IconArrow from '../../../assets/icons/arrow-right.svg'
import IconChecked from '../../../assets/icons/checkmark_filled.svg'
import { SideModal } from '../../../components/modals/side-modal'
import { Events } from '../../../models/response'
import { trackEvent } from '../../../services/event-tracking'
import { checklistAction } from '../../../utils/constants/checklist-action'
import { classNames } from '../../../utils/misc'
import { Goals } from '../../goals/goals'

interface Props {
    checklistEvents: Events[]
    seeAll?: boolean
}

interface ChecklistProps {
    task: string
    completed: boolean
    action: string
    wrapperClassName?: string
}

interface ContentProps {
    completed: boolean
    task: string
}

interface StatusIconProps {
    completed: boolean
}

const Checklist: React.FC<ChecklistProps> = ({
    task,
    completed,
    action,
    wrapperClassName,
}) => {
    const { t } = useTranslation()
    const [openGoals, setOpenGoals] = useState(false)

    const StatusIcon: React.FC<StatusIconProps> = ({ completed }) =>
        completed ? (
            <img
                src={IconChecked}
                alt={t('alt.checked')}
                className="mb-2 w-8 h-7"
            />
        ) : (
            <span className="rounded-full h-7 w-8 border-2 bg-[#fffff] border-[#e9eced]"></span>
        )

    const Content: React.FC<ContentProps> = ({ completed, task }) => (
        <div className="flex gap-6">
            <StatusIcon completed={completed} />
            <div className="flex flex-col w-full">
                <div className="flex justify-between">
                    <span className={classNames(completed && 'text-[#9d9ca1]')}>
                        {task}
                    </span>
                    {!completed && (
                        <img src={IconArrow} alt={t('alt.checked')} />
                    )}
                </div>
                <div className="flex-grow h-[1px] bg-[#e9eced] mt-4"></div>
            </div>
        </div>
    )

    return (
        <main className={classNames(wrapperClassName, 'mt-4')}>
            {action === 'addGoal' ? (
                <div
                    onClick={() => setOpenGoals(true)}
                    className="cursor-pointer"
                >
                    <Content completed={completed} task={task} />
                </div>
            ) : (
                <Link to={checklistAction(action)}>
                    <Content completed={completed} task={task} />
                </Link>
            )}

            {openGoals && <Goals isOpen={openGoals} setIsOpen={setOpenGoals} />}
        </main>
    )
}

export const ChecklistCard: React.FC<Props> = observer(
    ({ checklistEvents, seeAll = false }) => {
        const { t } = useTranslation()
        const [openAllChechlist, setOpenAllChechlist] = useState(false)
        const checklist = seeAll ? checklistEvents : checklistEvents.slice(0, 5)

        useEffect(() => {
            if (openAllChechlist) {
                trackEvent('OnboardingCheckList', { name: 'seeAll' })
            }
        }, [openAllChechlist])

        return (
            <main className="rounded-lg bg-white p-8 lg:max-w-[28rem] w-full">
                <span className="text-3xl text-[#090714]">
                    {t('dashboard.checklist.title')}
                </span>
                {checklist.map((event: Events, i: number) => (
                    <Checklist
                        task={event.description}
                        completed={event.completed}
                        action={event.action}
                        wrapperClassName={seeAll ? '!mt-8' : ''}
                        key={i}
                    />
                ))}
                {!seeAll && checklistEvents.length > 5 && (
                    <span
                        className="text-xl cursor-pointer block text-center mt-6 text-primary"
                        onClick={() => {
                            setOpenAllChechlist(!openAllChechlist)
                        }}
                    >
                        {t('others.seeAll')}
                    </span>
                )}

                {openAllChechlist && (
                    <SideModal
                        isOpen={openAllChechlist}
                        setIsOpen={setOpenAllChechlist}
                        className="max-w-[28rem] px-8 py-10"
                    >
                        <ChecklistCard
                            seeAll={true}
                            checklistEvents={checklistEvents}
                        />
                    </SideModal>
                )}
            </main>
        )
    },
)
