import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddAgeRangeModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public ageRange: string = ''

    constructor(ageRange = '') {
        this.ageRange = ageRange
    }

    public getRequestBody() {
        return {
            user: {
                ageRange: this.ageRange,
            },
        }
    }
}
