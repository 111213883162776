import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { MachineContext } from '../../stores/bookingMachine'
import { Action } from '../../stores/bookingMachine/config'
import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function ConfirmLeaving({
    when,
}: {
    when: boolean
}): ReactElement {
    const { t } = useTranslation()
    const [, send] = useContext(MachineContext)

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('ConfirmLeaving.title')}
        >
            <ReactMarkdown>{t('ConfirmLeaving.body') as string}</ReactMarkdown>
            <div>
                <button
                    className="cancel"
                    onClick={() => send(Action.cancelLeaving)}
                >
                    {t('common.cancel')}
                </button>
                <button
                    className="danger"
                    onClick={() => send(Action.confirmLeaving)}
                >
                    {t('ConfirmLeaving.leave')}
                </button>
            </div>
        </Popup>
    )
}
