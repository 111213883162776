import { MutableRefObject } from 'react'
import { Participant, Room, Track } from 'twilio-video'
import { MachineConfig } from 'xstate/lib/types'
import { assign } from 'xstate/lib/actions'

import {
    Booking,
    Session,
    User,
    Token,
    DeviceList,
    NetworkQuality,
    ParticipantRefs,
    TrackStatuses,
    ParticipantDetail,
} from '../../types/SamaApi'

export enum State {
    loadingBooking = 'loadingBooking',
    apiErrored = 'apiErrored',
    bookingErroredEarly = 'bookingErroredEarly',
    bookingErroredLate = 'bookingErroredLate',
    validatingDevice = 'validatingDevice',
    promptingNativeApp = 'promptingNativeApp',
    promptingRecentBrowser = 'promptingRecentBrowser',
    promptingUserMedia = 'promptingUserMedia',
    userMediaErrored = 'userMediaErrored',
    connectingSession = 'connectingSession',
    sessionConnected = 'sessionConnected',
    coachConnected = 'coachConnected',
    coachDisconnected = 'coachDisconnected',
    coachVideoInitial = 'coachVideoInitial',
    coachVideoSubscribed = 'coachVideoSubscribed',
    coachVideoUnsubscribed = 'coachVideoUnsubscribed',
    coachAudioSubscribed = 'coachAudioSubscribed',
    coachAudioUnsubscribed = 'coachAudioUnsubscribed',
    isPresent = 'isPresent',
    isLeaving = 'isLeaving',
    isRating = 'isRating',
    hasRated = 'hasRated',
    coacheeAudioOn = 'coacheeAudioOn',
    coacheeAudioOff = 'coacheeAudioOff',
    coacheeVideoInitial = 'coacheeVideoInitial',
    coacheeVideoOn = 'coacheeVideoOn',
    coacheeVideoOff = 'coacheeVideoOff',
    networkQuality = 'networkQuality',
    changeInputDevice = 'changeInputDevice',
    updateDominantSpeaker = 'updateDominantSpeaker',
    updateNetworkQuality = 'updateNetworkQuality',
    chanceTrackStatus = 'chanceTrackStatus',
    loadDevice = 'loadDevice',
    isViewingSettings = 'isViewingSettings',
}

export interface ISchema {
    states: {
        [State.loadingBooking]: any
        [State.apiErrored]: any
        [State.bookingErroredEarly]: any
        [State.bookingErroredLate]: any
        [State.validatingDevice]: any
        [State.promptingNativeApp]: any
        [State.promptingRecentBrowser]: any
        [State.promptingUserMedia]: any
        [State.userMediaErrored]: any
        [State.connectingSession]: any
        [State.sessionConnected]: {
            states: {
                coach: {
                    states: {
                        [State.coachConnected]: any
                        [State.coachDisconnected]: any
                    }
                }
                coachVideo: {
                    states: {
                        [State.coachVideoInitial]: any
                        [State.coachVideoSubscribed]: any
                        [State.coachVideoUnsubscribed]: any
                    }
                }
                coachAudio: {
                    states: {
                        [State.coachAudioSubscribed]: any
                        [State.coachAudioUnsubscribed]: any
                    }
                }
                coachee: {
                    states: {
                        [State.isPresent]: any
                        [State.isLeaving]: any
                        [State.isViewingSettings]: any
                        [State.isRating]: any
                        [State.hasRated]: any
                    }
                }
                coacheeAudio: {
                    states: {
                        [State.coacheeAudioOn]: any
                        [State.coacheeAudioOff]: any
                    }
                }
                coacheeVideo: {
                    states: {
                        [State.coacheeVideoInitial]: any
                        [State.coacheeVideoOn]: any
                        [State.coacheeVideoOff]: any
                    }
                }
                changeDevice: {
                    states: {
                        [State.changeInputDevice]: any
                    }
                }
                updateDominantSpeaker: {
                    states: {
                        [State.updateDominantSpeaker]: any
                    }
                }
                updateNetworkQuality: {
                    states: {
                        [State.updateNetworkQuality]: any
                    }
                }
                chanceTrackStatus: {
                    states: {
                        [State.chanceTrackStatus]: any
                    }
                }
            }
        }
    }
}

export enum Action {
    initLoadBooking = 'initLoadBooking',
    loadBooking = 'loadBooking',
    ignoreNativeApp = 'ignoreNativeApp',
    ignoreRecentBrowser = 'ignoreRecentBrowser',
    coachConnects = 'coachConnects',
    coachDisconnects = 'coachDisconnects',
    dominantSpeakerChanged = 'dominantSpeakerChanged',
    subscribeCoachVideo = 'subscribeCoachVideo',
    unsubscribeCoachVideo = 'unsubscribeCoachVideo',
    subscribeCoachAudio = 'subscribeCoachAudio',
    unsubscribeCoachAudio = 'unsubscribeCoachAudio',
    initLeaving = 'initLeaving',
    confirmLeaving = 'confirmLeaving',
    cancelLeaving = 'cancelLeaving',
    rateCoach = 'rateCoach',
    toggleCoacheeAudio = 'toggleCoacheeAudio',
    toggleCoacheeVideo = 'toggleCoacheeVideo',
    enableAudio = 'enableAudio',
    disableAudio = 'disableAudio',
    enableVideo = 'enableVideo',
    disableVideo = 'disableVideo',
    disconnect = 'disconnect',
    postRating = 'postRating',
    trackConnected = 'trackConnected',
    trackDisconnected = 'trackDisconnected',
    trackError = 'trackError',
    trackRatingOpen = 'trackRatingOpen',
    trackRatingSent = 'trackRatingSent',
    updateNetworkQuality = 'updateNetworkQuality',
    chanceTrackStatus = 'chanceTrackStatus',
    changeInputDevice = 'changeInputDevice',
    loadDevice = 'loadDevice',
    openSettings = 'openSettings',
    closeSettings = 'closeSettings',
}

export type TEvent =
    | { type: Action.initLoadBooking }
    | { type: Action.loadBooking }
    | { type: Action.ignoreNativeApp }
    | { type: Action.ignoreRecentBrowser }
    | { type: Action.coachConnects }
    | { type: Action.coachDisconnects }
    | { type: Action.dominantSpeakerChanged; participant: Participant }
    | { type: Action.subscribeCoachVideo }
    | { type: Action.unsubscribeCoachVideo }
    | { type: Action.subscribeCoachAudio }
    | { type: Action.unsubscribeCoachAudio }
    | { type: Action.initLeaving }
    | { type: Action.openSettings }
    | { type: Action.closeSettings }
    | { type: Action.confirmLeaving }
    | { type: Action.cancelLeaving }
    | { type: Action.rateCoach; data: number }
    | { type: Action.toggleCoacheeAudio }
    | { type: Action.toggleCoacheeVideo }
    | { type: Action.updateNetworkQuality; value: number; identity: string }
    | {
          type: Action.chanceTrackStatus
          name: string
          participant: Participant
          track: Track
          enabled: boolean
      }
    | { type: Action.changeInputDevice; data: string; inDebugMode: boolean }

export interface IContext {
    token: null | Token
    inDebugMode: null | boolean
    isLate: boolean
    bookingId: null | string
    booking: null | Booking
    session: null | Session
    user: null | User
    coach: null | { user: User; picUrl: string }
    twilioRoom: null | Room
    participantDetails: [] | ParticipantDetail[]
    localRef: null | MutableRefObject<any>
    deviceList: null | DeviceList
    activeDevices: null | any
    remoteRef: null | MutableRefObject<any>
    sessionSettings: {
        activeSpeaker: Participant | null
    }
    participantRefs: ParticipantRefs[] | []
    networkQuality: NetworkQuality[]
    trackStatuses: TrackStatuses[]
    capturedAudioDevice: null | string
    capturedVideoDevice: null | string
}

const initialContext: IContext = {
    token: null,
    inDebugMode: false,
    isLate: false,
    bookingId: null,
    booking: null,
    session: null,
    user: null,
    coach: null,
    twilioRoom: null,
    participantDetails: [],
    localRef: null,
    deviceList: null,
    activeDevices: null,
    remoteRef: null,
    sessionSettings: {
        activeSpeaker: null,
    },
    trackStatuses: [],
    participantRefs: [],
    networkQuality: [],
    capturedAudioDevice: null,
    capturedVideoDevice: null,
}

const machineConfig: MachineConfig<IContext, ISchema, TEvent> = {
    id: 'booking',
    initial: State.validatingDevice,
    context: initialContext,
    states: {
        validatingDevice: {
            invoke: {
                id: 'validateDevice',
                src: 'validateDevice',
                onDone: {
                    target: State.loadingBooking,
                },
                onError: [
                    {
                        target: State.promptingNativeApp,
                        cond: (ctx, { data }) =>
                            data === 'shouldPromptNativeApp',
                    },
                    {
                        target: State.promptingRecentBrowser,
                        cond: (ctx, { data }) =>
                            data === 'shouldPromptRecentBrowser',
                    },
                ],
            },
        },
        promptingNativeApp: {
            on: {
                [Action.ignoreNativeApp]: {
                    target: State.loadingBooking,
                },
            },
        },
        promptingRecentBrowser: {
            on: {
                [Action.ignoreRecentBrowser]: {
                    target: State.loadingBooking,
                },
            },
        },
        loadingBooking: {
            invoke: {
                id: 'getBooking',
                src: 'getBookingAndMe',
                onDone: {
                    target: State.promptingUserMedia,
                    actions: assign({
                        user: (ctx, { data: { user } }) => user,
                        booking: (ctx, { data: { booking } }) => booking,
                    }),
                },
                onError: [
                    {
                        target: State.apiErrored,
                        actions: Action.trackError,
                        cond: (ctx, { data }) => data instanceof Error,
                    },
                    {
                        target: State.bookingErroredEarly,
                        cond: (ctx, { data }) => data === 'tooEarly',
                    },
                    {
                        target: State.bookingErroredLate,
                        cond: (ctx, { data }) => data === 'tooLate',
                    },
                ],
            },
        },
        apiErrored: {},
        bookingErroredEarly: {},
        bookingErroredLate: {},
        promptingUserMedia: {
            invoke: {
                id: 'checkUserMedia',
                src: 'checkUserMedia',
                onDone: {
                    target: State.connectingSession,
                    actions: assign({
                        capturedAudioDevice: (ctx, { data: { audioDevice } }) =>
                            audioDevice,
                        capturedVideoDevice: (ctx, { data: { videoDevice } }) =>
                            videoDevice,
                    }),
                },
                onError: {
                    target: State.userMediaErrored,
                },
            },
        },
        userMediaErrored: {},
        connectingSession: {
            invoke: {
                id: 'connectSession',
                src: 'connectSession',
                onDone: {
                    target: State.sessionConnected,
                    actions: assign({
                        coach: (ctx, { data: { coach } }) => coach,
                        twilioRoom: (ctx, { data: { twilioRoom } }) =>
                            twilioRoom,
                        participantDetails: (
                            ctx,
                            { data: { participantDetails } },
                        ) => participantDetails,
                    }),
                },
                onError: {
                    target: State.apiErrored,
                    actions: Action.trackError,
                },
            },
        },
        sessionConnected: {
            id: 'session',
            type: 'parallel',
            entry: Action.trackConnected,
            invoke: {
                id: 'handleRoom',
                src: 'handleRoom',
            },
            states: {
                coach: {
                    initial: State.coachDisconnected,
                    states: {
                        [State.coachDisconnected]: {
                            on: {
                                [Action.coachConnects]: State.coachConnected,
                            },
                        },
                        [State.coachConnected]: {
                            on: {
                                [Action.coachDisconnects]:
                                    State.coachDisconnected,
                            },
                        },
                    },
                },
                coachVideo: {
                    initial: State.coachVideoUnsubscribed,
                    states: {
                        [State.coachVideoInitial]: {
                            on: {
                                '': [
                                    {
                                        cond: ({ booking }) =>
                                            booking?.isVideoSession === false,
                                        target: State.coachVideoUnsubscribed,
                                    },
                                    { target: State.coachVideoSubscribed },
                                ],
                            },
                        },
                        [State.coachVideoUnsubscribed]: {
                            on: {
                                [Action.subscribeCoachVideo]:
                                    State.coachVideoSubscribed,
                            },
                        },
                        [State.coachVideoSubscribed]: {
                            on: {
                                [Action.unsubscribeCoachVideo]:
                                    State.coachVideoSubscribed,
                            },
                        },
                    },
                },
                coachAudio: {
                    initial: State.coachAudioUnsubscribed,
                    states: {
                        [State.coachAudioUnsubscribed]: {
                            on: {
                                [Action.subscribeCoachAudio]:
                                    State.coachAudioSubscribed,
                            },
                        },
                        [State.coachAudioSubscribed]: {
                            on: {
                                [Action.unsubscribeCoachAudio]:
                                    State.coachAudioSubscribed,
                            },
                        },
                    },
                },
                coachee: {
                    id: 'coachee',
                    initial: State.isPresent,
                    states: {
                        [State.isPresent]: {
                            on: {
                                [Action.initLeaving]: State.isLeaving,
                                [Action.openSettings]: State.isViewingSettings,
                            },
                        },
                        [State.isViewingSettings]: {
                            on: {
                                [Action.closeSettings]: State.isPresent,
                            },
                        },
                        [State.isLeaving]: {
                            on: {
                                [Action.confirmLeaving]: {
                                    target: State.isRating,
                                    actions: [
                                        Action.disconnect,
                                        Action.trackDisconnected,
                                    ],
                                },
                                [Action.cancelLeaving]: State.isPresent,
                            },
                        },
                        [State.isRating]: {
                            entry: Action.trackRatingOpen,
                            on: {
                                [Action.rateCoach]: {
                                    target: State.hasRated,
                                    actions: [
                                        Action.postRating,
                                        Action.trackRatingSent,
                                    ],
                                },
                            },
                        },
                        [State.hasRated]: {},
                    },
                },
                coacheeAudio: {
                    id: 'coacheeAudio',
                    initial: State.coacheeAudioOn,
                    states: {
                        [State.coacheeAudioOn]: {
                            on: {
                                [Action.toggleCoacheeAudio]: {
                                    target: State.coacheeAudioOff,
                                    actions: [Action.disableAudio],
                                },
                            },
                        },
                        [State.coacheeAudioOff]: {
                            on: {
                                [Action.toggleCoacheeAudio]: {
                                    target: State.coacheeAudioOn,
                                    actions: Action.enableAudio,
                                },
                            },
                        },
                    },
                },
                coacheeVideo: {
                    id: 'coacheeVideo',
                    initial: State.coacheeVideoInitial,
                    states: {
                        [State.coacheeVideoInitial]: {
                            on: {
                                '': [
                                    {
                                        cond: ({ booking }) =>
                                            booking?.isVideoSession === false,
                                        target: State.coacheeVideoOff,
                                        actions: Action.disableVideo,
                                    },
                                    { target: State.coacheeVideoOn },
                                ],
                            },
                        },
                        [State.coacheeVideoOn]: {
                            on: {
                                [Action.toggleCoacheeVideo]: {
                                    target: State.coacheeVideoOff,
                                    actions: [Action.disableVideo],
                                },
                            },
                        },
                        [State.coacheeVideoOff]: {
                            on: {
                                [Action.toggleCoacheeVideo]: {
                                    target: State.coacheeVideoOn,
                                    actions: [Action.enableVideo],
                                },
                            },
                        },
                    },
                },
                changeDevice: {
                    id: 'changeDevice',
                    initial: State.changeInputDevice,
                    states: {
                        [State.changeInputDevice]: {
                            on: {
                                [Action.changeInputDevice]: {
                                    actions: [Action.loadDevice],
                                },
                            },
                        },
                    },
                },
                updateDominantSpeaker: {
                    id: 'updateDominantSpeaker',
                    initial: State.updateDominantSpeaker,
                    states: {
                        [State.updateDominantSpeaker]: {
                            on: {
                                [Action.dominantSpeakerChanged]: {
                                    actions: assign({
                                        sessionSettings: (ctx, data) => {
                                            return {
                                                activeSpeaker: data.participant,
                                            }
                                        },
                                    }),
                                },
                            },
                        },
                    },
                },
                updateNetworkQuality: {
                    id: 'updateNetworkQuality',
                    initial: State.updateNetworkQuality,
                    states: {
                        [State.updateNetworkQuality]: {
                            on: {
                                [Action.updateNetworkQuality]: {
                                    actions: assign({
                                        networkQuality: (ctx, data) => {
                                            const participantToUpdate =
                                                ctx.networkQuality?.find(
                                                    (participant) => {
                                                        return (
                                                            participant._id ===
                                                            data.identity
                                                        )
                                                    },
                                                )

                                            if (participantToUpdate) {
                                                participantToUpdate.value =
                                                    data.value
                                            } else {
                                                ctx.networkQuality.push({
                                                    _id: data.identity as string,
                                                    value: data.value as number,
                                                })
                                            }

                                            return ctx.networkQuality
                                        },
                                    }),
                                },
                            },
                        },
                    },
                },
                chanceTrackStatus: {
                    id: 'chanceTrackStatus',
                    initial: State.chanceTrackStatus,
                    states: {
                        [State.chanceTrackStatus]: {
                            on: {
                                [Action.chanceTrackStatus]: {
                                    actions: assign({
                                        trackStatuses: (ctx, data) => {
                                            const participantToUpdate =
                                                ctx.trackStatuses?.find(
                                                    (participant) => {
                                                        return (
                                                            participant._id ===
                                                                data.participant
                                                                    .identity &&
                                                            participant.type ===
                                                                data.track.kind
                                                        )
                                                    },
                                                )

                                            if (participantToUpdate) {
                                                participantToUpdate.enabled =
                                                    data.enabled
                                            } else {
                                                ctx.trackStatuses.push({
                                                    _id: data.participant
                                                        .identity as string,
                                                    enabled: data.enabled,
                                                    type: data.track.kind,
                                                })
                                            }
                                            return ctx.trackStatuses
                                        },
                                    }),
                                },
                            },
                        },
                    },
                },
            },
        },
    },
}

// Uncomment this to serialize the state machine config when browsing the app
// if (import.meta.env.NODE_ENV !== 'production') {
//   console.log(JSON.stringify(machineConfig, null, 2))
// }

export default machineConfig
