import { classNames } from '../../utils/misc'

import BlueTickImage from '../../assets/icons/blue-check-circle.svg'

interface Props {
    value: string
    className?: string
    classNameText?: string
}

export const TickList: React.FC<Props> = ({
    value,
    className,
    classNameText,
}) => {
    return (
        <div
            className={classNames(
                className,
                'h-[33px]',
                'flex-grow-0 m-0 mr-[11px]',
                'p-0 pt-[3px] pb-[4px]',
                'rounded-[50px] bg-[#eefbfd]',
                'flex items-center ',
            )}
        >
            <img height="17" width="17" src={BlueTickImage} className="ml-2" />
            <span
                className={classNames(
                    classNameText,
                    'pl-2 pr-4',
                    'text-center text-[#090714] text-[14px]',
                    'leading-[1.2] font-normal tracking-normal',
                )}
            >
                {value}
            </span>
        </div>
    )
}
