import { motion } from 'framer-motion'
import React from 'react'
import IconCircle from '../../../assets/icons/check-circle-open.svg'
import IconExclamation from '../../../assets/icons/exclamation-circle.svg'
import UrgentIcon from '../../../assets/icons/info-icon.svg'
import { NotificationType } from '../../../utils/constants'
import { classNames } from '../../../utils/misc'

interface Props {
    type: NotificationType
    isUrgent?: boolean
    message: string
    className?: string
}

export const PopupNotification: React.FC<Props> = ({
    type,
    isUrgent,
    message,
    className,
}) => {
    const infoIcon = isUrgent ? UrgentIcon : IconCircle
    const iconSrc = type === NotificationType.INFO ? infoIcon : IconExclamation
    return (
        <motion.div
            className={classNames(
                'rounded-xl w-full sm:w-[540px]',
                'flex items-start',
                'p-3 space-x-3 backdrop-blur-xl',
                'text-accent-2 text-xl',
                className,
                type === NotificationType.ERROR && 'text-[#ef5658]',
            )}
            style={{
                background: 'rgba(255, 255, 255, 0.9)',
                boxShadow:
                    '0px 2px 10px 2px rgba(17, 18, 55, 0.04), 0px 8px 12px rgba(17, 18, 55, 0.04)',
            }}
        >
            <img src={iconSrc} alt="Icon" className="h-6 w-6" />
            <span>{message}</span>
        </motion.div>
    )
}
