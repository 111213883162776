import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { Observable, tap } from 'rxjs'
import { EditGuestModel } from '../models/request/booking'
import { BookingModel } from '../models/request/booking/booking.model'
import { InviteGuestModel } from '../models/request/booking/invite-guest.model'
import { RescheduleBookingModel } from '../models/request/booking/reschedule-booking.model'
import { Booking, Participant } from '../models/response'
import { HttpMethod } from '../utils/constants'
import {
    Resettable,
    hydrateFromStorage,
    removeFromStorage,
} from '../utils/misc'
import { Response, request } from '../utils/request'

const BOOKING_KEY = 'SAMA:BOOKINGS'

export class BookingStore implements Resettable {
    public bookings: Booking[] = []
    public participants: Participant[] = []
    public nearestSessionEnded: boolean = false

    @computed
    public get nearestBooking(): Booking {
        return this.bookings[0]
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {
        this.bookings = hydrateFromStorage(BOOKING_KEY) ?? []
    }

    @action
    public setNearestSessionEnded(value: boolean): void { 
        this.nearestSessionEnded = value
    }

    @action
    public getCoacheeBookings() {
        return request('/me/coachee/bookings', HttpMethod.GET, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.bookings = response.data
                    }
                })
            }),
        )
    }

    @action
    public getSessionUrl(
        bookingId: string,
    ): Observable<Response<{ url: string }>> {
        return request(`/booking/${bookingId}/url`, HttpMethod.GET, {})
    }

    @action
    public createBookings(model: BookingModel) {
        return request('/booking/', HttpMethod.POST, {
            body: model.getRequestBody(),
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public getBooking(bookingId: string) {
        return request(`/booking/${bookingId}`, HttpMethod.GET, {})
    }

    @action
    public rescheduleBooking(model: RescheduleBookingModel) {
        return request(`/booking/${model._id}/reschedule`, HttpMethod.POST, {
            body: model.getRequestBody(),
            silentErrors: true,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public editBookingGuest(
        model: EditGuestModel,
        bookingId: string,
        participantId: string,
    ) {
        return request(
            `/booking/${bookingId}/${participantId}/guest`,
            HttpMethod.POST,
            {
                body: model,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public removeBookingGuest(bookingId: string, participantId: string) {
        return request(
            `/booking/${bookingId}/${participantId}/remove-guest`,
            HttpMethod.DELETE,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public deleteBooking(bookingId: string) {
        return request(`/booking/${bookingId}`, HttpMethod.DELETE, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public addBookingGuest(model: InviteGuestModel, bookingId: string) {
        return request(`/booking/${bookingId}/add-guest`, HttpMethod.PUT, {
            body: model.getRequestBody(),
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeBookings().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.bookings = []
        removeFromStorage(BOOKING_KEY)
    }
}
