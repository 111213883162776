import classNames from 'classnames'
import Linkify from 'linkify-react'
import { observer } from 'mobx-react'
import UrgentIcon from '../../../assets/icons/info-icon.svg'
import UrgentCoachIcon from '../../../assets/icons/urgent_coach.svg'
import { ChatMessage as Message } from '../../../models/response/channel'
import { AudioMessage } from './audioMessage'
import { FileMessage } from './fileMessage'

interface Props {
    message: Message
    isCoachMsg: boolean
    coachId?: string
}

export const ChatMessage: React.FC<Props> = observer(
    ({ message, isCoachMsg, coachId }) => {
        const linkifyOptions = {
            target: '_blank',
            rel: 'noopener noreferrer',
            className: 'underline',
        }
        return (
            <div
                className={classNames(
                    isCoachMsg ? 'items-start mb-3 ' : 'items-end mb-4',
                    'flex w-full flex-col',
                )}
            >
                <div
                    className={classNames(
                        isCoachMsg && 'flex items-end',
                        'min-w-auto max-w-[350px] lg:max-w-[500px]',
                    )}
                >
                    <div
                        className={classNames(
                            isCoachMsg
                                ? 'bg-accent text-white'
                                : 'bg-white text-[#10123a]',
                            message.isUrgent && 'pb-8',
                            'rounded-[0.5rem] whitespace-pre-wrap p-4 max-w-[588px] relative',
                            coachId ? 'max-w-[100%]' : '',
                        )}
                    >
                        {message.isMedia &&
                        message.mediaDetails.contentType !== 'audio/mp4' ? (
                            <FileMessage
                                mediaDetail={message.mediaDetails}
                                isCoachMsg={isCoachMsg}
                                isUrgent={message.isUrgent}
                            />
                        ) : message.isMedia &&
                          message.mediaDetails.contentType === 'audio/mp4' ? (
                            <AudioMessage
                                mediaDetail={message.mediaDetails}
                                isCoachMsg={isCoachMsg}
                            />
                        ) : (
                            <div>
                                <Linkify options={linkifyOptions}>
                                    {message.message}
                                </Linkify>

                                {message.isUrgent && (
                                    <img
                                        src={
                                            isCoachMsg
                                                ? UrgentCoachIcon
                                                : UrgentIcon
                                        }
                                        alt="Urgent"
                                        className="h-4 w-4 absolute bottom-0 right-0 mt-4 mb-1 mr-1"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    },
)
