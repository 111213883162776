import { IsNotEmpty, IsOptional, IsString } from 'class-validator'
import { BaseModel } from '../base.model'

export class NoteModel implements BaseModel {
    constructor() {}

    @IsOptional()
    public _id: string | undefined

    @IsString()
    @IsNotEmpty()
    public content: string = ''

    public getRequestBody() {
        return {
            _id: this._id,
            content: this.content,
        }
    }
}
