import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SideModal } from '../../../components/modals/side-modal'
import SideModalSkeleton from '../../../components/skeleton/sideSkeleton'
import { useStores } from '../../../utils/stores'
import { CompletedTaskList } from './completedTaskList'
import EmptyCompletedTask from '/src/assets/icons/empty-completedTask.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const CompletedTask: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()

        const { coachee } = useStores()
        const [isLoading, setIsLoading] = useState(true)
        const { taskCompleted } = coachee

        useEffect(() => {
            const subscription = coachee.getCompletedTask().subscribe({
                next: () => {
                    setIsLoading(false)
                },
                error: () => {
                    setIsLoading(false)
                },
            })

            return () => {
                subscription.unsubscribe()
            }
        }, [coachee])

        return (
            <SideModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
                title="tasks_completed"
            >
                <div className="rounded-lg bg-white max-w-[28rem] w-full">
                    <div className="flex flex-col px-8 py-7 sticky top-0 z-20 bg-white">
                        <span className="text-[32px] lg:text-[38px]">
                            {t('leaderboard.tasksCompleted')}
                        </span>
                    </div>

                    {isLoading ? (
                        <SideModalSkeleton />
                    ) : taskCompleted.length === 0 ? (
                        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-10">
                            <div className="flex flex-col items-center justify-center mt-10">
                                <img
                                    src={EmptyCompletedTask}
                                    className="w-[6rem] h-[6rem] m-auto"
                                />
                                <span className="text-[22px] mt-4 px-8 text-center">
                                    {t('leaderboard.completedTasksDesc')}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <CompletedTaskList />
                    )}
                </div>
            </SideModal>
        )
    },
)
