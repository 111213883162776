import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import doneIcon from '../../assets/icons/checkmark.svg'

import Popup from './Popup'

export default function VoteSubmitted({
    when,
}: {
    when: boolean
}): ReactElement {
    const { t } = useTranslation()

    return (
        <Popup
            when={when}
            icon={doneIcon.toString()}
            title={t('VoteSubmitted.title')}
        >
            <ReactMarkdown>{t('VoteSubmitted.body') as string}</ReactMarkdown>
        </Popup>
    )
}
