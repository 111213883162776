import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddCoachGenderModel } from '../../../models/request/onboarding'
import { Stage, coachGender } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const CoachGenderStage: React.FC<Props> = observer(
    ({ currentStage }) => {
        const { t } = useTranslation()
        const { coachee } = useStores()
        const [subscriptions] = useState<Subscription[]>([])

        const setGender = useCallback(
            (
                values: AddCoachGenderModel,
                helpers: FormikHelpers<AddCoachGenderModel>,
            ) => {
                helpers.setSubmitting(true)

                cancelSubscriptions(subscriptions)

                const subscription = coachee.updateCoachee(values).subscribe({
                    next(response) {
                        if (response.ok && coachee.coachee) {
                            coachee.setOnboardingStage(Stage.LANGUAGE)
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.coachGender = values.coachGender
                            coachee.setCoachee(newCoachee)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                    },
                })

                subscriptions.push(subscription)
            },
            [coachee, subscriptions],
        )

        return (
            <QuestionForm
                title={t('onboarding.coachGender.title')}
                currentNumber={currentStage}
                totalNumber={
                    coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
                }
                questions={
                    coachGender[
                        (coachee.coachee?.user
                            ?.appLanguage as keyof typeof coachGender) ??
                            ('en' as keyof typeof coachGender)
                    ]
                }
                name="coachGender"
                model={new AddCoachGenderModel(coachee.coachee?.coachGender)}
                onSubmit={setGender}
                isOnboarding={true}
                onPrevious={() =>
                    coachee.setOnboardingStage(
                        coachee.onboardingStage.stage - 1,
                    )
                }
            />
        )
    },
)
