import {
    IsNotEmpty,
    IsObject,
    IsOptional,
    IsPhoneNumber,
    Matches,
    ValidateIf,
} from 'class-validator'
import { t } from 'i18next'
import { Option } from '../../response'
import { BaseModel } from '../base.model'

export class PersonalCoacheeModel implements BaseModel {
    constructor(
        phone: string | undefined = '',
        gender: Option | null = null,
        age: Option | null = null,
        position: Option | null = null,
        industry: Option | null = null,
        funct: Option | null = null,
        appLanguage: Option | null = null,
        genderSpecified: string | undefined = '',
    ) {
        ;(this.phone = phone),
            (this.gender = gender),
            (this.age = age),
            (this.position = position),
            (this.industry = industry),
            (this.funct = funct),
            (this.appLanguage = appLanguage),
            (this.genderSpecified = genderSpecified)
    }

    @IsPhoneNumber(undefined, {
        message: 'validation.invalidPhone',
    })
    public phone: string = ''

    @IsOptional()
    public phoneCountry: string = ''

    @IsObject()
    @IsOptional()
    public gender: Option | null = null

    @IsObject()
    @IsOptional()
    public age: Option | null = null

    @IsObject()
    @IsOptional()
    public position: Option | null = null

    @IsObject()
    @IsOptional()
    public industry: Option | null = null

    @IsObject()
    @IsOptional()
    public funct: Option | null = null

    @IsObject()
    @IsOptional()
    public appLanguage: Option | null = null

    @ValidateIf(
        (object) => object.gender !== null && object.gender.value === 'other',
    )
    @IsNotEmpty({
        message: t('validation.mustBeSet') as string,
    })
    @Matches(/\S/, {
        message: t('validation.mustBeSet') as string,
    })
    public genderSpecified: string = ''

    public getRequestBody() {
        return {
            gender: this.gender?.value,
            position: this.position?.value,
            industry: this.industry?.value,
            function: this.funct?.value,
            genderSpecified: this.genderSpecified,
            user: {
                ageRange: this.age?.value,
                phoneNumber: this.phone,
                phoneCountry: this.phoneCountry,
                appLanguage: this.appLanguage?.value,
            },
        }
    }
}
