import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SideModal } from '../../../components/modals/side-modal'
import SideModalSkeleton from '../../../components/skeleton/sideSkeleton'
import { useStores } from '../../../utils/stores'
import { CheckInsList } from './checkInsList'
import EmptyCheckIns from '/src/assets/icons/empty-checkIns.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const CheckIns: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()

    const { coachee } = useStores()
    const [isLoading, setIsLoading] = useState(true)

    const { checkIns } = coachee

    useEffect(() => {
        const subscription = coachee?.getCheckIns()?.subscribe({
            next: () => {
                setIsLoading(false)
            },
            error: () => {
                setIsLoading(false)
            },
        })

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    return (
        <SideModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            className="max-w-[28rem]"
            title="assessment_history"
        >
            <div className="rounded-lg bg-white max-w-[28rem] w-full">
                <div className="flex flex-col px-8 py-7 sticky top-0 z-20 bg-white">
                    <span className="text-[32px] lg:text-[38px]">
                        {t('leaderboard.checkIns')}
                    </span>
                </div>

                {isLoading ? (
                    <SideModalSkeleton />
                ) : checkIns.length === 0 ? (
                    <div className="rounded-lg bg-[#fafafa] w-full h-screen py-10">
                        <div className="flex flex-col items-center justify-center mt-10">
                            <img
                                src={EmptyCheckIns}
                                className="w-[6rem] h-[6rem] m-auto"
                            />
                            <span className="text-[22px] mt-4 text-center">
                                {t('leaderboard.checkInsDesc')}
                            </span>
                        </div>
                    </div>
                ) : (
                    <CheckInsList />
                )}
            </div>
        </SideModal>
    )
})
