import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'

import './i18n'
import './index.css'

import reportWebVitals from './reportWebVitals'

import createCache from '@emotion/cache'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { history } from '../src/utils/misc'

Sentry.init({
    dsn: 'https://ffe22039e9734317ae342418f496eedb@o784619.ingest.sentry.io/5799157',
    environment: import.meta.env.NODE_ENV,
    tracesSampleRate: 1.0,
})

let newNonce = null
const getNonce = (element: Element) => {
    const contentArray = element.getAttribute('content')?.split('style-src ')
    if (contentArray?.length === 2) {
        const content = contentArray[1]
            .split(' ')[1]
            .replace('nonce-', '')
            .replace("'", '')

        return content.substring(0, content.length - 1)
    }
}

const metaElements = document.getElementsByTagName('META')
for (const element of metaElements) {
    if (element.getAttribute('http-equiv') === 'Content-Security-Policy') {
        newNonce = getNonce(element)
    }
}

const myCache = createCache({
    key: 'sama-key',
    nonce: newNonce ?? '',
    prepend: false,
})

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    // <React.StrictMode>
    // <CacheProvider value={myCache}>

    // @ts-expect-error: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301954272
    <HistoryRouter history={history}>
        <App />
    </HistoryRouter>,
    // </CacheProvider>,
    // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
