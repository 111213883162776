import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddFunctionModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const FunctionStage: React.FC<Props> = observer(({ currentStage }) => {
    const { t } = useTranslation()
    const { statics, coachee } = useStores()
    const [subscriptions] = useState<Subscription[]>([])

    const setFunction = useCallback(
        (
            values: AddFunctionModel,
            helpers: FormikHelpers<AddFunctionModel>,
        ) => {
            helpers.setSubmitting(true)

            cancelSubscriptions(subscriptions)

            const subscription = coachee.updateCoachee(values).subscribe({
                next(response) {
                    if (response.ok && coachee.coachee) {
                        coachee.setOnboardingStage(Stage.POSITION)
                        const newCoachee = { ...coachee.coachee }
                        newCoachee.function = values.function
                        coachee.setCoachee(newCoachee)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [coachee, subscriptions],
    )

    return (
        <QuestionForm
            title={t('onboarding.function.title')}
            currentNumber={currentStage}
            totalNumber={
                coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
            }
            questions={statics.functions}
            name="function"
            model={new AddFunctionModel(coachee.coachee?.function)}
            onSubmit={setFunction}
            isOnboarding={true}
            onPrevious={() =>
                coachee.setOnboardingStage(coachee.onboardingStage.stage - 1)
            }
        />
    )
})
