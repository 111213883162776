import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddCoachingLanguageModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public coachingLanguage: string = ''

    constructor(coachingLanguage = '') {
        this.coachingLanguage = coachingLanguage
    }

    public getRequestBody() {
        return {
            coachingLanguage: this.coachingLanguage,
        }
    }
}
