import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { Action, State, TEvent } from '../../stores/bookingMachine/config'
import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function RecentBrowserPrompt({
    when,
    send,
}: {
    when: boolean
    send: (event: TEvent) => State
}): ReactElement {
    const { t } = useTranslation()

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('RecentBrowserPrompt.title')}
        >
            <ReactMarkdown>
                {t('RecentBrowserPrompt.body') as string}
            </ReactMarkdown>
            <button
                className="cancel"
                onClick={() => send({ type: Action.ignoreRecentBrowser })}
            >
                {t('RecentBrowserPrompt.proceed')}
            </button>
        </Popup>
    )
}
