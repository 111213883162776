import { Formik } from 'formik'
import { clone } from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingButtons } from '../../../components/buttons/onboarding-button'
import { OptionInput } from '../../../components/inputs/option-input'
import { RateInput } from '../../../components/inputs/rate-input'
import { FormValidationMessage } from '../../../components/layout'
import { classNames } from '../../../utils/misc/class-names'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'

interface Props {
    title: string
    subTitle?: string
    questions: Question[]
    totalNumber: number
    currentNumber?: number
    name: string
    model: any
    onSubmit: any
    expand?: boolean
    rating?: boolean
    showBackArrow?: boolean
    buttonTranslationKey?: string
    className?: string
    maxSelectedOptions?: number
    extraComponent?: React.ReactNode
    canSkip?: boolean
    dropDownIcon?: boolean
    isOnboarding?: boolean
    iconActiveClassNames?: string
    iconClassNames?: string
    onPrevious?: () => void
    enableReinitialize?: boolean
    showMainError?: boolean
}

export interface Question {
    key: string
    name: string
    description?: string
    icon?: string
}

export const QuestionForm: React.FC<Props> = ({
    title,
    subTitle,
    questions,
    totalNumber,
    currentNumber,
    name,
    expand,
    rating,
    model,
    onSubmit,
    showBackArrow,
    buttonTranslationKey,
    className,
    maxSelectedOptions,
    extraComponent,
    iconClassNames,
    iconActiveClassNames,
    onPrevious,
    enableReinitialize = false,
    isOnboarding = false,
    dropDownIcon = false,
    canSkip = false,
    showMainError = true,
}) => {
    const { t } = useTranslation()
    const { coachee } = useStores()

    const getDisplayCurrentNumber = useCallback(() => {
        let number = clone(currentNumber)
        if (number && isOnboarding) {
            if (
                !coachee.onboardingOptions?.onboardingQuestions.ageRange.enabled
            ) {
                number--
            }
            if (
                !coachee.onboardingOptions?.onboardingQuestions.gender.enabled
            ) {
                number--
            }
        }
        return number
    }, [
        coachee.onboardingOptions?.onboardingQuestions.ageRange.enabled,
        coachee.onboardingOptions?.onboardingQuestions.gender.enabled,
        currentNumber,
        isOnboarding,
    ])

    return (
        <Formik
            validate={validateModel}
            initialValues={model}
            onSubmit={onSubmit}
            enableReinitialize={enableReinitialize}
        >
            {({ handleSubmit, isValid, dirty, setStatus, status, values }) => {
                const isRequiredValues = maxSelectedOptions
                    ? values.topValues.length < maxSelectedOptions
                    : false
                return (
                    <form
                        onSubmit={handleSubmit}
                        className={classNames(
                            className,
                            'max-w-[30rem] w-full px-5 md:p-0',
                        )}
                    >
                        <header className="flex flex-col mt-4">
                            {currentNumber && (
                                <div className="flex">
                                    <span className="uppercase text-[#6b6a72] text-sm font-bold">
                                        {t('onboarding.question', {
                                            number: getDisplayCurrentNumber(),
                                            total: totalNumber,
                                        })}
                                    </span>
                                    {canSkip && (
                                        <span
                                            onClick={() =>
                                                coachee.setOnboardingStage(
                                                    coachee.onboardingStage
                                                        .stage + 1,
                                                )
                                            }
                                            className={classNames(
                                                'cursor-pointer',
                                                'font-semibold ml-auto',
                                                'uppercase text-[#002b38] text-sm',
                                            )}
                                        >
                                            {t('others.skip')}
                                        </span>
                                    )}
                                </div>
                            )}

                            <span className="text-[2rem] font-semibold mt-2">
                                {title}
                            </span>

                            <span className="text-xl">{subTitle}</span>

                            {showMainError && !isValid && (
                                <div className="mt-[10px] error">
                                    <FormValidationMessage
                                        message={t('validation.thereIsAnError')}
                                    />
                                </div>
                            )}
                        </header>
                        <main className="mt-8 pb-[70px]">
                            {questions.map((question, i) =>
                                rating ? (
                                    <RateInput
                                        name={question.key}
                                        label={question.name}
                                        key={i}
                                        min={1}
                                        max={5}
                                        setStatus={setStatus}
                                    />
                                ) : (
                                    <OptionInput
                                        name={name}
                                        label={question.name}
                                        labelKey={question.key}
                                        maxSelectedOptions={maxSelectedOptions}
                                        key={i}
                                        expand={expand}
                                        description={question.description}
                                        icon={question.icon}
                                        iconClassNames={iconClassNames}
                                        iconActiveClassNames={
                                            iconActiveClassNames
                                        }
                                        setStatus={setStatus}
                                        dropDownIcon={dropDownIcon}
                                    />
                                ),
                            )}

                            {extraComponent && extraComponent}
                            <div className="md:h-2 h-16"></div>
                        </main>

                        <OnboardingButtons
                            showArrow={showBackArrow}
                            buttonTranslationKey={buttonTranslationKey}
                            disabled={
                                !isValid ||
                                (!dirty && status !== 'updated') ||
                                isRequiredValues
                            }
                            onPrevious={onPrevious}
                        />
                    </form>
                )
            }}
        </Formik>
    )
}

export default QuestionForm
