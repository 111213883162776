import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddCoachGenderModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public coachGender: string = ''

    constructor(coachGender = '') {
        this.coachGender = coachGender
    }

    public getRequestBody() {
        return {
            coachGender: this.coachGender,
        }
    }
}
