import { Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { OnboardingButtons } from '../../../../components/buttons/onboarding-button'
import { PhoneCodeInput } from '../../../../components/inputs/phonecode-input'
import { AddPhoneModel } from '../../../../models/request'
import { validateModel } from '../../../../utils/validation/validate-model'

interface Props {
    title: string
    subTitle?: string
    onSubmit: (
        model: AddPhoneModel,
        helpers: FormikHelpers<AddPhoneModel>,
    ) => any
}

export const SetPhoneForm: React.FC<Props> = ({
    title,
    subTitle,
    onSubmit,
}) => {
    const { t } = useTranslation()
    return (
        <Formik
            validate={validateModel}
            initialValues={new AddPhoneModel()}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                isSubmitting,
                handleChange,
                errors,
                touched,
                handleBlur,
                setFieldValue,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className="flex-col max-w-[30rem] w-full px-5 md:p-0"
                >
                    <header className="mb-10">
                        <span className="inline-flex text-[3.5rem]">
                            {title},
                        </span>
                        <span className="inline-flex text-[1.4rem]">
                            {subTitle}
                        </span>
                    </header>
                    <main className="space-y-3 mb-6">
                        <PhoneCodeInput
                            name="phone"
                            label={t('fields.phone.label') as string}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={errors}
                            disabled={isSubmitting}
                            setFieldValue={setFieldValue}
                            placeholder={
                                t('fields.phone.placeholder') as string
                            }
                        />
                    </main>
                    <OnboardingButtons showArrow={false} />
                </form>
            )}
        </Formik>
    )
}
