import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from '../../../components/misc/progress-bar'
import { CenterModal } from '../../../components/modals'
import { AddOutcomeAssessmentModel } from '../../../models/request/question/outcome-assessment.model'
import { OutcomeAssessmentQuestion } from '../../../models/response/questions/outcome-assessment-questions'
import { useStores } from '../../../utils/stores'
import { Question, QuestionForm } from '../../onboarding/forms'

import GladIcon from '/src/assets/questions/faces/glad.svg'
import NeutralIcon from '/src/assets/questions/faces/neutral.svg'
import SadIcon from '/src/assets/questions/faces/sad.svg'
import SlightlyGladIcon from '/src/assets/questions/faces/slightly-glad.svg'
import SlightlySadIcon from '/src/assets/questions/faces/slightly-sad.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const answerTextKey = ['low', 'moderate', 'good', 'strong', 'peak']
const answerImageKey = [
    SadIcon,
    SlightlySadIcon,
    NeutralIcon,
    SlightlyGladIcon,
    GladIcon,
]

const MAX_NUMBER_OF_QUESTIONS = 5

export const OutcomeAssessmentModel: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { question } = useStores()
        const { t } = useTranslation()
        const [assessmentQuestions, setAssessmentQuestions] =
            useState<OutcomeAssessmentQuestion[]>()
        const [currentQuestion, setCurrentQuestion] = useState<number>(0)

        useEffect(() => {
            question.getOutcomeAssessmentQuestions().subscribe({
                next(response) {
                    if (response.ok && response.data) {
                        setAssessmentQuestions(response.data)
                    }
                },
            })
            // if you add a dependency to this array, it will cause api request
            // that are not needed so think twice.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const onPrevious = useCallback(() => {
            if (currentAnswer !== 0) {
                setCurrentQuestion(currentQuestion - 1)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentQuestion])

        const saveQuestion = useCallback(
            (values: AddOutcomeAssessmentModel, { resetForm }: any) => {
                resetForm()
                if (
                    assessmentQuestions &&
                    assessmentQuestions[currentQuestion]
                ) {
                    const questionId = assessmentQuestions[currentQuestion]._id
                    question
                        .saveOutcomeAssessmentQuestions(questionId, values)
                        .subscribe({
                            next(response) {
                                if (response.ok) {
                                    setCurrentQuestion(currentQuestion + 1)
                                    const newQuestions = [
                                        ...assessmentQuestions,
                                    ]
                                    if (values.OAQuestion) {
                                        newQuestions[currentQuestion].answer =
                                            Number(values.OAQuestion)
                                        setAssessmentQuestions(newQuestions)
                                    }
                                    if (currentQuestion === 4) {
                                        setIsOpen(false)
                                    }
                                }
                            },
                        })
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [assessmentQuestions, currentQuestion, question],
        )

        const getQuestions = useCallback(() => {
            const options: Question[] = []
            for (let i = 1; i < 6; i++) {
                options.unshift({
                    key: i + '',
                    name:
                        i +
                        ' - ' +
                        t(
                            'questions.outcomeAssessment.answers.' +
                                answerTextKey[i - 1],
                        ),
                    icon: answerImageKey[i - 1],
                })
            }
            return options
        }, [t])

        const currentAnswer = useMemo(() => {
            if (
                assessmentQuestions &&
                assessmentQuestions[currentQuestion] &&
                assessmentQuestions[currentQuestion].answer
            ) {
                return assessmentQuestions[currentQuestion].answer
            }
            return null
        }, [assessmentQuestions, currentQuestion])

        const progress = useMemo(() => {
            return ((currentQuestion + 1) / MAX_NUMBER_OF_QUESTIONS) * 100
        }, [currentQuestion])

        return (
            <>
                {isOpen && assessmentQuestions && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        className="max-w-[36rem] h-full overflow-y-auto pt-2"
                        showCloseButton={true}
                    >
                        <div className="h-[200px] px-10 ">
                            {t('questions.outcomeAssessment.title')}
                            <ProgressBar
                                containerClassNames={
                                    'items-center justify-center m-auto w-full mb-5'
                                }
                                barClassNames="h-[8px] "
                                progress={progress}
                            />
                            <QuestionForm
                                title={
                                    assessmentQuestions &&
                                    assessmentQuestions[currentQuestion] &&
                                    assessmentQuestions[currentQuestion]
                                        .question
                                }
                                currentNumber={currentQuestion + 1}
                                totalNumber={MAX_NUMBER_OF_QUESTIONS}
                                questions={getQuestions()}
                                name={'OAQuestion'}
                                enableReinitialize={true}
                                model={
                                    new AddOutcomeAssessmentModel(currentAnswer)
                                }
                                onSubmit={saveQuestion}
                                showBackArrow={
                                    currentQuestion !== 0 ? true : false
                                }
                                iconClassNames="opacity-20"
                                iconActiveClassNames="opacity-100"
                                onPrevious={onPrevious}
                                className="max-w-none"
                            />
                        </div>
                    </CenterModal>
                )}
            </>
        )
    },
)
