import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { TextInput } from '../../../components/inputs/text-input'
import { CenterModal } from '../../../components/modals'
import { EditGuestModel } from '../../../models/request/booking'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    guestName: string
    bookingId: string
    participantId: string
}

export const EditGuestModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, guestName, bookingId, participantId }) => {
        const { t } = useTranslation()
        const { booking } = useStores()

        const onSubmit = useCallback(
            (
                values: EditGuestModel,
                helpers: FormikHelpers<EditGuestModel>,
            ) => {
                helpers.setSubmitting(true)
                booking
                    .editBookingGuest(values, bookingId, participantId)
                    .subscribe({
                        next(response) {
                            if (response.ok) {
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            helpers.setSubmitting(false)
                        },
                    })
            },
            [booking, bookingId, participantId, setIsOpen],
        )

        return (
            <div>
                {isOpen && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={() => setIsOpen(false)}
                        className="max-w-[32rem]"
                        headerTitle={t('modals.editGuest.heading')}
                    >
                        <main className="mt-8 bg-[#fafafa] w-full py-2 px-5">
                            <Formik
                                validate={validateModel}
                                initialValues={new EditGuestModel(guestName)}
                                onSubmit={onSubmit}
                            >
                                {({ handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex flex-col justify-center items-center">
                                            <TextInput
                                                name="name"
                                                inputClassName='bg-white'
                                                className="w-full"
                                                label={
                                                    t(
                                                        'fields.name.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.name.placeholder',
                                                    ) as string
                                                }
                                                disabled={isSubmitting}
                                            />
                                            <PrimaryButton
                                                className="w-full m-8"
                                                type="submit"
                                            >
                                                <span>{t('buttons.save')}</span>
                                            </PrimaryButton>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </main>
                    </CenterModal>
                )}
            </div>
        )
    },
)
