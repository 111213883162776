import { differenceInMinutes, set } from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale/en-US'
import { fr } from 'date-fns/locale/fr'
import i18next from 'i18next'

const getLocale = (lang: string) => {
    const cleanedLang = lang.slice(0, 2)
    if (cleanedLang === 'fr') {
        return fr
    } else {
        return enUS
    }
}

export const getTimestamp = (date?: Date): number => {
    const milliseconds = date ? date.getTime() : Date.now()
    return Math.floor(milliseconds / 1000)
}

export const prettyFormatDate = (date: Date | string | number) => {
    return format(date, getFormatString('date-time'), {
        locale: getLocale(i18next.language),
    })
}

export const formatTime = (date: Date) => {
    return format(date, getTimeFormatString(), {
        locale: getLocale(i18next.language),
    })
}

// Helper function to merge selected date and time
export const formatTimeAndDate = (time: string, selectedDay: Date) => {
    const newTime = timeToUTC(time)

    const [hours, minutesPart] = newTime.split(':')
    const minutes = minutesPart.slice(0, 2)
    const isPM = minutesPart.slice(2).toLowerCase().trim() === 'pm'
    const hoursInt = parseInt(hours, 10)
    let hours24
    if (isPM) {
        if (hoursInt < 12) {
            hours24 = hoursInt + 12
        } else {
            hours24 = hoursInt
        }
    } else {
        if (hoursInt === 12) {
            hours24 = 0
        } else {
            hours24 = hoursInt
        }
    }

    const selectedDateTime = set(selectedDay, {
        hours: hours24,
        minutes: parseInt(minutes, 10),
        seconds: 0,
        milliseconds: 0,
    })

    return format(selectedDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
        locale: getLocale(i18next.language),
    })
}

export const timeToUTC = (time: string) => {
    const [timePart, period] = time.split(' ')
    const [hours, minutes] = timePart.split(':')

    const hoursInt = parseInt(hours, 10)
    let hours24
    if (period) {
        if (period.toLowerCase() === 'pm') {
            if (hoursInt < 12) {
                hours24 = hoursInt + 12
            } else {
                hours24 = hoursInt
            }
        } else {
            if (hoursInt === 12) {
                hours24 = 0
            } else {
                hours24 = hoursInt
            }
        }
    } else {
        hours24 = hoursInt
    }

    const date = new Date()
    date.setHours(hours24)
    date.setMinutes(parseInt(minutes))

    const utcHours = date.getUTCHours()
    const utcMinutes = date.getUTCMinutes()

    const utcDate = new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            utcHours,
            utcMinutes,
        ),
    )

    const formattedTime = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC',
    }).format(utcDate)

    return formattedTime
}

export const getTimeFormatString = () => {
    switch (i18next.language) {
        case 'en':
            return 'h:mm a'
        case 'fr':
            return 'HH:mm'
        default:
            return 'HH:mm'
    }
}

export const getFormatString = (type: string) => {
    if (i18next.language === 'fr') {
        switch (type) {
            case 'extra-long':
                return 'EEE d MMMM, yyyy'
            case 'long':
                return 'dd MMMM, yyyy'
            case 'date-time':
                return 'do MMMM, yyyy ' + getTimeFormatString()
            case 'month-year':
                return 'MMMM, yyyy'
            default:
                return 'dd MMMM, yyyy'
        }
    } else {
        switch (type) {
            case 'extra-long':
                return 'EEE d MMM yyyy'
            case 'long':
                return 'dd MMMM yyyy'
            case 'date-time':
                return 'do MMMM yyyy ' + getTimeFormatString()
            case 'month-year':
                return 'MMMM yyyy'
            default:
                return 'dd MMMM yyyy'
        }
    }
}

export const formatDayDate = (date: number | Date) => {
    return format(date, getFormatString('long'), {
        locale: getLocale(i18next.language),
    })
}

export const formatDate = (date: number | Date | string, formatStr: string) => {
    return format(date, formatStr, {
        locale: getLocale(i18next.language),
    })
}

export const getGreetingKey = () => {
    const currentHour = new Date().getUTCHours()
    if (currentHour < 12) {
        return 'dashboard.greetingMorning'
    } else if (currentHour >= 12 && currentHour <= 18) {
        return 'dashboard.greetingAfternoon'
    } else {
        return 'dashboard.greetingEvening'
    }
}

export const formatDuration = (duration: number): string => {
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
}

export const sessionStartSoon = (date: Date, timezone?: string): boolean => {
    const sessionDate = toZonedTime(date, timezone ?? '')
    const mins = differenceInMinutes(sessionDate, new Date())

    if (mins <= 5) return true

    return false
}
