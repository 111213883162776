export enum RouteLink {
    ANY = '*',
    INDEX = '/',
    LOG_IN = '/login',
    LOG_OUT = '/logout',
    VERIFY_PASSWORD = '/login/password',
    SIGN_UP = '/signup',
    DASHBOARD = '/dashboard',
    RESET = '/reset',
    RESET_INITIAL_PASSWORD = '/reset/initial',
    FORGOTTEN_PASSWORD = '/forgotten-password',
    BOOKING = '/booking/:bookingId',
    TESTING = '/testing',
    MAGIC_LOGIN = '/magic-login',
    TWO_FA = '/login/2fa',
    ONBOARDING = '/onboarding',
    CHAT = '/chat',
    LEADERBOARD = '/leaderboard',
    ACCOUNT = '/account',
    GOALS = '/goals',
    TASKS = '/tasks',
    NOTES = '/notes',
    NEW_BOOKING = '/newBooking',
    LOADING_PAGE = '/loading',
    OKTA_LOGIN = '/auth/okta/sso',
    OKTA_CALLBACK = '/login/callback',
}
