import { Token } from '../types/SamaApi'

export default function parseToken(token: string): Token {
  const { userId, access, nbf, exp, objectData } = JSON.parse(
    atob(token.split('.')[1])
  )

  return {
    userId,
    objectData,
    bookingId: access.join().match(/booking\/([a-z0-9]+)\//)[1],
    notValidBefore: new Date(nbf * 1000),
    expiredAfter: new Date(exp * 1000),
  }
}
