import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { AddOutcomeAssessmentModel } from '../models/request/question/outcome-assessment.model'
import {
    FAQItem,
    OutcomeAssessmentQuestion,
} from '../models/response/questions/outcome-assessment-questions'
import { HttpMethod } from '../utils/constants'
import { Resettable } from '../utils/misc'
import { request } from '../utils/request'
import { stores } from '../utils/stores'

export class QuestionsStore implements Resettable {
    public outcomeAssessmentQuestions: OutcomeAssessmentQuestion[] = []
    public faq: FAQItem[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {}

    @action
    public reset(): void {}

    @action
    public getOutcomeAssessmentQuestions() {
        return request(
            `/coachee/${stores.coachee.coachee?._id}/assessment`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.outcomeAssessmentQuestions = response.data
                    }
                })
            }),
        )
    }

    @action
    public saveOutcomeAssessmentQuestions(
        questionId: string,
        model: AddOutcomeAssessmentModel,
    ) {
        return request(
            `/coachee/${stores.coachee.coachee?._id}/assessment/${questionId}`,
            HttpMethod.PUT,
            { body: model.getRequestBody() },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.outcomeAssessmentQuestions = response.data
                    }
                })
            }),
        )
    }

    @action
    public getFaq(language: string | undefined) {
        return request(`/faq?language=${language}`, HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.faq = response.data
                    }
                })
            }),
        )
    }
    @action
    public signOut(): void {
        this.reset()
    }
}
