import { observer } from 'mobx-react'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onClick: () => void
    message: string
    actionBtnText: string
    headerImg?: string
    actionBtnCancelText: string
    mainMessage?: string
    textHeader?: string
}

export const ConfirmationModal: React.FC<Props> = observer(
    ({
        isOpen,
        setIsOpen,
        onClick,
        message,
        headerImg,
        actionBtnText,
        actionBtnCancelText,
        mainMessage,
        textHeader,
    }) => {
        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
                showCloseButton={false}
            >
                <div className="px-9 pb-5">
                    {headerImg && (
                        <header className="flex flex-col">
                            <img
                                src={headerImg}
                                className="w-[6rem] h-[6rem] m-auto"
                            />
                        </header>
                    )}
                    {textHeader && (
                        <div className="flex flex-col mt-4 text-center">
                            <span className="text-2xl font-extrabold">
                                {textHeader}
                            </span>
                        </div>
                    )}

                    {mainMessage && (
                        <div className="flex flex-col mt-4 text-center">
                            <span className="text-xl">{mainMessage}</span>
                        </div>
                    )}

                    <main className="flex flex-col mt-4 text-center">
                        <span className="text-xl">{message}</span>
                    </main>

                    <footer className="flex pt-[25px]">
                        <PrimaryButton
                            className="btn-plain border-black border-2 w-full m-2 shadow"
                            bgColour="bg-white active:bg-white"
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{actionBtnCancelText}</span>
                        </PrimaryButton>
                        <PrimaryButton
                            className="btn-warn w-full m-2"
                            bgColour="bg-[#f3f4f4]"
                            onClick={onClick}
                        >
                            <span>{actionBtnText}</span>
                        </PrimaryButton>
                    </footer>
                </div>
            </CenterModal>
        )
    },
)
