import { IsNotEmpty, IsStrongPassword } from 'class-validator'
import { t } from 'i18next'
import { Match } from '../../../utils/validation/match'
import { BaseModel } from '../base.model'

export class PasswordInitialResetModel implements BaseModel {
    @IsNotEmpty({ message: 'validation.mustBeSet' })
    public currentPassword: string = ''

    @IsNotEmpty({ message: 'validation.mustBeSet' })
    @IsStrongPassword(
        {
            minLength: 10,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
        },
        { message: t('fields.password.validationMessage') },
    )
    public password: string = ''

    @IsNotEmpty({ message: 'validation.mustBeSet' })
    @Match('password', {
        message: 'validation.passwordsDoNotMatch',
    })
    public confirmPassword: string = ''

    public getRequestBody() {
        return {
            oldPassword: this.currentPassword,
            password: this.password,
        }
    }
}
