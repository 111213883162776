import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function BookingErrorEarly({
    when,
}: {
    when: boolean
}): ReactElement {
    const { t } = useTranslation()

    useEffect(() => {
        const threeMinutes = 3 * 60 * 1000
        const timeout = setTimeout(() => {
            if (when) {
                window.location.reload()
            }
        }, threeMinutes)

        return () => clearTimeout(timeout)
    })

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('BookingErrorEarly.title')}
        >
            <ReactMarkdown>
                {t('BookingErrorEarly.body') as string}
            </ReactMarkdown>
            <button onClick={() => window.location.reload()}>
                {t('common.refresh')}
            </button>
        </Popup>
    )
}
