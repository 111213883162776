import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddFunctionModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public function: string = ''

    constructor(func = '') {
        this.function = func
    }

    public getRequestBody() {
        return {
            function: this.function,
        }
    }
}
