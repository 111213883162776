import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../../utils/stores'
import { Account } from './account'
import { CoachingPreference } from './coachingPreference'
import { InfoSupport } from './infoSupport'
import { Personal } from './personal'

export const AccountTabs: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee, image } = useStores()

    useEffect(() => {
        if (coachee.coachee?._id) {
            image.getCoacheePicture(coachee.coachee?._id).subscribe()
        }
    }, [coachee.coachee?._id, image])

    const listOfTabs: Record<string, string>[] = [
        {
            name: t('account.personal'),
        },
        {
            name: t('account.coachingPreference'),
        },
        {
            name: t('account.account'),
        },
        {
            name: t('account.infoSupport'),
        },
    ]

    return (
        <>
            <TabGroup className="xl:px-16 xl:py-8 flex flex-col xl:flex-row">
                <div className="w-full xl:w-1/3 lg:px-3 md:px-3">
                    <TabList className="flex xl:flex-col justify-between px-4 xl:pb-4 xl:border xl:border-[#d2d9da] rounded">
                        {listOfTabs.map((tab, index) => {
                            return (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={classNames(
                                                'xl:w-full xl:h-[55px] w-[25%] py-2 xl:pt-4 xl:mt-2 text-left ',
                                                'outline-none ',
                                                selected
                                                    ? 'text-primary'
                                                    : 'text-[#6b6a72]',
                                            )}
                                        >
                                            <span className="lg:text-xl text-base line-clamp-1">
                                                {tab.name}
                                            </span>

                                            <div
                                                className={classNames(
                                                    'mt-4 xl:h-[1px] h-[3px]',
                                                    selected
                                                        ? 'bg-primary relative z-10'
                                                        : 'bg-[#d2d9da] relative z-10',
                                                )}
                                            ></div>
                                        </button>
                                    )}
                                </Tab>
                            )
                        })}
                    </TabList>
                </div>
                <div className="w-full xl:w-3/4 px-3">
                    <TabPanels>
                        <TabPanel>
                            <Personal />
                        </TabPanel>
                        <TabPanel>
                            <CoachingPreference />
                        </TabPanel>
                        <TabPanel>
                            <Account />
                        </TabPanel>
                        <TabPanel>
                            <InfoSupport />
                        </TabPanel>
                    </TabPanels>
                </div>
            </TabGroup>
        </>
    )
})
