import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SideModal } from '../../components/modals/side-modal'
import SideModalSkeleton from '../../components/skeleton/sideSkeleton'
import { useStores } from '../../utils/stores'
import { EmptyTaskList } from './components/emptyTaskList'
import { TaskList } from './components/taskList'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const Task: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()

    const { task, coachee } = useStores()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (coachee.coachee?._id) {
            const subscription = task
                .getCoacheeTasks(coachee.coachee?._id)
                .subscribe({
                    next: () => {
                        setIsLoading(false)
                    },
                    error: () => {
                        setIsLoading(false)
                    },
                })

            return () => {
                subscription.unsubscribe()
            }
        }
    }, [coachee.coachee?._id, task])

    return (
        <SideModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            className="max-w-[28rem]"
            title="my tasks"
        >
            <div className="rounded-lg bg-white max-w-[28rem] w-full">
                <div className="flex flex-col px-8 py-7 sticky top-0 z-20 bg-white">
                    <span className="text-[38px]">{t('task.title')}</span>
                </div>
                {isLoading ? (
                    <SideModalSkeleton />
                ) : task.tasks.length === 0 ? (
                    <EmptyTaskList />
                ) : (
                    <TaskList />
                )}
            </div>
        </SideModal>
    )
})
