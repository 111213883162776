import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EmailIcon from '../../../assets/icons/email.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const CheckEmailModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { auth } = useStores()
        const navigate = useNavigate()

        const close = useCallback(() => {
            setIsOpen(false)
        }, [setIsOpen])

        const onUsePassword = useCallback(() => {
            return navigate(RouteLink.VERIFY_PASSWORD, {
                state: {
                    canUsePassword: true,
                },
            })
        }, [navigate])

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
                showCloseButton={false}
            >
                <div className="p-14">
                    <header className="flex flex-col">
                        <img src={EmailIcon} className="w-[6rem] h-[6rem]" />
                    </header>
                    <main className="flex flex-col mt-4">
                        <span className="text-3xl">
                            {t('modals.checkEmail.heading') as string}
                        </span>
                        <span className="text-xl mt-4">
                            {t('modals.checkEmail.subText', {
                                email: auth.email,
                            })}
                        </span>
                    </main>

                    <footer>
                        <div className="flex justify-center items-center">
                            <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
                            <div className="mx-[0.6rem] my-[1.6rem] uppercase text-sm">
                                {t('or')}
                            </div>
                            <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
                        </div>

                        <PrimaryButton
                            className="w-full btn-accent"
                            onClick={onUsePassword}
                        >
                            <span>{t('buttons.usePassword')}</span>
                        </PrimaryButton>
                    </footer>
                </div>
            </CenterModal>
        )
    },
)
