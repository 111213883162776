import { IsNotEmpty, Matches, Validate, ValidateIf } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddGenderModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    @Validate(isValidGender, {
        message: t('validation.invalidFormat') as string,
    })
    public gender: string = ''

    @ValidateIf((object) => object.gender === 'other')
    @IsNotEmpty({
        message: t('validation.mustBeSet') as string,
    })
    @Matches(/\S/, {
        message: t('validation.mustBeSet') as string,
    })
    public genderSpecified: string = ''

    constructor(gender = '', genderSpecified = '') {
        this.genderSpecified = genderSpecified
        this.gender = gender
    }

    public getRequestBody() {
        return {
            gender: this.gender,
            genderSpecified: this.genderSpecified,
        }
    }
}

const gender = ['male', 'female', 'other', 'nonBinary', 'preferNotSay']

function isValidGender(value: string) {
    return gender.includes(value)
}
