import classNames from 'classnames'
import { differenceInHours, differenceInMinutes, isToday } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatTime, prettyFormatDate } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

interface Props {
    date: Date
    className?: string
}

export const SessionDate: React.FC<Props> = observer(({ date, className }) => {
    const { coachee } = useStores()
    const { t } = useTranslation()
    const [sessionInfo, setSessionInfo] = useState('')

    useEffect(() => {
        const updateSessionInfo = () => {
            const sessionDate = toZonedTime(
                date,
                coachee.coacheeUser?._timezone ?? '',
            )
            const now = new Date()
            const hours = differenceInHours(sessionDate, now)
            const mins = differenceInMinutes(sessionDate, now)

            if (isToday(sessionDate)) {
                if (hours < 1 && mins > 1) {
                    setSessionInfo(
                        t('dashboard.session.sessionSoon', { min: mins }),
                    )
                } else if (hours < 1 && mins <= 1) {
                    setSessionInfo(t('dashboard.session.sessionStarted'))
                } else if (hours >= 1 && hours < 12) {
                    setSessionInfo(
                        t('dashboard.session.todaySession', {
                            time: formatTime(sessionDate),
                        }),
                    )
                } else {
                    setSessionInfo(prettyFormatDate(sessionDate))
                }
            } else {
                setSessionInfo(prettyFormatDate(sessionDate))
            }
        }

        const timer = setInterval(updateSessionInfo, 60000)
        updateSessionInfo()
        return () => clearInterval(timer)
    }, [date, coachee.coacheeUser?._timezone, t])

    return (
        <div>
            <span className={classNames(className)}>{sessionInfo}</span>
        </div>
    )
})
