import { t } from 'i18next'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import LockIcon from '../../../assets/icons/lock.svg'
import { ProfilePictureCircle } from '../../../components/misc/profile-picture-circle'
import { dataURItoBlobString } from '../../../utils/misc/to-blog'
import { useStores } from '../../../utils/stores'

interface Props {
    coachId?: string
    coachInitial?: string
}
export const EmptyChat: React.FC<Props> = observer(
    ({ coachId, coachInitial }) => {
        const { coachee, image } = useStores()
        const [profileImage, setProfileImage] = useState<string | undefined>(
            undefined,
        )

        const updatedCoachId = coachId ?? coachee.coachee?.currentCoach._id

        useEffect(() => {
            if (updatedCoachId) {
                image.getCoachHistoryPicture(updatedCoachId).subscribe({
                    next(image) {
                        if (image) {
                            setProfileImage(dataURItoBlobString(image))
                        }
                    },
                })
            }
        }, [image, updatedCoachId])

        return (
            <div className="flex flex-col justify-center items-center ml-6 mt-[8rem]">
                <div className="mb-[3rem] ml-20">
                    <ProfilePictureCircle
                        initials={coachee.initials}
                        wrapperClassNames="w-[7rem] h-[7rem] z-10"
                        className="w-[7rem] h-[7rem]"
                        picture={image.picture}
                    />
                    <div className="relative">
                        <ProfilePictureCircle
                            initials={coachInitial ?? coachee.coachInitials}
                            className="w-[7rem] h-[7rem]"
                            wrapperClassNames="w-[7rem] h-[7rem] z-20 absolute bottom-[-30px] left-[-65px]"
                            picture={profileImage}
                        />
                        <img
                            src={LockIcon}
                            alt="lock"
                            className="absolute top-2 left-3 z-30"
                        />
                    </div>
                </div>
                {!coachId && (
                    <span className="text-2xl max-w-[90%] py-3 text-center capitalize my-1">
                        {coachee.coachee?.currentCoach.user.firstName}&nbsp;
                        {coachee.coachee?.currentCoach.user.lastName}
                    </span>
                )}

                <span className="text-[#3a3943] text-center w-[20rem] text-xl">
                    {t('chat.emptyChat')}
                </span>
            </div>
        )
    },
)
