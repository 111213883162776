import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconArrow from '../../../assets/icons/arrowCheckIns.svg'
import { Channel } from '../../../models/response/channel'
import { formatDate, getFormatString } from '../../../utils/misc'
import { dataURItoBlobString } from '../../../utils/misc/to-blog'
import { useStores } from '../../../utils/stores'
import { ChatHistoryModal } from '../modals/chat-history-modal'

interface ListProps {
    channel: Channel
}

const List: React.FC<ListProps> = observer(({ channel }) => {
    const { image } = useStores()
    const [profileImage, setProfileImage] = useState<string | undefined>()

    const [openChatHistory, setOpenChatHistory] = useState(false)

    const coachInitials = (): string => {
        const firstInitial = channel.coach?.user.firstName
            .charAt(0)
            .toUpperCase()

        const lastInitial = channel.coach?.user.lastName.charAt(0).toUpperCase()

        return firstInitial + lastInitial
    }
    const coachInitial = coachInitials()

    useEffect(() => {
        if (channel.coach._id) {
            image.getCoachHistoryPicture(channel.coach._id).subscribe({
                next(image) {
                    if (image) {
                        setProfileImage(dataURItoBlobString(image))
                    }
                },
            })
        }
    }, [channel.coach._id, image])
    return (
        <>
            <div className=" py-3 mt-3">
                <div
                    className="flex justify-between cursor-pointer"
                    onClick={() => setOpenChatHistory(true)}
                >
                    <div className="flex items-center">
                        <img
                            src={profileImage}
                            className="w-[4rem] h-[4rem] rounded-[100px]"
                        />
                        <div className="flex flex-col my-auto ml-3">
                            <span>
                                <strong>
                                    {channel.coach?.user.firstName}
                                    &nbsp;
                                    {channel.coach?.user.lastName}
                                </strong>
                            </span>
                            <span className="text-[17px] text-[#6b6a72]">
                                {formatDate(
                                    channel.dateOfMatching,
                                    getFormatString('long'),
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <img src={IconArrow} />
                    </div>
                </div>
                <div
                    className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-8')}
                ></div>
                {openChatHistory && (
                    <ChatHistoryModal
                        isOpen={openChatHistory}
                        setIsOpen={setOpenChatHistory}
                        channel={channel}
                        profileImage={profileImage}
                        coachInitial={coachInitial}
                    />
                )}
            </div>
        </>
    )
})

export const ChatHistory = observer(() => {
    const { t } = useTranslation()
    const { channel, coachee } = useStores()
    const { channels } = channel

    useEffect(() => {
        channel.getCoacheeChannel().subscribe()
    }, [channel])

    return (
        <>
            {channels.length > 1 && (
                <div className="mt-8">
                    <span className="text-[22px]">
                        {t('account.chatHistory')}
                    </span>
                    {channels
                        .filter(
                            (channel) =>
                                channel.coach._id !==
                                coachee.coachee?.currentCoach?._id,
                        )
                        .map((channel, index) => {
                            return <List key={index} channel={channel} />
                        })}
                </div>
            )}
        </>
    )
})
