import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MobileProfileBlob from '../../../assets/icons/blob-mobile.svg'
import ProfileBlob from '../../../assets/icons/profile-blob-mask.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { MessageBubble } from '../../../components/misc/message-bubble'
import { RouteLink } from '../../../utils/constants'
import { classNames } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { CoachModal } from '../modals/coach.modal'
export const CurrentCoach: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee, image, channel } = useStores()

    const [isProfileOpen, setProfileIsOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (coachee.coachee?.currentCoach._id) {
            image.getCoachPicture(coachee.coachee?.currentCoach._id).subscribe()
        }
    }, [coachee.coachee?.currentCoach._id, image])

    return (
        <div
            id="current-coach"
            className={classNames(
                'flex m-auto flex-col lg:flex-row w-full mb-10 lg:mb-0 py-0 lg:py-5',
            )}
        >
            <div className={classNames('mx-auto')}>
                <img
                    width="130"
                    height="130"
                    src={image.coachPicture ?? undefined}
                    alt=""
                    className={classNames(
                        'relative z-30 lg:ml-5 mt-[8px] rounded-[100px]',
                        'top-[50px] lg:top-0',
                    )}
                />
            </div>

            <div className="flex w-full lg:flex-row   flex-col bg-white rounded-[30px]">
                <div className={classNames('p-0 mt-0 hidden lg:block')}>
                    <img src={ProfileBlob} alt="" className="h-full" />
                </div>

                <div
                    className={classNames(
                        'relative bottom-1',
                        'p-0 mt-0 block lg:hidden',
                    )}
                >
                    <img src={MobileProfileBlob} alt="" className="w-full" />
                </div>
                <div className="m-auto text-center  lg:ml-8 lg:text-left pt-5 lg:pt-0">
                    <div className="lg:text-[17px] lg:text-left text-center">
                        {t('modals.currentCoach.heading')}
                    </div>

                    <div className="flex lg:justify-between justify-center lg:items-start items-center flex-wrap">
                        <span className="text-2xl mr-2">
                            {coachee.coachee?.currentCoach.user.firstName}
                        </span>
                        <span className="text-2xl">
                            {coachee.coachee?.currentCoach.user.lastName}
                        </span>
                    </div>
                </div>
                <div className="lg:my-auto  lg:mr-8 items-center justify-center flex flex-wrap  py-5 lg:py-0">
                    <PrimaryButton
                        onClick={() => setProfileIsOpen(true)}
                        className="btn-secondary w-[174px] h-[48px] m-2"
                        type="button"
                        bgColour="bg-[#f3f4f4]"
                    >
                        <div className="flex justify-center items-center">
                            <span>{t('buttons.profile')}</span>
                        </div>
                        <CoachModal
                            isOpen={isProfileOpen}
                            setIsOpen={setProfileIsOpen}
                        />
                    </PrimaryButton>
                    <PrimaryButton
                        className="btn-secondary w-[174px] h-[48px] m-2 "
                        type="button"
                        onClick={() => navigate(RouteLink.CHAT)}
                        bgColour="bg-[#f3f4f4]"
                    >
                        <div className="flex justify-center items-center">
                            <span>{t('buttons.chat')}</span>
                            {channel.numberOfUnreadMessages > 0 && (
                                <MessageBubble
                                    count={channel.numberOfUnreadMessages}
                                />
                            )}
                        </div>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
})
