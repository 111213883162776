import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AboutArrow from '../../assets/icons/about-arrow.svg'
import CheckInsIcon from '../../assets/icons/check-ins.svg'
import CompletedTasks from '../../assets/icons/completed-tasks.svg'
import MissedSessions from '../../assets/icons/missed-sessions.svg'
import TotalSessions from '../../assets/icons/total-sessions.svg'
import { DashboardPageWrapper } from '../../components/navigation'
import { useStores } from '../../utils/stores'
import { Card } from '../leaderboard/components/card'
import Trackers from './components/Trackers'
import { CheckIns } from './components/checkIns'
import { CompletedTask } from './components/completedTask'
import { MissedSession } from './components/missedSession'
import { TotalSession } from './components/totalSession'
import { TrackerModal } from './modals/tracker.modal'

export const LeaderboardPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee } = useStores()
    const [openTotalSession, setOpenTotalSession] = useState(false)
    const [openCompletedTask, setOpenCompletedTask] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [openMissedSession, setOpenMissedSession] = useState(false)
    const [openCheckIns, setOpenCheckIns] = useState(false)

    useEffect(() => {
        const subscription = coachee?.getOAStats()?.subscribe()

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    return (
        <DashboardPageWrapper
            pageName={t('pageTitles.leaderboard')}
            backgroundColour="bg-[#f9fbfc]"
        >
            <div className="py-10 px-10 mx-auto max-w-[1268px] flex flex-col">
                <div className="mb-16">
                    <div className="flex flex-wrap flex-col sm:flex-row gap-[2%]">
                        <Card
                            title={t('leaderboard.totalSessions')}
                            icon={TotalSessions}
                            value={coachee?.assessmentsStats?.totalSession}
                            onClick={() => setOpenTotalSession(true)}
                            className="md:w-[49%]"
                        />
                        <Card
                            title={t('leaderboard.completedTasks')}
                            icon={CompletedTasks}
                            value={coachee?.assessmentsStats?.taskCompleted}
                            onClick={() => setOpenCompletedTask(true)}
                            className="md:w-[49%] mt-[17px] md:mt-0"
                        />
                    </div>
                    <div className="flex flex-wrap gap-[2%] mt-4">
                        <Card
                            title={t('leaderboard.missedSessions')}
                            icon={MissedSessions}
                            value={
                                coachee?.assessmentsStats?.missedOpportunities
                            }
                            onClick={() => setOpenMissedSession(true)}
                            className="md:w-[49%]"
                        />
                        <Card
                            title={t('leaderboard.checkIns')}
                            icon={CheckInsIcon}
                            value={
                                coachee?.assessmentsStats?.completedAssessment
                            }
                            onClick={() => setOpenCheckIns(true)}
                            className="md:w-[49%] mt-[17px] md:mt-0"
                        />
                    </div>
                </div>
                <div className="flex gap-6 items-center">
                    <span className="text-[22px]">
                        {t('leaderboard.trends')}
                    </span>
                    <div
                        className="flex gap-4 items-center cursor-pointer"
                        onClick={() => setIsModalVisible(true)}
                    >
                        <span className="text-[19px]">
                            {t('leaderboard.about')}
                        </span>
                        <img src={AboutArrow} alt="" />
                    </div>
                </div>
                <div className="bg-white rounded-lg w-full p-6 md:p-12 mt-6">
                    <Trackers />
                </div>
                {openTotalSession && (
                    <TotalSession
                        isOpen={openTotalSession}
                        setIsOpen={setOpenTotalSession}
                    />
                )}

                {openCompletedTask && (
                    <CompletedTask
                        isOpen={openCompletedTask}
                        setIsOpen={setOpenCompletedTask}
                    />
                )}
                {isModalVisible && (
                    <TrackerModal
                        isOpen={isModalVisible}
                        setIsOpen={() => setIsModalVisible(false)}
                    />
                )}
                {openMissedSession && (
                    <MissedSession
                        isOpen={openMissedSession}
                        setIsOpen={setOpenMissedSession}
                    />
                )}
                {openCheckIns && (
                    <CheckIns
                        isOpen={openCheckIns}
                        setIsOpen={setOpenCheckIns}
                    />
                )}
            </div>
        </DashboardPageWrapper>
    )
})
