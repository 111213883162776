import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../utils/misc'

interface Props extends HTMLMotionProps<'button'> {}

export const ModalCloseButton: React.FC<Props> = ({
    className,
    onClick,
    ...props
}) => {
    return (
        <motion.button
            className={classNames(
                'bg-[url(assets/icons/x-circle.svg)]',
                'rounded-full h-8 w-8 mr-[10px] bg-contain',
                className,
            )}
            onClick={onClick}
            {...props}
        />
    )
}
