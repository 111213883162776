import { useField } from 'formik'
import { HTMLMotionProps, motion } from 'framer-motion'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../../assets/icons/infoicon.svg'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'

interface Props extends HTMLMotionProps<'textarea'> {
    name: string
    label?: string
    labelBig?: boolean
    canResize?: boolean
    showCharaterLeft?: boolean
    customOnChangeHandler?: (e: ChangeEvent<HTMLTextAreaElement>) => void
    isChat?: boolean
    textAreaRef?: React.RefObject<HTMLTextAreaElement>
}

export const TextareaInput: React.FC<Props> = ({
    name,
    label,
    labelBig,
    className,
    showCharaterLeft,
    isChat,
    canResize = false,
    customOnChangeHandler,
    textAreaRef,
    ...props
}) => {
    const { t } = useTranslation()
    const [field, meta] = useField(name)
    if (customOnChangeHandler) {
        field.onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
            customOnChangeHandler(e)
        }
    }
    return (
        <div className="flex flex-col">
            {label && (
                <label
                    htmlFor={name}
                    className={classNames(
                        'block mb-2',
                        labelBig ? 'text-[17px] ' : 'text-[11px] ',
                    )}
                >
                    {label}
                </label>
            )}
            <motion.textarea
                ref={textAreaRef}
                className={classNames(
                    className,
                    'bg-[#f6f7f9] h-[4.5rem]',
                    canResize ? '' : 'resize-none',
                    'rounded-[0.25rem]',
                    'px-4 py-2',
                    'text-[13px]',
                )}
                {...props}
                {...field}
            />
            {showCharaterLeft && props.maxLength && (
                <div className="flex gap-2">
                    <img src={InfoIcon} className="w-4 h-4 mt-1" />
                    <span className="text-[#6b6a72] text-base">
                        {t('others.charatersLeft', {
                            count: props.maxLength - field.value.length,
                        })}
                    </span>
                </div>
            )}

            {!isChat && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
