import classNames from 'classnames'
import { isAfter, isSameDay, parse, parseISO, startOfToday } from 'date-fns'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Availability } from '../../../models/response/booking'
import { formatDate, getTimeFormatString } from '../../../utils/misc'

type Props = {
    label: string
    selectedDay?: Date | undefined
    handleClick?: (value: React.SetStateAction<string>) => void
    availability?: Availability[]
    duration?: number
}

const SessionTime: React.FC<Props> = observer(
    ({ label, handleClick, availability, selectedDay, duration }) => {
        const now = new Date()
        const today = startOfToday()

        const { t } = useTranslation()

        const availabilityDuration = availability?.filter(
            (available) => available.duration === duration,
        )

        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const startingTimesOnSelectedDay =
            availabilityDuration &&
            availabilityDuration
                .flatMap((available) => {
                    return available.startingTimes.map((time: Date) => {
                        if (
                            isSameDay(
                                parseISO(time.toString()),
                                selectedDay ?? '',
                            )
                        ) {
                            const formatted = formatDate(
                                time,
                                getTimeFormatString(),
                            )

                            const parsedFormattedTime = parse(
                                formatted,
                                getTimeFormatString(),
                                new Date(),
                            )

                            if (isAfter(parsedFormattedTime, now)) {
                                return formatted
                            } else if (
                                isAfter(selectedDay ?? new Date(), today)
                            ) {
                                return formatted
                            }
                        }
                    })
                })
                .filter((time) => time !== undefined)

        return (
            <div className="lg:px-6 px-4 m-auto">
                <div className="h-auto flex-grow-0 m-10 mt-0 mx-auto p-0 pt-0 lg:px-9 px-4 pb-14 rounded-lg bg-white">
                    <div className="flex justify-between items-center">
                        <span className="flex font-normal mb-2 lg:text-3xl text-xl pt-6 px-3 pb-6">
                            {label}
                        </span>
                        <span className="flex font-normal mb-2 md:text-xl text-xl pt-6 px-3 pb-6">
                            {t('booking.timezone', {
                                timezone: currentTimezone,
                            })}
                        </span>
                    </div>

                    <div className="h-[1px] flex-grow-0 ml-[10px] mr-[10px]  mb-[20px] bg-[#d2d9da]"></div>

                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4">
                        {startingTimesOnSelectedDay?.map((time, index) => (
                            <button
                                key={index}
                                onClick={() =>
                                    handleClick && handleClick(time || '')
                                }
                                className={classNames(
                                    'w-[100px] h-[50px] flex  justify-center items-center',
                                    'rounded-full bg-[#f3f4f4] text-primary hover:bg-primary hover:text-white',
                                    'hover:scale-105',
                                    'transition-transform duration-300 ease-in-out',
                                )}
                            >
                                <span className="text-[17px]">{time}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        )
    },
)

export default SessionTime
