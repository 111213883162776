import React, { ReactElement } from 'react'

export default function SamaLogo(): ReactElement {
  return (
    <svg
      id="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="111"
      height="38"
      style={{
        position: 'absolute',
        zIndex: 30,
        top: '40px',
        left: '40px',
        width: '111px',
        height: '38px',
        filter: 'drop-shadow( 1px 1px 0px rgba(0, 0, 0, .4))',
      }}
    >
      <g fill="#eaeaeb" fillRule="nonzero" opacity=".9">
        <g transform="matrix(-1 0 0 1 31.645 0)">
          <ellipse cx="15.121" cy="15.077" rx="14.923" ry="15.077" />
          <circle cx="4.585" cy="33.105" r="4.196" />
        </g>
        <path d="M43.25 14.087c0-1.497 1.134-2.319 3.61-2.319 2.296 0 3.608.851 3.638 2.759h4.116c0-3.845-2.953-6.134-7.695-6.134-4.653 0-7.785 2.348-7.785 5.782 0 8.012 11.662 4.402 11.662 9.01 0 1.527-1.402 2.348-3.847 2.348-2.715 0-4.116-.998-4.116-2.817h-4.116c0 3.991 2.952 6.193 8.023 6.193 4.861 0 8.142-2.202 8.142-5.724 0-8.306-11.632-4.696-11.632-9.098zm27.226 11.329c-.328.117-.477.147-.746.147-.417 0-.686-.294-.686-.88v-4.638c0-3.64-2.266-5.84-6.531-5.84-3.788 0-6.353 1.79-6.592 5.047h3.699c.149-1.232 1.133-1.995 2.684-1.995 1.849 0 2.774.998 2.774 2.494v.646h-1.88c-3.996 0-7.665 1.027-7.665 4.55 0 2.377 1.73 3.932 4.951 3.932 3.102 0 4.325-1.438 4.802-2.318h.03c.507 1.585 1.73 2.318 3.31 2.318.836 0 1.522-.117 2.089-.293l-.239-3.17zm-8.858.528c-1.402 0-2.118-.528-2.118-1.467 0-1.262 1.283-1.879 3.46-1.879h2.118v.91c0 1.145-1.313 2.436-3.46 2.436zm27.584-11.74c-2.416 0-3.997 1.262-4.743 2.994-.686-1.79-2.237-2.994-4.593-2.994-2.028 0-3.46.91-4.295 2.23l-.119-2.083h-3.937v14.382h4.086V20.69c0-1.79 1.133-2.994 2.774-2.994 1.521 0 2.416 1.056 2.416 2.847v8.189h4.086V20.69c0-1.79 1.133-2.994 2.774-2.994 1.491 0 2.416 1.027 2.416 2.847v8.189h4.116v-9.07c0-3.199-1.73-5.459-4.981-5.459zm21.081 11.212c-.328.117-.477.147-.745.147-.418 0-.686-.294-.686-.88v-4.638c0-3.64-2.267-5.84-6.532-5.84-3.788 0-6.353 1.79-6.592 5.047h3.699c.149-1.232 1.133-1.995 2.684-1.995 1.85 0 2.774.998 2.774 2.494v.646h-1.88c-3.996 0-7.664 1.027-7.664 4.55 0 2.377 1.73 3.932 4.95 3.932 3.103 0 4.326-1.438 4.803-2.318h.03c.507 1.585 1.73 2.318 3.31 2.318.835 0 1.521-.117 2.088-.293l-.239-3.17zm-8.858.528c-1.402 0-2.117-.528-2.117-1.467 0-1.262 1.282-1.879 3.46-1.879h2.117v.91c0 1.145-1.312 2.436-3.46 2.436z" />
      </g>
    </svg>
  )
}
