import { observer } from 'mobx-react'
import React from 'react'
import { stripHtml } from 'string-strip-html'
import IconArrow from '../../../assets/icons/arrow-right.svg'
import { NoteResponse } from '../../../models/response/note/note'
import { classNames, prettyFormatDate } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

interface CardProps {
    title: string
    subtitle?: string
    onClick?: () => void
}

interface NoteListProps {
    updateItem?: (item: NoteResponse) => void
}

const getSummaryLine = (content: string, maxLength: number) => {
    if (content) {
        return stripHtml(content).result.substring(0, maxLength)
    } else {
        return ''
    }
}

const Card: React.FC<CardProps> = observer(({ title, subtitle, onClick }) => {
    return (
        <div
            className={classNames(
                'flex flex-row justify-between items-center relative',
                'rounded-[12px] bg-white h-[100px] shadow-lg',
                'cursor-pointer',
                'px-2 my-3',
            )}
            onClick={onClick}
        >
            <div className="flex flex-col ml-5">
                <span
                    className={classNames(
                        'text-[19px]',
                        'overflow-hidden whitespace-nowrap overflow-ellipsis w-64',
                    )}
                    title={title}
                >
                    {title}
                </span>
                {subtitle && (
                    <span className="text-[17px] font-normal">{subtitle}</span>
                )}
            </div>
            <div className="mr-5">
                <img src={IconArrow} />
            </div>
        </div>
    )
})

export const NoteList: React.FC<NoteListProps> = observer(({ updateItem }) => {
    const { note } = useStores()

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8">
                {note.notes.map((note) => (
                    <Card
                        key={note._id}
                        title={getSummaryLine(note.content, 100)}
                        subtitle={
                            note.updatedAt
                                ? prettyFormatDate(note.updatedAt)
                                : undefined
                        }
                        onClick={() => updateItem && updateItem(note)}
                    />
                ))}
            </div>
            <div className="h-40"></div>
        </div>
    )
})
