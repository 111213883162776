import React, { useState, useEffect, ReactElement } from 'react'
import * as D from 'date-fns'
import { Link } from 'react-router-dom'

import samaApi from '../services/SamaApi'
import { Booking } from '../types/SamaApi'
import SamaLogo from '../components/SamaLogo'

function Login(): ReactElement {
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [upcomingBookings, setBookings] = useState([] as Booking[])
    const [userId, setUserId] = useState('')

    const handleBookSessions = async () => {
        await samaApi.bookSessions()

        const sessions = await samaApi.listBookings(userId)

        setBookings(sessions)
    }

    useEffect(() => {
        void (async () => {
            await samaApi.logIn()

            const { _id } = await samaApi.getMeCoachee()
            const sessions = await samaApi.listBookings(_id)

            setUserId(_id)
            setLoggedIn(true)
            setBookings(sessions)
        })()
    }, [])

    return (
        <div id="page">
            <SamaLogo />

            <div className='pt-[100px]'>
                {!isLoggedIn ? <p>Logging in…</p> : <p>Logged in!</p>}
                {upcomingBookings.length && (
                    <>
                        <p>Upcoming sessions</p>
                        {/* <Link to="/booking/605a02bb74a1710e90579fd7">
            605a02bb74a1710e90579fd7
          </Link> */}
                        <ul>
                            {upcomingBookings.map((session: any, key) => (
                                <li key={key}>
                                    <Link to={`/booking/${session._id}`}>
                                        {D.format(session.date, 'MMM do hh:mm')}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                <p>
                    <button onClick={() => handleBookSessions()}>
                        Book sessions for next 3 hours
                    </button>
                </p>
            </div>
        </div>
    )
}

export default Login
