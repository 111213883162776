import { Client, Conversation, Message } from '@twilio/conversations'
import { md5 } from 'js-md5'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { ChatModel } from '../models/request/chat'
import { ChannelToken, ChatMessage } from '../models/response/channel'
import { Channel } from '../models/response/channel/channel'
import { countWords, mapMessgaes } from '../utils/chatHelper'
import { HttpMethod } from '../utils/constants'
import { Resettable } from '../utils/misc'
import { Response, request } from '../utils/request'
import { stores } from '../utils/stores'

export class ChannelStore implements Resettable {
    public channels: Channel[] = []
    public activeChannelMessages: ChatMessage[] = []
    public conversation: Conversation | null = null
    public chatToken: string | null = null
    public chatClient: Client | null = null
    public numberOfUnreadMessages: number = 0

    @computed
    public get activeChannel(): Channel | undefined {
        return this.channels.find((channel) => channel.currentlyOpen)
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public setActiveChannelMessage(messages: ChatMessage[]) {
        this.activeChannelMessages = messages
    }

    @action
    public setConversation(conversation: Conversation) {
        this.conversation = conversation
    }

    @action
    public setUnreadMessage(count: number) {
        this.numberOfUnreadMessages = count
    }

    @action
    public async setupMessageListeners() {
        if (this.activeChannel && this.chatClient) {
            const conversation = await this.chatClient.getConversationBySid(
                this.activeChannel.twilio.id,
            )

            conversation.on('messageAdded', async (message: Message) => {
                if (message.author === this.activeChannel?.coach._id) {
                    await this.getUnreadMessageCount()
                }
            })
        }
    }

    @action
    public async getUnreadMessageCount() {
        if (this.chatClient && this.activeChannel) {
            const conversation = await this.chatClient.getConversationBySid(
                this.activeChannel.twilio.id,
            )

            const unreadMessage =
                (await conversation.getUnreadMessagesCount()) as number
            this.setUnreadMessage(unreadMessage)
        }
    }

    @action
    public async getActiveChannelMessages(id: string) {
        if (id && this.chatClient) {
            const conversation = await this.chatClient.getConversationBySid(id)

            this.setConversation(conversation)
            const conversationMessages = await conversation.getMessages(1000)
            const messages = await mapMessgaes(conversationMessages)
            this.setActiveChannelMessage(messages)

            if (id === this.activeChannel?.twilio.id) {
                conversation.setAllMessagesRead()
                this.setUnreadMessage(0)
            }
        }
    }

    @action
    public getCoacheeChannel() {
        return request(`/me/coachee/channel`, HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.channels = response.data
                    }
                })
            }),
        )
    }

    @action
    public getChatClient() {
        if (this.chatToken) {
            const client = new Client(this.chatToken)
            this.chatClient = client

            client.on('tokenAboutToExpire', () => {
                // referesh token
                if (stores.auth.authenticated) {
                    this.getChannelToken().subscribe({
                        next(response: Response<ChannelToken | null>) {
                            if (response.data) {
                                client.updateToken(response.data.token)
                            }
                        },
                    })
                }
            })
        }
    }

    @action
    public getChannelToken() {
        const deviceId = md5(window.navigator.userAgent)

        return request(
            `/channel/token?deviceId=${deviceId}`,
            HttpMethod.GET,
        ).pipe(
            tap((response: Response<ChannelToken | null>) => {
                runInAction(() => {
                    if (response.data) {
                        this.chatToken = response.data.token
                    }
                })
            }),
        )
    }

    @action
    public postChatMessage(model: ChatModel) {
        if (this.conversation) {
            const attributes: Record<string, string | boolean> = {
                is_urgent: model.isUrgent,
            }

            if (model.file) {
                attributes.attachment_type = 'document'
            }

            if (model.message || model.file) {
                const message: string | FormData = model.file ?? model.message

                this.conversation.sendMessage(message, attributes)
            }
        }

        return request(
            `/channel/${this.activeChannel?._id}/message`,
            HttpMethod.POST,
            {
                body: {
                    ...model.getRequestBody(),
                    nbrWord: countWords(model.message),
                    twilioIndex: this.activeChannelMessages.length + 1,
                },
            },
        )
    }

    @action
    public reset(): void {
        this.channels = []
        this.activeChannelMessages = []
        this.chatToken = null
        this.conversation = null
        this.numberOfUnreadMessages = 0
    }
}
