import {
    registerDecorator,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator'

@ValidatorConstraint({ async: true })
export class IsFormDataConstraint implements ValidatorConstraintInterface {
    validate(value: any) {
        return value instanceof FormData
    }
}

export function IsFormData(validationOptions?: ValidationOptions) {
    return function (object: object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsFormDataConstraint,
        })
    }
}
