import classNames from 'classnames'
import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'
import { CenterModal } from '../../../components/modals'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const AboutSama: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()

    return (
        <CenterModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className="max-w-[36rem] overflow-y-auto md:overflow-y-visible h-full md:h-auto pt-2"
            headerTitle={t('account.infoSupportPage.about')}
            title="about"
        >
            <div className="px-7 mt-10 bg-[#fafafa]">
                <div className={classNames('pt-4 pb-8 whitespace-pre-wrap')}>
                    <Trans t={t} i18nKey="account.infoSupportPage.aboutText">
                        <a
                            className="text-[#1f3f48] underline font-semibold"
                            href={t('account.infoSupportPage.privacyLink')}
                            target="_blank"
                        />
                    </Trans>
                </div>
                <div className="md:h-2 h-16"></div>
            </div>
        </CenterModal>
    )
})
