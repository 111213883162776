import { addMinutes } from 'date-fns'
import { t } from 'i18next'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import {
    AddPhoneModel,
    MagicLoginModel,
    PasswordLoginModel,
    RequestMagicLinkModel,
    TwoFaModel,
} from '../models/request'
import { PasswordInitialResetModel } from '../models/request/auth/password-initial-reset'
import { PasswordResetModel } from '../models/request/auth/password-reset.model'
import { RequestPasswordResetModel } from '../models/request/auth/request-password-reset.model'
import { AuthResponse, RefreshTokenResponse } from '../models/response'
import { HttpMethod, NotificationType } from '../utils/constants'
import {
    Resettable,
    dehydrateToStorage,
    getTimestamp,
    hydrateFromStorage,
    removeFromStorage,
} from '../utils/misc'
import { request } from '../utils/request'
import { stores } from '../utils/stores'

const AUTH_KEY = 'SAMA:AUTH'

const MINS_TO_CACHE_INCOMPLETE_AUTH = 10

const PHONE_KEY = 'SAMA:PHONE'

export const RESEND_CODE_TIME_INTERVAL = 20 // In seconds

export class AuthStore implements Resettable {
    public authResponse: AuthResponse | null = null
    public ready: boolean = true
    public phone!: string | null
    public email!: string | null

    @computed
    public get authenticated(): boolean {
        return !!this.authResponse?.accessToken
    }

    @computed
    public get fullyAuthenticated(): boolean {
        return this.authenticated && !this.authResponse?.awaiting2fa
    }

    public getSecondsToResendCode(): number {
        if (!this.authResponse?.codeRequestedAt) {
            return -1
        }

        const currentTime = getTimestamp()
        const timeAtLastRequest = this.authResponse.codeRequestedAt
        const nextPossibleRequestTime =
            timeAtLastRequest + RESEND_CODE_TIME_INTERVAL

        const timeLeft = nextPossibleRequestTime - currentTime

        return timeLeft > 0 ? timeLeft : 0
    }

    public getObfiscatedPhoneNumber(): string | undefined {
        if (this.phone) {
            const length = this.phone.length
            return (
                this.phone.substring(0, 3) +
                '******' +
                this.phone.substring(length - 3, length)
            )
        }
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {
        this.authResponse = hydrateFromStorage(AUTH_KEY)

        if (this.authResponse?.expires) {
            const now = new Date()
            const expiresAt = new Date(this.authResponse.expires)

            if (now >= expiresAt) {
                this.reset()
            }
        }

        this.phone = hydrateFromStorage(PHONE_KEY)
    }

    @action
    public setAuthResponse(authResponse: AuthResponse): void {
        if (authResponse.awaiting2fa) {
            authResponse.expires = addMinutes(
                new Date(),
                MINS_TO_CACHE_INCOMPLETE_AUTH,
            )
        } else if (authResponse.expires) {
            authResponse.expires = undefined
        }

        this.authResponse = authResponse
        dehydrateToStorage(AUTH_KEY, authResponse)
    }

    @action
    public setTokens(tokens: RefreshTokenResponse): void {
        if (this.authResponse) {
            this.setAuthResponse({
                ...this.authResponse,
                accessToken: tokens.accessToken,
            })
        }
    }

    @action
    public requestMagicLink(model: RequestMagicLinkModel) {
        return request('/auth/magic-link', HttpMethod.POST, {
            body: {
                email: model.email,
            },
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.email = model.email
                    }
                })
            }),
        )
    }

    @action
    public requestPasswordResetEmail(model: RequestPasswordResetModel) {
        return request('/auth/reset-password', HttpMethod.POST, {
            body: {
                email: model.email,
            },
        })
    }

    @action
    public requestPasswordReset(model: PasswordResetModel) {
        return request('/auth/reset-my-password', HttpMethod.POST, {
            body: model.getRequestBody(),
        })
    }

    @action
    public initialPasswordReset(model: PasswordInitialResetModel) {
        return request('/auth/reset-generated-password', HttpMethod.POST, {
            body: {
                accessToken: this.authResponse?.accessToken,
                ...model.getRequestBody(),
            },
        })
    }

    @action
    public resend2fa() {
        return request('/auth/2fa', HttpMethod.POST, {
            body: { accessToken: this.authResponse?.accessToken },
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.setAuthResponse({
                            ...this.authResponse,
                            codeRequestedAt: getTimestamp(),
                        })
                    }
                })
            }),
        )
    }

    @action
    public setPhone(model: AddPhoneModel) {
        return request('/auth/phone', HttpMethod.PUT, {
            body: model,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.phone = model.phone
                        dehydrateToStorage(PHONE_KEY, model.phone)
                        this.setAuthResponse({
                            ...this.authResponse,
                            isPhoneNeeded: false,
                        })
                        if (this.authResponse.awaiting2fa) {
                            this.resend2fa().subscribe()
                        }
                    }
                })
            }),
        )
    }

    @action
    public validate2fa(model: TwoFaModel) {
        return request('/auth/2fa', HttpMethod.PUT, {
            body: model,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.setAuthResponse({
                            ...this.authResponse,
                            awaiting2fa: false,
                        })
                    }
                })
            }),
        )
    }

    @action
    public MagicLogIn(model: MagicLoginModel) {
        return request<MagicLoginModel, AuthResponse>(
            '/auth/login/magic',
            HttpMethod.POST,
            { body: model },
        )
        //     .pipe(
        //     tap((response) => {
        //         runInAction(() => {
        //             if (response.data) {
        //                 this.setAuthResponse(response.data)
        //             }
        //         })
        //     }),
        // )
    }

    @action
    public LogIn(model: PasswordLoginModel) {
        return request<PasswordLoginModel, AuthResponse>(
            '/auth/login',
            HttpMethod.POST,
            { body: model, silentErrors: true },
        ).pipe(
            tap((response) =>
                runInAction(() => {
                    if (response.data) {
                        this.setAuthResponse(response.data)
                    } else {
                        stores.notifications.createNotification(
                            NotificationType.ERROR,
                            t('validation.checkEmailOrPassword'),
                        )
                    }
                }),
            ),
        )
    }

    @action
    public reset(): void {
        this.authResponse = null
        this.phone = null
        removeFromStorage(AUTH_KEY, PHONE_KEY)
    }

    @action
    public signOut(): void {
        stores.reset()
    }
}
