import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/logo/logo-dark.svg'
import { RouteLink } from '../../../utils/constants'
import { classNames } from '../../../utils/misc'

interface Props {
    children: React.ReactNode
}

export const AuthPageWrapper: React.FC<Props> = ({ children }) => {
    const { t } = useTranslation()
    return (
        <div className="h-screen w-full min-w-screen flex">
            <div
                className={classNames(
                    'flex',
                    'flex-1',
                    'flex-col',
                    'justify-center',
                    'items-center',
                    'sm:h-full',
                    'w-full',
                    'bg-white',
                    'p-8 pt-[4.5rem] pb-10',
                )}
            >
                <Link
                    to={RouteLink.INDEX}
                    style={{
                        textDecoration: 'underline',
                    }}
                >
                    <img
                        src={Logo}
                        alt={t('alt.logo')}
                        className="absolute left-8 top-8"
                    />
                </Link>
                <div className="max-w-[30rem] w-full">{children}</div>
            </div>
            <div
                className={classNames(
                    'bg-[#FBFDFD]',
                    'flex-1',
                    'lg:flex',
                    'hidden',
                    'h-full',
                    'w-full',
                    'bg-[url(assets/icons/authBackgroundImage.svg)]',
                    'bg-cover bg-center',
                )}
            ></div>
        </div>
    )
}
