import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../../utils/misc'

type Props = {
    label: string
    type?: string
    handleClick?: (value: React.SetStateAction<string>) => void
}

const SessionType: React.FC<Props> = observer(({ label, handleClick }) => {
    const { t } = useTranslation()

    return (
        <div className="lg:px-6 px-4 m-auto">
            <div className="h-[300px] flex-grow-0 m-10 mt-0 mx-auto p-0 pt-0 lg:px-9 px-4 pb-14 rounded-lg bg-white">
                <span className="flex font-normal mb-2 lg:text-3xl text-xl pt-6 px-3 pb-6">
                    {label}
                </span>
                <div className="h-[1px] flex-grow-0 ml-[10px] mr-[10px]  mb-[20px] bg-[#d2d9da]"></div>

                <div className="flex items-center space-x-6">
                    <button
                        onClick={() => handleClick && handleClick('Video')}
                        className={classNames(
                            'w-full h-[100px] flex justify-center items-center',
                            'xl:mt-7 mt-2 rounded-[14px] bg-[#f3f4f4] text-primary hover:bg-primary hover:text-white',
                            'hover:scale-105',
                            'transition-transform duration-300 ease-in-out',
                        )}
                    >
                        <span className={classNames('text-[17px] text-center')}>
                            {t('booking.video')}
                        </span>
                    </button>
                    <button
                        onClick={() => handleClick && handleClick('Call')}
                        className={classNames(
                            'w-full h-[100px] flex justify-center items-center',
                            'xl:mt-7 mt-2 rounded-[14px] bg-[#f3f4f4] text-primary hover:bg-primary hover:text-white',
                            'hover:scale-105',
                            'transition-transform duration-300 ease-in-out',
                        )}
                    >
                        <span className={classNames('text-[17px] text-center')}>
                            {t('booking.call')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
})

export default SessionType
