import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { stores } from '../../../utils/stores'

export const LogOutPage: React.FC = observer(() => {
    useEffect(() => stores.reset(), [])
    return <></>
})

export default LogOutPage
