import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { PasswordInput } from '../../../components/inputs/password-input'
import {
    FormNotification,
    FormValidationMessage,
} from '../../../components/layout'
import { PasswordResetModel } from '../../../models/request/auth/password-reset.model'
import { NotificationType, RouteLink } from '../../../utils/constants'
import { history } from '../../../utils/misc/history'
import { useStores } from '../../../utils/stores'
import useQuery from '../../../utils/useQuery'
import { validateModel } from '../../../utils/validation/validate-model'
import { AuthPageWrapper } from '../components/auth-page-wrapper'

export const ResetPasswordPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [success, setSuccess] = useState(false)
    const [executed, setExecuted] = useState(false)
    const [error, setError] = useState<string>()
    const token = useQuery().get('token')

    const onSubmit = useCallback(
        (
            values: PasswordResetModel,
            helpers: FormikHelpers<PasswordResetModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.requestPasswordReset(values).subscribe({
                next(response) {
                    setExecuted(true)
                    if (response.ok) {
                        setSuccess(true)
                        history.replace({
                            pathname: RouteLink.LOG_IN,
                        })
                    } else if (response.status === 400) {
                        setError(t('ResetPassword.noToken'))
                    } else if (response.status === 404) {
                        setError(t('ResetPassword.invalidToken'))
                    } else if (response.status === 429) {
                        setError(t('messages.tooManyRequests'))
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                    helpers.resetForm()
                },
            })
        },
        [auth, t],
    )

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                validateOnChange={true}
                initialValues={new PasswordResetModel(token)}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting, isValid, isValidating }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col"
                        data-testid="form"
                    >
                        <header className="mb-10 flex flex-col justify-center items-center">
                            <h2 className="text-[2rem]" data-testid="title">
                                {t('ResetPassword.title')}
                            </h2>
                        </header>
                        {error && (
                            <div className="mt-[10px]">
                                <FormValidationMessage message={error} />
                            </div>
                        )}
                        {!token && !executed && (
                            <div
                                className="mt-[10px] pb-[25px]"
                                data-testid="errorNoTokenNoReset"
                            >
                                <FormValidationMessage
                                    message={t('ResetPassword.noToken')}
                                />
                            </div>
                        )}
                        <main className="space-y-3 mb-6 z-5">
                            <div data-testid="inputPassword">
                                <PasswordInput
                                    name="password"
                                    placeholder={
                                        t(
                                            'fields.password.placeholder',
                                        ) as string
                                    }
                                    label={t('fields.password.label') as string}
                                    disabled={isSubmitting || success}
                                />
                            </div>
                            <div
                                className="pt-[10px]"
                                data-testid="confirmPassword"
                            >
                                <PasswordInput
                                    name="confirmPassword"
                                    placeholder={
                                        t(
                                            'fields.verifyPassword.placeholder',
                                        ) as string
                                    }
                                    label={
                                        t(
                                            'fields.verifyPassword.label',
                                        ) as string
                                    }
                                    disabled={isSubmitting || success}
                                />
                            </div>
                            {success && (
                                <FormNotification
                                    type={NotificationType.INFO}
                                    message={t('messages.passwordReset')}
                                />
                            )}
                        </main>
                        <footer>
                            {!success && (
                                <div data-testid="confirmPasswordButton">
                                    <PrimaryButton
                                        className="w-full"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!isValid || isValidating}
                                    >
                                        <span>{t('buttons.continue')}</span>
                                    </PrimaryButton>
                                </div>
                            )}
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})
export default ResetPasswordPage
