import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { AuthPageWrapper } from '../components/auth-page-wrapper'
import { Formik, FormikHelpers } from 'formik'
import { validateModel } from '../../../utils/validation/validate-model'
import { useCallback, useState } from 'react'
import { RequestPasswordResetModel } from '../../../models/request/auth/request-password-reset.model'
import { useStores } from '../../../utils/stores'
import { TextInput } from '../../../components/inputs/text-input'
import { FormNotification } from '../../../components/layout'
import { NotificationType } from '../../../utils/constants'
import { PrimaryButton } from '../../../components/buttons/primary-button'

export const ResetPasswordRequestPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [success, setSuccess] = useState(false)

    const onSubmit = useCallback(
        (
            values: RequestPasswordResetModel,
            helpers: FormikHelpers<RequestPasswordResetModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.requestPasswordResetEmail(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setSuccess(true)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [auth],
    )

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                initialValues={new RequestPasswordResetModel()}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting, isValid, isValidating }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col"
                        data-testid="form"
                    >
                        <header className="mb-10 flex flex-col justify-center items-center">
                            <h2 className="text-[2rem]">
                                {t('ResetPassword.title')}
                            </h2>
                        </header>
                        <main className="space-y-3 mb-6">
                            <TextInput
                                name="email"
                                label={t('fields.email.label') as string}
                                placeholder={
                                    t('fields.email.placeholder') as string
                                }
                                disabled={isSubmitting || success}
                            />
                            {success && (
                                <FormNotification
                                    type={NotificationType.INFO}
                                    message={t('messages.emailSent')}
                                />
                            )}
                        </main>
                        <footer>
                            {!success && (
                                <div>
                                    <PrimaryButton
                                        className="w-full"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!isValid || isValidating}
                                    >
                                        <span>{t('buttons.continue')}</span>
                                    </PrimaryButton>
                                </div>
                            )}
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})
export default ResetPasswordRequestPage
