import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useEffect } from 'react'
import { trackEvent } from '../../services/event-tracking'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { ModalCloseButton } from '../buttons/modal-close-button'
import { ModalProps } from './modal-props'

interface Props extends ModalProps {
    title?: string
}

export const SideModal: React.FC<Props> = ({
    children,
    isOpen,
    setIsOpen,
    initialFocus,
    className,
    title,
}) => {
    const { coachee } = useStores()
    useEffect(() => {
        if (isOpen && title) {
            trackEvent('screen_viewed', {
                name: title,
                coachee_id: coachee.coachee?._id,
            })
        }
    }, [coachee.coachee?._id, isOpen, title])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <Transition
            show={isOpen}
            enter="transform transition ease-in-out duration-400"
            enterFrom="translate-x-full"
            enterTo="translate-x-0 bg-[#030412]/30"
            leave="transform transition ease-in-out duration-400"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            as={Fragment}
        >
            <Dialog
                open={isOpen}
                onClose={close}
                className="z-40 fixed inset-0"
                initialFocus={initialFocus}
            >
                <div
                    className="fixed inset-0 bg-[#030412]/30"
                    aria-hidden="true"
                />
                <div className="top-[60px] md:top-0 fixed inset-0 flex items-center justify-end ">
                    <Dialog.Panel
                        className={classNames(
                            className,
                            'md:pt-0',
                            'h-full overflow-y-auto w-full bg-white scroll-py-10',
                        )}
                    >
                        <ModalCloseButton
                            onClick={close}
                            className="absolute right-3 z-50  top-[40px] md:top-5"
                        />
                        <main>{children}</main>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition>
    )
}
