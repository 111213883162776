import { IsObject, IsOptional } from 'class-validator'
import { Option } from '../../response'
import { BaseModel } from '../base.model'

export class CoachingPreferenceModel implements BaseModel {
    constructor(
        areaOfCoaching: Option | null = null,
        coachGender: Option | null = null,
        coachingLanguage: Option | null = null,
    ) {
        ;(this.areaOfCoaching = areaOfCoaching),
            (this.coachGender = coachGender),
            (this.coachingLanguage = coachingLanguage)
    }

    @IsObject()
    @IsOptional()
    public areaOfCoaching: Option | null = null

    @IsObject()
    @IsOptional()
    public coachGender: Option | null = null

    @IsObject()
    @IsOptional()
    public coachingLanguage: Option | null = null

    public getRequestBody() {
        return {
            desireCompetency: this.areaOfCoaching?.value,
            coachGender: this.coachGender?.value,
            coachingLanguage: this.coachingLanguage?.value,
        }
    }
}
