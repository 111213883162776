import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import validationError from '../../../assets/icons/error-validation.svg'
import validationRight from '../../../assets/icons/right-validation.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { PasswordInput } from '../../../components/inputs/password-input'
import { FormValidationMessage } from '../../../components/layout'
import { PasswordInitialResetModel } from '../../../models/request/auth/password-initial-reset'
import { trackEvent } from '../../../services/event-tracking'
import { NotificationType } from '../../../utils/constants'
import { Response } from '../../../utils/request'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { DeleteAcccount } from './deleteAccount'

export const Spacer: React.FC = observer(() => {
    return <div className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-4')}></div>
})

export const Account = observer(() => {
    const { t } = useTranslation()
    const { auth, notifications, coachee } = useStores()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string>()

    useEffect(() => {
        trackEvent('screen_viewed', {
            name: 'account_account',
            coachee_id: coachee.coachee?._id,
        })
    }, [coachee.coachee?._id])

    const [passwordValidationStatus, setPasswordValidationStatus] = useState({
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
    })

    const validatePassword = (password: string) => {
        setPasswordValidationStatus({
            hasUpperCase: /[A-Z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            hasMinLength: password.length >= 10,
        })
    }

    const ValidationComponent = ({
        validationStatus,
        validationMessage,
    }: {
        validationStatus: boolean
        validationMessage: string
    }) => (
        <div className="flex flex-row space-x-4">
            <img src={validationStatus ? validationRight : validationError} />
            <span
                className={classNames(
                    'text-[17px]',
                    validationStatus ? 'text-primary' : 'text-[#ff0000]',
                )}
            >
                {validationMessage}
            </span>
        </div>
    )

    const handleNotification = useCallback(
        (response: Response) => {
            if (response.ok) {
                setSuccess(true)
                notifications.createNotification(
                    NotificationType.INFO,
                    t('messages.passwordReset'),
                    6 * 1000,
                )
            } else if (response.status === 400) {
                setError(t('ResetPassword.noToken'))
            } else if (response.status === 429) {
                setError(t('messages.tooManyRequests'))
            } else if (response.status === 403) {
                setError(t('ResetPassword.invalidCurrentPassword'))
            }
        },
        [notifications, t],
    )

    const onSubmit = useCallback(
        (
            values: PasswordInitialResetModel,
            helpers: FormikHelpers<PasswordInitialResetModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.initialPasswordReset(values).subscribe({
                next(response) {
                    handleNotification(response)
                },
                complete() {
                    helpers.setSubmitting(false)
                    helpers.resetForm()
                },
            })
        },
        [auth, handleNotification],
    )

    return (
        <div className="lg:pt-8 lg:px-12">
            <div>
                <span className="text-[38px] hidden lg:inline-block">
                    {t('account.account')}
                </span>
                <div className="hidden lg:block">
                    <Spacer />
                </div>
                <Formik
                    validate={validateModel}
                    validateOnChange={true}
                    initialValues={new PasswordInitialResetModel()}
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        values,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col"
                            data-testid="form"
                        >
                            <div className="space-y-2 mb-6 mt-2">
                                {error && (
                                    <div className="mt-[10px]">
                                        <FormValidationMessage
                                            message={error}
                                        />
                                    </div>
                                )}

                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.currentPassword.label')}
                                        :&nbsp;
                                    </span>{' '}
                                    <PasswordInput
                                        name="currentPassword"
                                        placeholder={
                                            t(
                                                'fields.currentPassword.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting || success}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-start lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.newPassword.label')}
                                        :&nbsp;
                                    </span>{' '}
                                    <div className="flex flex-col lg:w-1/2">
                                        <PasswordInput
                                            name="password"
                                            placeholder={
                                                t(
                                                    'fields.newPassword.placeholder',
                                                ) as string
                                            }
                                            disabled={isSubmitting || success}
                                            validatePassword={validatePassword}
                                            disableValidationMessage={true}
                                            className="border border-[#d2d9da] shadow rounded mb-4"
                                        />
                                        {values.password && (
                                            <>
                                                <ValidationComponent
                                                    validationStatus={
                                                        passwordValidationStatus.hasUpperCase
                                                    }
                                                    validationMessage={t(
                                                        'validation.upperCase',
                                                    )}
                                                />
                                                <ValidationComponent
                                                    validationStatus={
                                                        passwordValidationStatus.hasNumber
                                                    }
                                                    validationMessage={t(
                                                        'validation.containsNumber',
                                                    )}
                                                />
                                                <ValidationComponent
                                                    validationStatus={
                                                        passwordValidationStatus.hasSpecialChar
                                                    }
                                                    validationMessage={t(
                                                        'validation.specialCharacter',
                                                    )}
                                                />
                                                <ValidationComponent
                                                    validationStatus={
                                                        passwordValidationStatus.hasMinLength
                                                    }
                                                    validationMessage={t(
                                                        'validation.minimumLength',
                                                    )}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.retypePassword.label')}
                                        :&nbsp;
                                    </span>{' '}
                                    <PasswordInput
                                        name="confirmPassword"
                                        placeholder={
                                            t(
                                                'fields.retypePassword.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting || success}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                            </div>
                            <div
                                className={classNames(
                                    'lg:my-5',
                                    'flex flex-col items-center justify-center',
                                )}
                            >
                                <PrimaryButton
                                    className="w-full shadow"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid || isValidating}
                                >
                                    <span>{t('buttons.save')}</span>
                                </PrimaryButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <DeleteAcccount />
        </div>
    )
})
