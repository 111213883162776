import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EmptyTask from '/src/assets/icons/empty-task.svg'

export const EmptyTaskList = observer(() => {
    const { t } = useTranslation()

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-10">
            <div className="flex flex-col items-center justify-center mt-10">
                <img src={EmptyTask} className="w-[6rem] h-[6rem] m-auto" />
                <span className="text-[22px] font-bold mt-8">
                    {t('task.noTask')}
                </span>
                <span className="text-[17px] w-[150px] inline-block text-center">
                    {t('task.noTaskDesc')}
                </span>
            </div>
        </div>
    )
})
