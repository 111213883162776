/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import * as Sentry from '@sentry/browser'
import { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import audioIcon from '../../assets/icons/black-audio.svg'
import cameraOff from '../../assets/icons/camera-off.svg'
import infoIcon from '../../assets/icons/info-icon.svg'
import videoIcon from '../../assets/icons/video-icon.svg'
import { MachineContext } from '../../stores/bookingMachine'
import { Action } from '../../stores/bookingMachine/config'
import { DeviceList } from '../../types/SamaApi'

import useQuery from '../../utils/useQuery'
import InteractivePopup from './InteractivePopup'

export default function ViewSettings({
    when,
}: {
    when: boolean
}): ReactElement {
    const inDebugMode = useQuery().get('debug')

    const { t } = useTranslation()
    const [state, send] = useContext(MachineContext)
    const { twilioRoom, capturedAudioDevice, capturedVideoDevice } =
        state.context
    const stateMatches = state.matches

    const [speakerList, setSpeakerList] = useState([
        { value: '', label: 'Default' },
    ])
    const [speakerDevice, setSpeakerDevice] = useState({})
    const [microphoneDevice, setMicrophoneDevice] = useState({})
    const [microphoneList, setMicrophoneList] = useState([
        { value: '', label: '' },
    ])
    const [cameraList, setCameraList] = useState([
        { value: '', label: 'Default' },
    ])
    const [cameraDevice, setCameraDevice] = useState({
        label: '',
        value: 'Default',
    })
    const [currentSection, setSection] = useState('Audio')
    const [currentVideoDevice, setCurrentVideoDevice] = useState('')
    const sections = ['Audio', 'Video']

    const videoPreivewRef = useRef<HTMLVideoElement>(null)

    const isAudioOn = stateMatches(
        'sessionConnected.coacheeAudio.coacheeAudioOn',
    )
    const isVideoOn = stateMatches(
        'sessionConnected.coacheeVideo.coacheeVideoOn',
    )

    function refreshPreview(newDeviceId: string): boolean {
        if (inDebugMode) {
            Sentry.captureMessage(
                'refreshPreview() - Device Id selected "' + newDeviceId + '"',
            )
        }

        navigator.mediaDevices
            .getUserMedia({ video: { deviceId: newDeviceId } })
            .then(function (stream) {
                if (inDebugMode) {
                    Sentry.captureMessage('refreshPreview() - captured device')
                }
                setCurrentVideoDevice(newDeviceId)
                if (videoPreivewRef.current) {
                    if (inDebugMode) {
                        Sentry.captureMessage(
                            'refreshPreview() - setting stream',
                        )
                    }
                    videoPreivewRef.current.srcObject = stream
                    videoPreivewRef.current
                        .play()
                        .then((_) => {
                            if (inDebugMode) {
                                Sentry.captureMessage(
                                    'refreshPreview() - playing device',
                                )
                            }
                            // Automatic playback started!
                            // Show playing UI.
                        })
                        .catch((e) => {
                            Sentry.captureMessage(
                                'refreshPreview() - play error',
                            )
                            Sentry.captureException(e)
                            throw e
                        })
                }
            })
            .catch((e) => {
                Sentry.captureMessage('refreshPreview() - ' + newDeviceId)
                Sentry.captureException(e)
                throw e
            })
        return true
    }

    function cleanUpOnClose(): void {
        if (inDebugMode) {
            Sentry.captureMessage('cleanUpOnClose() - called')
        }
        if (videoPreivewRef.current) {
            videoPreivewRef.current.srcObject = null
        }
        setSection('Audio')
    }

    useEffect(() => {
        getDevices()
            .then((response) => {
                setSpeakerDevice({
                    value: capturedAudioDevice,
                    label: getDeviceName(
                        'speaker',
                        capturedAudioDevice,
                        response.speakerList,
                    ),
                })
                setMicrophoneDevice({
                    value: capturedAudioDevice,
                    label: getDeviceName(
                        'microphone',
                        capturedAudioDevice,
                        response.microphoneList,
                    ),
                })
                setCameraDevice({
                    value: capturedVideoDevice,
                    label: getDeviceName(
                        'camera',
                        capturedAudioDevice,
                        response.microphoneList,
                    ),
                })
            })
            .catch((e) => {
                Sentry.captureMessage('useEffect() - ')
                Sentry.captureException(e)
                throw e
            })

        // monitor for new device connections or disconnections
        navigator.mediaDevices.ondevicechange = function () {
            getDevices().catch((e) => {
                Sentry.captureMessage('ondevicechange() - ')
                Sentry.captureException(e)
                throw e
            })
        }
    }, [capturedAudioDevice, capturedVideoDevice])

    async function getDevices(): Promise<DeviceList> {
        if (inDebugMode) {
            Sentry.captureMessage('getDevices() - called')
            console.log('isVideoOn=' + isVideoOn)
        }

        const audioList: { value: string; label: string }[] = []
        const microphoneList: {
            value: string
            label: string
        }[] = []
        const cameraList: { value: string; label: string }[] = []

        return navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) {
                devices.forEach((device) => {
                    if (inDebugMode) {
                        console.log(device)
                    }
                    if (device.kind === 'audioinput') {
                        microphoneList.push({
                            value: device.deviceId,
                            label: device.label,
                        })
                    }
                    if (device.kind === 'audiooutput') {
                        audioList.push({
                            value: device.deviceId,
                            label: device.label,
                        })
                    }
                    if (device.kind === 'videoinput') {
                        cameraList.push({
                            value: device.deviceId,
                            label: device.label,
                        })
                    }
                })

                if (microphoneList.length < 1) {
                    microphoneList.push({
                        value: '',
                        label: 'Default',
                    })
                }
                if (audioList.length < 1) {
                    audioList.push({
                        value: '',
                        label: 'Default',
                    })
                }

                setSpeakerList(audioList)
                setMicrophoneList(microphoneList)
                setCameraList(cameraList)

                if (inDebugMode) {
                    Sentry.captureMessage(
                        'getDevices() - cameraList "' +
                            JSON.stringify(cameraList) +
                            '"',
                    )
                }

                return {
                    speakerList: audioList,
                    microphoneList: microphoneList,
                }
            })
            .catch(function (err) {
                Sentry.captureMessage(
                    'getDevices() - cameraList "' +
                        JSON.stringify(cameraList) +
                        '"',
                )
                Sentry.captureMessage(
                    'getDevices() - microphoneList "' +
                        JSON.stringify(microphoneList) +
                        '"',
                )
                Sentry.captureMessage(
                    'getDevices() - audioList "' +
                        JSON.stringify(audioList) +
                        '"',
                )

                Sentry.captureException(err)
                /* handle the error */
                throw err
            })
    }

    function getDeviceName(type: string, id: string, list: any): string {
        if (type === 'speaker') {
            let sList = speakerList
            if (!speakerList[0].value) {
                sList = list
            }
            const device = sList.find((element) => element.value === id)
            if (device) {
                return device.label
            } else {
                return speakerList[0].label
            }
        } else if (type === 'microphone') {
            let mList = microphoneList
            if (!microphoneList[0].value) {
                mList = list
            }
            const device = mList.find((element) => element.value === id)
            if (device) {
                return device.label
            } else {
                return microphoneList[0].label
            }
        } else if (type === 'camera') {
            let cList = cameraList
            if (!cameraList[0].value) {
                cList = list
            }
            const device = cList.find((element) => element.value === id)
            if (device) {
                return device.label
            }
        }
        return ''
    }

    const customSelectStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: '#f4f5f8',
            borderRadius: '7.3px',
            minHeight: '73px',
            maxWidth: '408px',
            borderWidth: '0px',
            boxShadow: null,
            padding: '0px 22px 0 0',
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            width: '0px',
        }),
        dropdownIndicator: (provided: any, state: any) => ({
            ...provided,
            color: '#ff7558',
            '&:hover': {
                color: '#ff7558',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            gridArea: '1/1/2/1',
            fontSize: '22px',
            color: '#828499',
            paddingLeft: '24px',
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            fontSize: '22px',
            color: '#828499',
            textAlign: 'left',
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            textAlign: 'left',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? '#FFF3F1'
                : state.isSelected
                ? '#FF7558'
                : null,
        }),
    }

    return (
        <InteractivePopup
            when={when}
            title={t('Settings.title')}
            closeAction={() => {
                cleanUpOnClose()
                send(Action.closeSettings)
            }}
        >
            <div>
                {sections.map((section, i) => (
                    <div
                        key={i}
                        onClick={() => setSection(section)}
                        css={css({
                            position: 'relative',
                            width: '93%',
                            height: '82px',
                            marginRight: '10px',
                            padding: '0px 22px 0 0',
                            borderRadius: '0px 40px 40px 0px',
                            backgroundColor:
                                currentSection === section ? '#fff' : 'none',
                            fontFamily: 'FoundersGroteskMedium',
                            fontSize: '26px',
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color:
                                currentSection === section
                                    ? '#ff6743'
                                    : '#111237',
                            paddingTop: '24px',
                            marginBottom: '24px',
                            cursor: 'pointer',
                        })}
                    >
                        <span
                            css={css({
                                paddingLeft: '42px',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                                display: 'flex',
                            })}
                        >
                            <img
                                alt="select device"
                                src={
                                    section === 'Audio'
                                        ? audioIcon.toString()
                                        : videoIcon.toString()
                                }
                                css={css({
                                    filter:
                                        currentSection === section
                                            ? 'invert(71%) sepia(91%) saturate(3460%) hue-rotate(319deg) brightness(115%) contrast(115%)'
                                            : 'invert(12%) sepia(7%) saturate(5870%) hue-rotate(202deg) brightness(90%) contrast(108%)',
                                    paddingRight: '20px',
                                })}
                            />
                            {section}
                        </span>
                    </div>
                ))}
            </div>
            <div
                css={css({
                    paddingTop: '92px',
                })}
            >
                <div
                    css={css({
                        display: currentSection === 'Audio' ? 'block' : 'none',
                    })}
                >
                    <h3
                        css={css({
                            fontSize: '24px',
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#10123a',
                        })}
                    >
                        {t('Settings.speakers')}
                    </h3>
                    <Select
                        value={speakerDevice}
                        onMenuOpen={getDevices}
                        onChange={(event: any) => {
                            setSpeakerDevice(event)
                            send({
                                type: Action.changeInputDevice,
                                payload: {
                                    device: event.value,
                                    type: 'speaker',
                                    room: twilioRoom,
                                },
                            })
                        }}
                        styles={customSelectStyles}
                        options={speakerList}
                    />
                    <br />
                    <h3
                        css={css({
                            fontSize: '24px',
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#10123a',
                        })}
                    >
                        {t('Settings.microphone')}
                    </h3>
                    <Select
                        value={microphoneDevice}
                        onChange={(event: any) => {
                            setMicrophoneDevice(event)
                            send({
                                type: Action.changeInputDevice,
                                payload: {
                                    device: event.value,
                                    type: 'microphone',
                                    room: twilioRoom,
                                    isAudioOn: isAudioOn,
                                },
                            })
                        }}
                        styles={customSelectStyles}
                        options={microphoneList}
                    />
                    {!isAudioOn && (
                        <div
                            css={css({
                                textAlign: 'left',
                                width: '250px',
                                height: '38px',
                                margin: '14px 261px 24px 0px',
                                borderRadius: '2000px',
                                backgroundColor: '#ffefed',
                                padding: '5px',
                                display: 'flex',
                            })}
                        >
                            <img
                                alt="infomation"
                                css={css({
                                    paddingRight: '10px',
                                })}
                                src={infoIcon.toString()}
                            />
                            <span
                                css={css({
                                    marginTop: '2px',
                                    color: '#FF7F70',
                                })}
                            >
                                {t('Settings.noMicrophone')}
                            </span>
                        </div>
                    )}
                </div>
                <div
                    css={css({
                        textAlign: 'left',
                        display: currentSection === 'Video' ? 'block' : 'none',
                    })}
                >
                    <h3
                        css={css({
                            fontSize: '24px',
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#10123a',
                        })}
                    >
                        {t('Settings.camera')}
                    </h3>
                    <Select
                        value={cameraDevice}
                        onChange={(event: any) => {
                            setCameraDevice(event)
                            refreshPreview(event.value)
                            send({
                                type: Action.changeInputDevice,
                                payload: {
                                    device: event.value,
                                    type: 'camera',
                                    room: twilioRoom,
                                    isVideoOn: isVideoOn,
                                },
                            })
                        }}
                        styles={customSelectStyles}
                        options={cameraList}
                    />
                    <div
                        css={css({
                            width: '200px',
                            minHeight: '100px',
                            backgroundColor: '#f4f5f8',
                            backgroundImage: `url(${cameraOff})`,
                            backgroundSize: '50px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginTop: '30px',
                            maxWidth: '250px',
                            borderRadius: '11px',
                        })}
                    >
                        {isVideoOn && (
                            <video
                                css={css({
                                    borderRadius: '11px',
                                })}
                                muted
                                ref={videoPreivewRef}
                            />
                        )}
                    </div>
                </div>
            </div>
        </InteractivePopup>
    )
}
