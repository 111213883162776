import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { TextInput } from '../../../components/inputs/text-input'
import { FormNotification } from '../../../components/layout'
import { CenterModal } from '../../../components/modals/center-modal'
import { InviteGuestModel } from '../../../models/request/booking/invite-guest.model'
import { Participant } from '../../../models/response/booking'
import { NotificationType } from '../../../utils/constants'
import { classNames, prettyFormatDate } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    timePeriod?: string
    setParticipants?: (value: React.SetStateAction<Participant[]>) => void
    bookingId?: string
    bookingDate?: Date
}

export const InviteGuestModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, setParticipants, bookingId, bookingDate }) => {
        const { t } = useTranslation()
        const { booking, notifications } = useStores()

        const onSubmit = useCallback(
            (
                values: InviteGuestModel,
                helpers: FormikHelpers<InviteGuestModel>,
            ) => {
                const participant = {
                    name: values.name,
                    email: values.email,
                }
                if (bookingId) {
                    helpers.setSubmitting(true)
                    booking.addBookingGuest(values, bookingId).subscribe({
                        next(response) {
                            if (response.ok) {
                                setIsOpen(false)
                                notifications.createNotification(
                                    NotificationType.INFO,
                                    t('messages.addedGuest', {
                                        name: values.name,
                                        email: values.email,
                                        date: bookingDate
                                            ? prettyFormatDate(bookingDate)
                                            : '',
                                    }),
                                    6 * 1000,
                                )
                            }
                        },
                        complete() {
                            helpers.setSubmitting(false)
                        },
                    })
                } else {
                    setParticipants &&
                        setParticipants((prevParticipants) => [
                            ...prevParticipants,
                            participant,
                        ])
                    setIsOpen(false)
                }
            },
            [
                booking,
                bookingDate,
                bookingId,
                notifications,
                setIsOpen,
                setParticipants,
                t,
            ],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[38rem] overflow-y-auto md:overflow-y-visible h-full md:h-auto"
                headerTitle={t('modals.guest.addColleague')}
            >
                <Formik
                    validate={validateModel}
                    initialValues={new InviteGuestModel()}
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        setFieldValue,
                        values,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col mt-12 px-8 pt-8 md:pb-11 pb-6 bg-[#fafafa] "
                            data-testid="form"
                        >
                            <div className="space-y-3 mb-3">
                                <TextInput
                                    name="name"
                                    label={t('fields.name.label') as string}
                                    placeholder={
                                        t('fields.name.placeholder') as string
                                    }
                                    disabled={isSubmitting}
                                />
                                <div>
                                    <TextInput
                                        name="email"
                                        label={
                                            t('fields.email.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.email.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="mt-8"
                                    />
                                </div>
                                <div className="pt-[18px]">
                                    <span className="text-[22px] font-normal">
                                        {t('modals.guest.checkGuest')}
                                    </span>
                                    <div className="flex py-1">
                                        <PrimaryButton
                                            className={classNames(
                                                'w-full m-2',
                                                values.showGuestActive === 'no'
                                                    ? 'btn-primary'
                                                    : 'btn-default',
                                            )}
                                            bgColour="bg-[#f3f4f4]"
                                            onClick={() => {
                                                setFieldValue('canGuest', false)
                                                setFieldValue(
                                                    'showGuestMessage',
                                                    true,
                                                )
                                                setFieldValue(
                                                    'showGuestActive',
                                                    'no',
                                                )
                                            }}
                                            type="button"
                                        >
                                            <span>{t('modals.guest.no')}</span>
                                        </PrimaryButton>

                                        <PrimaryButton
                                            className={classNames(
                                                'w-full m-2',
                                                values.showGuestActive === 'yes'
                                                    ? 'btn-primary'
                                                    : 'btn-default',
                                            )}
                                            bgColour="bg-[#f3f4f4]"
                                            onClick={() => {
                                                setFieldValue('canGuest', true)
                                                setFieldValue(
                                                    'showGuestMessage',
                                                    false,
                                                )
                                                setFieldValue(
                                                    'showGuestActive',
                                                    'yes',
                                                )
                                            }}
                                            type="button"
                                        >
                                            <span>{t('modals.guest.yes')}</span>
                                        </PrimaryButton>
                                    </div>
                                    {values.showGuestMessage && (
                                        <FormNotification
                                            className="uppercase"
                                            type={NotificationType.ERROR}
                                            message={t('messages.inviteGuest')}
                                        />
                                    )}
                                </div>

                                <div className="pt-[5px]">
                                    <span className="text-[22px] font-normal">
                                        {t('modals.guest.checkCoach')}
                                    </span>
                                    <div className="flex py-1">
                                        <PrimaryButton
                                            className={classNames(
                                                'w-full m-2',
                                                values.showCoachActive === 'no'
                                                    ? 'btn-primary'
                                                    : 'btn-default',
                                            )}
                                            bgColour="bg-[#f3f4f4]"
                                            onClick={() => {
                                                setFieldValue('canCoach', false)
                                                setFieldValue(
                                                    'showCoachMessage',
                                                    true,
                                                )
                                                setFieldValue(
                                                    'showCoachActive',
                                                    'no',
                                                )
                                            }}
                                            type="button"
                                        >
                                            <span>{t('modals.guest.no')}</span>
                                        </PrimaryButton>

                                        <PrimaryButton
                                            className={classNames(
                                                'w-full m-2',
                                                values.showCoachActive === 'yes'
                                                    ? 'btn-primary'
                                                    : 'btn-default',
                                            )}
                                            bgColour="bg-[#f3f4f4]"
                                            onClick={() => {
                                                setFieldValue('canCoach', true)
                                                setFieldValue(
                                                    'showCoachMessage',
                                                    false,
                                                )
                                                setFieldValue(
                                                    'showCoachActive',
                                                    'yes',
                                                )
                                            }}
                                            type="button"
                                        >
                                            <span>{t('modals.guest.yes')}</span>
                                        </PrimaryButton>
                                    </div>
                                    {values.showCoachMessage && (
                                        <FormNotification
                                            className="uppercase"
                                            type={NotificationType.ERROR}
                                            message={t(
                                                'messages.inviteGuestCoach',
                                            )}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="pt-[18px]">
                                <PrimaryButton
                                    className="w-full shadow"
                                    type="submit"
                                    disabled={
                                        !isValid ||
                                        isValidating ||
                                        values.canGuest === false ||
                                        values.canCoach === false
                                    }
                                >
                                    <span>
                                        {t('modals.guest.addColleague')}
                                    </span>
                                </PrimaryButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </CenterModal>
        )
    },
)
