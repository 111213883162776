import { useOktaAuth } from '@okta/okta-react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LogoutIcon from '../../../assets/icons/logout.svg'
import { DropDownModal } from '../../../components/modals'
import { AuthStore } from '../../../stores'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { ConfirmationModal } from './confirmation.modal'
import AccountIcon from '/src/assets/icons/account.svg'

interface ProfileDropDownModalProps {
    showAccount?: boolean
    isOnboarding?: boolean
    picture?: string | null
}

export const ProfileDropDownModal: React.FC<ProfileDropDownModalProps> =
    observer(({ showAccount = true, isOnboarding }) => {
        const { t } = useTranslation()
        const { auth } = useStores()
        const [openLogout, setOpenLogout] = useState(false)
        const { coachee, image } = useStores()
        const wrapperRef = useRef(null)
        const { oktaAuth } = useOktaAuth()

        const logout = async () => await oktaAuth.clearStorage()

        const isAccessTokenFromOkta = (auth: AuthStore) => {
            return auth.authResponse?.accessToken.startsWith('ATKO') ?? false
        }

        useEffect(() => {
            if (coachee.coachee?._id) {
                image.getCoacheePicture(coachee.coachee?._id).subscribe()
            }
        }, [coachee.coachee?._id, image, isOnboarding])

        return (
            <DropDownModal
                isOnboarding={isOnboarding}
                coachee={coachee}
                picture={image.picture ?? undefined}
                wrapperRef={wrapperRef}
            >
                <main className="w-48">
                    {showAccount && (
                        <Link
                            to={RouteLink.ACCOUNT}
                            className={classNames(
                                'gap-2 flex items-center',
                                'rounded-lg p-1.5 hover:bg-[#f3f4f4]',
                            )}
                        >
                            <img src={AccountIcon} alt="account" />
                            <span className="text-[#002b38]">
                                {t('pageTitles.account')}
                            </span>
                        </Link>
                    )}

                    <div
                        className="gap-2 flex items-center rounded-lg p-1.5 hover:bg-[#f3f4f4] cursor-pointer"
                        onClick={() => setOpenLogout(true)}
                    >
                        <img src={LogoutIcon} alt="logout" />
                        <span className="text-[#002b38]">
                            {t('pageTitles.logout')}
                        </span>
                    </div>
                </main>
                {openLogout && (
                    <ConfirmationModal
                        isOpen={openLogout}
                        setIsOpen={setOpenLogout}
                        onClick={() => {
                            if (isAccessTokenFromOkta(auth)) {
                                logout()
                                auth.signOut()
                            } else {
                                auth.signOut()
                            }
                        }}
                        message={t('modals.logOut.heading')}
                        actionBtnText={t('buttons.logOut')}
                        actionBtnCancelText={t('buttons.stayLogin')}
                    />
                )}
            </DropDownModal>
        )
    })
