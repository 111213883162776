import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddTopValuesModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public topValues: string[] = []

    constructor(topValues: string[] | null = null) {
        if (topValues) this.topValues = topValues
    }

    public getRequestBody() {
        return {
            topValues: this.topValues,
        }
    }
}
