import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import clockIcon from '../../../assets/icons/clock.svg'
import sessionIcon from '../../../assets/icons/session.svg'
import videoIcon from '../../../assets/icons/video.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { SessionLeft } from '../../../components/misc/sessions-left'
import { RouteLink } from '../../../utils/constants'
import { classNames, sessionStartSoon } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { InviteGuestModal } from '../../booking/modals/invite-guest.modal'
import { ConfirmationModal } from '../modals'
import { GuestModal } from '../modals/guest.modal'
import { SessionDate } from './sessionDate'

export const UpcomingSessionCard: React.FC = observer(() => {
    const [openGuest, setOpenGuest] = useState(false)
    const { t } = useTranslation()
    const { booking, coachee } = useStores()
    const navigate = useNavigate()
    const [openRemoveBooking, setOpenRemoveBooking] = useState(false)

    const [openInviteGuest, setOpenInviteGuest] = useState(false)
    const [showJoinButton, setShowJoinButton] = useState(false)

    useEffect(() => {
        const subscription = booking.getCoacheeBookings().subscribe()

        return () => {
            subscription.unsubscribe()
        }
    }, [booking])

    const startSession = useCallback(() => {
        booking.getSessionUrl(booking.nearestBooking._id).subscribe({
            next(response) {
                if (response.data) {
                    window.open(response.data.url, '_blank')
                }
            },
        })
    }, [booking])

    const handleDelete = useCallback(() => {
        booking.deleteBooking(booking.nearestBooking._id).subscribe({
            next: (response) => {
                if (response.ok) {
                    coachee.getCoachee().subscribe()
                    setOpenRemoveBooking(false)
                }
            },
        })
    }, [booking, coachee])

    const bookingDate = new Date(booking?.nearestBooking?.date)
    const currentDate = new Date()
    const isLessThan24Hours =
        bookingDate.getTime() - currentDate.getTime() < 24 * 60 * 60 * 1000

    useEffect(() => {
        const checkIfSessionStartsSoon = () => {
            const startsSoon = sessionStartSoon(
                booking.nearestBooking?.date,
                coachee.coacheeUser?._timezone,
            )
            setShowJoinButton(startsSoon)
        }

        checkIfSessionStartsSoon()
        const intervalId = setInterval(checkIfSessionStartsSoon, 60000)

        return () => clearInterval(intervalId)
    }, [booking.nearestBooking?.date, coachee.coacheeUser?._timezone])

    return (
        <main
            className={classNames('rounded-lg bg-[#ff7558] pt-8 pb-4 w-full')}
        >
            <div className="flex flex-col">
                <img
                    src={sessionIcon}
                    alt={t('alt.upcomingSession')}
                    className="w-12 h-12 ml-8 mb-4"
                />
                <span className="text-white text-md px-8">
                    {t('dashboard.session.coachingSession')}
                </span>
                <span className="text-white text-5xl mb-[2rem] px-8">
                    {booking.bookings && booking.bookings.length !== 0 ? (
                        <SessionDate date={booking.nearestBooking.date} />
                    ) : (
                        t('dashboard.session.noSession')
                    )}
                </span>

                {booking.bookings && booking.bookings.length !== 0 ? (
                    <div>
                        <div className="flex gap-4 px-8 ">
                            <div className="flex  border border-white py-3 px-5 gap-2 rounded-[30px]">
                                {booking.nearestBooking?.isVideoSession && (
                                    <img src={videoIcon} alt={t('alt.video')} />
                                )}
                                <span className="text-white text-[17px]">
                                    {booking.nearestBooking?.isVideoSession
                                        ? t('dashboard.session.video')
                                        : t('dashboard.session.audio')}
                                </span>
                            </div>
                            <div className="flex border border-white py-3 px-5 gap-2 rounded-[30px]">
                                <img src={clockIcon} alt={t('alt.clock')} />
                                <span className="text-white text-[17px] whitespace-nowrap">
                                    {t('dashboard.session.duration', {
                                        mins: booking.nearestBooking?.duration,
                                    })}
                                </span>
                            </div>
                        </div>

                        <div
                            className={classNames(
                                'bg-white px-4 py-12 mx-2 mt-8 rounded-md ',
                                'flex flex-col items-center justify-center',
                            )}
                        >
                            <PrimaryButton
                                className="w-full btn-primary py-7 flex justify-center items-center"
                                disabled={showJoinButton ? false : true}
                                onClick={startSession}
                            >
                                <span> {t('buttons.joinSession')}</span>
                            </PrimaryButton>

                            <div className="flex text-sm gap-6 mt-8">
                                {!(
                                    coachee.coachee?.user.company.settings
                                        .sessionGuests.canInvite === false &&
                                    booking.nearestBooking.participants
                                        .length === 0
                                ) && (
                                    <>
                                        <span
                                            className="cursor-pointer text-[17px] text-primary"
                                            onClick={() => {
                                                if (
                                                    booking.nearestBooking
                                                        .participants.length ===
                                                    0
                                                ) {
                                                    setOpenInviteGuest(true)
                                                } else {
                                                    setOpenGuest(true)
                                                }
                                            }}
                                        >
                                            {booking.nearestBooking.participants
                                                .length === 0
                                                ? t('dashboard.session.guest')
                                                : t(
                                                      'dashboard.session.guestLength',
                                                      {
                                                          length: booking
                                                              .nearestBooking
                                                              .participants
                                                              .length,
                                                      },
                                                  )}
                                        </span>
                                        <span className="border-l border-[#d2d9da] h-[19px] inline-block mt-1"></span>
                                    </>
                                )}
                                <span>
                                    <Link
                                        className="text-[17px] text-primary"
                                        to={`/newBooking?bookingId=${booking.nearestBooking._id}`}
                                    >
                                        {t('dashboard.session.reschedule')}
                                    </Link>
                                </span>
                                <span className="border-l border-[#d2d9da] h-[19px] inline-block mt-1"></span>
                                <span
                                    className="text-[#ff0000] cursor-pointer text-[17px]"
                                    onClick={() => setOpenRemoveBooking(true)}
                                >
                                    {t('dashboard.session.cancel')}
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="px-8">
                        <PrimaryButton
                            className="w-full btn-primary py-7 flex justify-center items-center"
                            onClick={() => navigate(RouteLink.NEW_BOOKING)}
                            disabled={
                                coachee.coachee?.coacheeMinutesRemaining !==
                                    undefined &&
                                coachee.coachee?.coacheeMinutesRemaining <= 0
                            }
                        >
                            <span className="text-xl">
                                {t('buttons.bookSession')}
                            </span>
                        </PrimaryButton>
                        <div className="flex space-x-2 items-center justify-center mt-[20px]">
                            <SessionLeft isDashboard className="text-white" />
                        </div>
                    </div>
                )}
            </div>
            {openGuest && booking.nearestBooking.participants.length !== 0 && (
                <GuestModal
                    isOpen={openGuest}
                    setIsOpen={setOpenGuest}
                    participants={booking.nearestBooking.participants}
                    bookingId={booking.nearestBooking._id}
                />
            )}

            {openRemoveBooking && (
                <ConfirmationModal
                    isOpen={openRemoveBooking}
                    setIsOpen={setOpenRemoveBooking}
                    onClick={handleDelete}
                    message={
                        isLessThan24Hours
                            ? t('modals.removeBooking.lateCancelHeading')
                            : t('modals.removeBooking.heading')
                    }
                    actionBtnText={t('buttons.cancelSession')}
                    actionBtnCancelText={t('buttons.keepSession')}
                    mainMessage={
                        isLessThan24Hours
                            ? t('modals.removeBooking.mainMessage')
                            : undefined
                    }
                    textHeader={
                        isLessThan24Hours
                            ? t('modals.removeBooking.textHeader')
                            : undefined
                    }
                />
            )}

            {openInviteGuest && (
                <InviteGuestModal
                    isOpen={openInviteGuest}
                    setIsOpen={setOpenInviteGuest}
                    bookingId={booking.nearestBooking._id}
                    bookingDate={booking.nearestBooking.date}
                />
            )}
        </main>
    )
})
