import { useOktaAuth } from '@okta/okta-react'
import OktaSignIn from '@okta/okta-signin-widget'
import * as Sentry from '@sentry/browser'
import { useEffect, useRef } from 'react'
import '../../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import Logo from '../../../assets/logo/logo-dark.svg'
import { getOktaConfigFromUrl } from '../../../oktaConfig'

const OktaLogin = () => {
    const { oktaAuth } = useOktaAuth()
    const widgetRef = useRef<HTMLDivElement>(null)

    const queryParams = new URLSearchParams(window.location.search)
    const encodedData = queryParams.get('details')
        ? queryParams.get('details')
        : null

    const error = queryParams.has('error') ? queryParams.get('error') : null

    useEffect(() => {
        if (!widgetRef.current) {
            return
        }

        const REDIRECT_URI = window.location.origin + '/login/callback'

        let clientId = null
        let issuer = null

        if (encodedData) {
            const decodedData = atob(encodedData)
            const split = decodedData.split(',')
            clientId = split[0]
            issuer = split[1]
        } else {
            const data = getOktaConfigFromUrl()
            clientId = data.clientId
            issuer = data.issuer
        }

        const widget = new OktaSignIn({
            redirectUri: REDIRECT_URI,
            logo: Logo,
            clientId: clientId,
            useClassicEngine: true,
            authParams: {
                issuer: issuer,
                scopes: ['openid', 'profile', 'email', 'offline_access'],
            },

            el: '#root',
            i18n: {
                en: {
                    'primaryauth.title': 'Sign in to SAMA',
                },
                fr: {
                    'primaryauth.title': 'Connectez-vous à SAMA',
                },
            },
        })
        if (!error) {
            oktaAuth.token
                .getWithRedirect({
                    responseType: ['id_token', 'token'],
                    scopes: ['openid', 'profile', 'email', 'offline_access'],
                    prompt: 'none',
                })
                .catch((error: Error) => {
                    Sentry.captureException(error)
                    console.log(error)
                })
        }

        if (error) {
            widget.showSignInAndRedirect().catch((error: Error) => {
                Sentry.captureException(error)
                console.log(error)
            })
        }

        // Note: Can't distinguish CORS error from other network errors
        const isCorsError = (err: { name: string; statusCode: any }) =>
            err.name === 'AuthApiError' && !err.statusCode

        widget.on('afterError', (_context: any, error: any) => {
            Sentry.captureException(error)
            if (isCorsError(error)) {
                console.error('CORS error')
            }
        })

        return () => widget.remove()
    }, [encodedData, error, oktaAuth])

    return (
        <div>
            <div ref={widgetRef} />
        </div>
    )
}

export default OktaLogin
