import * as D from 'date-fns'
import { convertToObject } from 'typescript'

import { Booking, Token } from '../types/SamaApi'

export async function validateToken(token: Token): Promise<void> {
    return new Promise((resolve, reject) => {
        if (D.isPast(token.expiredAfter)) {
            return reject('tooLate')
        }

        if (D.isFuture(token.notValidBefore)) {
            return reject('tooEarly')
        }
        return resolve()
    })
}

export async function validateBooking(booking: Booking): Promise<void> {
    return new Promise((resolve, reject) => {
        if (!/(planned|open|inProgress)/.test(booking.state)) {
            return reject('tooLate')
        }

        if (D.isPast(D.addMinutes(booking.date, booking.duration + 15))) {
            return reject('tooLate')
        }

        if (D.isFuture(D.subMinutes(booking.date, 5))) {
            return reject('tooEarly')
        }

        return resolve()
    })
}

export async function validateDevice(userAgent: string): Promise<void> {
    return new Promise((resolve, reject) => {
        // We only want to detect platforms that have the app available,
        // so this simple regex is enough
        // if (/(iphone|(android.+mobile))/i.test(window.navigator.userAgent)) {
        //     return reject('shouldPromptNativeApp')
        // }

        // if (
        //     /(MSIE|Trident|Opera|Version\/1[0-2]\..+ Safari)/i.test(
        //         window.navigator.userAgent,
        //     )
        // ) {
        //     return reject('shouldPromptRecentBrowser')
        // }

        return resolve()
    })
}

export function validateCameraAccess(mediaDevices: MediaDeviceInfo[]): boolean {
    const isVideoDeviceEnabled = mediaDevices.some((device) => {
        return device.kind === 'videoinput' && device.label !== ''
    })
    const isAudioDeviceEnabled = mediaDevices.some((device) => {
        return device.kind === 'audioinput' && device.label !== ''
    })

    return isVideoDeviceEnabled && isAudioDeviceEnabled
}
