import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { MagicLoginModel } from '../../../models/request'
import { NotificationType, RouteLink } from '../../../utils/constants'
import { stores, useStores } from '../../../utils/stores'
import { OnboardingPageWrapper } from '../components/onboarding-page-wrapper'

export const MagicSignupPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const token = search.get('magicToken')
    const { auth, notifications, coachee } = useStores()
    const [loading, setLoading] = useState(true)

    const logIn = useCallback(() => {
        if (!token) {
            return navigate(RouteLink.INDEX, { replace: true })
        }

        auth.MagicLogIn(new MagicLoginModel(token)).subscribe({
            next(response) {
                if (response.ok) {
                    if (response.data) {
                        auth.setAuthResponse(response.data)
                        const data = response.data
                        if (!data.isPhoneNeeded && data.awaiting2fa) {
                            return navigate(RouteLink.TWO_FA, {
                                replace: true,
                            })
                        }

                        if (data.isPhoneNeeded) {
                            return navigate(RouteLink.SIGN_UP, {
                                replace: true,
                                state: {
                                    canSignUp: true,
                                },
                            })
                        }

                        return coachee.getCoachee().subscribe({
                            next(response) {
                                if (
                                    response.ok &&
                                    coachee.coachee?.currentCoach._id
                                ) {
                                    return navigate(RouteLink.DASHBOARD, {
                                        replace: true,
                                    })
                                } else {
                                    return navigate(RouteLink.SIGN_UP, {
                                        replace: true,
                                        state: {
                                            canSignUp: true,
                                        },
                                    })
                                }
                            },
                        })
                    }
                }

                notifications.createNotification(
                    NotificationType.ERROR,
                    t('messages.invalidToken'),
                    5 * 1000 /* 5 seconds */,
                )
                stores.reset()
                navigate(RouteLink.LOG_IN, { replace: true })
            },
            complete() {
                setLoading(false)
            },
        })
    }, [auth, coachee, navigate, notifications, t, token])

    return token ? (
        <OnboardingPageWrapper>
            <>
                <main className="flex flex-col items-center justify-center  p-10 rounded-[8px] h-full bg-[#d1e5e94d] mb-6">
                    <h3 className="text-[1.4rem]">{t('titles.logIn')}</h3>
                    <PrimaryButton className="px-[48px] mt-6" onClick={logIn}>
                        {t('buttons.continue')}
                    </PrimaryButton>
                </main>
            </>
        </OnboardingPageWrapper>
    ) : null
})

export default MagicSignupPage
