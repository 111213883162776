import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isBefore,
    isEqual,
    isSameDay,
    isSameMonth,
    isToday,
    parse,
    parseISO,
    startOfToday,
} from 'date-fns'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Availability } from '../../../models/response'
import { classNames, formatDate, getFormatString } from '../../../utils/misc'

type Props = {
    selectedDay: Date | undefined
    currentMonth?: string
    setSelectedDay: (day: Date) => void
    setCurrentMonth?: (month: string) => void
    availability?: Availability[]
}

const colStartClasses = [
    'col-start-7',
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
]

const Calender: React.FC<Props> = observer(
    ({
        selectedDay,
        setSelectedDay,
        currentMonth,
        setCurrentMonth,
        availability,
    }) => {
        const { t } = useTranslation()

        const today = startOfToday()
        // const [selectedDay, setSelectedDay] = useState<Date>()

        const firstDayCurrentMonth = parse(
            currentMonth ?? '',
            'MMM-yyyy',
            new Date(),
        )

        const days = eachDayOfInterval({
            start: firstDayCurrentMonth,
            end: endOfMonth(firstDayCurrentMonth),
        })

        const previousMonth = () => {
            const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
            setCurrentMonth &&
                setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
        }

        const nextMonth = () => {
            const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
            setCurrentMonth &&
                setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
        }

        return (
            <div className="lg:px-6 px-4 m-auto">
                <div className="h-[512px] flex-grow-0 m-10 mt-0 mx-auto p-0 pt-0 lg:px-9 px-4 pb-14 rounded-lg bg-white">
                    <span className="flex font-normal mb-2 lg:text-3xl text-xl pt-6 px-3 pb-6">
                        {t('booking.selectDate')}
                    </span>
                    <div className="h-[1px] flex-grow-0 ml-[10px] mr-[10px]  mb-[20px] bg-[#d2d9da]"></div>
                    <div className="md:pr-8">
                        <div className="flex items-center">
                            <h2 className="flex-auto font-normal text-accent-2 px-3">
                                {formatDate(
                                    firstDayCurrentMonth,
                                    getFormatString('month-year'),
                                )}
                            </h2>
                            <button
                                type="button"
                                onClick={previousMonth}
                                className={classNames(
                                    '-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400',
                                    'hover:text-gray-500 bg-[#e9eced] hover:bg-[#a8eaf6] rounded-full',
                                )}
                            >
                                <span className="sr-only">
                                    {' '}
                                    {t('booking.prevMonth')}
                                </span>
                                <ChevronLeftIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                onClick={nextMonth}
                                type="button"
                                className={classNames(
                                    '-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400',
                                    'hover:text-gray-500 bg-[#e9eced] hover:bg-[#a8eaf6] rounded-full',
                                )}
                            >
                                <span className="sr-only">
                                    {t('booking.nextMonth')}
                                </span>
                                <ChevronRightIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-accent-2 font-normal">
                            <div className="text-[19px]">
                                {t('booking.monday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.tuesday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.wednesday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.thursday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.friday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.saturday')}
                            </div>
                            <div className="text-[19px]">
                                {t('booking.sunday')}
                            </div>
                        </div>
                        <div className="grid grid-cols-7 mt-2 text-sm">
                            {days.map((day, dayIdx) => (
                                <div
                                    key={day.toString()}
                                    className={classNames(
                                        dayIdx === 0 &&
                                            colStartClasses[getDay(day)],
                                        'py-1.5',
                                    )}
                                >
                                    <button
                                        type="button"
                                        onClick={() => setSelectedDay(day)}
                                        className={classNames(
                                            selectedDay &&
                                                isEqual(day, selectedDay) &&
                                                'text-white',
                                            selectedDay &&
                                                !isEqual(day, selectedDay) &&
                                                !isToday(day) &&
                                                isSameMonth(
                                                    day,
                                                    firstDayCurrentMonth,
                                                ) &&
                                                'text-accent-2',
                                            selectedDay &&
                                                !isEqual(day, selectedDay) &&
                                                !isToday(day) &&
                                                !isSameMonth(
                                                    day,
                                                    firstDayCurrentMonth,
                                                ) &&
                                                'text-accent-2',
                                            selectedDay &&
                                                isEqual(day, selectedDay) &&
                                                isToday(day) &&
                                                'bg-primary text-white',
                                            selectedDay &&
                                                isEqual(day, selectedDay) &&
                                                !isToday(day) &&
                                                'bg-accent-2',
                                            selectedDay &&
                                                !isEqual(day, selectedDay) &&
                                                'hover:bg-gray-200',
                                            selectedDay &&
                                                (isEqual(day, selectedDay) ||
                                                    isToday(day)) &&
                                                'font-semibold',
                                            'mx-auto flex h-10 w-10 items-center justify-center rounded-full flex-col',
                                            isBefore(day, today)
                                                ? 'cursor-not-allowed opacity-50'
                                                : 'hover:bg-primary hover:text-white hover:scale-105',
                                            'transition-transform duration-300 ease-in-out',
                                        )}
                                        disabled={
                                            isBefore(day, today) ||
                                            !availability?.some((available) =>
                                                available.startingTimes.some(
                                                    (startingTime: Date) =>
                                                        isSameDay(
                                                            parseISO(
                                                                startingTime.toString(),
                                                            ),
                                                            day,
                                                        ),
                                                ),
                                            )
                                        }
                                    >
                                        <time
                                            dateTime={formatDate(
                                                day,
                                                'yyyy-MM-dd',
                                            )}
                                            className="text-[19px]"
                                        >
                                            {formatDate(day, 'd')}
                                        </time>

                                        <div className="w-1 h-1 mx-auto">
                                            {availability &&
                                                availability.some((available) =>
                                                    available.startingTimes.some(
                                                        (startingTime: Date) =>
                                                            isSameDay(
                                                                parseISO(
                                                                    startingTime.toString(),
                                                                ),
                                                                day,
                                                            ),
                                                    ),
                                                ) && (
                                                    <div className="w-2 h-2 rounded-full bg-primary"></div>
                                                )}
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    },
)

export default Calender
