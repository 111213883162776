import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import DropDown from '../../assets/icons/dropdown-circle.svg'
import { ProfilePictureCircle } from '../misc/profile-picture-circle'

interface Props {
    children: React.ReactNode
    isOnboarding?: boolean
    picture?: string
    coachee?: any
    wrapperRef?: React.MutableRefObject<null>
}

export const DropDownModal: React.FC<Props> = ({
    children,
    isOnboarding,
    picture,
    coachee,
    wrapperRef,
}) => {
    const { t } = useTranslation()
    return (
        <Popover>
            <Popover.Button className=" focus:outline-none">
                <div
                    className={classNames(
                        'relative flex space-x-4 items-center',
                        'w-[82px] h-[48px] flex-grow-0',
                        'p-[5px] pr-[8px] rounded-full bg-[#d2d9da] mr-2',
                    )}
                >
                    {isOnboarding ? (
                        <ProfilePictureCircle initials={coachee.initials} />
                    ) : (
                        <ProfilePictureCircle
                            initials={coachee.initials}
                            wrapperClassNames="cursor-pointer"
                            wrapperRef={wrapperRef}
                            picture={picture}
                        />
                    )}
                    <div className="absolute right-1 top-1/2 transform -translate-y-1/2">
                        <img
                            src={DropDown}
                            className="!w-7 !h-6"
                            alt={t('alt.profile')}
                        />
                    </div>
                </div>
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute z-10 mt-1 right-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 max-w-xs">
                        <div className="relative grid gap-8 bg-white p-3">
                            {children}
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
