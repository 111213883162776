import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface FileLoaderProps {
    filename: string
    progress: number
}

export const FileLoader: FC<FileLoaderProps> = ({ filename, progress }) => {
    const { t } = useTranslation()
    return (
        <div
            className={classNames(
                'h-[55px] absolute right-0 -top-11 left-0 mx-auto w-full',
                'border-b border-gray-200 px-4 md:px-8 lg:px-10 bg-[#f7f9fd]',
            )}
        >
            <span className="text-[14px] capitalize font-semibold">
                {t('chat.fileLoader', { filename: filename })}
            </span>
            <div className="flex items-center">
                <div className="h-2 w-[88%] lg:w-[96%]  lg:mt-[2px] bg-gray-300 rounded-full">
                    <div
                        className="h-2 bg-accent rounded-full"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <span className="text-[12px] ml-1">{progress}%</span>
            </div>
        </div>
    )
}
