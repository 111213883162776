import { JSONObject, Message, Paginator } from '@twilio/conversations'
import { MediaDetails } from '../models/response/channel'

export const countWords = (str: string) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, '')
    str = str.replace(/[ ]{2,}/gi, ' ')
    str = str.replace(/\n /, '\n')
    return str.split(' ').length
}

export const getMp3 = async (url: string) => {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer()
    const blob = new Blob([arrayBuffer], { type: 'audio/mp3' })
    return URL.createObjectURL(blob)
}

export const mapMessgaes = async (messages: Paginator<Message>) => {
    return await Promise.all(
        messages.items.map(async (message) => {
            return await mapSingleMessage(message)
        }),
    )
}

export const mapSingleMessage = async (message: Message) => {
    let mediaDetails = {}
    if (message.type === 'media' && message.attachedMedia) {
        mediaDetails = {
            contentType: message.attachedMedia[0].contentType,
            size: message.attachedMedia[0].size,
            sid: message.attachedMedia[0].sid,
            fileName: message.attachedMedia[0].filename,
        }
    }

    return {
        sid: message.sid,
        message: message.body,
        sender: message.author,
        dateCreated: message.dateCreated,
        isMedia: message.type === 'media',
        isUrgent:
            ((message.attributes as JSONObject)?.is_urgent as boolean) || false,
        mediaDetails: mediaDetails as MediaDetails,
    }
}

export const isAllowedAttachmentContentType = (type: string) => {
    return (
        type.search(
            /pdf|application|docx|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.spreadsheetml.sheet|csv|jpg|jpeg|gif|png|xlsx|txt/i,
        ) >= 0
    )
}

export const getFileSize = (size: number) => {
    const sizeInMB = size / (1024 * 1024)
    if (sizeInMB < 0.1) {
        const sizeInKB = size / 1024
        return `${sizeInKB.toFixed(2)} KB`
    }

    return `${sizeInMB.toFixed(2)} MB`
}
