import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ExclamationWarning from '../../../assets/icons/exclamation-mark-warning.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { ConfirmationModal } from '../../dashboard/modals'
import { Spacer } from './account'

export const DeleteAcccount = observer(() => {
    const { t } = useTranslation()
    const { coachee, auth } = useStores()
    const navigate = useNavigate()

    const [openRemoveAccount, setOpenRemoveAccount] = useState(false)
    const [openLogout, setOpenLogout] = useState(false)

    const handleDelete = useCallback(() => {
        coachee.deleteCoachee().subscribe({
            next(response) {
                if (response.ok) {
                    auth.signOut()
                    navigate(RouteLink.LOG_IN)
                }
            },
        })
    }, [auth, coachee, navigate])

    return (
        <div className="my-8">
            <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5">
                <div
                    className={classNames(
                        'my-5 lg:w-1/2',
                        'flex flex-col items-center justify-center',
                    )}
                >
                    <PrimaryButton
                        className="btn-secondary w-[80%] shadow"
                        bgColour="active:btn-secondary"
                        type="button"
                        onClick={() => setOpenLogout(true)}
                    >
                        <span>{t('pageTitles.logout')}</span>
                    </PrimaryButton>
                </div>
                <div
                    className={classNames(
                        'my-5 lg:w-1/2',
                        'flex flex-col items-center justify-center',
                    )}
                >
                    <PrimaryButton
                        className="w-full btn-delete"
                        bgColour="active:btn-delete"
                        type="button"
                        onClick={() => setOpenRemoveAccount(true)}
                    >
                        <span>{t('buttons.deleteAccount')}</span>
                    </PrimaryButton>
                </div>
            </div>
            <Spacer />
            {openRemoveAccount && (
                <ConfirmationModal
                    isOpen={openRemoveAccount}
                    setIsOpen={setOpenRemoveAccount}
                    onClick={handleDelete}
                    message={t('modals.removeAccount.heading')}
                    actionBtnText={t('buttons.deleteAccount')}
                    headerImg={ExclamationWarning}
                    actionBtnCancelText={t('buttons.cancel')}
                />
            )}
            {openLogout && (
                <ConfirmationModal
                    isOpen={openLogout}
                    setIsOpen={setOpenLogout}
                    onClick={auth.signOut}
                    message={t('modals.logOut.heading')}
                    actionBtnText={t('buttons.logOut')}
                    actionBtnCancelText={t('buttons.stayLogin')}
                />
            )}
        </div>
    )
})
