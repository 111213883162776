import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import editIcon from '../../../assets/icons/edit2.svg'
import removeIcon from '../../../assets/icons/remove.svg'
import { ProfilePictureCircle } from '../../../components/misc/profile-picture-circle'
import { CenterModal } from '../../../components/modals'
import { Participant } from '../../../models/response'
import { useStores } from '../../../utils/stores'
import { ConfirmationModal } from './confirmation.modal'
import { EditGuestModal } from './edit-guest.modal'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    participants: Participant[]
    bookingId: string
}

export const GuestModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, participants, bookingId }) => {
        const { t } = useTranslation()
        const { booking, coachee } = useStores()
        const [openEditGuest, setOpenEditGuest] = useState(false)
        const [openRemoveConfirm, setOpenRemoveConfirm] = useState(false)
        const [participant, setParticipant] = useState<Participant | null>(null)

        const handleRemoveGuest = useCallback(
            (participantId: string) => {
                booking.removeBookingGuest(bookingId, participantId).subscribe({
                    next(response) {
                        if (response.ok) {
                            setOpenRemoveConfirm(false)
                        }
                    },
                })
            },
            [booking, bookingId],
        )

        const handleGuestPopup = (
            participant: Participant,
            remove: boolean = false,
        ) => {
            setParticipant(participant)

            if (remove) {
                setOpenRemoveConfirm(true)
                return
            }

            setOpenEditGuest(true)
        }

        return (
            <div>
                {isOpen && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={() => setIsOpen(false)}
                        className="max-w-[32rem]"
                        headerTitle={t('modals.guest.heading')}
                    >
                        <main className="mt-8 bg-[#fafafa] w-full py-2 px-5">
                            <span className="text-[17px]">
                                {t('modals.guest.subText', {
                                    number: participants.length,
                                    total: coachee.coacheeCompanySettings
                                        ?.sessionGuests.max,
                                })}
                            </span>
                            {participants.map(
                                (participant: Participant, i: number) => (
                                    <div
                                        key={i}
                                        className="flex justify-between items-center pt-4 pb-10"
                                    >
                                        <div className="flex gap-2">
                                            <ProfilePictureCircle
                                                initials={participant.name[0]}
                                            />
                                            <div className="flex flex-col ">
                                                <div className="flex gap-1">
                                                    <span className="font-bold capitalize text-[17px]">
                                                        {participant.name}
                                                    </span>
                                                    <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        className="w-5 h-3 cursor-pointer mt-1"
                                                        onClick={() =>
                                                            handleGuestPopup(
                                                                participant,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <span className="text-[17px]">
                                                    {participant.email}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex gap-2">
                                            <span
                                                className="text-[17px] text-[#ff0000] cursor-pointer"
                                                onClick={() =>
                                                    handleGuestPopup(
                                                        participant,
                                                        true,
                                                    )
                                                }
                                            >
                                                {t('others.remove')}
                                            </span>
                                            <img
                                                src={removeIcon}
                                                alt="close"
                                                className="w-6 h-6"
                                            />
                                        </div>
                                    </div>
                                ),
                            )}
                            {openEditGuest &&
                                participant &&
                                participant._id && (
                                    <EditGuestModal
                                        isOpen={openEditGuest}
                                        setIsOpen={setOpenEditGuest}
                                        guestName={participant.name}
                                        bookingId={bookingId}
                                        participantId={participant._id}
                                    />
                                )}
                            {openRemoveConfirm &&
                                participant &&
                                participant._id && (
                                    <ConfirmationModal
                                        isOpen={openRemoveConfirm}
                                        setIsOpen={setOpenRemoveConfirm}
                                        onClick={() =>
                                            handleRemoveGuest(
                                                participant._id ?? '',
                                            )
                                        }
                                        message={t(
                                            'modals.removeGuest.heading',
                                            {
                                                email: participant.email,
                                            },
                                        )}
                                        actionBtnText={t('buttons.delete')}
                                        actionBtnCancelText={t(
                                            'buttons.cancel',
                                        )}
                                    />
                                )}
                        </main>
                    </CenterModal>
                )}
            </div>
        )
    },
)
