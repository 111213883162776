import OktaAuth from '@okta/okta-auth-js'
import { hydrateFromStorage } from './utils/misc'

export const getOktaConfigFromUrl = () => {
    const storedString = hydrateFromStorage('authConfig')

    let authConfig: { issuer?: string; clientId?: string } = {}
    if (typeof storedString === 'string') {
        authConfig = JSON.parse(storedString)
    }
    let oktaDomain
    if (authConfig.issuer) {
        const parsedUrl = new URL(authConfig.issuer)
        oktaDomain = parsedUrl.hostname
    }

    return {
        issuer: authConfig.issuer || `https://${oktaDomain}/oauth2/default`,
        clientId: authConfig.clientId as string,
        redirectUri: window.location.origin + '/login/callback',
    }
}

const oktaConfig = getOktaConfigFromUrl()

export const oktaAuthInfo = new OktaAuth({
    issuer: oktaConfig.issuer as string,
    clientId: oktaConfig.clientId,
    redirectUri: oktaConfig.redirectUri,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
})
