import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EmptyNote from '/src/assets/icons/empty-note.svg'

export const EmptyNoteList = observer(() => {
    const { t } = useTranslation()

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-10">
            <div className="flex flex-col items-center justify-center mt-10">
                <img src={EmptyNote} className="w-[6rem] h-[6rem] m-auto" />
                <span className="text-[22px] mt-8">{t('note.noNote')}</span>
                <span className="text-[17px]">{t('note.noNoteDesc')}</span>
            </div>
        </div>
    )
})
