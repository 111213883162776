import { useField } from 'formik'
import React, { useCallback } from 'react'

import IconChecked from '../../assets/icons/checkmark_filled.svg'
import { Option } from '../../models/response'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'
import { ListBox } from './list-box'

interface Props {
    name: string
    label?: string | React.ReactNode
    className?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
    onChange?: any
}

const CheckElement: React.FC = () => {
    return <img src={IconChecked} alt="Check in circle" className="w-3 ml-2" />
}

export const SelectInput: React.FC<Props> = ({
    name,
    label,
    className,
    placeholder,
    options,
    disabled,
    onChange,
}) => {
    const [field, meta, helpers] = useField(name)

    const onSelect = useCallback((option: Option) => {
        if (onChange) {
            onChange(option)
        }

        helpers.setValue(option)
    }, [])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[13px] mb-2">
                    {label}
                </label>
            )}
            <ListBox
                onSelect={onSelect}
                value={field.value || ''}
                options={options}
                placeholder={placeholder}
                buttonClassName="!h-12"
                optionsClassName="!w-full bg-[#ffffff] !divide-[#10123A]/[3%] !p-0"
                optionClassName="text-[#6b6a72]"
                optionClassNameActive="!text-primary"
                optionClassNameSelected="!text-primary flex-row-reverse justify-between"
                disabled={disabled}
                checkElement={<CheckElement />}
            />
            {meta.error && (
                <div className="mt-[10px] error">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
