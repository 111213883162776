import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddAreaOfCoachingModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const QuizComponant: React.FC = observer(() => {
    const { t } = useTranslation()
    const { coachee } = useStores()
    return (
        <div className="w-[60%] m-auto text-center pt-4">
            <Trans t={t} i18nKey="onboarding.areaOfCoaching.quizText">
                <a
                    className="text-[#1f3f48] underline font-semibold"
                    href={t('onboarding.areaOfCoaching.quizLink', {
                        userId: coachee.coachee?.user?._id,
                    })}
                    target="_blank"
                />
            </Trans>
        </div>
    )
})

export const AreaOfCoachingStage: React.FC<Props> = observer(
    ({ currentStage }) => {
        const { t } = useTranslation()
        const { statics, coachee } = useStores()
        const [subscriptions] = useState<Subscription[]>([])

        const setAreaOfCoaching = useCallback(
            (
                values: AddAreaOfCoachingModel,
                helpers: FormikHelpers<AddAreaOfCoachingModel>,
            ) => {
                helpers.setSubmitting(true)

                cancelSubscriptions(subscriptions)

                const subscription = coachee.updateCoachee(values).subscribe({
                    next(response) {
                        if (response.ok && coachee.coachee) {
                            coachee.setOnboardingStage(Stage.TOP_VALUES)
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.desireCompetency = values.areaOfCoaching
                            coachee.setCoachee(newCoachee)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                    },
                })

                subscriptions.push(subscription)
            },
            [coachee, subscriptions],
        )

        return (
            <QuestionForm
                title={t('onboarding.areaOfCoaching.title')}
                subTitle={t('onboarding.areaOfCoaching.subTitle')}
                currentNumber={currentStage}
                totalNumber={
                    coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
                }
                questions={statics.competencies}
                name="areaOfCoaching"
                expand={true}
                model={
                    new AddAreaOfCoachingModel(
                        coachee.coachee?.desireCompetency,
                    )
                }
                onSubmit={setAreaOfCoaching}
                extraComponent={<QuizComponant />}
                dropDownIcon={true}
                isOnboarding={true}
                onPrevious={() =>
                    coachee.setOnboardingStage(
                        coachee.onboardingStage.stage - 1,
                    )
                }
            />
        )
    },
)
