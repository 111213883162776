import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingButtons } from '../../../../components/buttons/onboarding-button'
import { PrimaryButton } from '../../../../components/buttons/primary-button'
import { OtpInput } from '../../../../components/inputs/otp-input'
import { TwoFaModel } from '../../../../models/request'
import { useStores } from '../../../../utils/stores'
import { validateModel } from '../../../../utils/validation/validate-model'
import { Resend2fa } from '../../components/resend-2fa'

interface Props {
    title: string
    subTitle?: string
    onPrevious?: any
    isOnboarding?: boolean
    onSubmit: (model: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => any
}

export const VerifyPhoneForm: React.FC<Props> = observer(
    ({ onPrevious, onSubmit, isOnboarding }) => {
        const { auth } = useStores()
        const { t } = useTranslation()

        const canSendCode = useMemo(() => {
            return !auth.authResponse?.codeRequestedAt
        }, [auth.authResponse?.codeRequestedAt])

        const resendCode = useCallback(() => {
            auth.resend2fa().subscribe()
        }, [auth])

        return (
            <Formik
                enableReinitialize
                validate={validateModel}
                initialValues={new TwoFaModel(auth.phone ?? undefined)}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    isValid,
                    isValidating,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex-col max-w-[30rem] w-full"
                    >
                        <header className="mb-4 flex flex-col">
                            <span className="cursor-pointer font-semibold inline-flex text-[1.8rem]">
                                {t('titles.twoFaHeader.header')}
                            </span>
                        </header>
                        <main className="flex flex-col items-center justify-center  py-10 rounded-[8px] h-full bg-[#d1e5e94d] mb-6">
                            <h3 className="text-[1.4rem]">
                                {t('titles.twoFaHeader.subHeader')}
                            </h3>
                            {canSendCode &&
                            !auth.authResponse?.codeRequestedAt ? (
                                <>
                                    <span className="text-[14px] w-[300px]  -mt-3 text-center">
                                        {t('titles.twoFaHeader.content')}
                                    </span>

                                    <PrimaryButton
                                        className="px-[48px] mt-6"
                                        onClick={resendCode}
                                    >
                                        <Trans
                                            t={t}
                                            i18nKey="subTitles.sendTwoFa"
                                            values={{
                                                phone_number:
                                                    auth.authResponse
                                                        ?.phoneNumber2fa,
                                            }}
                                        />
                                    </PrimaryButton>
                                </>
                            ) : (
                                <>
                                    <OtpInput
                                        name="code"
                                        disabled={isSubmitting}
                                        setCode={setFieldValue}
                                    />{' '}
                                    {isOnboarding ? (
                                        <OnboardingButtons
                                            onPrevious={onPrevious}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            className="w-[90%] md:w-[85%] mt-6"
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={!isValid || isValidating}
                                        >
                                            <span>{t('buttons.verify')}</span>
                                        </PrimaryButton>
                                    )}
                                    <span className="text-[14px] py-5 w-[70%] text-center">
                                        {t('subTitles.twoFaCodeSent')}
                                    </span>
                                </>
                            )}
                        </main>
                        <Resend2fa />
                    </form>
                )}
            </Formik>
        )
    },
)
