import { AuthRequirement } from '../../pages/routes'
import { RouteLink } from '../../utils/constants'
import { stores } from '../../utils/stores'

type AuthRequirementResponse = [boolean, RouteLink, any?]

export const AUTH_REQUIREMENTS: Record<
    AuthRequirement,
    () => AuthRequirementResponse
> = {
    [AuthRequirement.AUTHENTICATED]() {
        return [
            stores.auth.authenticated,
            RouteLink.LOG_IN,
        ] as AuthRequirementResponse
    },

    [AuthRequirement.UNAUTHENTICATED]() {
        const response = !stores.auth.authenticated

        if (stores.auth.authResponse?.isPasswordGenerated) {
            return [true, RouteLink.RESET_INITIAL_PASSWORD]
        }
        if (stores.auth.authResponse?.isPhoneNeeded) {
            return [response, RouteLink.SIGN_UP]
        }
        if (stores.auth.authResponse?.awaiting2fa) {
            return [response, RouteLink.TWO_FA]
        }

        return [response, RouteLink.LOADING_PAGE]
    },

    [AuthRequirement.AWAITING_2FA]() {
        return [!!stores.auth.authResponse?.awaiting2fa, RouteLink.DASHBOARD]
    },

    [AuthRequirement.NOT_AWAITING_2FA]() {
        return [!stores.auth.authResponse?.awaiting2fa, RouteLink.TWO_FA]
    },
    [AuthRequirement.HAS_PHONE]() {
        return [!stores.auth.authResponse?.isPhoneNeeded, RouteLink.SIGN_UP]
    },

    [AuthRequirement.DOES_NOT_HAVE_PHONE]() {
        return [!!stores.auth.authResponse?.isPhoneNeeded, RouteLink.TWO_FA]
    },
    [AuthRequirement.FULLY_AUTHENTICATED_ONBOARDING]() {
        const [authenticated] = this[AuthRequirement.AUTHENTICATED]()
        const [awaiting2fa] = this[AuthRequirement.AWAITING_2FA]()
        const [notFullyAuthed] = this[AuthRequirement.AWAITING_2FA]()
        const response = authenticated && !awaiting2fa
        if (stores.auth.authResponse?.isPhoneNeeded) {
            return [false, RouteLink.SIGN_UP]
        }

        if (stores.auth.authResponse?.isPasswordGenerated) {
            return [response, RouteLink.FORGOTTEN_PASSWORD]
        }

        if (stores.auth.authResponse?.awaiting2fa) {
            return [response, RouteLink.TWO_FA]
        }
        return [
            authenticated && !awaiting2fa && !notFullyAuthed,
            RouteLink.LOG_IN,
        ]
    },
    [AuthRequirement.FULLY_AUTHENTICATED]() {
        const [authenticated] = this[AuthRequirement.AUTHENTICATED]()
        const [awaiting2fa] = this[AuthRequirement.AWAITING_2FA]()
        const response = authenticated && !awaiting2fa

        if (stores.auth.authResponse?.isPhoneNeeded) {
            return [false, RouteLink.SIGN_UP]
        }

        if (stores.auth.authResponse?.awaiting2fa) {
            return [response, RouteLink.TWO_FA]
        }

        if (
            stores.coachee.coachee?.industry !== undefined &&
            !stores.coachee.hasCompletedAllRequiredOnboardingQuestions
        ) {
            stores.coachee.onboardingStage.stage = 0

            return [false, RouteLink.ONBOARDING, { canOnboard: true }]
        }

        return [authenticated && !awaiting2fa, RouteLink.LOG_IN]
    },
}
