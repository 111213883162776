import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { PersonalValues } from '../../response/coachee/personal-values'
import { BaseModel } from '../base.model'

export class AddPersonalValuesModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public career: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public finance: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public personalDevelopment: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public purpose: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public health: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public fAndF: number | null = null

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public socialLifeAndFun: number | null = null

    constructor(initialValues: PersonalValues | null = null) {
        if (initialValues) {
            this.career = initialValues.career
            this.finance = initialValues.finance
            this.personalDevelopment = initialValues.personalDevelopment
            this.purpose = initialValues.purpose
            this.health = initialValues.health
            this.fAndF = initialValues.fAndF
            this.socialLifeAndFun = initialValues.socialLifeAndFun
        }
    }

    public getRequestBody() {
        return {
            personalValues: {
                career: this.career,
                finance: this.finance,
                personalDevelopment: this.personalDevelopment,
                purpose: this.purpose,
                health: this.health,
                fAndF: this.fAndF,
                socialLifeAndFun: this.socialLifeAndFun,
            },
        }
    }
}
