import { Allow } from 'class-validator'
import { BaseModel } from '../base.model'

export class MagicLoginModel implements BaseModel {
    @Allow()
    public magicToken: string

    constructor(magicToken: string) {
        this.magicToken = magicToken
    }
}
