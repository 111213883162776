import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { useStores } from '../../../utils/stores'
import { CoachModal } from '../../dashboard/modals/coach.modal'
import { ChangeMyCoach } from '../modals/changeMyCoach.modal'

export const MyCoach = observer(() => {
    const { t } = useTranslation()

    const { coachee, image } = useStores()
    const [isProfileOpen, setProfileIsOpen] = useState(false)
    const [isChangeCoachOpen, setIsChangeCoachOpen] = useState(false)

    useEffect(() => {
        if (coachee.coachee?.currentCoach._id) {
            image.getCoachPicture(coachee.coachee?.currentCoach._id).subscribe()
        }
    }, [coachee.coachee?.currentCoach._id, image])

    return (
        <div className="mt-8">
            <span className="text-[22px]">{t('account.myCoach')}</span>
            <div className="border border-[#d2d9da] rounded p-6 mt-3">
                <div className="flex">
                    <img
                        src={image.coachPicture ?? undefined}
                        className="w-[4rem] h-[4rem] rounded-[100px]"
                    />
                    <div className="flex flex-col my-auto ml-3">
                        <span>
                            <strong>
                                {coachee.coachee?.currentCoach.user.firstName}
                                &nbsp;
                                {coachee.coachee?.currentCoach.user.lastName}
                            </strong>
                        </span>
                        <span
                            className="text-[17px] text-[#6b6a72] cursor-pointer"
                            onClick={() => setProfileIsOpen(true)}
                        >
                            {t('buttons.profile')}
                        </span>
                    </div>
                </div>
                <div
                    className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-8')}
                ></div>
                <div
                    className={classNames(
                        'mt-6',
                        'flex flex-col items-center justify-center',
                    )}
                >
                    <PrimaryButton
                        className="w-full shadow"
                        type="button"
                        onClick={() => setIsChangeCoachOpen(true)}
                    >
                        <span>{t('account.changeCoach')}</span>
                    </PrimaryButton>
                </div>
            </div>
            <CoachModal isOpen={isProfileOpen} setIsOpen={setProfileIsOpen} />
            <ChangeMyCoach
                isOpen={isChangeCoachOpen}
                setIsOpen={setIsChangeCoachOpen}
            />
        </div>
    )
})
