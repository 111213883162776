import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function ApiError({ when }: { when: boolean }): ReactElement {
    const { t } = useTranslation()

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('ApiError.title')}
        >
            <ReactMarkdown>{t('ApiError.body') as string}</ReactMarkdown>
        </Popup>
    )
}
