import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useEffect } from 'react'
import { trackEvent } from '../../services/event-tracking'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { ModalCloseButton } from '../buttons/modal-close-button'
import { ModalProps } from './modal-props'

interface Props extends ModalProps {
    className?: string
    showCloseButton?: boolean
    headerTitle?: string
    optionalClose?: () => void
    title?: string
}

export const CenterModal: React.FC<Props> = ({
    children,
    className,
    isOpen,
    setIsOpen,
    initialFocus,
    showCloseButton = true,
    headerTitle,
    optionalClose,
    title,
}) => {
    const { coachee } = useStores()
    useEffect(() => {
        if (isOpen && title) {
            trackEvent('screen_viewed', {
                name: title,
                coachee_id: coachee.coachee?._id,
            })
        }
    }, [coachee.coachee?._id, isOpen, title])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo=" translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom=" translate-y-0 sm:scale-100"
            leaveTo="translate-y-4 sm:translate-y-0 sm:scale-95"
            as={Fragment}
        >
            <Dialog
                open={isOpen}
                onClose={optionalClose || close}
                className="z-50 isolate fixed inset-0 flex items-center justify-center p-5 overflow-y-auto scroll-my-5 h-screen"
                initialFocus={initialFocus}
            >
                <div
                    className="fixed inset-0 bg-[#030412]/30"
                    aria-hidden="true"
                />

                <Dialog.Panel
                    className={classNames(
                        className,
                        'w-full max-w-[28rem] bg-white pt-9 scroll-py-10 rounded-md relative mt-5',
                    )}
                >
                    <div className={classNames(headerTitle && 'z-30')}>
                        {headerTitle && (
                            <span className="absolute  top-7 left-7 text-xl font-bold">
                                {headerTitle}
                            </span>
                        )}
                        {showCloseButton && (
                            <ModalCloseButton
                                onClick={optionalClose || close}
                                className={classNames(
                                    headerTitle ? 'top-7' : 'top-3',
                                    'absolute right-3',
                                )}
                            />
                        )}
                    </div>

                    <main>{children}</main>
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}
