import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileDropDownModal } from '../../pages/dashboard/modals'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'

interface Props {
    pageName?: string
    hasScrolled?: boolean
}

export const DashboardPageHeader: React.FC<Props> = observer(
    ({ pageName, hasScrolled }) => {
        const { coachee, image } = useStores()
        const { t } = useTranslation()
        const coachTimeZone = coachee.coachee?.currentCoach.user._timezone

        const localTimeInCoachTimeZone = toZonedTime(
            new Date(),
            coachTimeZone ?? '',
        )

        useEffect(() => {
            if (coachee.coachee?._id) {
                image.getCoacheePicture(coachee.coachee?._id).subscribe()
            }
        }, [coachee.coachee?._id, image])

        return (
            <header
                className={classNames(
                    'fixed z-40 pt-1',
                    hasScrolled
                        ? ' md:flex bg-[#FFFFFF] shadow-lg opacity-95'
                        : ' md:flex',
                    'transition-all w-[-webkit-fill-available] w-[-moz-available]',
                    'md:px-6 h-[70px] flex-shrink-0 flex-wrap items-center justify-between  sm:w-[calc(100vw_-_200px)] lg:w-[calc(100vw_-_300px)]',
                )}
            >
                <span className="text-[22px] hidden md:block ml-2">
                    {pageName}
                </span>
                {pageName === t('pageTitles.chat') && (
                    <div
                        className={classNames(
                            'flex flex-col sticky md:mt-0   mt-[68px] z-40',
                            'md:bg-transparent items-center space-x-4 my-2',
                            hasScrolled && 'bg-white md:bg-transparent',
                        )}
                    >
                        <span className="lg:text-xl text-[17px] text-center capitalize">
                            {coachee.coachee?.currentCoach.user.firstName}
                            &nbsp;
                            {coachee.coachee?.currentCoach.user.lastName}
                        </span>
                        <span className="text-[#828399] text-xl">
                            {t('chat.coachTime', {
                                time: format(
                                    localTimeInCoachTimeZone,
                                    'h:mm aa',
                                ),
                            })}
                        </span>
                    </div>
                )}
                <div className={classNames('hidden md:block', 'relative ')}>
                    <ProfileDropDownModal isOnboarding={false} />
                </div>

                {!hasScrolled && (
                    <div
                        className={classNames(
                            'w-[calc(100vw_-_350px)] md:h-[1px] p-0 bg-[#d2d9da]',
                            'ml-[10px] mr-[10px]',
                        )}
                    ></div>
                )}
            </header>
        )
    },
)
