import * as Sentry from '@sentry/browser'
import * as D from 'date-fns'
import ky, { Options } from 'ky'
import fp from 'lodash/fp'

import defaultImage from '../assets/icons/camera-off_icon.png'
import { Booking, ParticipantDetail, Session, User } from '../types/SamaApi'
import { trackEvent as trackMixpanel } from './event-tracking'

const VITE_APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL as string

if (!VITE_APP_BASE_URL) {
    throw new Error('VITE_APP_BASE_URL should be configured in .env')
}

class SamaApi {
    ky = ky
    prefixUrl = ''

    constructor() {
        this.prefixUrl = VITE_APP_BASE_URL

        this.ky = ky.create({
            prefixUrl: this.prefixUrl,
            timeout: false,
            retry: 0,
            fetch: window.fetch.bind(window),
        })
    }

    extend = (options: Options) => {
        this.ky = this.ky.extend(options)
    }

    getMe = async (): Promise<User> => {
        const me: User = await this.ky.get('me').json()

        return me
    }

    getCoach = async (
        coachId: string,
    ): Promise<{ user: User; picUrl: string }> => {
        const [coach, picUrl] = await Promise.all([
            this.ky.get(`coach/${coachId}`).json(),
            this.ky
                .get(`coach/${coachId}/picture`)
                .blob()
                .then((blob) => {
                    const reader = new FileReader()
                    return new Promise((resolve) => {
                        reader.onload = () => resolve(URL.createObjectURL(blob))
                        reader.readAsDataURL(blob)
                    })
                })
                .catch((e) => {
                    Sentry.captureException(e)
                    // todo return default image
                    // as we could not get one
                    // from the server for some reason
                    return null
                }),
        ])

        return {
            ...(coach as any),
            picUrl,
        }
    }

    getImage = async (type: string, id: string): Promise<string> => {
        // Check if the type is 'guest' and return a default image
        if (type === 'guest') {
            return defaultImage
        }
        const url =
            type === 'coach' ? `coach/${id}/picture` : `coachee/${id}/picture`

        const picUrl = await this.ky
            .get(url)
            .blob()
            .then((blob) => {
                const reader = new FileReader()
                return new Promise((resolve) => {
                    reader.onload = () => resolve(URL.createObjectURL(blob))
                    reader.readAsDataURL(blob)
                })
            })
            .catch((e) => {
                Sentry.captureException(e)

                return defaultImage
            })

        return picUrl as string
    }

    getBooking = async (bookingId: string): Promise<Booking> => {
        const booking: any = await this.ky(`booking/${bookingId}`, {
            method: 'get',
        }).json()

        booking.date = D.parseISO(booking.date)
        booking.time = D.format(booking.date, 'HH:mm')

        return booking
    }

    endBooking = async (bookingId: string): Promise<void> => {
        return this.ky.put(`booking/${bookingId}/end`).json()
    }

    getSession = async (bookingId: string): Promise<Session> => {
        const data: any = await this.ky
            .get(`booking/${bookingId}/session`)
            .json()
        return data.room
    }

    rateSession = async (bookingId: string, rate: number): Promise<Session> => {
        return this.ky
            .post(`booking/${bookingId}/session/rate`, {
                json: { rate },
            })
            .json()
    }

    /**
     *
     *  @deprecated use trackMixpanel function instead
     */
    trackEvent = async (name: string, properties?: any) => {
        return trackMixpanel(name, properties)
    }

    logIn = async (userType = 'coachee'): Promise<void> => {
        return this.ky
            .post('auth/login', {
                json:
                    userType === 'coachee'
                        ? {
                              email: '',
                              password: '',
                          }
                        : {
                              email: '',
                              password: '',
                          },
            })
            .json()
            .then((body: any) => {
                sessionStorage.setItem('accessToken', body.accessToken)

                this.extend({
                    headers: {
                        Authorization: `Bearer ${body.accessToken}`,
                        'user-type': 'coachee',
                        platform: 'coacheeWeb',
                    },
                })
            })
    }

    getMeCoachee = async (): Promise<User> => {
        return this.ky.get('me/coachee').json()
    }

    bookSessions = async (isVideoSession = true): Promise<Booking[]> => {
        const bookingRequests = Array.from(Array(3)).map(
            async (val, i): Promise<Booking | boolean> => {
                try {
                    return await this.ky
                        .post(`booking`, {
                            json: {
                                date: D.startOfHour(
                                    D.addHours(new Date(), i + 1),
                                ),
                                isVideoSession,
                                duration: 50,
                            },
                        })
                        .json()
                } catch (error) {
                    Sentry.captureException(error)
                    console.error(error)

                    return false
                }
            },
        )

        return Promise.all(bookingRequests).then(
            (responses) => responses.filter(Boolean) as Booking[],
        )
    }

    listBookings = async (userId: string): Promise<Booking[]> => {
        const body: any = await this.ky.get(`coachee/${userId}/bookings`).json()

        return fp.sortBy('date', body).map((booking: any) => ({
            ...booking,
            date: D.parseISO(booking.date),
        }))
    }

    getSessionParticipantDetails = async (
        bookingId: string,
    ): Promise<ParticipantDetail[]> => {
        try {
            return await this.ky
                .get(`booking/${bookingId}/participants`)
                ?.json()
        } catch (e) {
            // This is temporary it is to handel the fact this api
            // has not yet been deployed to production
            return Promise.resolve([])
        }
    }

    logEvent = async (data: object): Promise<void> => {
        this.ky
            .post(`sama/l/event`, {
                json: { ...data },
            })
            .json()
    }

    resetPassword = (token: string, password: string): Promise<void> => {
        return this.ky
            .post('auth/reset-my-password', {
                json: {
                    token: token,
                    password: password,
                },
            })
            .json()
    }

    testingEndpoint = async (): Promise<void> => {
        // this header forces the OPTIONS request
        this.extend({
            headers: {
                'x-temp-authorization': `Bearer RANDOM_TOKEN`,
            },
        })

        const me: any = await this.ky.get('am-i-really-here').json()
        return me
    }
}

const samaApi = new SamaApi()

export default samaApi
