import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'
import ExclamationMark from '/src/assets/icons/exclamation-blue-slanted.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onBookSession: () => void
    timePeriod?: string
}

export const OutOfTimeBooking: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, onBookSession, timePeriod }) => {
        const { t } = useTranslation()

        const handleBookSession = () => {
            setIsOpen(false)
            onBookSession()
        }

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
            >
                <div className="px-9 pb-5">
                    <header className="flex flex-col">
                        <img
                            src={ExclamationMark}
                            className="w-[6rem] h-[6rem] m-auto"
                        />
                    </header>
                    <main className="flex flex-col mt-4 text-center">
                        <span className="text-2xl font-bold">
                            {t('modals.booking.heading')}
                        </span>
                        <span className="text-xl mt-4">
                            {t('modals.booking.subText', {
                                period: timePeriod,
                            })}
                        </span>
                    </main>

                    <footer className="flex pt-[25px]">
                        <PrimaryButton
                            className="w-full m-2 btn-default"
                            bgColour="bg-[#f3f4f4]"
                            onClick={handleBookSession}
                        >
                            <span>{t('buttons.bookLateSession')}</span>
                        </PrimaryButton>

                        <PrimaryButton
                            className="w-full m-2 btn-default"
                            bgColour="bg-[#f3f4f4]"
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{t('buttons.cancel')}</span>
                        </PrimaryButton>
                    </footer>
                </div>
            </CenterModal>
        )
    },
)
