import { FormikHelpers, useField } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { TextInput } from '../../../components/inputs/text-input'
import { AddGenderModel } from '../../../models/request/onboarding'
import { Coachee } from '../../../models/response/coachee'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

const GenderOtherQuestion: React.FC = () => {
    const { t } = useTranslation()
    const [field] = useField('gender')
    return (
        <div className="mt-2">
            {field.value === 'other' && (
                <TextInput
                    name="genderSpecified"
                    label={t('onboarding.genderSpecified.title') as string}
                    placeholder={
                        t('onboarding.genderSpecified.placeholder') as string
                    }
                />
            )}
        </div>
    )
}

export const GenderStage: React.FC<Props> = observer(({ currentStage }) => {
    const { t } = useTranslation()
    const { statics, coachee } = useStores()
    const [subscriptions] = useState<Subscription[]>([])

    useEffect(() => {
        if (
            coachee.onboardingQuestions &&
            !coachee.onboardingQuestions.gender.enabled
        ) {
            coachee.setOnboardingStage(currentStage + 1)
        }
    }, [coachee, coachee.onboardingQuestions, currentStage])

    const setGender = useCallback(
        (values: AddGenderModel, helpers: FormikHelpers<AddGenderModel>) => {
            helpers.setSubmitting(true)

            cancelSubscriptions(subscriptions)

            const subscription = coachee.updateCoachee(values).subscribe({
                next(response) {
                    if (response.ok && coachee.coachee) {
                        coachee.setOnboardingStage(Stage.AGE_RANGE)
                        const newCoachee = { ...coachee.coachee }
                        newCoachee.genderSpecified = values.genderSpecified
                        newCoachee.gender = values.gender as Coachee['gender']
                        coachee.setCoachee(newCoachee)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [coachee, subscriptions],
    )

    return (
        <QuestionForm
            title={t('onboarding.gender.title')}
            currentNumber={currentStage}
            totalNumber={
                coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
            }
            questions={statics.coacheeGender}
            name="gender"
            model={
                new AddGenderModel(
                    coachee.coachee?.gender,
                    coachee.coachee?.genderSpecified,
                )
            }
            extraComponent={<GenderOtherQuestion />}
            onSubmit={setGender}
            isOnboarding={true}
            onPrevious={() =>
                coachee.setOnboardingStage(coachee.onboardingStage.stage - 1)
            }
        />
    )
})
