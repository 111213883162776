import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { SelectInput } from '../../../components/inputs/select-input'
import { CoachingPreferenceModel } from '../../../models/request/coachee/coaching-preference.model'
import { Option } from '../../../models/response'
import { trackEvent } from '../../../services/event-tracking'
import { getStaticsValue } from '../../../stores'
import { coachGender } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { QuizComponant } from '../../onboarding/stages'
import { ChatHistory } from './chatHistory'
import { MyCoach } from './myCoach'

export const Spacer: React.FC = observer(() => {
    return <div className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-4')}></div>
})

export const CoachingPreference = observer(() => {
    const { coachee, statics } = useStores()
    const { t } = useTranslation()

    useEffect(() => {
        trackEvent('screen_viewed', {
            name: 'coaching_preference',
            coachee_id: coachee.coachee?._id,
        })
    }, [coachee.coachee?._id])

    const genderOptions = useMemo(() => {
        return coachGender[
            (coachee.coachee?.user?.appLanguage as keyof typeof coachGender) ??
                ('en' as keyof typeof coachGender)
        ].map(
            (gender): Option => ({
                value: gender.key,
                display: gender.name,
            }),
        )
    }, [coachee.coachee])

    const genderToMatch = coachee?.coachee?.coachGender
    const matchingCoachGender = genderOptions.find(
        (coachee) => coachee.value === genderToMatch,
    )

    const competenciesOptions = useMemo(() => {
        return statics.competencies.map(
            (competency): Option => ({
                value: competency.key,
                display: competency.name,
                description: competency.description,
                icon: competency.icon,
            }),
        )
    }, [statics.competencies])

    const compitencyToMatch = coachee?.coachee?.desireCompetency
    const matchingCoacheeCompetency = competenciesOptions.find(
        (coachee) => coachee.value === compitencyToMatch,
    )

    const getCoachingLanguages = useMemo(() => {
        if (coachee.allowedCoachingLangugages.length > 0) {
            return coachee.allowedCoachingLangugages.map((lang) => {
                return {
                    value: lang,
                    display: getStaticsValue(statics.languages, lang),
                } as Option
            })
        }
    }, [coachee.allowedCoachingLangugages, statics.languages])

    const languageToMatch = coachee?.coachee?.coachingLanguage
    const coachingLanguage = getCoachingLanguages?.find(
        (coachee) => coachee.value === languageToMatch,
    )

    const onSubmit = useCallback(
        (
            values: CoachingPreferenceModel,
            helpers: FormikHelpers<CoachingPreferenceModel>,
        ) => {
            helpers.setSubmitting(true)
            coachee.updatePersonalCoachee(values).subscribe({
                next(response) {
                    if (response.ok) {
                        coachee.getCoachee().subscribe()
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [coachee],
    )

    return (
        <div className="lg:pt-8 lg:px-12">
            <div>
                <span className="text-[38px] hidden lg:inline-block">
                    {t('account.coachingPreference')}
                </span>
                <div className="hidden lg:block">
                    <Spacer />
                </div>
                <Formik
                    validate={validateModel}
                    initialValues={
                        new CoachingPreferenceModel(
                            matchingCoacheeCompetency,
                            matchingCoachGender,
                            coachingLanguage,
                        )
                    }
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        values,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col"
                            data-testid="form"
                        >
                            <div className="space-y-2 mb-6 mt-2">
                                <div className="flex flex-col lg:flex-row justify-between lg:items-start lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.areaOfCoaching.label')}
                                        :&nbsp;
                                    </span>{' '}
                                    {values.areaOfCoaching ? (
                                        <div className="lg:w-1/2 flex flex-col border border-[#d2d9da] rounded p-2">
                                            <SelectInput
                                                name="areaOfCoaching"
                                                options={competenciesOptions}
                                                placeholder={
                                                    t(
                                                        'fields.areaOfCoaching.placeholder',
                                                    ) as string
                                                }
                                                disabled={isSubmitting}
                                                className="border border-[#d2d9da] shadow rounded"
                                            />

                                            <span className="p-4 text-[17px]">
                                                {
                                                    values.areaOfCoaching
                                                        .description
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        <SelectInput
                                            name="areaOfCoaching"
                                            options={competenciesOptions}
                                            placeholder={
                                                t(
                                                    'fields.areaOfCoaching.placeholder',
                                                ) as string
                                            }
                                            disabled={isSubmitting}
                                            className="w-1/2 border border-[#d2d9da] shadow rounded"
                                        />
                                    )}
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.coachGender.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="coachGender"
                                        options={genderOptions}
                                        placeholder={
                                            t(
                                                'fields.coachGender.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                {getCoachingLanguages && (
                                    <>
                                        <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                            <span className="text-xl mb-2">
                                                {t(
                                                    'fields.coachingLanguage.label',
                                                )}
                                                :&nbsp;
                                            </span>{' '}
                                            <SelectInput
                                                name="coachingLanguage"
                                                options={getCoachingLanguages}
                                                placeholder={
                                                    t(
                                                        'fields.coachingLanguage.placeholder',
                                                    ) as string
                                                }
                                                disabled={isSubmitting}
                                                className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                            />
                                        </div>
                                        <Spacer />
                                    </>
                                )}
                            </div>
                            <div
                                className={classNames(
                                    'lg:my-6',
                                    'flex flex-col items-center justify-center',
                                )}
                            >
                                <PrimaryButton
                                    className="w-full shadow"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid || isValidating}
                                >
                                    <span>{t('buttons.save')}</span>
                                </PrimaryButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <MyCoach />
            <div className="mx-auto max-w-[30rem] mt-3">
                <QuizComponant />
            </div>
            <ChatHistory />
        </div>
    )
})
