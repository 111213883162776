const dataURItoBlob = (dataURI: string) => {
    const data = dataURI.split(',')[1]
    if (data) {
        var byteString = atob(data)
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab], { type: mimeString })
    }
}

export const dataURItoBlobString = (dataURI: string) => {
    const dataUrl = dataURItoBlob(dataURI)
    if (dataUrl) {
        return URL.createObjectURL(dataUrl)
    } else {
        return ''
    }
}
