import { IsNotEmpty } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class AddIndustryModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public industry: string = ''

    constructor(industry = '') {
        this.industry = industry
    }

    public getRequestBody() {
        return {
            industry: this.industry,
        }
    }
}
