import { ResourceLanguage } from 'i18next'
import { Translation } from '../translation'

export const english: Translation & ResourceLanguage = {
    common: {
        cancel: 'Cancel',
        refresh: 'Refresh the page',
    },
    ApiError: {
        title: 'There has been an unexpected error',
        body: `Please check back later.\\
Sorry about the inconvenience.\\
This has been reported to our team. `,
    },
    ConfirmLeaving: {
        title: 'Leave meeting',
        body: 'Are you sure you want to leave the meeting?',
        leave: 'Leave meeting',
    },
    BookingErrorEarly: {
        title: 'The session is not active',
        body: `You can only join the room up to 5 minutes before.`,
    },
    BookingErrorLate: {
        title: 'This session is not active',
        body: `It is no longer possible to join this session.`,
    },
    Loader: { loading: 'Loading' },
    MediaPrompt: {
        title: 'Please enable your camera',
        body: 'You need to allow the browser access to your camera and microphone.',
    },
    NativeAppPrompt: {
        title: 'Video sessions are better in the Sama app',
        body: 'The Sama App is available for your device. It offers the best experience on mobile screens.',
    },
    RecentBrowserPrompt: {
        title: 'Your browser is not supported',
        body: `Sama isn't supported on your browser and might not work at all.\\
Please use an up-to-date version of Chrome, Firefox, Safari or Edge.`,
        procedd: 'Proceed anyway',
    },
    RatingPrompt: {
        title: 'How would you rate the quality of the video?',
        submit: 'Submit',
    },
    Session: {
        hideSelf: 'Hide self view',
    },
    UserMediaError: {
        title: 'We could not access your camera or microphone',
        body: 'You need to grant permission to access your camera and microphone in order to start a session.',
    },
    VoteSubmitted: {
        title: 'Vote submitted',
        body: 'It’s now safe to close the browser window. You’ll automagically be logged out.',
    },
    WaitingForCoach: {
        title: '',
        body: '',
        picAlt: '',
    },
    WaitingSessionMessage: {
        title: 'Waiting for {{type}} {{name}} to join',
        body: 'Meeting scheduled for {{bookingTime}}',
        picAlt: '{{type}} profile picture',
    },
    Settings: {
        title: 'Settings',
        speakers: 'Speakers',
        microphone: 'Microphones',
        camera: 'Cameras',
        tooltip: 'Your settings can be adjusted here',
        noMicrophone: 'Microphone is off',
    },
    ResetPassword: {
        title: 'Reset your password',
        emailField: 'EMAIL ADDRESS',
        invalidCurrentPassword: 'Your password is not valid',
        invalidToken:
            'Token invalid, please request a new reset password email.',
        noToken:
            'No token provided. Please request a new reset password email.',
    },
    pageTitles: {
        logIn: 'Log into your account',
        resetPassword: 'Reset your password',
        signUp: 'Sign up for your account',
        dashboard: 'Dashboard',
        reset: 'Reset your password',
        booking: 'Booking',
        newBooking: 'Booking',
        magicLogin: 'Magic login',
        onboarding: 'Onboarding',
        chat: 'Chat',
        leaderboard: 'Leaderboard',
        account: 'Account',
        goals: 'My Goals',
        tasks: 'My Tasks',
        notes: 'My Notes',
        logout: 'Log out',
        verifyPassword: 'Verify password',
        loadingPage: 'Loading',
        twoFa: 'Enter code',
        forgottenPassword: 'Forgotten password',
        redirecting: 'Redirecting',
        oktaLogin: 'Okta Login',
        oktaCallback: 'Okta Callback',
    },
    notifications: {
        slidedown: {
            actionMessage:
                'Stay connected! Subscribe for session updates, chat notifications and important Sama news.',
            acceptButtonText: 'Subscribe',
            cancelButtonText: 'Later',
        },
    },
    titles: {
        logIn: 'Welcome to Sama',
        verifyPassword: 'Verify your password',
        setPhone: 'Hi {{name}}',
        twoFa: 'Enter the code we sent to:',
        twoFaHeader: {
            header: 'Two-factor authentication',
            subHeader: 'Authentication code',
            resend: 'Resend SMS',
            content:
                'When your phone is ready, click the button below to recieve a code via SMS',
        },
        tc: 'Terms and Conditions',
    },
    subTitles: {
        logIn: 'To get started, please sign in',
        sendTwoFa: 'Send SMS to: {{phone_number}}',
        twoFaCodeSent:
            'We just sent you a mesage via SMS with your authentication code. Enter the code in the form above to verify your indentity',
        setPhone:
            'Answer these few questions and get the best coaching experience tailor-made for you.',
        tc: `Your confidential data stays with you. Sama will not share this information with third parties.
            We promise that any conversation you have with us is completely confidential, so you can feel comfortable being open and honest about whatever is on your mind.
            By continuing you acknowledge that you have read, understood and agree with our Terms and Privacy policy.`,
    },
    fields: {
        email: {
            label: 'Email address',
            placeholder: 'Enter your email address',
        },
        password: {
            label: 'Password',
            placeholder: 'Password',
            validationMessage:
                'Your password must contain a minimum of 10 charactors and consist of at least 1 uppercase letter, lowercase letter, number and a special character',
        },
        currentPassword: {
            label: 'Current password',
            placeholder: 'Current password',
            validationMessage: '',
        },
        verifyPassword: {
            label: 'Verify Password',
            placeholder: 'Verify Password',
        },
        phone: {
            label: 'Phone number',
            placeholder: 'Phone number',
        },
        tc: {
            label: 'I agree with the <a1>Terms and Conditions</a1> and our <a2>Privacy Policy</a2>.',
            tcLink: 'https://sama.io/terms-and-conditions?from_mobile=true',
            privacyLink: 'https://sama.io/privacy-policy?from_mobile=true',
        },
        feedback: {
            label: 'Oh no! We would appreciate if you told us how to improve.',
            placeholder: '',
        },
        name: {
            label: 'Name',
            placeholder: 'Name',
        },
        goal: {
            label: 'Define your goal:',
            placeholder: 'My goals',
        },
        message: {
            placeholder: 'Type your message here...',
        },
        gender: {
            placeholder: 'Gender identity',
            label: 'Gender identity',
        },
        age: {
            label: 'Age range',
            placeholder: 'Age range',
        },
        position: {
            label: 'Position',
            placeholder: 'Position',
        },
        industry: {
            placeholder: 'Industry',
            label: 'Industry',
        },
        function: {
            label: 'Function',
            placeholder: 'Function',
        },
        language: {
            label: 'Language of platform',
            placeholder: 'Language of platform',
        },
        areaOfCoaching: {
            label: 'Area of coaching',
            placeholder: 'Area of coaching',
        },
        coachGender: {
            label: 'Coach gender preference',
            placeholder: 'Coach gender preference',
        },
        coachingLanguage: {
            label: 'Coaching language',
            placeholder: 'Coaching language',
        },
        reason: {
            label: 'OTHER, PLEASE SEPICIFY REASON',
            placeholder: 'A valid Reason',
        },
        newPassword: {
            label: 'New password',
            placeholder: 'New password',
        },
        retypePassword: {
            label: 'Retype new password',
            placeholder: 'Retype new password',
        },
        profileImage: {
            label: 'Profile image',
        },
    },
    modals: {
        checkEmail: {
            heading: 'Check your inbox',
            subText:
                'To confirm your email address, click the button in the email we sent to: {{email}}',
        },
        editGuest: {
            heading: 'Edit name',
        },
        removeGuest: {
            heading: 'Revoke invitation {{email}}?',
        },
        remainingSessions: {
            heading: 'You have {{count}} session left',
            subText:
                'A session equals 25 minutes. A session of 50 minutes equals to 2 sessions.',
            contactUs: 'Contact us at support@sama.io',
        },
        currentCoach: {
            heading: 'Your coach',
        },
        coachProfile: {
            canCoach: 'CAN COACH ON',
            certifications: 'COACH CERTIFICATIONS',
            memberships: 'MEMBERSHIPS',
            timezone: 'TIME ZONE',
        },
        booking: {
            heading: 'Are you sure you wish to book this time?',
            subText: 'It looks a little {{period}}',
            early: 'early',
            late: 'late',
        },
        rateCoach: {
            title: '',
            subTitle: 'Rate your coach',
            rate: '{{rate}} stars',
        },
        notes: {
            createNote: 'Create note',
            editNote: 'Edit note',
        },
        guest: {
            checkGuest: 'Have you checked they are available at that time?',
            checkCoach:
                'Have you discussed with your coach about inviting your colleague?',
            yes: 'Yes',
            no: 'No',
            addColleague: 'Add colleague',
            heading: 'Guest',
            subText: 'Guest ({{number}})',
        },
        uploadProfileImage: {
            heading: 'Upload logo',
            subText: 'Image size should be 500x500px.',
            browse: 'browse',
            dragAndDrop: 'Drag and drop image, or <0>{{browse}}</0>',
            supportedFileTypes: 'Supports .jpg, .png or .svg',
        },
        removeAccount: {
            heading:
                'If you delete your data, you will be deleting your account. Do you wish to continue?',
        },
        removeBooking: {
            heading: 'Are you sure you want to cancel this session?',
            textHeader: '', // removed on purpose
            mainMessage:
                'As it is less than 24 hours before the session, your employer will be charged for the session.',
            lateCancelHeading: 'Do you still want to cancel?',
        },
        logOut: {
            heading: 'Are you sure you want to log out?',
        },
    },
    login: {
        forgotPassword: ' Don’t have or forgot your password?',
        reset: '<0>Reset your password</0>',
    },
    onboarding: {
        question: 'Question {{number}} of {{total}}',
        areaOfCoaching: {
            title: 'Area of coaching',
            subTitle: 'Pick the area you want to work on first.',
            quizText:
                'Unsure where to start with coaching? <0>Take our 3 minute quiz<0>',
            quizLink: 'https://sama.io/assessment/?userId={{userId}}',
        },
        gender: {
            title: `What gender do you identify with?`,
        },
        genderSpecified: {
            title: `OTHER, PLEASE SPECIFY`,
            placeholder: 'Please specify',
        },
        ageRange: {
            title: 'What is your age range?',
        },
        industry: {
            title: 'Which industry do you work in?',
        },
        function: {
            title: 'What is your function?',
        },
        position: {
            title: 'What is your position?',
        },
        rateSatifaction: {
            title: 'Rate your satisfaction in these areas',
            subTitle: '1 = Low satisfaction; 5 = Extremely satisfied',
        },
        topValues: {
            title: 'Pick your 5 top values',
            subTitle: 'Pick 5 values that you appreciate the most',
        },
        coachGender: {
            title: 'Do you prefer your coach to be',
        },
        langauge: {
            title: 'Coaching language',
            subTitle: 'Pick the language you prefer to be coached in.',
        },
        findCoach: {
            title: 'Finding your coach',
            foundTitle: 'Your coach',
            foundSubTitle: 'based on your criteria',
            findingSubTitles: [
                'The best coach for you...',
                'Science backed AI matching...',
                'Empower your career...',
                'A life of purpose...',
                'The best coach for you...',
            ],
        },
        initial: {
            title: 'Coaching experience',
            title2: 'tailored for you',
            subTitle:
                'To match you with the best coach for you, we would like you to tell us a bit about yourself.',
        },
    },
    buttons: {
        continue: 'Continue',
        okta: 'Continue with Okta',
        usePassword: 'Use password to sign in',
        next: 'Next',
        save: 'Save',
        resend2fa: 'Resend code',
        close: 'Close',
        contactUs: 'Contact us',
        bookSession: 'Book session',
        joinSession: 'Join session',
        chat: 'Chat',
        profile: 'See profile',
        rate: 'Send feedback',
        delete: 'Delete',
        cancel: 'Cancel',
        explore: 'Explore Sama',
        upload: 'Upload',
        edit: 'Edit',
        remove: 'Remove',
        deleteAccount: 'Delete account',
        cancelSession: 'Yes, cancel session',
        keepSession: 'No, keep session',
        logOut: 'Log out',
        stayLogin: 'Stay logged in',
        microsoft: 'Continue with Microsoft',
        verify: 'Verify',
        bookLateSession: 'Book session',
    },
    messages: {
        loading: 'Loading',
        emailSent: `Email sent. Can't find it? Please check your spam folder.`,
        passwordReset: 'Your password has been updated!',
        invalidToken: 'Token is invalid',
        resend2fa: `Send again (in {{seconds}}s).`,
        canResend2fa: `Resend SMS`,
        tooManyRequests:
            'Too many attempts please wait 15 minutes and try again.',
        remainingSessions: 'You have {{count}} session remaining',
        sessionBooked: 'Your session is successfully booked.',
        inviteGuest:
            'Check consent and availability pirior to inviting your colleague.',
        inviteGuestCoach:
            'Check consent and availability pirior to inviting your colleague.',
        urgentMessage: 'Your message has been marked as urgent.',
        addedGuest:
            '{{name}}({{email}}) has been invited to your session on {{date}}.',
        languageChanged: 'Language has been updated',
    },
    validation: {
        invalidFormat: 'Invalid format',
        invalidEmail: 'Please use a valid email address',
        checkEmailOrPassword: 'Please check your email or password.',
        invalidPhone: 'Format required: +447706123456',
        mustBeSet: 'Must be set',
        thereIsAnError: 'There is an error below.',
        passwordsDoNotMatch: 'Passwords do not match',
        upperCase: 'At least 1 upper case',
        containsNumber: 'Contain a number',
        specialCharacter: 'Contain a special chracter (e.g, "!")',
        minimumLength: 'Contain a minimum of 10 characters',
    },
    alt: {
        logo: 'Logo',
        arrowLeft: 'previous',
        checked: 'checked',
        onboarding: 'onboarding',
        video: 'video icon',
        clock: 'duration icon',
        rating: 'rating icon',
        typing: 'Typing',
        profile: 'Icon for profile picture',
        upcomingSession: 'Upcoming session',
    },
    or: 'or',
    questions: {
        outcomeAssessment: {
            title: 'Progress tracking',
            answers: {
                peak: 'Peak',
                strong: 'Strong',
                good: 'Good',
                moderate: 'Moderate',
                low: 'Low',
            },
        },
    },
    dashboard: {
        greetingMorning: 'Good morning {{name}},',
        greetingAfternoon: 'Good afternoon {{name}},',
        greetingEvening: 'Good evening {{name}},',
        milestone: 'Updated milestone',
        goal: 'My goals',
        task: 'My tasks',
        note: 'My notes',
        seeAll: 'See all sessions',
        bookings: 'Upcoming sessions',
        session: {
            coachingSession: 'Coaching session',
            noSession:
                'Your coach is ready to work with you. Book a session today.',
            todaySession: 'Today at {{time}}',
            video: 'Video',
            audio: 'Audio',
            duration: '{{mins}} min',
            cancel: 'Cancel',
            reschedule: 'Reschedule',
            guest: 'Guest',
            sessionSoon: 'Starts in {{min}} mins',
            sessionStarted: 'Session started',
            guestLength: 'Guest ({{length}})',
            noUpcomingSession: 'You have no upcoming session',
        },
        checklist: {
            title: 'What’s next',
        },
    },
    others: {
        seeAll: 'See all',
        remove: 'Remove',
        skip: 'Skip',
        charatersLeft: '{{count}} characters left',
    },
    booking: {
        selectDate: 'Select date',
        selectDuration: 'Select session duration',
        selectTime: 'Select session start time',
        selectType: 'Select type of session',
        inviteGuests: 'Invite guests',
        minutes25: '25 min',
        minutes50: '50 min',
        timezone: 'Shown in your timezone, {{timezone}}',
        video: 'Video',
        call: 'Call',
        bookSession: 'Book session',
        addColleague: 'Add colleague',
        allowColleagues: 'Allow colleagues to join the session with you',
        nextMonth: 'Next Month',
        prevMonth: 'Previous Month',
        monday: 'MON',
        tuesday: 'TUE',
        wednesday: 'WED',
        thursday: 'THU',
        friday: 'FRI',
        saturday: 'SAT',
        sunday: 'SUN',
        lateBooking: 'This session is no longer available',
    },
    assessments: {
        selfAssessment: {
            title: 'Know your strengths',
            subtitle: 'Well done! Stay tuned for the next questions',
            subtitleIncomplete: 'Complete your self-assessment',
        },
        satisfactionLevels: {
            title: 'Perspective',
            subtitle: 'Review your satisfaction levels',
        },
        topValues: {
            title: 'Perspective',
            subtitle: 'Review your top values',
        },
        banner: {
            title: 'Sama on your phone',
            subtitle:
                'Sama on the go. Get the app today on AppStore or Google Play.',
            googleLink:
                'https://play.google.com/store/apps/details?id=io.sama.coachee',
            appleLink:
                'https://apps.apple.com/us/app/sama-professional/id1520645244',
        },
    },
    note: {
        title: 'My notes',
        subtitle: 'Confidential and only visible to you.',
        addNote: 'Create note',
        createNote: 'Create note',
        noNote: 'No notes added',
        noNoteDesc: 'You have not added any notes yet.',
    },
    task: {
        title: 'My tasks',
        noTask: 'No tasks',
        noTaskDesc: 'Your coach has not assigned any tasks.',
        today: 'Today',
    },
    goals: {
        title: 'My goals',
        subtitle: 'Your coach can see your goals.',
        smartGoals: 'Set SMART goals',
        addGoal: 'Create goal',
        editGoal: 'Edit goal',
        dueDate: 'Due by {{date}}',
        due: 'Due date',
        overdue: 'Due date passed {{date}}',
        completedAt: 'Completed at {{date}}',
        noDueDate: 'Due date not set',
        noGoals: 'No goals added',
        noActiveGoals: 'No active goals',
        noCompleteGoals: 'No completed goals',
        trackProgress: 'Track your progress:',
        started: 'Started',
        nearlyDone: 'Nearly done',
        notStarted: 'Not started',
        halfWay: 'Halfway there!',
        done: 'Completed',
        selectDueDate: 'Select due date',
        tabs: {
            all: 'All',
            active: 'Active',
            completed: 'Completed',
        },
        smartGoalList: {
            specific: 'Specific',
            measurable: 'Measurable',
            achievable: 'Achievable',
            relevant: 'Relevant',
            time: 'Time Bound',
        },
    },
    leaderboard: {
        totalSessions: 'Total sessions',
        completedTasks: 'Completed tasks',
        missedSessions: 'Missed sessions',
        checkIns: 'Check-ins',
        trends: 'Trackers',
        about: 'About',
        rating: 'RATING',
        levels: 'LEVELS',
        noTotalSessions: 'You haven’t had any sessions',
        noTotalSessionsDesc: 'Why don’t you <0>book one now?<0>',
        completedTasksDesc: 'You haven’t completed any tasks yet',
        tasksCompleted: 'Tasks completed',
        month: 'MONTH',
        completed: 'COMPLETED',
        aboutTracker: 'About trackers',
        aboutTrackerDesc:
            'Track how you rate yourself over time on five key drivers of engagement:  resilience, adaptability, alignment, motivation and self-efficacy',
        peak: 'Peak',
        strong: 'Strong',
        good: 'Good',
        moderate: 'Moderate',
        low: 'Low',
        missedSessionsDesc: 'You haven’t missed a meeting. Great!',
        missedSessionsInfo:
            'Your employer is being charged when you cancel an appointment within 24 hrs.',
        checkInsDesc: 'There are no check-ins available for you yet.',
        level: 'Level',
        cancel: 'Cancelled',
        tracker: {
            resilience: 'Resilience',
            adaptability: 'Adaptability',
            'self-efficacy': 'Self-efficacy',
            alignment: 'Alignment',
            motivation: 'Motivation',
        },
    },
    chat: {
        emptyChat: 'Messages with your coach are confidential and secure',
        voiceMessage: 'Voice message',
        coachTime: 'Their local time: {{time}}',
        fileLoader: 'Uploading... {{filename}}',
        wrongFileFormat:
            'Please upload one of the following file types pdf, docx, csv, jpg, jpeg, gif, png, xlsx, txt',
        cantReply: 'You cannot reply to this coach',
    },
    account: {
        personal: 'Personal',
        coachingPreference: 'Coaching preferences',
        account: 'Account',
        infoSupport: 'Info & Support',
        myCoach: 'My coach',
        changeCoach: 'Change my coach',
        changeCoachDesc:
            'If you would like to rematch with your current coach at a later date, please contact us on support@sama.io.',
        changeReasonQuestion: 'Why do you want to change your coach?',
        delete: 'Permanently delete account',
        chatHistory: 'Chat history',
        misson: 'Our mission',
        infoSupportPage: {
            about: 'About Sama',
            faq: 'FAQ',
            contactUs: 'Contact us',
            terms: 'Terms and Conditions',
            privacy: 'Privacy Policy',
            aboutText: `At Sama, we’re elevating engagement to make the world a better place. We do this by democratising coaching.\n\nWe match you with the best coach for you, based on what you want to work on and your specific characteristics, such as your experience.\n\nOur coaches are put through a rigorous screening process to ensure that they have the highest qualifications and experience to best guide you within your professional and life journey.\n\nOnce you are matched with your coach, you can have sessions through chat, call or video call.\n\nWhile Sama services are paid by your employer, any interaction you have with Sama will remain strictly confidential, as per the terms of our <0>Privacy Policy.<0>`,
            termsText: `At Sama, our mission is to provide access to quality professional coaches in the hands of every employee on earth to increase engagement and happiness.\n\nPlease read these terms carefully so that you understand them. These terms cover the services we provide to you through our app or other platform (“App”) and Websites. When you use the App, you may access the private Sama service.\n\nAny reference to “Sama”, “our”, “us” and “Company” are references to Sama Europe Ltd, a limited company registered in England (company number 12526304), the registered office being 71-75 Shelton Street, London WC2H 9JQ.`,
            privacyLink: 'https://sama.io/privacy-policy',
            termsLink: 'https://sama.io/terms-and-conditions',
            contactUsLink: 'support@sama.io',
        },
    },
}
