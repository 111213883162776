import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterModal } from '../../../components/modals'
import { classNames } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

import CertificationImage from '../../../assets/icons/coach-certifications.svg'
import MembershipImage from '../../../assets/icons/coach-memberships.svg'
import TimeZoneImage from '../../../assets/icons/time-zone-clock.svg'

import { TickList } from '../../../components/misc/tick-list'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface LineProps {
    className?: string
}

export const Line: React.FC<LineProps> = observer(({ className }) => {
    return (
        <div
            className={classNames(
                className,
                'flex justify-center items-center py-5',
            )}
        >
            <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
            <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
        </div>
    )
})

export const CoachModal: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()
    const { coachee, image } = useStores()

    useEffect(() => {
        if (coachee.coachee?.currentCoach._id) {
            image.getCoachPicture(coachee.coachee?.currentCoach._id).subscribe()
        }
    }, [coachee.coachee?.currentCoach._id, image])

    return (
        <>
            {isOpen && (
                <CenterModal
                    isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}
                    className="max-w-[32rem] pt-5"
                    title="view_coach_profile"
                >
                    <div className="px-5">
                        <header>
                            <div className="flex">
                                <img
                                    src={image.coachPicture ?? undefined}
                                    className="w-[4rem] h-[4rem] rounded-[100px]"
                                />
                                <div className="my-auto ml-2">
                                    <div className="text-xs">
                                        {t('modals.currentCoach.heading')}
                                    </div>
                                    <span>
                                        <strong>
                                            {
                                                coachee.coachee?.currentCoach
                                                    .user.firstName
                                            }
                                            &nbsp;
                                            {
                                                coachee.coachee?.currentCoach
                                                    .user.lastName
                                            }
                                        </strong>
                                    </span>
                                </div>
                            </div>
                            <div className="pt-5 text-[17px]">
                                <span>{coachee.coachee?.currentCoach.bio}</span>
                            </div>
                            <Line />
                        </header>
                        <main className="flex flex-col ">
                            <span className="text-xs">
                                {t('modals.coachProfile.canCoach') as string}
                            </span>
                            <span className="flex mt-2 flex-wrap  pb-6">
                                {coachee.coachee?.currentCoach.areasCompetencies?.map(
                                    (area, index) => (
                                        <TickList
                                            className="min-w-fit mb-2"
                                            key={index}
                                            value={area}
                                        />
                                    ),
                                )}
                            </span>
                        </main>

                        <footer>
                            <div className="flex items-center pr-3">
                                <img
                                    width={42}
                                    src={CertificationImage}
                                    className="mr-2"
                                />
                                <div className="text-xs">
                                    <div>
                                        {
                                            t(
                                                'modals.coachProfile.certifications',
                                            ) as string
                                        }
                                    </div>
                                    <div className="text-sm">
                                        {coachee.coachee?.currentCoach.coachingAccreditations?.join(
                                            ', ',
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Line className="w-[90%] m-auto" />
                            <div className="flex items-center pr-3">
                                <img
                                    width={42}
                                    src={MembershipImage}
                                    className="mr-2"
                                />
                                <div className="text-xs">
                                    <div>
                                        {
                                            t(
                                                'modals.coachProfile.memberships',
                                            ) as string
                                        }
                                    </div>
                                    <div className="text-sm">
                                        {coachee.coachee?.currentCoach.coachingMemberships?.join(
                                            ', ',
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Line className="w-[90%] m-auto" />
                            <div className="flex items-center">
                                <img
                                    width={42}
                                    src={TimeZoneImage}
                                    className="mr-2"
                                />
                                <div className="text-xs">
                                    <div>
                                        {
                                            t(
                                                'modals.coachProfile.timezone',
                                            ) as string
                                        }
                                    </div>
                                    <div className="text-xs">
                                        {
                                            coachee.coachee?.currentCoach
                                                .formattedTimeZone
                                        }
                                    </div>
                                </div>
                            </div>
                            <Line className="w-[90%] m-auto" />
                        </footer>
                    </div>
                </CenterModal>
            )}
        </>
    )
})
