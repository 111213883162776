import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { DashboardPageWrapper } from '../../components/navigation'
import { AccountTabs } from './components/accountTab'

export const AccountPage: React.FC = observer(() => {
    const { t } = useTranslation()

    return (
        <DashboardPageWrapper
            pageName={t('pageTitles.account')}
            backgroundColour="bg-[#f9fbfc]"
        >
            <div>
                <AccountTabs />
            </div>
        </DashboardPageWrapper>
    )
})
