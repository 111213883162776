import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../components/buttons/primary-button'
import { SideModal } from '../../components/modals/side-modal'
import SideModalSkeleton from '../../components/skeleton/sideSkeleton'
import { NoteResponse } from '../../models/response/note/note'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { EmptyNoteList } from './components/emptyNoteList'
import { NoteList } from './components/noteList'
import { NoteModal } from './modals/note.modal'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const Note: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()

    const { note } = useStores()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentItem, setCurrentItem] = useState<NoteResponse>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        const subscription = note.getCoacheeNotes().subscribe({
            next: () => {
                setIsLoading(false)
            },
            error: () => {
                setIsLoading(false)
            },
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [note])

    const updateItem = (item: NoteResponse) => {
        setCurrentItem(item)
        setIsModalVisible(true)
    }

    return (
        <SideModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            className="max-w-[28rem]"
            title="notes_list"
        >
            <div className="rounded-lg bg-white max-w-[28rem] w-full">
                <div className="flex flex-col px-8 py-10 sticky top-0 z-20 bg-white">
                    <span className="text-[38px]">{t('note.title')}</span>
                    <span className="text-[19px]">{t('note.subtitle')}</span>
                </div>
                {isLoading ? (
                    <SideModalSkeleton />
                ) : note.notes.length === 0 ? (
                    <EmptyNoteList />
                ) : (
                    <NoteList updateItem={updateItem} />
                )}
                <div
                    className={classNames(
                        'flex',
                        'w-[25rem]',
                        'fixed bottom-0 right-5',
                        'mx-auto',
                        'mb-10',
                        'bg-white p-5 rounded-[50px] shadow-lg',
                    )}
                >
                    <PrimaryButton
                        className="w-full max-w-[30rem] text-[#a8eaf6]"
                        onClick={() => setIsModalVisible(true)}
                    >
                        <span>
                            {note.notes.length === 0
                                ? t('note.addNote')
                                : t('note.createNote')}
                        </span>
                    </PrimaryButton>
                </div>
            </div>
            <NoteModal
                isOpen={isModalVisible}
                setIsOpen={() => setIsModalVisible(false)}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </SideModal>
    )
})
