import { useOktaAuth } from '@okta/okta-react'
import i18next from 'i18next'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Subscription } from 'rxjs'
import Booking from '../../pages/Booking'
import Error from '../../pages/Error'
import Login from '../../pages/Login'
import { Route as RouteDefinition } from '../../pages/routes'
import { RouteLink } from '../../utils/constants'
import { cancelSubscriptions } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { RouteWrapper } from './route-wrapper'

interface Props {
    routes: RouteDefinition[]
}
const subscriptions: Subscription[] = []
export const Router: React.FC<Props> = observer(({ routes }) => {
    const { auth, statics, coachee } = useStores()
    const { oktaAuth, authState } = useOktaAuth()

    useEffect(() => {
        const checkAuthentication = async () => {
            if (!authState?.isAuthenticated) {
                // User is not authenticated
                return
            }

            try {
                if (authState?.isAuthenticated) {
                    const authResponse = {
                        accessToken:
                            'ATKO' + authState?.accessToken?.accessToken || '',
                        awaiting2fa: false,
                        coachingLanguages: [],
                        dobOnBoardingEnabled: false,
                        isPasswordGenerated: false,
                        isPhoneNeeded: false,
                        success: false,
                        phoneNumber2fa: '',
                        userType: 'coachee',
                        expires: authState?.accessToken?.expiresAt
                            ? new Date(authState.accessToken.expiresAt * 1000)
                            : undefined,
                    }
                    auth.setAuthResponse(authResponse)
                }
            } catch (error) {
                console.error('Error retrieving access token:', error)
            }
        }

        checkAuthentication()
    }, [auth, authState?.isAuthenticated, oktaAuth])

    useEffect(() => {
        if (auth.fullyAuthenticated) {
            statics.loadStatics()
            subscriptions.push(
                coachee.getCoachee().subscribe({
                    next() {
                        if (
                            coachee.coachee &&
                            coachee.coachee.user.appLanguage !==
                                i18next.language
                        ) {
                            coachee.changeSiteLanguage(
                                coachee.coachee.user.appLanguage,
                                false,
                            )
                        }
                    },
                }),
            )
            subscriptions.push(coachee.Launch())

            return () => {
                cancelSubscriptions(subscriptions)
            }
        }
    }, [auth.fullyAuthenticated, coachee, statics])

    return auth.ready ? (
        <Routes>
            {
                // Disable login route in prod
                import.meta.env.NODE_ENV !== 'production' && (
                    <Route path="/login/:userType" element={<Login />} />
                )
            }

            {routes.map(([paths, title, component, authRequirements], i) => {
                return paths.map((path, j) => {
                    return (
                        <Route
                            key={`${i}${j}`}
                            path={path}
                            element={
                                <RouteWrapper
                                    title={title}
                                    path={path}
                                    component={component}
                                    authRequirements={authRequirements}
                                />
                            }
                        />
                    )
                })
            })}
            <Route path={RouteLink.BOOKING} element={<Booking />} />
            <Route element={<Error />} />
        </Routes>
    ) : null
})
