import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { State, TEvent } from '../../stores/bookingMachine/config'
import errorIcon from '../../assets/icons/error.svg'

import Popup from './Popup'

export default function NativeAppPrompt({
    when,
    send,
}: {
    when: boolean
    send: (event: TEvent) => State
}): ReactElement {
    const { t } = useTranslation()

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('NativeAppPrompt.title')}
        >
            <ReactMarkdown>{t('NativeAppPrompt.body') as string}</ReactMarkdown>
        </Popup>
    )
}
