import classNames from 'classnames'
import React from 'react'

interface Props {
    title: string
    onClick?: () => void
    icon?: string
    value?: number
    className?: string
}

export const Card: React.FC<Props> = ({
    title,
    onClick,
    icon,
    value,
    className,
}) => {
    return (
        <div
            className={classNames(
                className,
                'flex flex-col gap-[4rem]',
                'bg-white rounded-lg w-full h-[250px] p-8 relative ',
                'cursor-pointer overflow-hidden',
                'hover:bg-gradient-to-r hover:from-transparent hover:to-[#ff7558]',
                'hover:scale-105',
                'transition-transform duration-300 ease-in-out',
            )}
            onClick={onClick}
        >
            <div className="flex justify-between">
                <span className="text-[22px]">{title}</span>
                <img src={icon} alt="" className="w-[54px] h-[54px] z-10" />
            </div>
            <span className="text-[70px] text-primary">{value}</span>
        </div>
    )
}
