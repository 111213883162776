import { useField } from 'formik'
import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    min: number
    max: number
    step: number
    label?: string
}

export const RangeInput: React.FC<Props> = ({
    name,
    min,
    max,
    step,
    label,
    className,
    ...props
}) => {
    const [field, meta] = useField(name)

    return (
        <div className={classNames('flex flex-col relative')}>
            {label && (
                <label htmlFor={name} className="block text-[13px] mb-2">
                    {label}
                </label>
            )}
            <motion.input
                data-testid="input"
                type="range"
                min={min}
                max={max}
                step={step}
                className={classNames(
                    className,
                    'mt-4 bg-[#236978] h-1',
                    'rounded-full',
                    'accent-[#236978]',
                )}
                {...props}
                {...field}
                list="values"
            />

            <motion.datalist id="values">
                <option value="0" label="0"></option>
                <option value="25" label="25"></option>
                <option value="50" label="50"></option>
                <option value="75" label="75"></option>
                <option value="100" label="100"></option>
            </motion.datalist>
            {/* <div className="absolute top-1/2 transform -translate-y-1/2 left-0 w-full flex justify-between">
                {Array((max - min) / step + 1)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            key={index}
                            className="z-10 bg-[#002b38] rounded-full w-2 h-2 mt-[-10px]"
                        />
                    ))}
            </div> */}

            {meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
