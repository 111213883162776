import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconArrow from '../../../assets/icons/arrowCheckIns.svg'
import { CheckIns } from '../../../models/response/questions/outcome-assessment-questions'
import { classNames, formatDate, getFormatString } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { OutcomeAssessmentModel } from '../../assessments/models/outcome'
import FilledCheckInsUnfiiled from '/src/assets/icons/CheckInsUnfilled.svg'
import FilledCheckIns from '/src/assets/icons/filledCheckIns.svg'

interface ListProps {
    checkIns: CheckIns
    onClick?: () => void
}

const List: React.FC<ListProps> = observer(({ checkIns, onClick }) => {
    const { t } = useTranslation()
    return (
        <>
            <div
                className={classNames(
                    'flex flex-row items-center space-x-4',
                    'px-2 py-2 my-1',
                    !checkIns.completed && 'cursor-pointer',
                )}
                onClick={onClick}
            >
                {checkIns.completed ? (
                    <img src={FilledCheckIns} />
                ) : (
                    <img src={FilledCheckInsUnfiiled} />
                )}

                <div className="flex justify-between w-full">
                    <div className="flex flex-col ">
                        <span className="text-[19px]">
                            {`${t('leaderboard.level')} ${checkIns.level}`}
                        </span>
                        <span className="text-[17px] text-[#6b6a72]">
                            {formatDate(checkIns.date, getFormatString('long'))}
                        </span>
                    </div>
                    {!checkIns.completed && <img src={IconArrow} />}
                </div>
            </div>
            <div className="flex-grow h-[2px] bg-[#e9eced]"></div>
        </>
    )
})

export const CheckInsList = observer(() => {
    const { coachee } = useStores()
    const { checkIns } = coachee
    const [isSelfAssessmentQuestions, setOpenSelfAssessmentQuestions] =
        useState(false)

    const getStats = useCallback(() => {
        const subscription = coachee?.getOAStats()?.subscribe()

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    const getCheckIns = useCallback(() => {
        const subscription = coachee?.getCheckIns()?.subscribe()

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    useEffect(() => {
        getStats()
        getCheckIns()
    }, [coachee, getCheckIns, getStats])

    const closeAssessmentQuestions = useCallback(() => {
        getStats()
        getCheckIns()
        setOpenSelfAssessmentQuestions(false)
    }, [getCheckIns, getStats])

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8 pb-4">
                {checkIns.length > 0 &&
                    checkIns
                        .slice()
                        .sort((a, b) => b.level - a.level)
                        .map((item, index) => {
                            if (index !== 0) {
                                item.completed = true
                            }
                            return (
                                <List
                                    key={index}
                                    checkIns={item}
                                    onClick={() => {
                                        if (!item.completed) {
                                            setOpenSelfAssessmentQuestions(true)
                                        }
                                    }}
                                />
                            )
                        })}
            </div>
            <OutcomeAssessmentModel
                isOpen={isSelfAssessmentQuestions}
                setIsOpen={closeAssessmentQuestions}
            />
        </div>
    )
})
