/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react'
import { css } from '@emotion/react'

export default function SessionBg({
    picUrl = '',
}: {
    picUrl: string
}): ReactElement {
    return (
        <div
            css={css({
                position: 'absolute',
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${picUrl})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            })}
        ></div>
    )
}
