import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Camera from '../../../assets/icons/camera.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { PhoneCodeInput } from '../../../components/inputs/phonecode-input'
import { SelectInput } from '../../../components/inputs/select-input'
import { TextInput } from '../../../components/inputs/text-input'
import { ProfilePictureCircle } from '../../../components/misc/profile-picture-circle'
import { PersonalCoacheeModel } from '../../../models/request/coachee/personal-coachee.model'
import { Option } from '../../../models/response'
import { trackEvent } from '../../../services/event-tracking'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { UploadProfileImageModal } from '../modals/upload-profile-image.modal'

export const Spacer: React.FC = observer(() => {
    return <div className={classNames('h-[1px] p-0 bg-[#d2d9da] mt-4')}></div>
})

export const Personal = observer(() => {
    const { coachee, statics, image } = useStores()
    const { t } = useTranslation()
    const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false)

    useEffect(() => {
        trackEvent('screen_viewed', {
            name: 'personal',
            coachee_id: coachee.coachee?._id,
        })
    }, [coachee.coachee?._id])

    const genderOptions = useMemo(() => {
        return statics.coacheeGender.map(
            (gender): Option => ({
                value: gender.key,
                display: gender.name,
            }),
        )
    }, [statics.coacheeGender])

    const genderToMatch = coachee?.coachee?.gender
    const matchingCoacheeGender = genderOptions.find(
        (coachee) => coachee.value === genderToMatch,
    )

    const ageOptions = useMemo(() => {
        return statics.ageRanges.map(
            (age): Option => ({
                value: age.key,
                display: age.name,
            }),
        )
    }, [statics.ageRanges])

    const ageToMatch = coachee?.coachee?.user.ageRange
    const matchingCoacheeAge = ageOptions.find(
        (coachee) => coachee.value === ageToMatch,
    )

    const positionOptions = useMemo(() => {
        return statics.positions.map(
            (position): Option => ({
                value: position.key,
                display: position.name,
            }),
        )
    }, [statics.positions])

    const positionToMatch = coachee?.coachee?.position
    const matchingCoacheePosition = positionOptions.find(
        (coachee) => coachee.value === positionToMatch,
    )

    const industryOptions = useMemo(() => {
        return statics.industries.map(
            (industry): Option => ({
                value: industry.key,
                display: industry.name,
            }),
        )
    }, [statics.industries])

    const industryToMatch = coachee?.coachee?.industry
    const matchingCoacheeIndustry = industryOptions.find(
        (coachee) => coachee.value === industryToMatch,
    )

    const functionOptions = useMemo(() => {
        return statics.functions.map(
            (fun): Option => ({
                value: fun.key,
                display: fun.name,
            }),
        )
    }, [statics.functions])

    const functionToMatch = coachee?.coachee?.function
    const matchingCoacheeFunction = functionOptions.find(
        (coachee) => coachee.value === functionToMatch,
    )

    const languageOptions = useMemo(() => {
        return statics.supportedLanguages.map(
            (language): Option => ({
                value: language.key,
                display: language.name,
            }),
        )
    }, [statics.supportedLanguages])

    const languageToMatch = coachee?.coachee?.user.appLanguage
    const appLanguage = languageOptions.find(
        (coachee) => coachee.value === languageToMatch,
    )

    const openUploadLogoModalOpen = useCallback((open: boolean) => {
        trackEvent('button_clicked', {
            name: 'uploadProfileImage',
            type: 'coachee',
            action: open ? 'open' : 'close',
        })
        setIsUploadLogoModalOpen(open)
    }, [])

    const onSubmit = useCallback(
        (
            values: PersonalCoacheeModel,
            helpers: FormikHelpers<PersonalCoacheeModel>,
        ) => {
            helpers.setSubmitting(true)

            coachee.updatePersonalCoachee(values).subscribe({
                next(response) {
                    if (response.ok) {
                        if (
                            values.appLanguage?.value !==
                            coachee.coachee?.user.appLanguage
                        ) {
                            coachee.changeSiteLanguage(
                                (values.appLanguage?.value as string) ?? 'en',
                                true,
                            )
                        }
                        coachee.getCoachee().subscribe()
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [coachee],
    )

    return (
        <div className="lg:pt-8 lg:px-12 md:px-12">
            <div>
                <span className="text-[38px] hidden lg:inline-block">
                    {t('account.personal')}
                </span>
                <div className="hidden lg:block">
                    <Spacer />
                </div>
                <div className="flex flex-col lg:flex-row justify-between lg:items-start lg:pt-5 lg:pb-4 pb-2">
                    <span className="text-xl mt-2">
                        {t('fields.profileImage.label')}:&nbsp;
                    </span>{' '}
                    <div className="relative flex flex-col justify-center mb-2 items-center mt-1 px-4">
                        <ProfilePictureCircle
                            initials={coachee.initials}
                            wrapperClassNames="w-[7rem] h-[7rem] "
                            className="w-[7rem] h-[7rem]"
                            picture={image.picture}
                            onClick={openUploadLogoModalOpen.bind(null, true)}
                        />
                        {image.picture ? (
                            <PrimaryButton
                                className="btn-default !h-[35px] m-2 absolute bottom-[-25px]"
                                bgColour="bg-[#f6f7f9]"
                                type="button"
                                onClick={openUploadLogoModalOpen.bind(
                                    null,
                                    true,
                                )}
                            >
                                <div className="flex justify-center items-center space-x-4">
                                    <img src={Camera} />
                                    <span className="text-[17px]">
                                        {t('buttons.edit')}
                                    </span>
                                    {/* <span className="border-l border-[#d2d9da] h-[17px] inline-block mt-1"></span>
                                    <span className="text-[17px]">
                                        {t('buttons.remove')}
                                    </span> */}
                                </div>
                            </PrimaryButton>
                        ) : (
                            <PrimaryButton
                                className="btn-default !h-[35px] m-2 absolute bottom-[-25px]"
                                bgColour="bg-[#f6f7f9]"
                                type="button"
                                onClick={openUploadLogoModalOpen.bind(
                                    null,
                                    true,
                                )}
                            >
                                <div className="flex justify-center items-center space-x-4">
                                    <img src={Camera} />
                                    <span className="text-[17px]">
                                        {t('buttons.upload')}
                                    </span>
                                </div>
                            </PrimaryButton>
                        )}
                    </div>
                </div>
                <Spacer />
                <Formik
                    validate={validateModel}
                    initialValues={
                        new PersonalCoacheeModel(
                            coachee?.coachee?.user.phoneNumber ?? '',
                            matchingCoacheeGender,
                            matchingCoacheeAge,
                            matchingCoacheePosition,
                            matchingCoacheeIndustry,
                            matchingCoacheeFunction,
                            appLanguage,
                            coachee?.coachee?.genderSpecified ?? '',
                        )
                    }
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        handleChange,
                        errors,
                        touched,
                        handleBlur,
                        setFieldValue,
                        values,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col"
                            data-testid="form"
                        >
                            <div className="space-y-2 mb-6 mt-2">
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.phone.label')}:
                                    </span>
                                    <PhoneCodeInput
                                        name="phone"
                                        touched={touched}
                                        value={
                                            coachee?.coachee?.user
                                                .phoneNumber ?? ''
                                        }
                                        countryCode={
                                            coachee?.coachee?.user
                                                .phoneCountry ?? ''
                                        }
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={errors}
                                        disabled={isSubmitting}
                                        setFieldValue={setFieldValue}
                                        placeholder={
                                            t(
                                                'fields.phone.placeholder',
                                            ) as string
                                        }
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                        bgColor="#ffffff"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-start lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.gender.label')}:&nbsp;
                                    </span>{' '}
                                    <div className="flex flex-col lg:w-1/2">
                                        <SelectInput
                                            name="gender"
                                            options={genderOptions}
                                            placeholder={
                                                t(
                                                    'fields.gender.placeholder',
                                                ) as string
                                            }
                                            disabled={isSubmitting}
                                            className="border border-[#d2d9da] shadow rounded"
                                        />
                                        {values.gender?.value === 'other' && (
                                            <TextInput
                                                name="genderSpecified"
                                                label={
                                                    t(
                                                        'onboarding.genderSpecified.title',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'onboarding.genderSpecified.placeholder',
                                                    ) as string
                                                }
                                                className="mt-4"
                                                applyFocusStyle={true}
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.age.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="age"
                                        options={ageOptions}
                                        placeholder={
                                            t(
                                                'fields.age.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.position.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="position"
                                        options={positionOptions}
                                        placeholder={
                                            t(
                                                'fields.position.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.industry.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="industry"
                                        options={industryOptions}
                                        placeholder={
                                            t(
                                                'fields.industry.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.function.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="funct"
                                        options={functionOptions}
                                        placeholder={
                                            t(
                                                'fields.function.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                                <div className="flex flex-col lg:flex-row justify-between lg:items-center lg:pt-5 lg:pb-4 pb-2">
                                    <span className="text-xl mb-2">
                                        {t('fields.language.label')}:&nbsp;
                                    </span>{' '}
                                    <SelectInput
                                        name="appLanguage"
                                        options={languageOptions}
                                        placeholder={
                                            t(
                                                'fields.language.placeholder',
                                            ) as string
                                        }
                                        disabled={isSubmitting}
                                        className="lg:w-1/2 border border-[#d2d9da] shadow rounded"
                                    />
                                </div>
                                <Spacer />
                            </div>
                            <div
                                className={classNames(
                                    'lg:my-6 my-4',
                                    'flex flex-col items-center justify-center',
                                )}
                            >
                                <PrimaryButton
                                    className="w-full shadow"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid || isValidating}
                                >
                                    <span>{t('buttons.save')}</span>
                                </PrimaryButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {isUploadLogoModalOpen && (
                <UploadProfileImageModal
                    isOpen={isUploadLogoModalOpen}
                    setIsOpen={openUploadLogoModalOpen}
                    uploadType="coachee"
                    uploadId={coachee?.coachee?._id ?? ''}
                    setPicture={image.setPicture}
                />
            )}
        </div>
    )
})
