import { isSameDay } from 'date-fns'
import Linkify from 'linkify-react'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconChecked from '../../../assets/icons/checkbox-marked.svg'
import UnCheckedIcon from '../../../assets/icons/uncheckedbox.svg'
import { TaskResponse } from '../../../models/response/task/task'
import { classNames, formatDayDate } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

interface CardProps {
    onClick?: () => void
    task: TaskResponse
}

type GroupedTasks = {
    [date: string]: TaskResponse[]
}

const truncate = (input: string, length: number) =>
    input.length > length ? `${input.substring(0, length)}...` : input

const Card: React.FC<CardProps> = observer(({ onClick, task }) => {
    const [isChecked, setIsChecked] = useState(task.over)

    const handleClick = () => {
        setIsChecked(!isChecked)
        onClick && onClick()
    }
    return (
        <div
            className={classNames(
                'flex flex-row  space-x-4 relative',
                'rounded-[12px] bg-white h-auto shadow-lg',
                'p-5 my-2',
            )}
        >
            {isChecked ? (
                <img
                    src={IconChecked}
                    alt={''}
                    className="h-7 w-9 cursor-pointer"
                    onClick={handleClick}
                />
            ) : (
                <img
                    src={UnCheckedIcon}
                    alt={''}
                    className="h-7 w-9 cursor-pointer"
                    onClick={handleClick}
                />
            )}

            <Linkify options={{ target: 'blank' }}>
                <span
                    className={classNames(
                        'text-[19px]',
                        'overflow-hidden whitespace-wrap overflow-ellipsis  w-[500px]',
                    )}
                    title={task.label}
                >
                    {truncate(task.label, 150)}
                </span>
            </Linkify>
        </div>
    )
})

export const TaskList = observer(() => {
    const { task, coachee } = useStores()
    const { t } = useTranslation()
    const { tasks } = task

    const formatDate = (date: string) => {
        const d = new Date(date)
        const year = d.getFullYear()
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    }

    const tasksByDate = tasks.reduce((groupedTasks: GroupedTasks, task) => {
        const date = formatDate(task.createdAt)
        if (!groupedTasks[date]) {
            groupedTasks[date] = []
        }
        groupedTasks[date].push(task)
        return groupedTasks
    }, {})

    const sortedDates = Object.keys(tasksByDate).sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    )

    const handleSubmit = useCallback(
        (item: TaskResponse) => {
            if (coachee.coachee?._id) {
                const data = {
                    over: !item.over,
                }
                task.updateTask(item._id, data).subscribe({
                    next(response) {
                        if (response.ok) {
                            task.getCoacheeTasks(
                                coachee.coachee?._id ?? '',
                            ).subscribe()
                        }
                    },
                })
            }
        },
        [coachee.coachee?._id, task],
    )

    return (
        <div className="rounded-lg bg-[#fafafa] w-full h-screen py-2">
            <div className="px-8 pb-4">
                {sortedDates.map((date) => {
                    const dateObject = new Date(date)
                    const formattedDate = isSameDay(dateObject, new Date())
                        ? `${t('task.today')} - ${formatDayDate(dateObject)}`
                        : formatDayDate(dateObject)
                    return (
                        <div key={date} className="mt-6">
                            <span className="text-[22px]">{formattedDate}</span>
                            {tasksByDate[date].map((task) => (
                                <Card
                                    key={task._id}
                                    task={task}
                                    onClick={() => handleSubmit(task)}
                                />
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
})
