import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import validationError from '../../../assets/icons/error-validation.svg'
import validationRight from '../../../assets/icons/right-validation.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { PasswordInput } from '../../../components/inputs/password-input'
import {
    FormNotification,
    FormValidationMessage,
} from '../../../components/layout'
import { PasswordInitialResetModel } from '../../../models/request/auth/password-initial-reset'
import { NotificationType, RouteLink } from '../../../utils/constants'
import { history } from '../../../utils/misc/history'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { AuthPageWrapper } from '../components/auth-page-wrapper'

export const ResetInitialPasswordPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string>()
    const [passwordValidationStatus, setPasswordValidationStatus] = useState({
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
    })

    const validatePassword = (password: string) => {
        setPasswordValidationStatus({
            hasUpperCase: /[A-Z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            hasMinLength: password.length >= 10,
        })
    }

    const ValidationComponent = ({
        validationStatus,
        validationMessage,
    }: {
        validationStatus: boolean
        validationMessage: string
    }) => (
        <div className="flex flex-row space-x-4">
            <img src={validationStatus ? validationRight : validationError} />
            <span
                className={classNames(
                    'text-[17px]',
                    validationStatus ? 'text-primary' : 'text-[#ff0000]',
                )}
            >
                {validationMessage}
            </span>
        </div>
    )

    const handleErrorNotification = useCallback(
        (code: number) => {
            switch (code) {
                case 400:
                    setError(t('ResetPassword.noToken'))
                    break
                case 429:
                    setError(t('messages.tooManyRequests'))
                    break
                case 403:
                    setError(t('ResetPassword.invalidCurrentPassword'))
                    break
                default:
                    setError(t('ApiError.title'))
            }
        },
        [t],
    )

    const onSubmit = useCallback(
        (
            values: PasswordInitialResetModel,
            helpers: FormikHelpers<PasswordInitialResetModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.initialPasswordReset(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setSuccess(true)

                        if (auth.authResponse?.awaiting2fa) {
                            history.replace({ pathname: RouteLink.TWO_FA })
                        } else {
                            history.replace({ pathname: RouteLink.DASHBOARD })
                        }
                    }

                    handleErrorNotification(response.status)
                },
                complete() {
                    helpers.setSubmitting(false)
                    helpers.resetForm()
                },
            })
        },
        [auth, handleErrorNotification],
    )

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                validateOnChange={true}
                initialValues={new PasswordInitialResetModel()}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    isValidating,
                    values,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col"
                        data-testid="form"
                    >
                        <header className="mb-10 flex flex-col justify-center items-center">
                            <h2 className="text-[2rem]" data-testid="title">
                                {t('ResetPassword.title')}
                            </h2>
                        </header>
                        {error && (
                            <div className="mt-[10px] mb-3">
                                <FormValidationMessage message={error} />
                            </div>
                        )}

                        <main className="space-y-3 mb-6 z-5">
                            <div data-testid="inputPassword">
                                <PasswordInput
                                    name="currentPassword"
                                    placeholder={
                                        t(
                                            'fields.currentPassword.placeholder',
                                        ) as string
                                    }
                                    label={
                                        t(
                                            'fields.currentPassword.label',
                                        ) as string
                                    }
                                    disabled={isSubmitting || success}
                                />
                            </div>
                            <div data-testid="inputPassword">
                                <PasswordInput
                                    name="password"
                                    placeholder={
                                        t(
                                            'fields.password.placeholder',
                                        ) as string
                                    }
                                    label={t('fields.password.label') as string}
                                    disabled={isSubmitting || success}
                                    validatePassword={validatePassword}
                                    disableValidationMessage={true}
                                    className="mb-4"
                                />
                                {values.password && (
                                    <>
                                        <ValidationComponent
                                            validationStatus={
                                                passwordValidationStatus.hasUpperCase
                                            }
                                            validationMessage={t(
                                                'validation.upperCase',
                                            )}
                                        />
                                        <ValidationComponent
                                            validationStatus={
                                                passwordValidationStatus.hasNumber
                                            }
                                            validationMessage={t(
                                                'validation.containsNumber',
                                            )}
                                        />
                                        <ValidationComponent
                                            validationStatus={
                                                passwordValidationStatus.hasSpecialChar
                                            }
                                            validationMessage={t(
                                                'validation.specialCharacter',
                                            )}
                                        />
                                        <ValidationComponent
                                            validationStatus={
                                                passwordValidationStatus.hasMinLength
                                            }
                                            validationMessage={t(
                                                'validation.minimumLength',
                                            )}
                                        />
                                    </>
                                )}
                            </div>
                            <div
                                className="pt-[10px]"
                                data-testid="confirmPassword"
                            >
                                <PasswordInput
                                    name="confirmPassword"
                                    placeholder={
                                        t(
                                            'fields.verifyPassword.placeholder',
                                        ) as string
                                    }
                                    label={
                                        t(
                                            'fields.verifyPassword.label',
                                        ) as string
                                    }
                                    disabled={isSubmitting || success}
                                />
                            </div>
                            {success && (
                                <FormNotification
                                    type={NotificationType.INFO}
                                    message={t('messages.passwordReset')}
                                />
                            )}
                        </main>
                        <footer>
                            {!success && (
                                <div data-testid="confirmPasswordButton">
                                    <PrimaryButton
                                        className="w-full"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!isValid || isValidating}
                                    >
                                        <span>{t('buttons.continue')}</span>
                                    </PrimaryButton>
                                </div>
                            )}
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})
export default ResetInitialPasswordPage
