import { IsBoolean, IsEmail, IsNotEmpty, IsString } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class InviteGuestModel implements BaseModel {
    constructor() {}

    @IsString()
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public name: string = ''

    @IsEmail(undefined, { message: t('validation.mustBeSet') as string })
    public email: string = ''

    @IsBoolean()
    public canGuest: boolean = false

    @IsBoolean()
    public canCoach: boolean = false

    @IsBoolean()
    public showGuestMessage: boolean = false

    @IsBoolean()
    public showCoachMessage: boolean = false

    @IsString()
    public showGuestActive: string = ''

    @IsString()
    public showCoachActive: string = ''

    public getRequestBody() {
        return {
            participants: [
                {
                    name: this.name,
                    email: this.email,
                },
            ],
        }
    }
}
