import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { GoalsList } from './goalsList'

export const GoalTabs: React.FC = observer(() => {
    const { t } = useTranslation()

    const listOfTabs: Record<string, string>[] = [
        {
            name: t('goals.tabs.all'),
        },
        {
            name: t('goals.tabs.active'),
        },
        {
            name: t('goals.tabs.completed'),
        },
    ]

    return (
        <Tab.Group>
            <Tab.List className="flex justify-between px-8">
                {listOfTabs.map((tab, index) => {
                    return (
                        <Tab key={index} as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={classNames(
                                        'w-[33.3%] h-[55px] pt-3',
                                        'outline-none ',
                                        selected
                                            ? 'text-primary bg-white'
                                            : 'bg-white text-[#6b6a72]',
                                    )}
                                >
                                    {tab.name}
                                    <div
                                        className={classNames(
                                            'mt-3 h-[3px]',
                                            selected &&
                                                'bg-primary relative z-10',
                                        )}
                                    ></div>
                                </button>
                            )}
                        </Tab>
                    )
                })}
            </Tab.List>
            <Tab.Panels className="bg-[#fafafa] w-full h-screen">
                <Tab.Panel>
                    <GoalsList tab="all" />
                </Tab.Panel>
                <Tab.Panel>
                    <GoalsList tab="active" />
                </Tab.Panel>
                <Tab.Panel>
                    <GoalsList tab="completed" />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    )
})
