import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react'
import React from 'react'
import { useStores } from '../../../utils/stores'
import { PopupNotification } from './popup-notification'

export const NotificationArea: React.FC = observer(() => {
    const { notifications } = useStores()

    return (
        <div className="absolute top-[4%] left-[35%] p-4 space-y-4 z-[60]">
            <AnimatePresence>
                {notifications.notifications.map(
                    ({ id, type, message, isUrgent }) => (
                        <PopupNotification
                            key={id}
                            type={type}
                            message={message}
                            isUrgent={isUrgent}
                        />
                    ),
                )}
            </AnimatePresence>
        </div>
    )
})
