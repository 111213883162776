import { ReactElement, useContext, useEffect, useState } from 'react'
import SamaApi from '../../services/SamaApi'
import { MachineContext } from '../../stores/bookingMachine'
import { ParticipantDetail } from '../../types/SamaApi'

export default function ProfilePicture({
    identityId,
}: {
    identityId: string
}): ReactElement {
    const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null)

    const [state] = useContext(MachineContext)
    const {
        participantDetails,
    }: {
        participantDetails: ParticipantDetail[]
    } = state.context

    useEffect(() => {
        const participant = participantDetails
            .filter((detail) => detail.twilioParticipantId === identityId)
            .pop()

        if (
            participant &&
            participant.twilioParticipantId === identityId &&
            participant.profileImgId
        ) {
            SamaApi.getImage(
                participant.type,
                participant.twilioParticipantId,
            ).then((imageUrl) => {
                setProfileImageUrl(imageUrl)
            })
        }
    }, [participantDetails, identityId])

    return (
        <>
            {profileImageUrl && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        alt="Profile picture"
                        style={{
                            margin: 'auto',
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        src={profileImageUrl}
                    />
                </div>
            )}
        </>
    )
}
