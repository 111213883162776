import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import IconMicrosoft from '../../../assets/icons/microsoft.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { TextInput } from '../../../components/inputs/text-input'
import { RequestMagicLinkModel } from '../../../models/request'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { AuthPageWrapper } from '../components/auth-page-wrapper'
import { CheckEmailModal } from '../models'

export const LogInPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [isCheckEmailOpen, setIsCheckEmailOpen] = useState(false)

    const onSubmit = useCallback(
        (
            values: RequestMagicLinkModel,
            helpers: FormikHelpers<RequestMagicLinkModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.requestMagicLink(values).subscribe({
                complete() {
                    helpers.setSubmitting(false)
                    setIsCheckEmailOpen(true)
                },
            })
        },
        [auth],
    )

    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email)
    }

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                initialValues={new RequestMagicLinkModel()}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    isValidating,
                    values,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col"
                        data-testid="form"
                    >
                        <header className="mb-10 flex flex-col justify-center items-center text-center">
                            <h2 className="text-[2rem]">{t('titles.logIn')}</h2>

                            <span className="text-xl">
                                {t('subTitles.logIn')}
                            </span>
                        </header>
                        <main className="space-y-3 mb-6">
                            <TextInput
                                name="email"
                                label={t('fields.email.label') as string}
                                placeholder={
                                    t('fields.email.placeholder') as string
                                }
                                disabled={isSubmitting}
                            />
                            {isCheckEmailOpen && (
                                <CheckEmailModal
                                    isOpen={isCheckEmailOpen}
                                    setIsOpen={setIsCheckEmailOpen}
                                />
                            )}
                        </main>
                        <footer>
                            <div>
                                <PrimaryButton
                                    className="w-full"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={
                                        !isValid ||
                                        isValidating ||
                                        !isValidEmail(values.email)
                                    }
                                >
                                    <span>{t('buttons.continue')}</span>
                                </PrimaryButton>

                                {/* <div className="flex justify-center items-center">
                                    <div className="mx-[0.6rem] my-[1.6rem] uppercase text-sm">
                                        {t('or')}
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <PrimaryButton
                                        className="btn-microsoft rounded-none border border-[#cecdd0] border-solid"
                                        type="button"
                                    >
                                        <div className="flex justify-center items-center">
                                            <img
                                                src={IconMicrosoft}
                                                className="mr-4"
                                            />
                                            <span>
                                                {t('buttons.microsoft')}
                                            </span>
                                        </div>
                                    </PrimaryButton>
                                </div> */}

                                {/* <div className="flex justify-center items-center">
                                    <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
                                    <div className="mx-[0.6rem] my-[1.6rem]">
                                        {t('or')}
                                    </div>
                                    <div className="flex-grow h-[1px] bg-[#d9d9d9]"></div>
                                </div>

                                <PrimaryButton
                                    className="w-full btn-blue"
                                    type="submit"
                                >
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={IconOkta}
                                            alt="okta"
                                            className="mr-4"
                                        />
                                        <span>{t('buttons.okta')}</span>
                                    </div>
                                </PrimaryButton> */}
                            </div>
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})

export default LogInPage
