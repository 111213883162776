import { motion } from 'framer-motion'
import React from 'react'
import IconCircle from '../../assets/icons/check-circle-open.svg'
import IconExclamation from '../../assets/icons/exclamation-circle.svg'
import { NotificationType } from '../../utils/constants'
import { classNames } from '../../utils/misc'

interface Props {
    type: NotificationType
    message?: string
    messageComponent?: React.ReactNode
    className?: string
    onClick?: () => void
}

export const FormNotification: React.FC<Props> = ({
    type,
    message,
    messageComponent,
    className,
    onClick,
}) => {
    const iconSrc =
        type === NotificationType.INFO ? IconCircle : IconExclamation

    return (
        <motion.div
            data-testid="notification"
            className={classNames(
                'rounded-full',
                'flex items-center',
                'text-xs',
                'p-2 space-x-2',
                'bg-[#d1e5e94d] text-accent-2',
                className,
                type === NotificationType.ERROR &&
                    'text-[#ef5658] bg-[#ffefef]',
            )}
        >
            <img src={iconSrc} alt="Icon" className="h-4 w-4" />
            <span onClick={onClick}>
                {message ? message : messageComponent}
            </span>
        </motion.div>
    )
}
