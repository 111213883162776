import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { AddAgeRangeModel } from '../../../models/request/onboarding'
import { Stage } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { QuestionForm } from '../forms'

interface Props {
    currentStage: Stage
}

export const AgeRangeStage: React.FC<Props> = observer(({ currentStage }) => {
    const { t } = useTranslation()
    const { statics, coachee } = useStores()
    const [subscriptions] = useState<Subscription[]>([])

    useEffect(() => {
        if (
            coachee.onboardingQuestions &&
            !coachee.onboardingQuestions.ageRange.enabled
        ) {
            coachee.setOnboardingStage(currentStage + 1)
        }
    }, [coachee, coachee.onboardingQuestions, currentStage])

    const setAgeRange = useCallback(
        (
            values: AddAgeRangeModel,
            helpers: FormikHelpers<AddAgeRangeModel>,
        ) => {
            helpers.setSubmitting(true)

            cancelSubscriptions(subscriptions)

            const subscription = coachee.updateCoachee(values).subscribe({
                next(response) {
                    if (response.ok && coachee.coachee) {
                        coachee.setOnboardingStage(Stage.INDUSTRY)
                        const newCoachee = { ...coachee.coachee }
                        newCoachee.user.ageRange = values.ageRange
                        coachee.setCoachee(newCoachee)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [coachee, subscriptions],
    )

    return (
        <QuestionForm
            title={t('onboarding.ageRange.title')}
            currentNumber={currentStage}
            totalNumber={
                coachee.onboardingOptions?.numberOfOnboardingQuestions ?? 10
            }
            questions={statics.ageRanges}
            name="ageRange"
            model={new AddAgeRangeModel(coachee.coachee?.user.ageRange)}
            onSubmit={setAgeRange}
            isOnboarding={true}
            onPrevious={() =>
                coachee.setOnboardingStage(coachee.onboardingStage.stage - 1)
            }
        />
    )
})
