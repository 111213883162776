import classNames from 'classnames'

interface ProgressBarProps {
    progress: number
    containerClassNames?: string
    barClassNames?: string
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    progress,
    containerClassNames,
    barClassNames,
}) => {
    return (
        <div
            className={classNames(
                containerClassNames,
                'mt-[15px] w-[70%] h-[12px] bg-[#f3f4f4] rounded-lg overflow-hidden',
            )}
        >
            <div
                className={classNames(
                    barClassNames,
                    'h-[12px] bg-[#002b38] rounded-lg',
                )}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    )
}
