import { ReactElement, useContext } from 'react'

import signalOff from '../../assets/signal/signal-no-connection.svg'
import signalOne from '../../assets/signal/signal-1-bar.svg'
import signalTwo from '../../assets/signal/signal-2-bars.svg'
import signalThree from '../../assets/signal/signal-3-bars.svg'
import signalFour from '../../assets/signal/signal-4-bars.svg'
import signalFive from '../../assets/signal/signal-4-bars.svg'
import { NetworkQuality } from '../../types/SamaApi'
import { MachineContext } from '../../stores/bookingMachine'

const networkLevelIndicator = [
    signalOff,
    signalOne,
    signalTwo,
    signalThree,
    signalFour,
    signalFive,
]

export default function Session({
    identityId,
}: {
    identityId: string
}): ReactElement {
    const [state] = useContext(MachineContext)
    const {
        networkQuality,
    }: {
        networkQuality: NetworkQuality[]
    } = state.context

    return (
        <>
            {networkQuality.map(({ _id, value }, key: number) => {
                if (_id === identityId)
                    return (
                        <div key={key}>
                            <img
                                alt="Signal strength"
                                style={{
                                    transform: 'rotateY(180deg)',
                                    position: 'absolute',
                                    width: '40px',
                                    zIndex: 2,
                                }}
                                src={networkLevelIndicator[value].toString()}
                            />
                        </div>
                    )
            })}
        </>
    )
}
