import { IsNotEmpty, IsOptional, Max, Min } from 'class-validator'
import { t } from 'i18next'
import { BaseModel } from '../base.model'

export class RateCoachModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    @Min(1)
    @Max(5)
    public rate: number = 0

    @IsOptional()
    public feedback: string = ''

    constructor() {}
}
