import { FormikErrors, FormikTouched } from 'formik'
import { HTMLMotionProps } from 'framer-motion'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { AddPhoneModel } from '../../models/request'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    label?: string
    small?: boolean
    handleChange: (e: ChangeEvent<any>) => void
    touched: FormikTouched<AddPhoneModel>
    error: FormikErrors<AddPhoneModel>
    handleBlur: any
    setFieldValue: any
    value?: string
    countryCode?: string
    bgColor?: string
}

export const PhoneCodeInput: React.FC<Props> = ({
    name,
    label,
    small,
    className,
    handleChange,
    placeholder,
    touched,
    error,
    handleBlur,
    setFieldValue,
    value,
    countryCode,
    bgColor,
}) => {
    const { t } = useTranslation()

    const priority = countryCode === 'CA' ? { ca: 0, us: 1 } : {}

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}

            <PhoneInput
                country={'gb'}
                specialLabel={''}
                enableSearch={true}
                priority={priority}
                preferredCountries={['ca', 'gb', 'us']}
                inputProps={{
                    required: true,
                    name: name,
                }}
                inputStyle={{
                    backgroundColor: bgColor ? bgColor : '#f6f7f9',
                    borderRadius: '0.25rem',
                    width: '100%',
                    height: small ? '2.7rem' : '3rem',
                    border: 'none',
                }}
                onChange={(value, country, e, formattedValue) => {
                    setFieldValue(name, formattedValue)
                    const countryData = country as CountryData
                    setFieldValue(
                        'phoneCountry',
                        countryData?.countryCode.toUpperCase() ?? 'GB',
                    )
                    handleChange(e)
                }}
                onBlur={(e) => handleBlur(e)}
                placeholder={placeholder}
                autoFormat={false}
                value={value ?? ''}
            />

            {touched.phone && error.phone && (
                <div className="mt-[10px]">
                    <FormValidationMessage
                        message={error.phone && t(error.phone[0])}
                    />
                </div>
            )}
        </div>
    )
}
