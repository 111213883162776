import { useField } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DropDownIcon from '../../assets/icons/dropdown-icon.svg'
import IconChecked from '../../assets/icons/filled.svg'
import { classNames } from '../../utils/misc'
import { FormValidationMessage } from '../layout/form-validation-message'
import IconUnChecked from '/src/assets/icons/CheckInsUnfilled.svg'

interface Props {
    name: string
    label: string
    labelKey: string
    expand?: boolean
    description?: string
    icon?: string
    iconClassNames?: string
    iconActiveClassNames?: string
    maxSelectedOptions?: number
    setStatus?: any
    dropDownIcon?: boolean
}

export const OptionInput: React.FC<Props> = ({
    name,
    label,
    labelKey,
    expand,
    description,
    icon,
    iconClassNames,
    iconActiveClassNames,
    maxSelectedOptions,
    setStatus,
    dropDownIcon = false,
}) => {
    const { t } = useTranslation()
    const [field, meta, helpers] = useField(name)

    useEffect(() => {
        if (field.value) {
            setStatus && setStatus('updated')
        }
    }, [field.value, setStatus])

    const onSelect = (value: string) => {
        if (maxSelectedOptions) {
            if (
                field.value.length < maxSelectedOptions ||
                field.value.includes(value)
            ) {
                updateValue(value)
            }
        } else {
            updateValue(value)
        }
        setStatus && setStatus('updated')
    }

    const updateValue = (value: string) => {
        if (Array.isArray(field.value)) {
            if (field.value.includes(value)) {
                field.value.splice(field.value.indexOf(value), 1)
            } else {
                field.value.push(value)
            }
        } else {
            field.value = value
        }
        helpers.setValue(field.value)
    }

    const isSelected = useCallback(
        (value: string) => {
            if (Array.isArray(field.value)) {
                if (field.value.includes(value)) {
                    return true
                }
            } else if (String(field.value) === value) {
                return true
            }
            return false
        },
        [field.value, field],
    )

    return (
        <div>
            <label
                htmlFor={name}
                className={classNames(
                    'block text-[17px]',
                    isSelected(labelKey) && 'text-white',
                    'flex flex-col',
                    isSelected(labelKey) ? 'bg-[#ff7558]' : 'bg-[#f3f4f4]',
                    'p-4 mt-3 rounded-[0.5rem]',
                )}
                onClick={() => onSelect(labelKey)}
            >
                <div className="flex justify-between">
                    <div className="flex justify-between">
                        {icon && (
                            <img
                                className={classNames(
                                    isSelected(labelKey)
                                        ? iconActiveClassNames
                                        : iconClassNames,

                                    'h-8 w-8 mr-4',
                                )}
                                src={icon}
                                alt={t('alt.icon')}
                            />
                        )}
                        <span>{label}</span>
                    </div>

                    <input
                        type="radio"
                        checked={isSelected(labelKey)}
                        readOnly
                    />

                    {isSelected(labelKey) ? (
                        <img
                            src={IconChecked}
                            alt={t('alt.checked')}
                            className="w-6 h-6"
                        />
                    ) : (
                        <>
                            {dropDownIcon ? (
                                <img src={DropDownIcon} className="w-6 h-6" />
                            ) : (
                                <img src={IconUnChecked} className="w-6 h-6" />
                            )}
                        </>
                    )}
                </div>

                {expand && isSelected(labelKey) && description && (
                    <span className="pt-4">{description}</span>
                )}
            </label>
            {meta.touched && meta.error && (
                <div className="mt-[10px] error">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
