import { RouteLink } from './route-links'

export const checklistAction = (action: string) => {
    if (action === 'matchWithCoach') {
        return RouteLink.ONBOARDING
    }

    if (action === 'viewCoachChat') {
        return RouteLink.CHAT
    }

    if (action === 'bookSession') {
        return RouteLink.NEW_BOOKING
    }

    if (action === 'addGoal') {
        return RouteLink.GOALS
    }

    return RouteLink.DASHBOARD
}
