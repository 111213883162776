import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import OnboardingIcon from '../../../assets/icons/onboarding.svg'
import { OnboardingButtons } from '../../../components/buttons/onboarding-button'
import { Stage } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'

interface Props {
    currentStage: Stage
}

export const InitialStage: React.FC<Props> = ({ currentStage }) => {
    const { coachee } = useStores()
    const { t } = useTranslation()

    const onNext = useCallback(() => {
        coachee.setOnboardingStage(currentStage + 1)
    }, [coachee, currentStage])

    return (
        <div>
            <div className="flex flex-col justify-center items-center px-5 md:p-0">
                <img
                    src={OnboardingIcon}
                    alt={t('alt.onboarding')}
                    className="w-[15rem]"
                />
                <span className="text-[2.7rem] text-center">
                    {t('onboarding.initial.title')}
                </span>
                <span className="text-[2.5rem] font-semibold mt-[-0.9rem] text-center">
                    {t('onboarding.initial.title2')}
                </span>

                <span className="text-[1.4rem] w-[25rem] mt-4 text-center">
                    {t('onboarding.initial.subTitle')}
                </span>
            </div>

            <div className="h-20"></div>
            <OnboardingButtons onNext={onNext} />
        </div>
    )
}
