import { FormikHelpers } from 'formik'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { TwoFaModel } from '../../../models/request'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { AuthPageWrapper } from '../components/auth-page-wrapper'
import { VerifyPhoneForm } from '../signup/forms'

export const TwoFaPage: React.FC = observer(() => {
    const { auth } = useStores()
    const navigate = useNavigate()

    const onSubmit = useCallback(
        (values: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => {
            helpers.setSubmitting(true)

            auth.validate2fa(values).subscribe({
                next(response) {
                    if (response.ok && !auth.authResponse?.awaiting2fa) {
                        navigate(RouteLink.DASHBOARD, {
                            replace: true,
                        })
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [auth, navigate],
    )

    return (
        <AuthPageWrapper>
            <VerifyPhoneForm
                title={t('titles.twoFA') as string}
                subTitle={auth.authResponse?.phoneNumber2fa}
                onSubmit={onSubmit}
            />
        </AuthPageWrapper>
    )
})

export default TwoFaPage
