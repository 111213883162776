import { ReactElement, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import { useMachine } from '@xstate/react'

import useQuery from '../utils/useQuery'
import parseToken from '../utils/parseToken'
import machine, { MachineContext } from '../stores/bookingMachine'
import machineConfig, { State } from '../stores/bookingMachine/config'
import * as bookingServices from '../services/booking'
import UserMediaPrompt from '../components/booking/UserMediaPrompt'
import NativeAppPrompt from '../components/booking/NativeAppPrompt'
import RecentBrowserPrompt from '../components/booking/RecentBrowserPrompt'
import UserMediaError from '../components/booking/UserMediaError'
import Session from '../components/booking/Session'
import ApiError from '../components/booking/ApiError'
import Loader from '../components/booking/Loader'
import BookingErrorEarly from '../components/booking/BookingErrorEarly'
import BookingErrorLate from '../components/booking/BookingErrorLate'
import samaApi from '../services/SamaApi'
import SamaLogo from '../components/SamaLogo'

const {
    getBookingAndMe,
    validateDevice,
    handleRoom,
    connectSession,
    checkUserMedia,
} = bookingServices

function Booking(): ReactElement {
    const params: { bookingId: string } = useParams() as {
        bookingId: string
    }
    const tempToken = useQuery().get('tempToken')
    const token = tempToken ? parseToken(tempToken) : null
    const { bookingId } = token || params
    const inDebugMode = useQuery().get('debug') ? true : false

    const [state, send] = useMachine(machine, {
        context: {
            ...machineConfig.context,
            token,
            inDebugMode,
            bookingId,
            localRef: useRef(null),
            remoteRef: useRef(null),
            participantRefs: [
                { ref: useRef(null) },
                { ref: useRef(null) },
                { ref: useRef(null) },
                { ref: useRef(null) },
                { ref: useRef(null) },
            ],
        },
        actions: bookingServices,
        services: {
            getBookingAndMe,
            validateDevice,
            handleRoom,
            connectSession,
            checkUserMedia,
        },
    })
    const stateMatches = state.matches
    const isConnectingOrConnected =
        stateMatches(State.connectingSession) ||
        stateMatches(State.sessionConnected)

    // This should not happen on each render, but if we put
    // this in a useEffect, it won't happen before getBookingAndMe
    // is executed by the machine. I also tried putting this code
    // in useEffect, adding an initial "idle" state and transitionning
    // to "loadingBooking" state only after the following code had
    // executed, but this resulted in an infinite loop of useEffect
    // execution

    tempToken &&
        samaApi.extend({
            headers: {
                'x-temp-authorization': `Bearer ${tempToken}`,
                service: 'coacheeWeb',
            },
        })

    inDebugMode &&
        samaApi.extend({
            headers: {
                'x-temp-authorization': `Bearer RANDOM`,
            },
        })
    return (
        <div id="page">
            <SamaLogo />
            {stateMatches(State.loadingBooking) && <Loader />}
            <TransitionGroup>
                <ApiError when={stateMatches(State.apiErrored)} />
                <BookingErrorEarly
                    when={stateMatches(State.bookingErroredEarly)}
                />
                <BookingErrorLate
                    when={stateMatches(State.bookingErroredLate)}
                />
                <NativeAppPrompt
                    when={stateMatches(State.promptingNativeApp)}
                    send={send as any}
                />
                <RecentBrowserPrompt
                    when={stateMatches(State.promptingRecentBrowser)}
                    send={send as any}
                />
                <UserMediaPrompt
                    when={stateMatches(State.promptingUserMedia)}
                />
                <UserMediaError when={stateMatches(State.userMediaErrored)} />
            </TransitionGroup>
            {isConnectingOrConnected && (
                <MachineContext.Provider value={[state as any, send as any]}>
                    <Session />
                </MachineContext.Provider>
            )}
        </div>
    )
}

export default Booking
