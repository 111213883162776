import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyGoal from '../../../assets/icons/empty-note.svg'
import { useStores } from '../../../utils/stores'
import { GoalCard } from './goalCard'

interface Props {
    tab: string
}

export const GoalsList: React.FC<Props> = observer(({ tab }) => {
    const { coachee } = useStores()
    const { t } = useTranslation()

    const goals = useMemo(() => {
        if (tab === 'active') {
            return coachee.goals.filter((goal) => !goal.completedAt)
        }
        if (tab === 'completed') {
            return coachee.goals.filter((goal) => goal.completedAt)
        }

        return coachee.goals
    }, [coachee.goals, tab])

    return (
        <div className="pt-10">
            {goals.length !== 0 ? (
                goals.map((goal, index) => <GoalCard key={index} goal={goal} />)
            ) : (
                <div className="flex flex-col items-center justify-center pt-10">
                    <img src={EmptyGoal} className="w-[6rem] h-[6rem] m-auto" />
                    <span className="text-[22px] mt-8">
                        {tab === 'active'
                            ? t('goals.noActiveGoals')
                            : tab === 'completed'
                            ? t('goals.noCompleteGoals')
                            : t('goals.noGoals')}
                    </span>
                </div>
            )}
            <div className="h-40"></div>
        </div>
    )
})
