import classNames from 'classnames'
import React, { useState } from 'react'

interface SidebarModalLinkProps {
    title: string
    iconPath: string
    iconPathInactive: string
    isActive?: boolean
    onClick?: () => void
}

const SidebarModalLink: React.FC<SidebarModalLinkProps> = ({
    title,
    iconPath,
    iconPathInactive,
    isActive,
    onClick,
}) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div
            onClick={onClick}
            className={classNames(
                'border-b-[1px] border-none relative flex items-center px-4 mx-4 my-[6px]',
                'font-normal w-[273px] h-[50px] cursor-pointer',
                'text-primary',
                'hover:rounded-[8px] hover:ml-4  hover:bg-[#f9fbfc]',
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isActive && (
                <div
                    className={classNames(
                        'rounded-[8px] bg-[#f9fbfc]',
                        'absolute inset-0 ',
                    )}
                ></div>
            )}
            {iconPathInactive && (
                <div className={classNames('p-1 rounded-md !w-7 z-10')}>
                    <img
                        src={
                            isActive || isHovered ? iconPath : iconPathInactive
                        }
                        className={classNames('!w-6 !h-6')}
                        alt="Icon for link"
                    />
                </div>
            )}

            <div
                className={classNames(
                    'relative z-10 ml-2 text-[19px] text-[#002b38] text-decoration-none',
                )}
            >
                {title}
            </div>
        </div>
    )
}

export default SidebarModalLink
