import { LoginCallback } from '@okta/okta-react'
import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { RouteLink } from '../../../utils/constants'
import { history } from '../../../utils/misc/history'

const OktaLoginCallbackErrors = ({ error }: { error: Error }) => {
    useEffect(() => {
        if (
            error.message ===
            'The client specified not to prompt, but the user is not logged in.'
        ) {
            history.replace({
                pathname: RouteLink.OKTA_LOGIN + '?error=not_logged_in',
            })
        }
        Sentry.captureException(error)
    })

    return <>{error.message}</>
}

export const OktaLoginCallback = () => {
    return <LoginCallback errorComponent={OktaLoginCallbackErrors} />
}
