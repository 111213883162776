import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals'
import { Line } from './coach.modal'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode
    onSubmit: () => void
    disable: boolean
}

export const RateModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, children, onSubmit, disable }) => {
        const { t } = useTranslation()

        return (
            <div>
                {isOpen && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={() => setIsOpen(false)}
                        className="max-w-[32rem] py-2 px-5"
                    >
                        <header>
                            <div className=" text-xl font-bold">
                                <span>{t('modals.rateCoach.title')}</span>
                            </div>
                            <Line />
                        </header>
                        <main>{children}</main>
                        <footer className="py-5">
                            <PrimaryButton
                                className="w-full"
                                onClick={onSubmit}
                                disabled={disable}
                            >
                                {t('buttons.rate')}
                            </PrimaryButton>
                        </footer>
                    </CenterModal>
                )}
            </div>
        )
    },
)
