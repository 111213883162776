import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SideModal } from '../../../components/modals/side-modal'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { BookingCard } from './bookingCard'
import EmptyTotalSession from '/src/assets/icons/empty-totalSession.svg'

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const AllBookings: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { booking } = useStores()
        const { bookings } = booking

        useEffect(() => {
            const subscription = booking.getCoacheeBookings().subscribe()

            return () => {
                subscription.unsubscribe()
            }
        }, [booking])

        return (
            <SideModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[28rem]"
                title="all_bookings"
            >
                <div className="rounded-lg bg-white max-w-[28rem] w-full">
                    <div className="flex flex-col px-8 py-7 sticky top-0 z-20 bg-white">
                        <span className="text-[38px]">
                            {t('dashboard.bookings')}
                        </span>
                    </div>
                    <div className="rounded-lg bg-[#fafafa] w-full h-screen py-6">
                        <div className="px-8 pb-4">
                            {bookings.length === 0 ? (
                                <div className="rounded-lg bg-[#fafafa] w-full h-screen py-10">
                                    <div className="flex flex-col items-center justify-center mt-10">
                                        <img
                                            src={EmptyTotalSession}
                                            className="w-[6rem] h-[6rem] m-auto"
                                        />
                                        <span className="text-[22px] mt-4">
                                            {t(
                                                'dashboard.session.noUpcomingSession',
                                            )}
                                        </span>
                                        <div>
                                            <Trans
                                                t={t}
                                                i18nKey="leaderboard.noTotalSessionsDesc"
                                            >
                                                <Link
                                                    to={RouteLink.NEW_BOOKING}
                                                    style={{
                                                        textDecoration:
                                                            'underline',
                                                    }}
                                                />
                                            </Trans>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                bookings.map((booking) => (
                                    <BookingCard
                                        key={booking._id}
                                        booking={booking}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </SideModal>
        )
    },
)
