import React, { useCallback, useEffect, useRef, useState } from 'react'
import { classNames } from '../../utils/misc'
import { DashboardPageHeader } from './dashboard-page-header'
import { SideBar } from './side-bar'

interface Props {
    children: React.ReactNode
    backgroundColour?: string
    setIsHorizontalScrollVisible?: any
    pageName?: string
}

export const DashboardPageWrapper: React.FC<Props> = ({
    children,
    backgroundColour,
    setIsHorizontalScrollVisible,
    pageName,
}) => {
    const [hasScrolled, setHasScrolled] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const setHozScrollVisable = useCallback(() => {
        const el = ref?.current
        if (el && setIsHorizontalScrollVisible) {
            setIsHorizontalScrollVisible(
                el.scrollWidth > el.getBoundingClientRect().width,
            )
        }
    }, [setIsHorizontalScrollVisible])

    useEffect(() => {
        setTimeout(() => {
            setHozScrollVisable()
        }, 600)

        setTimeout(() => {
            setHozScrollVisable()
        }, 1500)

        addEventListener('resize', () => {
            setHozScrollVisable()
        })
    }, [ref, setHozScrollVisable])

    const onScroll = useCallback(() => {
        if (ref.current && ref.current.scrollTop > 0) {
            setHasScrolled(true)
        } else if (hasScrolled === true) {
            setHasScrolled(false)
        }
    }, [hasScrolled])

    return (
        <div className="h-screen w-screen flex">
            <SideBar />
            <div
                onScroll={onScroll}
                ref={ref}
                className={classNames(
                    backgroundColour ? backgroundColour : '',
                    'flex-1 flex flex-col overflow-y-auto',
                    'scrollbar-thin scrollbar-thumb-[#c4c4c4] scrollbar-thumb-rounded',
                )}
            >
                <DashboardPageHeader
                    pageName={pageName}
                    hasScrolled={hasScrolled}
                />
                <div className="w-[calc(100vw_-_350px)] h-[1px] flex-grow-0 ml-[10px] mr-[10px] bg-[#d2d9da]"></div>
                <main className="flex-1 pt-20">{children}</main>
            </div>
        </div>
    )
}
