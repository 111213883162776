import { ReactElement, useContext } from 'react'
import { MachineContext } from '../../stores/bookingMachine'
import microphoneOff from '../../assets/icons/microphone-off.svg'
import { TrackStatuses } from '../../types/SamaApi'

export default function MicView({
    identityId,
}: {
    identityId: string
}): ReactElement {
    const [state] = useContext(MachineContext)
    const {
        trackStatuses,
    }: {
        trackStatuses: TrackStatuses[]
    } = state.context

    return (
        <>
            {trackStatuses.map(({ _id, enabled, type }, key: number) => {
                if (_id === identityId && !enabled && type === 'audio')
                    return (
                        <div key={key}>
                            <img
                                alt="Mic off image"
                                style={{
                                    transform: 'rotateY(180deg)',
                                    position: 'absolute',
                                    width: '35px',
                                    marginLeft: '45px',
                                    zIndex: 2,
                                }}
                                src={microphoneOff.toString()}
                            />
                        </div>
                    )
            })}
        </>
    )
}
